import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { track } from '@amplitude/analytics-browser';
import { Alert, Link, Text } from '@nimbus-ds/components';
import { useStoreInfo } from 'commons/hooks';
import { ChargebackWithOrderDataType } from 'commons/types';
import {
  daysUntil,
  earliestDate,
  formatLongDateWithoutYear,
  useTranslationWithPrefix,
} from 'commons/utils';
import ChargebackList from './ChargebackList';

interface PendingChargebackAlertProps {
  chargebacks: ChargebackWithOrderDataType[];
}

export default function PendingChargebackAlert({
  chargebacks,
}: PendingChargebackAlertProps) {
  const { t } = useTranslationWithPrefix('home.chargebackAlerts.pending');
  const { storeInfo } = useStoreInfo();
  const [showAlert, setShowAlert] = useState(true);

  const deadlines = chargebacks.map(
    (chargeback) => new Date(chargeback.deadline),
  );
  const closestDeadline = earliestDate(deadlines);
  const daysUntilDeadline = daysUntil(closestDeadline);
  const DAYS_TO_REMIND = 2;

  useEffect(() => {
    if (showAlert && !!chargebacks.length) {
      track('pago-nube-contracargo-view', { status: 'pending' });
    }
  }, [chargebacks.length, showAlert]);

  const OwnerMail = () => (
    <Link as="a" href={`mailto: ${storeInfo.user?.email}`}>
      {storeInfo.owner?.email}
    </Link>
  );

  return (
    <Alert
      title={
        daysUntilDeadline <= DAYS_TO_REMIND
          ? t('title_reminder', { count: daysUntilDeadline })
          : t('title', { count: chargebacks.length })
      }
      show={showAlert && !!chargebacks.length}
      appearance="warning"
      onRemove={() => setShowAlert(false)}
    >
      <Text>
        <Trans
          t={t}
          i18nKey={
            daysUntilDeadline <= DAYS_TO_REMIND
              ? t('description_reminder', { count: daysUntilDeadline })
              : t('description', { count: chargebacks.length })
          }
          values={{
            deadline: formatLongDateWithoutYear(
              storeInfo,
              closestDeadline.toString(),
            ),
          }}
          components={[
            <ChargebackList
              key="chargeback-list-pending"
              chargebacks={chargebacks}
              status="pending"
            />,
            <OwnerMail key="status-alert-user-email" />,
          ]}
        />
      </Text>
      <Link as="a" href={t('documentationUrl')} target="_blank">
        {t('uploadDocumentation')}
      </Link>
    </Alert>
  );
}
