import React from 'react';
import { Alert } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

function DisableNotification() {
  const { t } = useTranslationWithPrefix(
    'dashboard.components.kycModal.disableNotification',
  );

  return (
    <Alert
      data-testid="nuvempago-disabled-alert"
      appearance="warning"
      title={t('title')}
      onRemove={function noRefCheck() {}}
    >
      {t('description')}
    </Alert>
  );
}

export default DisableNotification;
