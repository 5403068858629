import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from '@nimbus-ds/components';
import { WithdrawStatusType } from 'commons/types';
import { withdrawTagProps } from 'commons/utils';

interface StatusTagInterface {
  statusType: WithdrawStatusType;
  tagKey: string;
}

function StatusTag({ statusType, tagKey }: StatusTagInterface): JSX.Element {
  const { t } = useTranslation();

  return <Tag {...withdrawTagProps(t, statusType, tagKey)} />;
}

export default StatusTag;
