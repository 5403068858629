/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { AxiosError } from 'axios';
import paymentLinkService, {
  PaymentLinkRefundArgs,
} from 'commons/services/paymentLink/paymentLinkService';
import { GetRefundDetailsResponse } from 'commons/services/refundService';
import { ErrorResponse, MoneyObjectInterface } from 'commons/types';
import Refund, { Caller } from './Refund';

interface PaymentLinkDetailParams {
  referenceUUID: string;
}

function PaymentLinkRefund(): JSX.Element {
  const { referenceUUID } = useParams<PaymentLinkDetailParams>();
  const [data, setData] = useState<GetRefundDetailsResponse>();
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    setError(false);
    setSuccess(false);
    setLoading(true);

    paymentLinkService
      .getRefundDetails(referenceUUID)
      .then((data) => {
        setData(data);
        setSuccess(true);
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [referenceUUID]);

  //this function should be passed as a callback function
  const onRefund = useCallback(
    async (amount: MoneyObjectInterface) => {
      try {
        const args: PaymentLinkRefundArgs = {
          referenceUUID,
          amount,
        };
        await paymentLinkService.refund(args);
      } catch (error: unknown) {
        const { response } = error as AxiosError;
        throw response;
      }
    },
    [referenceUUID],
  );

  return (
    <Refund
      {...{
        refundId: referenceUUID,
        caller: Caller.PaymentLinkRefund,
        isError,
        isLoading,
        isSuccess,
        data,
        onRefund,
        confirmationDelaySeconds: 3,
      }}
    />
  );
}

export default PaymentLinkRefund;
