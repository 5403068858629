import React from 'react';
import { Box, Card, Checkbox, Input, Title } from '@nimbus-ds/components';

function RefundCardSkeleton(): JSX.Element {
  return (
    <Card>
      <Card.Header padding="none">
        <Title.Skeleton as="h3" />
      </Card.Header>
      <Box display="flex" gap="4" flexDirection="column">
        <Box display="flex" gap="2" flexDirection="column">
          <Input.Skeleton />
        </Box>
        <Checkbox.Skeleton />
      </Box>
    </Card>
  );
}

export default RefundCardSkeleton;
