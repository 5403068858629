import { Locale } from '../i18n/enums';
import { DomainTranslations } from '../i18n/types';
import esAR from './common.es-AR.json';
import esMX from './common.es-MX.json';
import ptBR from './common.pt-BR.json';

const commonTranslations: DomainTranslations = {
  name: 'common',
  languages: {
    [Locale.ARGENTINA]: esAR,
    [Locale.MEXICO]: esMX,
    [Locale.BRAZIL]: ptBR,
  },
};

export default commonTranslations;
