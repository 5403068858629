import React from 'react';
import { Box, Text } from '@nimbus-ds/components';
import { useStoreInfo } from 'commons/hooks';
import {
  InstallmentsFeeResponsible,
  MoneyObjectInterface,
} from 'commons/types';
import { snakeToTitleCase, useTranslationWithPrefix } from 'commons/utils';
import { getInstallmentsText } from '../getInstallmentsText';

interface InterfaceCardMethod {
  installments?: number;
  installmentsValue?: MoneyObjectInterface;
  card?: {
    brand: string;
    holderName: string;
    lastFourDigits: string;
  };
  installmentsPlan?: string;
  installmentsFeeResponsible?: InstallmentsFeeResponsible;
}

export default function CardMethod({
  card,
  installments,
  installmentsValue,
  installmentsPlan,
  installmentsFeeResponsible,
}: InterfaceCardMethod) {
  const { t } = useTranslationWithPrefix('transactionDetails.paymentMethods');
  const { storeInfo } = useStoreInfo();

  return (
    <Box display="flex" gap="4" flexDirection="column">
      {card ? (
        <Text>
          {`${snakeToTitleCase(card.brand)} ${t('endedIn')} ${
            card.lastFourDigits
          }`}
        </Text>
      ) : null}
      {installments ? (
        <Text>
          {getInstallmentsText(
            t,
            storeInfo,
            installments,
            installmentsValue,
            installmentsPlan,
            installmentsFeeResponsible,
          )}
        </Text>
      ) : null}
    </Box>
  );
}
