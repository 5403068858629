import i18next from 'i18next';
import { getFixedLocaleFromLocale } from '../../commons/utils';
import { safelySetIntoLocalStorage } from '../storage';
import { i18nConfig, Locale } from './enums';

export const buildLocaleFromLanguageCountry = (
  language: string,
  country: string,
): Locale => {
  return getFixedLocaleFromLocale(`${language}-${country}`);
};

const saveLanguage = (defaultLanguage: string, country: string): void => {
  const locale = buildLocaleFromLanguageCountry(defaultLanguage, country);

  safelySetIntoLocalStorage(i18nConfig.STORAGE, locale);

  if (i18next.language !== locale) {
    i18next.changeLanguage(locale);
  }
};

export default saveLanguage;
