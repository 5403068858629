import React from 'react';
import { Icon } from '@nimbus-ds/components';
import { ExclamationTriangleIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { useTranslationWithPrefix } from 'commons/utils';

function EmptyList(): JSX.Element {
  const { t } = useTranslationWithPrefix('dashboard.emptyList');

  return (
    <EmptyMessage
      text={t('description')}
      title={t('title')}
      icon={
        <Icon
          color="neutral-textHigh"
          source={<ExclamationTriangleIcon size="large" />}
        />
      }
      data-testid="Dashboard-EmptyList"
    />
  );
}

export default EmptyList;
