import React, { useEffect, useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import {
  Box,
  Card,
  Icon,
  Link,
  Text,
  Title,
  useToast,
} from '@nimbus-ds/components';
import { CopyIcon, ExternalLinkIcon } from '@nimbus-ds/icons';
import { copyToClipboard } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile, useStoreInfo } from 'commons/hooks';
import { paymentLinkInfo } from 'commons/services/paymentLink/paymentLinkService';
import { useTranslationWithPrefix } from 'commons/utils';
import { formatCurrency } from 'commons/utils';
import AppLayout from 'domains/Brazil/AppLayout/AppLayout';
import MobileTopNavBar from 'domains/Brazil/commons/components/MobileTopNavBar';

export type paymentLinkResult = Omit<paymentLinkInfo, 'paymentSuccessUrl'>;

const fieldResult = (title: string, value: string | number) => {
  return (
    <Box display="grid" gridGap="2">
      {title && (
        <Text color="neutral-textLow" fontWeight="regular">
          {title}
        </Text>
      )}
      <Text color="neutral-textHigh" fontWeight="medium" wordBreak="break-word">
        {value}
      </Text>
    </Box>
  );
};

function FooterButtons(props: Pick<paymentLinkInfo, 'paymentUrl'>) {
  const [toastLinkShow, setToastLinkShow] = useState(true);
  const { addToast } = useToast();
  const { t } = useTranslationWithPrefix('paymentLink.result');

  function copyLink() {
    if (toastLinkShow) {
      track('nuvempago_payment_links_abstract_copy');

      copyToClipboard(nexo, props.paymentUrl);
      addToast({
        type: 'success',
        text: t('linkCopied'),
        duration: 4000,
        id: 'payment-link-copy-toast',
      });
    }
    setToastLinkShow(false);

    setTimeout(() => {
      setToastLinkShow(true);
    }, 4000);
  }

  return (
    <Box display="flex" gap="4">
      <Link
        appearance="primary"
        textDecoration="none"
        fontSize="highlight"
        onClick={copyLink}
      >
        <Icon source={<CopyIcon />} color="currentColor" />
        {t('copyButton')}
      </Link>
      <Link
        as="a"
        target="_blank"
        textDecoration="none"
        fontSize="highlight"
        appearance="primary"
        href={props.paymentUrl}
        onClick={() => track('nuvempago_payment_links_abstract_access_link')}
      >
        <Icon source={<ExternalLinkIcon />} color="currentColor" />
        {t('accessButton')}
      </Link>
    </Box>
  );
}

function ResultMobile(data: paymentLinkResult) {
  const { storeInfo } = useStoreInfo();

  const { t } = useTranslationWithPrefix('paymentLink.result.mobile');

  return (
    <Box justifyContent="center" paddingBottom="4">
      <Box
        boxSizing="content-box"
        padding="4"
        borderStyle="solid"
        borderRadius="2"
        borderWidth="1"
        borderColor="neutral-surfaceHighlight"
      >
        <Box display="grid" gridGap="4">
          <Title as="h3">
            {t('paymentLinkNumber', { id: data.linkNumber })}
          </Title>
          {fieldResult(t('clientNameField.title'), data.customerName)}
          {fieldResult(
            t('amountField.title'),
            ` ${formatCurrency(
              { value: data.amount, currency: 'BRL' },
              storeInfo,
            )}`,
          )}
          {fieldResult(
            t('expirationField.title'),
            t(`expirationOptions.${data.expirationTime}`),
          )}
          {fieldResult(
            t('paymentMethodField.title'),
            t('paymentMethodOptions.creditCard'),
          )}
          {fieldResult(t('descriptionField.title'), data.description)}
          <FooterButtons paymentUrl={data.paymentUrl} />
        </Box>
      </Box>
    </Box>
  );
}

function ResultDesktop(data: paymentLinkResult) {
  const { storeInfo } = useStoreInfo();

  const { t } = useTranslationWithPrefix('paymentLink.result.desktop');

  return (
    <Box
      display="grid"
      gridGap="4"
      gridTemplateRows="auto"
      justifyContent="flex-start"
      alignItems="flex-start"
      gridTemplateColumns="2fr 1fr"
    >
      <Box display="grid" gridGap="4">
        <Box display="block">
          <Card>
            <Card.Header>
              <Title color="primary-textHigh" as="h3">
                {t('cards.payment')}
              </Title>
            </Card.Header>
            <Box display="grid" gridGap="4">
              {fieldResult(
                t('amountField.title'),
                `${formatCurrency(
                  { value: data.amount, currency: 'BRL' },
                  storeInfo,
                )}`,
              )}
              {fieldResult(
                t('expirationField.title'),
                t(`expirationOptions.${data.expirationTime}`),
              )}
              {fieldResult(
                t('paymentMethodOptions.title'),
                t('paymentMethodOptions.creditCard'),
              )}
            </Box>
          </Card>
        </Box>
        <Box display="block">
          <Card>
            <Card.Header>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                gap="1-5"
              >
                <Title color="primary-textHigh" as="h3">
                  {t('cards.description')}
                </Title>
                <Text color="neutral-interactive" fontSize="base">
                  {t('descriptionField.subTitle')}
                </Text>
              </Box>
            </Card.Header>
            {fieldResult('', data.description)}
          </Card>
        </Box>
      </Box>
      <Box display="grid" gridGap="4">
        <Box display="block">
          <Card>
            <Card.Header>
              <Title color="primary-textHigh" as="h3">
                {t('paymentLinkNumber', { id: data.linkNumber })}
              </Title>
            </Card.Header>
            <Box display="grid" gridGap="4">
              <Text color="neutral-textHigh">
                {t('paymentLinkShareNotification')}
              </Text>
              <FooterButtons paymentUrl={data.paymentUrl} />
            </Box>
          </Card>
        </Box>
        <Box display="block">
          <Card>
            <Card.Header>
              <Title color="primary-textHigh" as="h3">
                {t('cards.customer')}
              </Title>
            </Card.Header>
            {fieldResult('', data.customerName)}
          </Card>
        </Box>
      </Box>
    </Box>
  );
}

function PaymentLinkResult(data: paymentLinkResult): JSX.Element {
  const { t } = useTranslationWithPrefix('paymentLink.result');
  const { t: navT } = useTranslationWithPrefix('navigation');
  const isMobile = useIsMobile();
  const { addToast } = useToast();
  const [toastShow, setToastShow] = useState(true);

  useEffect(() => {
    if (toastShow) {
      setTimeout(() => {
        addToast({
          type: 'success',
          text: t('creationSuccess'),
          duration: 4000,
          id: 'payment-link-success-toast',
        });
      }, 1000);
      setToastShow(false);
    }
  }, [addToast, t, toastShow]);

  return (
    <AppLayout>
      <MobileTopNavBar
        paddingTop="none"
        paddingX="none"
        backLinkPath={ROUTES.DASHBOARD}
        backLinkText={navT('back')}
      />
      <Title as="h1">{t('title')}</Title>
      {isMobile ? ResultMobile(data) : ResultDesktop(data)}
    </AppLayout>
  );
}

export default PaymentLinkResult;
