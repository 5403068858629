import React, { useCallback } from 'react';
import { track } from '@amplitude/analytics-browser';
import { goToOldAdmin } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { useTranslationWithPrefix } from 'commons/utils';
import BalanceCard, { BalanceCardInterface } from './BalanceCard';

function WaitingFundsCard(
  props: Pick<BalanceCardInterface, 'balance'>,
): JSX.Element {
  const { t } = useTranslationWithPrefix('dashboard.cards.waitingFunds');

  const redirectToStatement = useCallback(() => {
    track('nuvempago_dashboard_waiting_funds_details_click');

    goToOldAdmin(nexo, '/nuvempago/incoming_activities/');
  }, []);

  return (
    <BalanceCard title={t('title')} {...props} onClick={redirectToStatement} />
  );
}

export default WaitingFundsCard;
