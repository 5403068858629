import React from 'react';
import { Table, Tag, Text } from '@nimbus-ds/components';

export default function WithdrawsTableRowsSkeleton(): JSX.Element {
  const rowsMapped = [];
  for (let i = 0; i < 10; i++) {
    rowsMapped.push(
      <Table.Row key={i} id={`${i}`}>
        <Table.Cell>
          <Text.Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Text.Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Text.Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Text.Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Tag.Skeleton />
        </Table.Cell>
      </Table.Row>,
    );
  }
  return <>{rowsMapped}</>;
}
