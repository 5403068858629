import React, { useCallback, useContext } from 'react';
import { Box, Chip } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';
import DateRangeFilterEnum from 'domains/Brazil/commons/types/DateRangeFilterEnum';
import StatusFilterEnum from 'domains/Brazil/commons/types/TransactionStatusFilterEnum';
import TypeFilterEnum from 'domains/Brazil/commons/types/TransactionTypeFilterEnum';
import DashboardFiltersContext from './DashboardFiltersContext';

function FilterChips(): JSX.Element {
  const sideModalContext = useContext(DashboardFiltersContext);
  const { t } = useTranslationWithPrefix('home');

  const formatDate = useCallback((date: string) => {
    return new Date(date).toLocaleDateString('pt-BR', {
      dateStyle: 'medium',
      timeZone: 'UTC',
    });
  }, []);

  const setCustomSearch = useCallback(
    (removedFilter: string) => {
      const customSearch = sideModalContext.customSearch?.filter(
        (customFilter) => customFilter !== removedFilter,
      );

      if (!customSearch || !customSearch.length)
        sideModalContext.setCustomSearch(undefined);
      else sideModalContext.setCustomSearch(customSearch);
    },
    [sideModalContext],
  );

  const setCustomFilterChips = useCallback(
    (filterChipValue: string) => {
      const isAmount =
        /\d{1,10}(?:\.\d{3}){0,3}?,\d{2}$/m.test(filterChipValue) || // 0000,00 and 0.000,00
        /\d{1,10}\.\d{2}$/m.test(filterChipValue); // 0000.00

      const currencyFormater = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });

      const chipValue = isAmount
        ? currencyFormater.format(+filterChipValue.replace(/\D/g, '') / 100)
        : filterChipValue;

      return (
        <Chip
          key={chipValue}
          removable
          text={chipValue}
          onClick={() => setCustomSearch(filterChipValue)}
          data-testid="customSearchChip"
        />
      );
    },
    [setCustomSearch],
  );

  return (
    <Box
      display="flex"
      align-items="flex-start"
      flexWrap="wrap"
      flex-direction="column"
      align-self="stretch"
      gap="2-5"
    >
      {sideModalContext.type != TypeFilterEnum.ALL && (
        <Chip
          removable
          text={t(`transactionTypes.${sideModalContext.type}`)}
          onClick={() => {
            sideModalContext.setType(TypeFilterEnum.ALL);
          }}
          data-testid="transactionTypeChip"
        />
      )}
      {![DateRangeFilterEnum.ALL, DateRangeFilterEnum.CUSTOM].includes(
        sideModalContext.dateRange,
      ) && (
        <Chip
          removable
          text={t(`dateRangeOptions.${sideModalContext.dateRange}`)}
          onClick={() => {
            sideModalContext.setDateRange(DateRangeFilterEnum.ALL);
            sideModalContext.setFromDate(undefined);
            sideModalContext.setToDate(undefined);
          }}
          data-testid="transactionDateRangeChip"
        />
      )}
      {sideModalContext.dateRange === DateRangeFilterEnum.CUSTOM &&
        sideModalContext.fromDate != undefined && (
          <Chip
            removable
            text={t(`transactionsSearch.fromDate`, {
              date: formatDate(sideModalContext.fromDate),
            })}
            onClick={() => {
              sideModalContext.setFromDate(undefined);
            }}
            data-testid="transactionFromDateChip"
          />
        )}
      {sideModalContext.dateRange === DateRangeFilterEnum.CUSTOM &&
        sideModalContext.toDate != undefined && (
          <Chip
            removable
            text={t(`transactionsSearch.toDate`, {
              date: formatDate(sideModalContext.toDate),
            })}
            onClick={() => {
              sideModalContext.setToDate(undefined);
            }}
            data-testid="transactionToDateChip"
          />
        )}
      {sideModalContext.status != StatusFilterEnum.ALL && (
        <Chip
          removable
          text={t(`status.${sideModalContext.status}`)}
          onClick={() => {
            sideModalContext.setStatus(StatusFilterEnum.ALL);
          }}
          data-testid="transactionStatusChip"
        />
      )}
      {sideModalContext.customSearch?.map(setCustomFilterChips)}
    </Box>
  );
}

export default FilterChips;
