import axios from 'App/axios';
import * as FingerprintClient from 'App/fingerprintClient';
import { AxiosError } from 'axios';
import camelcase from 'camelcase';
import { MoneyObjectInterface, OwnerType } from 'commons/types';
import { TransferErrorType } from 'domains/Transfer/TransferErrorType';
import { WithdrawDto } from '../withdrawsService/dtos/withdraw.dto';

export enum Permissions {
  withdrawV2 = 'withdrawal_v2',
}
export interface GetBalanceResponse {
  available: MoneyObjectInterface;
  waitingFunds: MoneyObjectInterface;
  blocked: MoneyObjectInterface;
  availableToWithdraw: MoneyObjectInterface;
  inTransit: MoneyObjectInterface;
  transferFee?: MoneyObjectInterface;
}

type GetBalanceType = () => Promise<GetBalanceResponse>;

const getBalance: GetBalanceType = async () => {
  const response = await axios.get('/admin/balances');
  return response.data;
};

export type TransferType = (
  amount?: MoneyObjectInterface,
) => Promise<WithdrawDto>;
export type TransferErrorResponseObjectType = {
  response: {
    data: {
      failureCode: TransferErrorType;
    };
  };
};

const transfer: TransferType = async (amount?: MoneyObjectInterface) => {
  const headers = await FingerprintClient.getHeaders();
  const response = await axios.post('/admin/transfer', { amount }, { headers });
  return response.data as WithdrawDto;
};

interface GetActiveStoreResponse {
  storeId: number;
  active: boolean;
  owner: OwnerType;
  flags: string[];
}

type GetActiveStoreType = () => Promise<GetActiveStoreResponse>;

export class StoreNotFoundError extends Error {}

const getActiveStore: GetActiveStoreType = async () => {
  try {
    const response = await axios.get('/admin/active-store');
    return response.data;
  } catch (error: unknown) {
    if ((error as AxiosError).response?.status === 404) {
      throw new StoreNotFoundError('Store not found', { cause: error });
    } else {
      throw error;
    }
  }
};

export interface GetActiveUserResponse {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  isOwner: boolean;
  isEmployee: boolean;
  roles: string[];
}

interface GetPermission {
  permitted: boolean;
}

type GetActiveUserType = () => Promise<GetActiveUserResponse | undefined>;

const getActiveUser: GetActiveUserType = async () => {
  try {
    const { data } = await axios.get('/admin/active-user');
    return (
      typeof data === 'object' &&
      Object.keys(data).reduce(
        (obj: any, key) => ({
          ...obj,
          [camelcase(key)]: data[key],
        }),
        {},
      )
    );
  } catch (error: any) {
    // eslint-disable-next-line
    console.error('Error fetching active user', error);
    return undefined;
  }
};

type GetPermissionType = (permission: string) => Promise<GetPermission>;

const getPermission: GetPermissionType = async (permission: string) => {
  const { data } = await axios.get(
    `/admin/merchants/has-permission/${permission}`,
  );

  return data;
};

type RetrieveTransferType = (withdrawId: number) => Promise<WithdrawDto>;

const retrieveTransfer: RetrieveTransferType = async (withdrawId: number) => {
  const response = await axios.get(`/admin/transfers/${withdrawId}`, {
    timeout: 5_000,
  });
  return response.data;
};

interface MerchantServiceInterface {
  getBalance: GetBalanceType;
  transfer: TransferType;
  getActiveStore: GetActiveStoreType;
  getActiveUser: GetActiveUserType;
  getPermission: GetPermissionType;
  retrieveTransfer: RetrieveTransferType;
}

const merchantService: MerchantServiceInterface = {
  getBalance,
  transfer,
  getActiveStore,
  getActiveUser,
  getPermission,
  retrieveTransfer,
};

export default merchantService;
