import React from 'react';
import { Box, Card, Link, Text, Title } from '@nimbus-ds/components';

function ClientCardSkeleton(): JSX.Element {
  return (
    <Card>
      <Card.Header padding="none">
        <Title.Skeleton as="h3" />
      </Card.Header>
      <Box display="flex" gap="4" flexDirection="column">
        <Text.Skeleton />
        <Link.Skeleton />
      </Box>
    </Card>
  );
}

export default ClientCardSkeleton;
