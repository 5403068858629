import React from 'react';
import { Box, Card, Text, Thumbnail } from '@nimbus-ds/components';

function SingleDniDocumentSkeleton(): JSX.Element {
  return (
    <Card>
      <Text.Skeleton />
      <Box display="flex" gap="4" alignItems="flex-start">
        <Thumbnail.Skeleton />
        <Thumbnail.Skeleton />
      </Box>
    </Card>
  );
}

export default SingleDniDocumentSkeleton;
