import React, { useContext } from 'react';
import { Box, Title } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { useTranslationWithPrefix } from 'commons/utils';
import HomeTableMobileRow from '../HomeTable/HomeTableMobile/HomeTableMobileRow';
import { HomeMockContext } from './HomeMockContext';
import OnBoardingPopover from './OnBoardingPopover';
import { OnBoardingSteps } from './OnBoardingSteps';
import TransactionsFilterMock from './TransactionsFilterMock';
import { transactionsMock } from './transactionsMock';

function HomeTableMobileMock(): JSX.Element | null {
  const { step, nextStep, closeOnBoarding } = useContext(HomeMockContext);
  const { t } = useTranslationWithPrefix('home');
  const transactions = transactionsMock;

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <Box display="inline-flex">
        <OnBoardingPopover
          visible={step === OnBoardingSteps.Transactions}
          title={t('mock.transactionsPopover.title')}
          description={t('mock.transactionsPopover.description')}
          linkText={t('mock.next')}
          onLinkClick={nextStep}
          onDismiss={closeOnBoarding}
        >
          <Title as="h2">{t('homeTable.title')}</Title>
        </OnBoardingPopover>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <TransactionsFilterMock />
        <DataList>
          <HomeTableMobileRow order={transactions[0]} mock />
        </DataList>
      </Box>
    </Box>
  );
}

export default HomeTableMobileMock;
