import React from 'react';
import { Box, Card, Icon, Link, Text, Title } from '@nimbus-ds/components';
import { MailIcon, WhatsappIcon } from '@nimbus-ds/icons';
import { PaymentLinkCustomerType } from 'commons/types/PaymentLinkDetailsType';
import { useTranslationWithPrefix } from 'commons/utils';
import { documentAnonimize } from 'domains/Brazil/commons/utils/document';
import SkeletonHandler from './Skeleton';

interface Props {
  customer: PaymentLinkCustomerType | undefined;
}

export default function CustomerCard(props: Props): JSX.Element {
  const { t } = useTranslationWithPrefix('paymentLink.details.customer');

  function renderCustomerName(customerName: string | undefined) {
    return (
      <Box marginBottom="2-5">
        <SkeletonHandler
          skeletonComponent={<Text.Skeleton width="100%" />}
          trigger={!customerName}
        >
          <Text fontSize="base" textAlign="left" color="neutral-textLow">
            {props.customer?.name}
          </Text>
        </SkeletonHandler>
      </Box>
    );
  }

  function renderCustomerEmail(email: string | undefined) {
    return (
      <Box marginBottom="2-5">
        <SkeletonHandler
          skeletonComponent={<Text.Skeleton width="100%" />}
          trigger={!email}
        >
          <Box
            display="grid"
            gridTemplateRows="1fr"
            gridTemplateColumns="18px 1fr"
            alignItems="stretch"
          >
            <Link
              textDecoration="none"
              appearance="primary"
              as="a"
              target="_blank"
              href={`mailto:${email}`}
            >
              <Icon color="primary-interactive" source={<MailIcon />} />
              {email}
            </Link>
          </Box>
        </SkeletonHandler>
      </Box>
    );
  }

  function renderCustomerPhone(phone: string | undefined) {
    const phoneNumber = phone?.replace(/\D/g, '');

    return (
      <Box marginBottom="2-5">
        <SkeletonHandler
          skeletonComponent={<Text.Skeleton width="100%" />}
          trigger={!phone}
        >
          <Box
            display="grid"
            gridTemplateRows="1fr"
            gridTemplateColumns="300px 1fr"
            alignItems="stretch"
          >
            <Link
              textDecoration="none"
              appearance="primary"
              as="a"
              href={`https://wa.me/${phoneNumber}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon color="primary-interactive" source={<WhatsappIcon />} />
              {phone}
            </Link>
          </Box>
        </SkeletonHandler>
      </Box>
    );
  }

  function renderCustomerDocument(
    type: string | undefined,
    document: string | undefined,
  ) {
    const documentType = type === 'individual' ? 'CPF' : 'CNPJ';

    return (
      <Box marginTop={'1'}>
        <SkeletonHandler
          skeletonComponent={<Text.Skeleton width="100%" />}
          trigger={!document}
        >
          <Text fontSize="base" textAlign="left" color="neutral-textLow">
            {t('document', {
              type: documentType,
              document: document && documentAnonimize(document),
            })}
          </Text>
        </SkeletonHandler>
      </Box>
    );
  }

  return (
    <Card padding="none">
      <Box marginLeft={'4'} marginTop={'4'}>
        <SkeletonHandler
          skeletonComponent={<Title.Skeleton />}
          trigger={!props?.customer}
        >
          <Card.Header title={t('title')} />
        </SkeletonHandler>
      </Box>
      <Card.Body padding="none">
        <Box display="grid" marginLeft={'4'} marginBottom={'4'}>
          {renderCustomerName(props.customer?.name)}
          {renderCustomerEmail(props.customer?.email)}
          {renderCustomerPhone(props.customer?.phone)}
          {renderCustomerDocument(
            props.customer?.type,
            props.customer?.document,
          )}
        </Box>
      </Card.Body>
    </Card>
  );
}
