import React, { useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import {
  Box,
  Button,
  Input,
  Label,
  Sidebar,
  Text,
} from '@nimbus-ds/components';
import { SelectReportDateOptions, SidebarHeader } from 'commons/components';
import { ReportDateRangeType } from 'commons/types';
import { todayDate, useTranslationWithPrefix } from 'commons/utils';
import { MIN_REPORT_DAY } from 'commons/utils/constants';
import moment from 'moment';

interface ReportSidebarInterface {
  open: boolean;
  onRemove: () => void;
  applyFilters: (
    dateRange: ReportDateRangeType,
    fromDate: string,
    toDate: string,
  ) => void;
  isLoading: boolean;
  defaultDateSettings: {
    dateRange: ReportDateRangeType;
    fromDate: string;
    toDate: string;
  };
}

function FiltersSidebar({
  open,
  onRemove,
  applyFilters,
  isLoading,
  defaultDateSettings,
}: ReportSidebarInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('home.reportSidebar');
  const { fromDate, toDate, dateRange } = defaultDateSettings;

  const [dateRangeTemp, setDateRangeTemp] =
    useState<ReportDateRangeType>(dateRange);
  const [fromDateTemp, setFromDateTemp] = useState(fromDate);
  const [toDateTemp, setToDateTemp] = useState(toDate);

  function changeFromDate(value: string) {
    if (moment(value).isAfter(moment(toDateTemp))) {
      setToDateTemp(value);
    }
    setFromDateTemp(value);
  }

  function changeToDate(value: string) {
    if (moment(value).isBefore(moment(fromDateTemp))) {
      setFromDateTemp(value);
    }
    setToDateTemp(value);
  }

  const CustomDateFilter = (
    <Box display="flex" flexDirection="row" gap="4">
      <Box display="flex" flexDirection="column">
        <Label>{t('fromDate')}</Label>
        <Input
          name={'fromDate'}
          data-testid="fromDate"
          type="date"
          value={fromDateTemp}
          onChange={(event) => changeFromDate(event.target.value)}
          min={moment(MIN_REPORT_DAY).format('YYYY-MM-DD')}
          max={todayDate()}
        />
      </Box>
      <Box display="flex" flexDirection="column">
        <Label>{t('toDate')}</Label>
        <Input
          name={'toDate'}
          data-testid="toDate"
          type="date"
          value={toDateTemp}
          onChange={(event) => changeToDate(event.target.value)}
          min={moment(MIN_REPORT_DAY).format('YYYY-MM-DD')}
          max={todayDate()}
        />
      </Box>
    </Box>
  );

  const setAndTrackFilter = (event: { target: { value: string } }) => {
    const value = event.target.value as ReportDateRangeType;
    track(`nuvempago_exportar_listado_selected_${value}`);
    setDateRangeTemp(value);
  };

  const onFilterClick = () => {
    track('pago-nube-listado-filtrar');
    applyFilters(dateRangeTemp, fromDateTemp, toDateTemp);
  };

  return (
    <Sidebar open={open} onRemove={onRemove} padding="base">
      <SidebarHeader title={t('title')} onRemove={onRemove} />
      <Sidebar.Body>
        <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap="2"
          >
            <Text color="neutral-textLow">{t('filterByDate')}</Text>
            <SelectReportDateOptions
              dateRange={dateRangeTemp}
              setDateRange={setAndTrackFilter}
            />
          </Box>
          {dateRangeTemp === 'custom' && CustomDateFilter}
        </Box>
      </Sidebar.Body>
      <Sidebar.Footer>
        <Box display="flex" flexDirection="row" gap="4">
          <Button onClick={onRemove}>{t('cancel')}</Button>
          <Button
            onClick={onFilterClick}
            appearance="primary"
            disabled={isLoading}
          >
            {t('filter')}
          </Button>
        </Box>
      </Sidebar.Footer>
    </Sidebar>
  );
}

export default FiltersSidebar;
