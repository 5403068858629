import React, { ReactNode, useState } from 'react';
import { Box, Card, Toggle } from '@nimbus-ds/components';
import { ChevronDownIcon, ChevronUpIcon } from '@nimbus-ds/icons';
import CardCollapsibleSkeleton from './CardCollapsibleSkeleton';

interface CardCollapsibleProps {
  title: ReactNode;
  open: boolean;
  appearance?: 'chevron' | 'toggle';
  alignSwitch?: 'flex-start' | 'center' | 'flex-end' | 'stretch';
  children: ReactNode;
  onChange?: (open: boolean) => void;
}

function CardCollapsible({
  title,
  open,
  children,
  appearance = 'chevron',
  alignSwitch = 'center',
  onChange,
}: CardCollapsibleProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(open);

  const renderComponent: Record<'chevron' | 'toggle', ReactNode> = {
    chevron: isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />,
    toggle: <Toggle name="" checked={isOpen} />,
  };

  const handleToggle = () => {
    onChange?.(!isOpen);

    setIsOpen(!isOpen);
  };

  return (
    <Card>
      <Card.Header onClick={handleToggle}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems={alignSwitch}
          cursor="pointer"
        >
          {title}
          {renderComponent[appearance]}
        </Box>
      </Card.Header>
      {isOpen && <Card.Body>{children}</Card.Body>}
    </Card>
  );
}

CardCollapsible.Skeleton = CardCollapsibleSkeleton;

export default CardCollapsible;
