type InvalidPathFormats =
  | ''
  | '.'
  | `.${string}`
  | `${string}.`
  | `${string}..${string}`
  | `${string}[${string}]${string}`;
type PropertyPath<T extends string> = T extends InvalidPathFormats ? never : T;

const isObject = (obj: unknown) => !!obj && typeof obj === 'object';
const isValidTarget = (obj: unknown) => Array.isArray(obj) || isObject(obj);
const isNullish = (obj: unknown) => obj === null || obj === undefined;
const getPathParts = (path: string) =>
  path.replace(/\[|\]/g, '.').split('.').filter(Boolean);
const hasForbiddenKeys = (parts: string[]) =>
  ['__proto__', 'prototype', 'constructor'].some((key) => parts.includes(key));

/**
 * Sets the `value` at `path` of `obj`. This **mutates** the object and/or its
 * nested structures.
 *
 * `obj` must be a truthy object-like (`typeof` equals `'object'`) or an array.
 *
 * If a part of `path` doesn't exist: arrays are created for missing indexes
 * (numeric parts), and objects for any other parts.
 * Array index can be expressed either by dot (`a.0.b`) or bracket notation
 * (`a[0].b`).
 * Empty parts within the path (`''`) are skipped.
 *
 * ```typescript
 * setDeepProperty({}, 'root.nested.0.deep', 1); // { root: { nested: [{ deep: 1 }] } }
 * setDeepProperty([{ value: 1}], '0.other', 2); // [{ value: 1, other: 2 }]
 * ```
 *
 * Access to `prototype`, `__proto__` and `constructor` is forbidden and
 * cancels the operation.
 *
 * ```typescript
 * setDeepProperty({}, 'prototype.polluted', true); // {}, obj is unchanged
 * ```
 */
export function setDeepProperty<T extends string>(
  obj: any[] | Record<string, any>,
  path: PropertyPath<T>,
  value: any,
): void {
  if (!isValidTarget(obj)) return;

  const parts = getPathParts(path);

  if (hasForbiddenKeys(parts)) return;

  const lastIndex = parts.length - 1;
  let target = obj;

  for (let i = 0; i <= lastIndex; i += 1) {
    const key = parts[i] as keyof typeof target;

    if (i === lastIndex) {
      target[key] = value;
      break;
    }

    /**
     * If the current key doesn't exist in the target, set it.
     * However, if it exists but doesn't hold a valid target, replace it.
     * If the next key is a number, assign an array; otherwise, an object.
     */
    if (!Object.hasOwn(target, key) || !isValidTarget(target[key])) {
      const nextKey = parts[i + 1];
      target[key] = Number.isNaN(+nextKey) ? {} : [];
    }

    // Move to the next level.
    target = target[key];
  }
}

/**
 * Gets the value at `path` of `obj`. If the *resolved* value is `undefined`,
 * the `defaultValue` is returned in its place. Any other resolved value
 * prevails and is returned, even if it is `null`.
 *
 * `defaultValue` defaults to `undefined`.
 *
 * If `obj` is nullish or `path` is empty after parsing, `defaultValue` is
 * returned.
 * Array index can be expressed either by dot (`a.0.b`) or bracket notation
 * (`a[0].b`).
 * Empty parts within the path (`''`) are skipped.
 *
 * ```typescript
 * getDeepProperty({ root: { nested: [{ deep: 1 }] } }, 'root.nested.0.deep'); // 1
 * getDeepProperty({ address: {} }, 'country'); // undefined
 * getDeepProperty({ address: {} }, 'country', 'US'); // 'US'
 * getDeepProperty({ address: null }, 'country', 'US'); // null, defaultValue is ignored
 * ```
 */
export function getDeepProperty<
  ReturnT = object,
  DefaultT = undefined,
  PathT extends string = string,
>(
  obj: any,
  path: PropertyPath<PathT>,
  defaultValue?: DefaultT,
): ReturnT | DefaultT {
  if (obj === null) return defaultValue as DefaultT;

  const parts = getPathParts(path);

  if (!parts.length) return defaultValue as DefaultT;

  const lastIndex = parts.length - 1;
  let target = obj;

  for (let i = 0; i <= lastIndex && !isNullish(target); i += 1) {
    target = target[parts[i]];
  }

  return target === undefined ? defaultValue : target;
}
