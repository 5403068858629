import { useEffect } from 'react';
import useStoreInfo from './useStoreInfo';

declare global {
  interface Window {
    satismeter: any;
  }
}

const SATISMETER_WRITE_KEY = process.env.REACT_APP_SATISMETER_WRITE_KEY || '';

export function useSatismeter(event: string) {
  const { storeInfo } = useStoreInfo();

  useEffect(() => {
    if (window.satismeter) {
      window.satismeter({
        writeKey: SATISMETER_WRITE_KEY,
        userId: storeInfo.id,
      });
      window.satismeter('track', {
        event,
      });
    }
  }, [event, storeInfo.id]);
}
