import { useStoreInfo } from 'commons/hooks';

const VIEWS_CONFIGURATION_MX = Object.freeze({
  configuration: false,
  report: false,
  transfer: false,
  withdraws: false,
  transferDetails: false,
});

export type Views = keyof typeof VIEWS_CONFIGURATION_MX;

export const linkAllowedTo = (view: Views, country: string): boolean => {
  if (country === 'MX') return VIEWS_CONFIGURATION_MX[view];

  return true;
};

export function useShouldLinkTo() {
  const { storeInfo } = useStoreInfo();
  const shouldLinkTo = (view: Views) => {
    return linkAllowedTo(view, storeInfo.country);
  };
  return { shouldLinkTo };
}
