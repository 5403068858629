import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { track } from '@amplitude/analytics-browser';
import { Alert, Link, Text } from '@nimbus-ds/components';
import { useStoreInfo } from 'commons/hooks';
import { ChargebackWithOrderDataType } from 'commons/types';
import {
  earliestDate,
  formatLongDateWithoutYear,
  useTranslationWithPrefix,
} from 'commons/utils';
import ChargebackList from './ChargebackList';

interface DisputeRejectedChargebackAlertProps {
  chargebacks: ChargebackWithOrderDataType[];
}

export default function DisputeRejectedChargebackAlert({
  chargebacks,
}: DisputeRejectedChargebackAlertProps) {
  const { t } = useTranslationWithPrefix(
    'home.chargebackAlerts.disputeRejected',
  );
  const { storeInfo } = useStoreInfo();
  const [showAlert, setShowAlert] = useState(true);

  const deadlines = chargebacks.map(
    (chargeback) => new Date(chargeback.deadline),
  );
  const closestDeadline = earliestDate(deadlines);

  useEffect(() => {
    if (showAlert && !!chargebacks.length) {
      track('pago-nube-contracargo-view', { status: 'disputeRejected' });
    }
  }, [chargebacks.length, showAlert]);

  return (
    <Alert
      title={t('title', { count: chargebacks.length })}
      show={showAlert && !!chargebacks.length}
      appearance="danger"
      onRemove={() => setShowAlert(false)}
    >
      <Text>
        <Trans
          t={t}
          i18nKey="description"
          count={chargebacks.length}
          values={{
            deadline: formatLongDateWithoutYear(
              storeInfo,
              closestDeadline.toString(),
            ),
          }}
          components={[
            <ChargebackList
              key="chargeback-list-pending"
              chargebacks={chargebacks}
              status="pending"
            />,
          ]}
        />
      </Text>
      <Link as="a" href={t('documentationUrl')} target="_blank">
        {t('uploadDocumentation')}
      </Link>
    </Alert>
  );
}
