import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Box, Icon, Link, Text, Title } from '@nimbus-ds/components';
import { ForbiddenIcon, ShoppingCartIcon, UploadIcon } from '@nimbus-ds/icons';
import AppLayout from 'App/components/AppLayout';
import { useIdentityValidation, useStoreInfo } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import {
  dataDogLogger,
  DataDogLoggerActionTypes,
} from 'commons/utils/dataDogLogger';
import IdentityValidationError from 'domains/IdentityValidation/IdentityValidationError';
import DesktopCard from '../components/DesktopCard';
import FooterButtons from '../FooterButtons';
import { useIdValidationRoutes } from '../hooks';
import IntroductoryInfoSkeleton from './IntroductoryInfoSkeleton';

function IntroductoryInfo(): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');
  const { goBack } = useHistory();
  const { storeInfo } = useStoreInfo();
  const identityValidation = useIdentityValidation();
  const { goToNextRoute } = useIdValidationRoutes();

  const proofOfLifeUrlIsMissing =
    identityValidation.data?.useKycV2 &&
    !identityValidation.data.proofOfLifeUrl;

  useEffect(() => {
    dataDogLogger.generate({
      actionMessage: `Store ${storeInfo.id} started new id validation flow`,
      actionOwner: 'IntroductoryInfo',
      actionType: DataDogLoggerActionTypes.Info,
      actionData: {
        storeId: storeInfo.id,
      },
    });

    if (proofOfLifeUrlIsMissing) {
      dataDogLogger.generate({
        actionMessage: `proofOfLifeUrl is missing for store ${storeInfo.id}`,
        actionOwner: 'IntroductoryInfo',
        actionType: DataDogLoggerActionTypes.Error,
        actionData: {
          storeId: storeInfo.id,
        },
      });
    }
  }, [storeInfo.id, proofOfLifeUrlIsMissing]);

  const handleSubmit = () => {
    track('pago-nube-prueba-de-vida-introduccion');
    goToNextRoute();
  };

  const footerButtons = (
    <FooterButtons onCancelClick={goBack} onSubmitClick={handleSubmit} />
  );

  const InfoBox = ({
    icon,
    textKey,
  }: {
    icon: React.ReactNode;
    textKey: string;
  }) => (
    <Box display="flex" flexDirection="row" alignItems="flex-start" gap="3">
      <Icon source={icon} color="primary-interactive" />
      <Text color="neutral-textLow">{t(textKey)}</Text>
    </Box>
  );

  if (identityValidation.isLoading) {
    return <IntroductoryInfoSkeleton />;
  }

  if (proofOfLifeUrlIsMissing) {
    return <IdentityValidationError />;
  }

  return (
    <AppLayout title={t('title')} footer={footerButtons}>
      <DesktopCard>
        <Box display="flex" flexDirection="column" gap="6">
          <Title as="h4">{t('introductoryInfo.subtitle')}</Title>
          <Box display="flex" flexDirection="column" gap="4">
            <InfoBox
              icon={<UploadIcon />}
              textKey="introductoryInfo.validationDescription"
            />
            <InfoBox
              icon={<ShoppingCartIcon />}
              textKey="introductoryInfo.productsDescription"
            />
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              gap="3"
            >
              <Icon source={<ForbiddenIcon />} color="primary-interactive" />
              <Box>
                <Text color="neutral-textLow" as="span">
                  {t('introductoryInfo.forbiddenIndustries')}
                </Text>
                <Link
                  as="a"
                  href={t('introductoryInfo.forbiddenIndustriesHelpLink.href')}
                  target="_blank"
                >
                  <Text>
                    {t('introductoryInfo.forbiddenIndustriesHelpLink.text')}
                  </Text>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </DesktopCard>
    </AppLayout>
  );
}

export default IntroductoryInfo;
