import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Event,
  GetRefundReceiptDetailsResponse,
} from 'commons/services/refundReceiptService';
import refundReceiptService from 'commons/services/refundReceiptService/refundReceiptService';
import { OrderType } from 'commons/types/OrderType.enum';
import RefundReceipt from './RefundReceipt';

interface RefundReceiptDetailPathParams {
  transactionId: string;
  orderId: string;
}

function TransactionRefundReceipt(): JSX.Element {
  const { transactionId, orderId } = useParams<RefundReceiptDetailPathParams>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [details, setDetails] = useState<GetRefundReceiptDetailsResponse>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const details =
          await refundReceiptService.getRefundReceiptDetails(orderId);
        if (Array.isArray(details.events)) {
          details.events.sort(
            (a: Event, b: Event) =>
              new Date(b.occurredAt).valueOf() -
              new Date(a.occurredAt).valueOf(),
          );
        }

        details.transactionId ??= transactionId;
        details.orderId ??= orderId;

        setDetails(details);
      } catch (error: any) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [orderId, transactionId]);

  return (
    <RefundReceipt
      details={details}
      isError={isError}
      isLoading={isLoading}
      receiptPaymentMethod={OrderType.Checkout}
    />
  );
}
export default TransactionRefundReceipt;
