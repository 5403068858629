import { useEffect, useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  configurationService,
  GetInstallmentsV2Response,
} from 'commons/services';
import {
  InstallmentsFeeResponsible,
  InstallmentV2Type,
  MoneyObjectInterface,
} from 'commons/types';

type SelectedInstallments = {
  withoutInterest: InstallmentV2Type[];
  planAhora: InstallmentV2Type[];
};

type InstallmentsState = SelectedInstallments & {
  changingResponsible: boolean;
  responsible: InstallmentsFeeResponsible;
  minInstallmentValue: MoneyObjectInterface;
};

export type ResponsibleStatus =
  | 'customer_active'
  | 'merchant_active'
  | 'changing_to_customer'
  | 'changing_to_merchant';

function selectWithoutInterestInstallments(data: GetInstallmentsV2Response) {
  return {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    withoutInterest: data.withoutInterest
      .filter((i) => i.type === 'standard')
      .map(({ type, ...rest }) => rest),
    planAhora: data.withoutInterest
      .filter((i) => i.type === 'plan_ahora')
      .map(({ type, ...rest }) => rest),
    /* eslint-enable @typescript-eslint/no-unused-vars */
  };
}

export function useInstallmentsV2(opts: {
  onResponsibleChange: {
    success: (responsibleChangedTo: InstallmentsFeeResponsible) => void;
    error: () => void;
  };
}) {
  const [installments, setInstallments] = useState<SelectedInstallments>({
    withoutInterest: [],
    planAhora: [],
  });
  const [minInstallmentValue, setMinInstallmentValue] =
    useState<MoneyObjectInterface>({
      value: 0,
      currency: 'ARS',
    });
  const [installmentsChanged, setInstallmentsChanged] = useState(false);
  const [minInstallmentValueChanged, setMinInstallmentValueChanged] =
    useState(false);
  const changed = installmentsChanged || minInstallmentValueChanged;

  const queryKey = ['installmentsV2'];
  const queryClient = useQueryClient();
  const queryInstallments = useQuery<
    GetInstallmentsV2Response,
    unknown,
    InstallmentsState
  >(queryKey, () => configurationService.getInstallmentsV2(), {
    select(data) {
      return {
        ...selectWithoutInterestInstallments(data),
        changingResponsible: data.changingResponsible,
        responsible: data.responsible,
        minInstallmentValue: data.minInstallmentValue,
      };
    },
    onSuccess(data) {
      setInstallments({
        withoutInterest: data.withoutInterest,
        planAhora: data.planAhora,
      });
      setMinInstallmentValue(data.minInstallmentValue);
    },
  });

  const currentResponsible = queryInstallments.data?.responsible;
  const responsibleToChange =
    currentResponsible === InstallmentsFeeResponsible.MERCHANT
      ? InstallmentsFeeResponsible.CUSTOMER
      : InstallmentsFeeResponsible.MERCHANT;
  const changingResponsible = queryInstallments.data?.changingResponsible;
  const responsibleStatus: ResponsibleStatus = changingResponsible
    ? currentResponsible === InstallmentsFeeResponsible.CUSTOMER
      ? 'changing_to_merchant'
      : 'changing_to_customer'
    : currentResponsible === InstallmentsFeeResponsible.CUSTOMER
      ? 'customer_active'
      : 'merchant_active';

  useEffect(() => {
    if (changingResponsible) {
      track('pago-nube-error-cambio-cuotas');
    }
  }, [changingResponsible]);

  const setChanged = (changed: boolean) => {
    setInstallmentsChanged(changed);
    setMinInstallmentValueChanged(changed);
  };

  const updateInstallments = (installments: SelectedInstallments) => {
    setInstallments({
      withoutInterest: installments.withoutInterest,
      planAhora: installments.planAhora,
    });
    setInstallmentsChanged(true);
  };

  const updateMinInstallmentValue = (value: MoneyObjectInterface) => {
    setMinInstallmentValue(value);
    setMinInstallmentValueChanged(true);
  };

  const mutateInstallments = useMutation(queryKey, {
    mutationFn: () => {
      const installmentsToUpdate = installments
        ? {
            planAhora: installments.planAhora,
            withoutInterest: installments.withoutInterest,
          }
        : {};
      if (minInstallmentValueChanged) {
        track('pago-nube-configurar-monto-minimo', {
          value: minInstallmentValue,
        });
      }
      return configurationService.patchInstallmentsV2({
        ...installmentsToUpdate,
        minInstallmentValue: minInstallmentValueChanged
          ? minInstallmentValue
          : undefined,
      });
    },
    onSuccess: () => {
      queryInstallments.refetch();
    },
  });

  const mutateResponsible = useMutation(
    ['responsible'],
    () => {
      return configurationService.patchInstallmentsResponsible(
        responsibleToChange,
      );
    },
    {
      onSuccess() {
        queryClient.setQueryData<InstallmentsState>(queryKey, (old) => ({
          ...old!,
          changingResponsible: true,
        }));
        opts.onResponsibleChange.success(responsibleToChange);
      },
      onError() {
        opts.onResponsibleChange.error();
      },
    },
  );

  return {
    installments: {
      withoutInterest: installments?.withoutInterest ?? [],
      planAhora: installments?.planAhora ?? [],
      withInterest: [],
    },
    responsible: {
      status: responsibleStatus,
    },
    changed,
    isLoadingInstallments: queryInstallments.isLoading,
    isErrorInstallments: queryInstallments.isError,
    setChanged,
    updateInstallments,
    changeResponsible: mutateResponsible,
    submitInstallments: mutateInstallments,
    minInstallmentValue,
    updateMinInstallmentValue,
  };
}
