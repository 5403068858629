import React from 'react';
import { Alert } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

interface SiteAlertProps {
  show: boolean;
}

export default function SiteAlert({ show }: SiteAlertProps): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation.siteAlert');

  return (
    <Alert appearance="warning" title={t('title')} show={show}>
      {t('description')}
    </Alert>
  );
}
