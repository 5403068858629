import React from 'react';
import { track } from '@amplitude/analytics-browser';
import { Box, Icon, Link } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { HelpLink as NimbusHelpLink } from '@nimbus-ds/patterns';

interface HelpLinkProps {
  href: string;
  text: string;
  appearance?: React.ComponentProps<typeof Link>['appearance'];
  textDecoration?: React.ComponentProps<typeof Link>['textDecoration'];
  trackingTag?: string;
}

export default function HelpLink({
  href,
  text,
  appearance,
  textDecoration,
  trackingTag,
}: HelpLinkProps): JSX.Element {
  const handleLinkClick = trackingTag ? () => track(trackingTag) : undefined;

  return (
    <Box marginY="4">
      <NimbusHelpLink>
        <Link
          as="a"
          href={href}
          target="_blank"
          appearance={appearance}
          textDecoration={textDecoration}
          onClick={handleLinkClick}
        >
          {text}
          <Icon source={<ExternalLinkIcon />} color="currentColor" />
        </Link>
      </NimbusHelpLink>
    </Box>
  );
}
