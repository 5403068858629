import React from 'react';
import { Box, Table, Text } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';
import { WithdrawsFilter } from '../WithdrawsFilter';
import WithdrawsTableRowsSkeleton from './WithdrawsTableRowsSkeleton';

function WithdrawsTableDesktopSkeleton(): JSX.Element {
  const { t } = useTranslationWithPrefix('withdraws.withdrawsTable');

  const headers = (
    <Table.Row backgroundColor="neutral-surface">
      {[
        t('headers.withdraw'),
        t('headers.date'),
        t('headers.client'),
        t('headers.value'),
        t('headers.status'),
      ].map((header, index) => (
        <Table.Cell key={index}>
          <Text fontWeight="medium">{header}</Text>
        </Table.Cell>
      ))}
    </Table.Row>
  );

  const renderDataTable = () => (
    <Table>
      <Table.Head>{headers}</Table.Head>
      <WithdrawsTableRowsSkeleton />
    </Table>
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <WithdrawsFilter.Skeleton />
      {renderDataTable()}
    </Box>
  );
}

export default WithdrawsTableDesktopSkeleton;
