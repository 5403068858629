import React from 'react';
import { Box, Card, Text, Title } from '@nimbus-ds/components';

function WithInterestSkeleton(): JSX.Element {
  return (
    <Card>
      <Card.Header>
        <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
          <Title.Skeleton />
          <Text.Skeleton />
        </Box>
      </Card.Header>
      <Text.Skeleton />
    </Card>
  );
}

export default WithInterestSkeleton;
