import React, { useContext, useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { Box, Button, Input, Sidebar, Text } from '@nimbus-ds/components';
import {
  NotificationContext,
  SelectReportDateOptions,
  SidebarHeader,
} from 'commons/components';
import { useStoreInfo } from 'commons/hooks';
import { notificationService } from 'commons/services';
import { reportService } from 'commons/services/reportService';
import { ReportDateRangeType } from 'commons/types';
import {
  getRelativeDateFromToday,
  todayDate,
  useTranslationWithPrefix,
} from 'commons/utils';
import { MIN_REPORT_DAY } from 'commons/utils/constants';
import moment from 'moment';

interface ReportSidebarInterface {
  open: boolean;
  onRemove: () => void;
}

function ReportSidebar({
  open,
  onRemove,
}: ReportSidebarInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('home.reportSidebar');
  const [filter, setFilter] = useState<ReportDateRangeType>('all');
  const [fromDate, setFromDate] = useState(getRelativeDateFromToday(-1));
  const [toDate, setToDate] = useState(todayDate());
  const [isLoading, setIsLoading] = useState(false);
  const { fetchNotifications } = useContext(NotificationContext);
  const { storeInfo } = useStoreInfo();

  const sendErrorNotification = async () => {
    track('nuvempago_exportar_listado_error');
    await notificationService.sendNotification({
      title: t('error'),
      style: 'error',
      message: '',
    });
  };

  const sendSuccessNotification = async () => {
    try {
      await notificationService.sendNotification({
        title: t('success', { email: storeInfo.user?.email }),
        message: '',
        style: 'success',
      });
      track('nuvempago_exportar_listado_success');
    } catch {
      await sendErrorNotification();
    }
  };

  function exportReport() {
    setIsLoading(true);
    track('nuvempago_exportar_listado_exportar_button');
    reportService
      .sendReport(filter, fromDate, toDate)
      .then(sendSuccessNotification)
      .catch(sendErrorNotification)
      .finally(async () => {
        await fetchNotifications();
        onRemove();
        setIsLoading(false);
      });
  }

  function changeFromDate(value: string) {
    if (moment(value).isAfter(moment(toDate))) {
      setToDate(value);
    }
    setFromDate(value);
  }

  function changeToDate(value: string) {
    if (moment(value).isBefore(moment(fromDate))) {
      setFromDate(value);
    }
    setToDate(value);
  }

  const CustomDateFilter = (
    <Box display="flex" flexDirection="row" gap="4">
      <Box display="flex" flexDirection="column" gap="1">
        <Text fontSize="caption">{t('fromDate')}</Text>
        <Input
          aria-label="fromDate"
          name="fromDate"
          type="date"
          value={fromDate}
          onChange={(event) => changeFromDate(event.target.value)}
          min={moment(MIN_REPORT_DAY).format('YYYY-MM-DD')}
          max={todayDate()}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="1">
        <Text fontSize="caption">{t('toDate')}</Text>
        <Input
          aria-label="toDate"
          name="toDate"
          type="date"
          value={toDate}
          onChange={(event) => changeToDate(event.target.value)}
          min={moment(MIN_REPORT_DAY).format('YYYY-MM-DD')}
          max={todayDate()}
        />
      </Box>
    </Box>
  );

  const setAndTrackFilter = (event: { target: { value: string } }) => {
    const value = event.target.value as ReportDateRangeType;
    track(`nuvempago_exportar_listado_selected_${value}`);
    setFilter(value);
  };

  return (
    <Sidebar open={open} onRemove={onRemove} padding="base">
      <SidebarHeader title={t('title')} onRemove={onRemove} />
      <Sidebar.Body>
        <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap="2"
          >
            <Text color="neutral-textLow">{t('filterByDate')}</Text>
            <SelectReportDateOptions
              dateRange={filter}
              setDateRange={setAndTrackFilter}
            />
          </Box>
          {filter === 'custom' && CustomDateFilter}
        </Box>
      </Sidebar.Body>
      <Sidebar.Footer>
        <Box display="flex" flexDirection="row" gap="4">
          <Button onClick={onRemove}>{t('cancel')}</Button>
          <Button
            onClick={exportReport}
            appearance="primary"
            disabled={isLoading}
          >
            {t('export')}
          </Button>
        </Box>
      </Sidebar.Footer>
    </Sidebar>
  );
}

export default ReportSidebar;
