import React from 'react';
import { InteractiveList } from '@nimbus-ds/patterns';
import Pagination from '../components/Pagination';
import { ErrorMessage } from '../ErrorMessage';
import { useFutureIncomesDesktopQuery } from '../hooks';
import IncomesByDateSkeleton from './IncomesByDateSkeleton';
import IncomesByDateTable from './IncomesByDateTable';

function IncomesByDateDesktop(): JSX.Element {
  const { data, isError, isLoading, isFetching, page, setPage } =
    useFutureIncomesDesktopQuery();

  if (isLoading) return <IncomesByDateSkeleton />;
  if (isError) return <ErrorMessage />;

  const meta = data.meta;

  return (
    <>
      {isFetching ? (
        <IncomesByDateSkeleton numberOfRows={meta.itemsPerPage} />
      ) : (
        <InteractiveList>
          <IncomesByDateTable incomes={data.items} />
        </InteractiveList>
      )}
      <Pagination
        firstItemOfPage={meta.firstItemOfPage}
        lastItemOfPage={meta.lastItemOfPage}
        totalCount={meta.totalCount}
        totalPages={meta.totalPages}
        page={page}
        setPage={setPage}
      />
    </>
  );
}

export default IncomesByDateDesktop;
