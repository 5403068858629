import React from 'react';
import { Text } from '@nimbus-ds/components';
import BaseMoneyCard from './BaseMoneyCard';

function MoneyCardSkeleton(): JSX.Element {
  return (
    <BaseMoneyCard>
      <Text.Skeleton />
      <Text.Skeleton />
    </BaseMoneyCard>
  );
}

export default MoneyCardSkeleton;
