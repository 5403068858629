import React from 'react';
import { Box, Input } from '@nimbus-ds/components';

function TransactionsFilterSkeleton(): JSX.Element {
  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <Input.Skeleton />
    </Box>
  );
}

export default TransactionsFilterSkeleton;
