import React from 'react';
import {
  Box,
  Pagination as NimbusPagination,
  Text,
} from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

interface PaginationProps {
  firstItemOfPage: number;
  lastItemOfPage: number;
  totalCount: number;
  totalPages: number;
  page: number;
  setPage: (page: number) => void;
}

function Pagination({
  firstItemOfPage,
  lastItemOfPage,
  totalCount,
  totalPages,
  page,
  setPage,
}: PaginationProps): JSX.Element {
  const { t } = useTranslationWithPrefix('futureIncome');
  return (
    <Box display="flex" justifyContent="space-between" padding="2">
      <Text fontSize="highlight">
        {t('pagination', {
          first: firstItemOfPage,
          last: lastItemOfPage,
          total: totalCount,
        })}
      </Text>
      <NimbusPagination
        activePage={page}
        pageCount={totalPages}
        showNumbers
        onPageChange={(newPage: number) => {
          setPage(newPage);
        }}
      />
    </Box>
  );
}

export default Pagination;
