import React, { useEffect, useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  Icon,
  IconButton,
  Link,
  Spinner,
  Text,
} from '@nimbus-ds/components';
import { DownloadIcon, SlidersIcon } from '@nimbus-ds/icons';
import AppLayout from 'App/components/AppLayout';
import { useIsMobile, useStoreInfo } from 'commons/hooks';
import { ReportStatusDataType } from 'commons/services/reportService';
import { ReportDateRangeType, ReportStatus } from 'commons/types';
import {
  downloadFromUrl,
  getRelativeDateFromToday,
  todayDate,
  useTranslationWithPrefix,
} from 'commons/utils';
import { useBackgroundReportGeneration } from './hooks/useBackgroundReportGeneration';
import FiltersSidebar from './FiltersSidebar';

export default function TransactionsReport(): JSX.Element {
  const { t } = useTranslationWithPrefix('transactionsReport');
  const isMobile = useIsMobile();
  const [openFilters, setOpenFilters] = useState(false);
  const [filtersAdded, setFiltersAdded] = useState(false);

  const [dateRange, setDateRange] = useState<ReportDateRangeType>('all');
  const [fromDate, setFromDate] = useState(getRelativeDateFromToday(-1));
  const [toDate, setToDate] = useState(todayDate());

  const { storeInfo } = useStoreInfo();

  const closeSidebar = () => setOpenFilters(false);
  const openSidebar = () => setOpenFilters(true);

  const generateReport = useBackgroundReportGeneration({
    onFinished: ({ status, temporaryDownloadUrl }: ReportStatusDataType) => {
      if (status === ReportStatus.Completed && temporaryDownloadUrl) {
        downloadFromUrl(temporaryDownloadUrl);
      }
    },
  });

  const exportReport = () => {
    track('nuvempago_exportar_listado_exportar_button');
    generateReport.startReportGeneration({ dateRange, fromDate, toDate });
  };

  const isLoading = generateReport.isLoading;

  const userEmail = storeInfo.user?.email;

  const loadingAlert = (
    <Alert title={t('loadingAlert.title')} appearance="primary">
      {t('loadingAlert.description', { email: userEmail })}
    </Alert>
  );

  const errorAlert = (
    <Alert appearance="danger">{t('errorAlert.description')}</Alert>
  );

  const successAlert = (
    <Alert title={t('successAlert.title')} appearance="success">
      {t('successAlert.description', { email: userEmail })}
      {generateReport.report?.temporaryDownloadUrl && (
        <Link
          as="a"
          href={generateReport.report.temporaryDownloadUrl}
          appearance="neutral"
          onClick={() => track('pago-nube-listado-exportar-descargar')}
        >
          {t('successAlert.download')}
        </Link>
      )}
    </Alert>
  );

  const setDefaultFilters = () => {
    setDateRange('all');
    setFiltersAdded(false);
  };

  const applyFilters = (
    dateRange: ReportDateRangeType,
    fromDate: string,
    toDate: string,
  ) => {
    setDateRange(dateRange);
    setFromDate(fromDate);
    setToDate(toDate);
    setFiltersAdded(true);
    closeSidebar();
  };

  const reportStatusFailed =
    generateReport.report?.status === ReportStatus.Failed;

  const showErrorAlert = generateReport.isError || reportStatusFailed;

  const reportStatusCompleted =
    generateReport.report?.status === ReportStatus.Completed;

  useEffect(() => {
    if (reportStatusCompleted) {
      track('pago-nube-listado-exportar-success');
    } else if (showErrorAlert) {
      track('pago-nube-listado-exportar-error');
    }
  }, [reportStatusCompleted, showErrorAlert]);

  return (
    <AppLayout title={t('title')}>
      <Box display="flex" flexDirection="column" gap="2">
        {isLoading && loadingAlert}
        {showErrorAlert && errorAlert}
        {reportStatusCompleted && successAlert}
        <Card>
          <Card.Body>
            <Box
              display="flex"
              flexDirection="column"
              gap={isMobile ? '5' : '0-5'}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Text>{t('description')}</Text>
                <Box display="flex">
                  <IconButton source={<SlidersIcon />} onClick={openSidebar} />
                </Box>
              </Box>
              {filtersAdded && (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="2"
                >
                  <Text>{t('filtersBy')}</Text>
                  <Chip
                    text={t(`filterChip.${dateRange}`)}
                    removable
                    onClick={setDefaultFilters}
                  />
                </Box>
              )}
            </Box>
          </Card.Body>
          <Card.Footer>
            <Button
              appearance="primary"
              onClick={exportReport}
              disabled={isLoading}
            >
              <Icon
                color="currentColor"
                source={isLoading ? <Spinner size="small" /> : <DownloadIcon />}
              />
              {t('export')}
            </Button>
          </Card.Footer>
        </Card>
        <FiltersSidebar
          open={openFilters}
          onRemove={closeSidebar}
          applyFilters={applyFilters}
          isLoading={isLoading}
          defaultDateSettings={{
            dateRange,
            fromDate,
            toDate,
          }}
        />
      </Box>
    </AppLayout>
  );
}
