import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ID_VALIDATION_ROUTES } from './constants';
import IntroductoryInfo from './IntroductoryInfo';
import IsPepOrSo from './IsPepOrSo';
import JuridicalValidation from './JuridicalValidation';
import PepOrSoContact from './PepOrSoContact';
import ProofOfLife from './ProofOfLife';

function IdentityValidationRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={IntroductoryInfo} />
      <Route
        exact
        path={`${path}${ID_VALIDATION_ROUTES.IS_PEP_OR_SO}`}
        component={IsPepOrSo}
      />
      <Route
        exact
        path={`${path}${ID_VALIDATION_ROUTES.PEP_OR_SO_CONTACT}`}
        component={PepOrSoContact}
      />
      <Route
        exact
        path={`${path}${ID_VALIDATION_ROUTES.JURIDICAL_VALIDATION}`}
        component={JuridicalValidation}
      />
      <Route
        exact
        path={`${path}${ID_VALIDATION_ROUTES.PROOF_OF_LIFE}`}
        component={ProofOfLife}
      />
    </Switch>
  );
}

export default IdentityValidationRoutes;
