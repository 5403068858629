import React from 'react';
import { Box, Icon, Title } from '@nimbus-ds/components';
import { InteractiveList } from '@nimbus-ds/patterns';

interface IncomesByDateSkeletonPops {
  numberOfRows?: number;
}

function IncomesByDateSkeleton({
  numberOfRows = 7,
}: IncomesByDateSkeletonPops): JSX.Element {
  const rows = [];
  for (let i = 0; i < numberOfRows; i++) {
    rows.push(
      <Box key={i} padding="5" display="flex" justifyContent="space-between">
        <Title.Skeleton as="h5" />
        <Box
          display="flex"
          justifyContent="space-between"
          gap="1"
          alignItems="center"
        >
          <Title.Skeleton as="h5" />
          <Icon.Skeleton />
        </Box>
      </Box>,
    );
  }

  return (
    <InteractiveList>
      <InteractiveList.Structure title="" showTitle={false}>
        {rows}
      </InteractiveList.Structure>
    </InteractiveList>
  );
}

export default IncomesByDateSkeleton;
