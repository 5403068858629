import React from 'react';
import { Text } from '@nimbus-ds/components';
import { Currency } from 'commons/types/Currency.enum';

type MoneyFieldArgs = {
  value: string | number;
  type?: 'money' | 'cents';
  currency: Currency;
  prefixString?: string | undefined;
  color?:
    | 'primary-interactive'
    | 'primary-surface'
    | 'primary-textLow'
    | 'success-interactive'
    | 'success-surface'
    | 'success-textLow'
    | 'warning-interactive'
    | 'warning-surface'
    | 'warning-textLow'
    | 'danger-interactive'
    | 'danger-surface'
    | 'danger-textLow'
    | 'neutral-background'
    | 'neutral-interactive'
    | 'neutral-surface'
    | 'neutral-textLow'
    | 'neutral-textHigh'
    | undefined;
  textAlign?: 'center' | 'justify' | 'left' | 'right' | undefined;
  fontSize?: 'base' | 'caption' | 'highlight' | undefined;
  fontWeight?: 'regular' | 'medium' | 'bold' | undefined;
  lineHeight?: 'base' | 'caption' | 'highlight' | undefined;
};
function MoneyField({
  value,
  currency,
  type = 'money',
  prefixString = '',
  color,
  textAlign,
  fontSize,
  fontWeight,
  lineHeight,
}: MoneyFieldArgs): JSX.Element {
  let amount = Number(value);

  if (type === 'cents') {
    amount = amount / 100;
  }

  return (
    <Text
      color={color}
      textAlign={textAlign}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      data-testid={`moneyField-${value}`}
    >
      {prefixString}
      {`${Intl.NumberFormat('pt-br', {
        style: 'currency',
        currency: currency,
      }).format(amount)}`}
    </Text>
  );
}

function Skeleton(): JSX.Element {
  return <Text.Skeleton width="100%" height="20px" />;
}

MoneyField.Skeleton = Skeleton;

export default MoneyField;
