import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Box, Checkbox, Text, Title } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { useBankAccount } from 'commons/hooks';
import {
  capitalize,
  isJuridicalPerson,
  useTranslationWithPrefix,
} from 'commons/utils';
import DesktopCard from '../components/DesktopCard';
import FooterButtons from '../FooterButtons';
import { useIdValidationRoutes, useKycInfoMutation } from '../hooks';
import IsPepOrSoSkeleton from './IsPepOrSoSkeleton';

function IsPepOrSo(): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');
  const bankAccount = useBankAccount();
  const { goBack } = useHistory();
  const [isPepOrSo, setIsPepOrSo] = useState(false);
  const { goToNextRoute } = useIdValidationRoutes();

  const isJuridical = isJuridicalPerson(bankAccount.data?.fiscalDocument);

  const toggleCheckbox = () => {
    setIsPepOrSo((value) => !value);
  };

  const trackAndGoToNextRoute = () => {
    track('pago-nube-prueba-de-vida-pep-so');
    goToNextRoute(isPepOrSo);
  };

  const { mutate, isLoading } = useKycInfoMutation({
    onSuccess: () => trackAndGoToNextRoute(),
  });

  const handleSubmit = () => {
    if (!isJuridical && !isPepOrSo) {
      mutate(1); // numberOfOwners = 1
    } else {
      trackAndGoToNextRoute();
    }
  };

  const footerButtons = (
    <FooterButtons
      onCancelClick={goBack}
      onSubmitClick={handleSubmit}
      isLoading={isLoading}
    />
  );

  const InfoBox = ({
    title,
    description,
  }: {
    title: string;
    description: string;
  }) => (
    <Box>
      <Title as="h5" color="neutral-textHigh" fontWeight="bold">
        {t(`notEligible.${title}`)}
      </Title>
      <Text color="neutral-textLow">
        {capitalize(t(`notEligible.${description}`))}
      </Text>
    </Box>
  );

  if (bankAccount.isLoading) {
    return <IsPepOrSoSkeleton />;
  }

  return (
    <AppLayout title={t('title')} footer={footerButtons}>
      <DesktopCard>
        <Box display="flex" flexDirection="column" gap="7">
          <Box display="flex" flexDirection="column" gap="3">
            <InfoBox
              title="obligatedSubject"
              description="obligatedSubjectDescription"
            />
            <InfoBox
              title="exposedPerson"
              description="exposedPersonDescription"
            />
          </Box>
          <Checkbox
            label={t('physical.checkbox')}
            name="checkbox"
            checked={!isPepOrSo}
            onChange={toggleCheckbox}
          />
        </Box>
      </DesktopCard>
    </AppLayout>
  );
}

export default IsPepOrSo;
