import { useEffect, useState } from 'react';
import axios from 'axios';
import { configurationService, Plan } from 'commons/services';
import { SettlementPeriodType } from 'commons/types';

export type SettlementFee = {
  fee: number;
  settlementPeriod: SettlementPeriodType;
};

export function extractSettlementFees(currentPlan: Plan): SettlementFee[] {
  return currentPlan.anticipations
    .filter((anticipation) => anticipation.paymentMethod === 'credit_card')
    .map((anticipation) => {
      const installment = anticipation.installments.find(
        (installment) => installment.installmentNumber === 1,
      );

      if (!installment) return;

      return {
        fee: installment.fee,
        settlementPeriod: anticipation.settlementPeriod,
      };
    })
    .filter((self: SettlementFee | undefined): self is SettlementFee => !!self)
    .sort((a, b) => a.settlementPeriod - b.settlementPeriod); // sort ascending
}

export function extractWireTransferSettlementFee(
  currentPlan: Plan,
): SettlementFee {
  const anticipation = currentPlan.anticipations.filter(
    (anticipation) => anticipation.paymentMethod === 'wire_transfer',
  )[0];

  return {
    fee: anticipation.installments[0].fee,
    settlementPeriod: anticipation.settlementPeriod,
  };
}

export const FALLBACK_SETTLEMENT_FEES: SettlementFee[] = [
  { fee: 0.0629, settlementPeriod: 0 },
  { fee: 0.0419, settlementPeriod: 7 },
  { fee: 0.0329, settlementPeriod: 14 },
];

export function useSettlementFees(): {
  loading: boolean;
  settlementFees: SettlementFee[];
  wireTransferSettlementFee?: SettlementFee;
} {
  const [loading, setLoading] = useState(true);
  const [remoteSettlementFees, setRemoteSettlementFees] = useState<
    SettlementFee[]
  >(FALLBACK_SETTLEMENT_FEES);
  const [wireTransferSettlementFee, setWireTransferSettlementFee] =
    useState<SettlementFee>();

  useEffect(() => {
    const tryFetchSettlementFees = async () => {
      setLoading(true);

      try {
        const currentPlan = await configurationService.getStorePlan();
        setLoading(false);

        const fees = extractSettlementFees(currentPlan);
        if (fees.length) setRemoteSettlementFees(fees);
        const wireTransferFee = extractWireTransferSettlementFee(currentPlan);
        setWireTransferSettlementFee(wireTransferFee);
      } catch (error: any) {
        if (!axios.isAxiosError(error)) throw error;

        // It's quite possible that this API can't respond - yet - the plans fees for a Store, therefore we fallback to hardcoded fees.
        setLoading(false);
      }
    };

    tryFetchSettlementFees();
  }, [setLoading, setRemoteSettlementFees]);

  return {
    loading,
    settlementFees: remoteSettlementFees,
    wireTransferSettlementFee: wireTransferSettlementFee,
  };
}
