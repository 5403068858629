import axios from 'App/axios';
import { GetChargebacksResponse } from './chargebackServiceResponses';

const getChargebacks = async () => {
  const response =
    await axios.get<GetChargebacksResponse>(`/admin/chargebacks`);

  return response.data;
};

const chargebacksService = {
  getChargebacks,
};

export default chargebacksService;
