import React, { useCallback, useEffect, useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { Alert, Box, Button, Card, Text, Title } from '@nimbus-ds/components';
import { Layout, Page } from '@nimbus-ds/patterns';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile } from 'commons/hooks';
import bankAccountService, {
  GetAvailiableBanksResponse,
  GetBankAccountDataType,
} from 'commons/services/bankAccountService/bankAccountService';
import { useTranslationWithPrefix } from 'commons/utils';
import MobileTopNavBar from 'domains/Brazil/commons/components/MobileTopNavBar';
import NuvemPagoCta from 'domains/Brazil/commons/components/NuvemPagoCta';
import {
  makeOnlyNumberMask,
  makeXOrDigitMask,
} from 'domains/Brazil/commons/utils/account';
import {
  documentMask,
  documentType,
} from 'domains/Brazil/commons/utils/document';
import { WithdrawalPageArgs } from '../../withdrawal';
import InputWithTitle from './components/InputWithTitle';
import SelectAvailableBanks from './components/SelectAvailableBanks';

type BankAccountInfoArgs = {
  bankAccountInfo: GetBankAccountDataType;
  setBankAccount: (value: GetBankAccountDataType) => void;
} & WithdrawalPageArgs;

function BankAccountInfo({
  bankAccountInfo,
  setBankAccount,
  nextPage = () => null,
}: BankAccountInfoArgs): JSX.Element {
  const { t } = useTranslationWithPrefix('bankAccount');
  const [enableConfirmButton, setEnableConfirmButton] = useState<boolean>(true);
  const [listAvailableBanks, setListAvailableBanks] = useState<
    GetAvailiableBanksResponse[]
  >([]);

  const [holderDocument, setHolderDocument] = useState<string>(
    bankAccountInfo?.holderDocument,
  );
  const [bankCode, setBankCode] = useState<string>(bankAccountInfo?.bankCode);
  const [branch, setBranch] = useState<string>(bankAccountInfo?.branchNumber);
  const [branchDigit, setBranchDigit] = useState<string>(
    bankAccountInfo?.branchCheckDigit,
  );
  const [account, setAccount] = useState<string>(
    bankAccountInfo?.accountNumber,
  );
  const [accountDigit, setAccountDigit] = useState<string>(
    bankAccountInfo?.accountCheckDigit,
  );

  const [holderName] = useState<string>(bankAccountInfo.holderName);
  const [holderType] = useState<string>(bankAccountInfo.holderType);
  const [type] = useState<string>(bankAccountInfo.type);

  const isMobile = useIsMobile();

  const updateBankAccount = useCallback(() => {
    track('nuvempago_account_registration_click');
    bankAccountService
      .patchBankAccount({
        holderName: holderName,
        holderDocument: holderDocument,
        branchNumber: branch,
        branchCheckDigit: branchDigit,
        accountNumber: account,
        accountCheckDigit: accountDigit,
        bank: bankCode,
        holderType: holderType,
        type: type,
      })
      .then(async () => {
        setBankAccount(await bankAccountService.getBankAccount());
      })
      .finally(() => {
        nextPage('review');
      });
  }, [
    account,
    accountDigit,
    bankCode,
    branch,
    branchDigit,
    holderDocument,
    holderName,
    holderType,
    type,
    nextPage,
    setBankAccount,
  ]);

  useEffect(() => {
    bankAccountService.getAvailableBanks().then((banks) => {
      setListAvailableBanks(banks);
    });
  }, []);

  useEffect(() => {
    if (branch && accountDigit && account && bankCode) {
      setEnableConfirmButton(false);
    } else {
      setEnableConfirmButton(true);
    }
  }, [branch, accountDigit, account, bankCode]);

  return (
    <Box
      backgroundColor={isMobile ? 'neutral-background' : 'neutral-surface'}
      maxWidth="100vw"
      minHeight="100vh"
      padding="none"
      margin="none"
    >
      {isMobile && <MobileTopNavBar backLinkPath={ROUTES.DASHBOARD} />}
      <Page data-testid="bank-account-page" maxWidth="800px" height="100%">
        <Page.Body paddingX={isMobile ? '4' : '6'}>
          <Layout columns="1">
            <Layout.Section>
              <Title>{t('title')}</Title>
            </Layout.Section>
            <Layout.Section>
              <Text
                color={'neutral-textLow'}
                fontSize={'highlight'}
                lineHeight={'highlight'}
              >
                {t('description')}
              </Text>
              <Box data-testid="alert-bank-account">
                <Alert appearance="neutral" title={t('alert.title')}>
                  {t('alert.description')}
                </Alert>
              </Box>
            </Layout.Section>
            <Layout.Section>
              <Card>
                <InputWithTitle
                  testId="holder-document"
                  title={documentType(
                    bankAccountInfo?.holderDocument,
                  ).toUpperCase()}
                  text={documentMask(holderDocument)}
                  disable={true}
                  handler={setHolderDocument}
                  tooltipText={t('tooltip.document')}
                />
                {listAvailableBanks && (
                  <SelectAvailableBanks
                    title={t('bank')}
                    bank={bankCode}
                    setBank={setBankCode}
                    availableBanks={listAvailableBanks}
                  />
                )}
                <Layout columns="1">
                  <Layout.Section>
                    <Box
                      display="grid"
                      gridTemplateAreas="lfield rfield"
                      gridTemplateColumns="[lfield] 2fr [rfield] 1fr"
                      flexWrap={'wrap'}
                      flexDirection={'column'}
                      gridGap={'2'}
                    >
                      <Box display="grid" gridArea={'lfield'} gap={'5'}>
                        <InputWithTitle
                          testId="branch-number"
                          title={t('branch')}
                          handler={setBranch}
                          text={makeOnlyNumberMask(branch)}
                        />
                        <InputWithTitle
                          testId="account-number"
                          title={t('accountNumber')}
                          text={makeOnlyNumberMask(account)}
                          handler={setAccount}
                          tooltipText={t('tooltip.account')}
                        />
                      </Box>
                      <Box display="grid" gridArea={'rfield'} gap={'5'}>
                        <InputWithTitle
                          testId="branch-digit"
                          title={t('digit')}
                          text={makeXOrDigitMask(branchDigit)}
                          handler={setBranchDigit}
                          tooltipText={t('tooltip.branchDigit')}
                          maxLength={1}
                        />
                        <InputWithTitle
                          testId="account-digit"
                          title={t('digit')}
                          handler={setAccountDigit}
                          text={makeXOrDigitMask(accountDigit)}
                          maxLength={2}
                        />
                      </Box>
                    </Box>
                  </Layout.Section>
                </Layout>
              </Card>
              <Box
                display={'inline-flex'}
                justifyContent={'flex-end'}
                gap={'2'}
                data-testid="withdraw-footer-box"
              >
                <Button
                  onClick={() => nextPage('review')}
                  data-testid="cancel-button-withdraw"
                >
                  {t('cancel')}
                </Button>
                <Button
                  data-testid="confirm-change-account"
                  disabled={enableConfirmButton}
                  onClick={updateBankAccount}
                  appearance={'primary'}
                >
                  {t('confirm')}
                </Button>
              </Box>
              <NuvemPagoCta
                data-testid="withdraw-cta"
                href={
                  'https://atendimento.nuvemshop.com.br/pt_BR/gestao-dos-pagamentos/como-resgatar-saldo-do-nuvem-pago'
                }
                title={t('cta')}
                trackingTag="nuvempago_transfer_can_link_click"
              />
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </Box>
  );
}

export default BankAccountInfo;
