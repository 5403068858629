// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useMemo, useState } from 'react';
import { useHasRole } from 'App/components/RequireRole/useHasRole';
import { FeatureFlag, featureFlagService } from 'commons/services';
import { Roles } from './Roles.enum';

type ShowWithRoleProps = {
  children: JSX.Element;
  role?: Roles;
  roles?: Roles[];
};

function ShowWithRole({ children, role, roles = [] }: ShowWithRoleProps) {
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(true);

  useEffect(() => {
    featureFlagService
      .isFeatureFlagEnabled(FeatureFlag.FS_VALIDATE_USER_ROLE)
      .then((flagEnabled) => setIsFeatureEnabled(flagEnabled))
      .catch(() => setIsFeatureEnabled(false));
  }, []);

  const hasRole = useHasRole({ role, roles });

  if (!isFeatureEnabled) {
    return children;
  }

  return hasRole ? children : null;
}

export default ShowWithRole;
