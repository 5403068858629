/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { Table } from '@nimbus-ds/components';
import { DataTable } from '@nimbus-ds/patterns';
import { DashboardTransaction } from 'commons/services/dashboardService/dashboard.service';
import { PagarmeStatusTranslation } from 'commons/services/dashboardService/pagarme-status-translation.service';
import { OrderType } from 'commons/types/OrderType.enum';
import { useTranslationWithPrefix } from 'commons/utils';
import ListItem from './ListItem';

function TableHeader() {
  const { t } = useTranslationWithPrefix(
    'dashboard.components.dashboardList.columns',
  );

  return (
    <Table.Head>
      <Table.Row backgroundColor={'neutral-surface'}>
        <DataTable.Cell width="60px">{t('type')}</DataTable.Cell>
        <DataTable.Cell width="100px">{t('date')}</DataTable.Cell>
        <DataTable.Cell width="120px">{t('client')}</DataTable.Cell>
        <DataTable.Cell width="120px">{t('paymentMethod')}</DataTable.Cell>
        <DataTable.Cell width="120px">{t('amount')}</DataTable.Cell>
        <DataTable.Cell width="150px">{t('status')}</DataTable.Cell>
      </Table.Row>
    </Table.Head>
  );
}

interface PaginationArgs {
  listItens: DashboardTransaction[];
  isRefundPartial: boolean;
}

function PaginateTransactions({ listItens, isRefundPartial }: PaginationArgs) {
  const [startIndex, setStartIndex] = useState<number>(0);
  const limitItens = 10;

  const [lastIndex, setLastIndex] = useState<number>(startIndex + limitItens);
  const [pages, setPages] = useState<number>(1);

  const [showOnList, setShowOnList] = useState<DashboardTransaction[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { t } = useTranslationWithPrefix(
    'dashboard.components.paginationTable',
  );

  useEffect(() => {
    setPages(Math.max(Math.round(listItens.length / limitItens), 1));
    const start = (currentPage - 1) * limitItens;
    setStartIndex(start);
    setLastIndex(Math.min(listItens.length, start + limitItens));
  }, [currentPage, listItens]);

  useEffect(() => {
    setShowOnList([]);
    const test = listItens.slice(startIndex, lastIndex);

    setShowOnList(test);
  }, [lastIndex, listItens, startIndex]);

  const ListItens = useCallback(() => {
    const rows = showOnList?.map((item) => {
      const type =
        item?.paymentMethod === 'payment_link'
          ? OrderType.PaymentLink
          : OrderType.Checkout;

      const clientName = item?.customer?.name;

      const amount = Number(item?.amounts?.total / 100).toFixed(2);

      const status = PagarmeStatusTranslation(item.status, item.rejectedBy);

      const orderNumber = item?.orderNumber ?? item?.paymentLink?.linkNumber;

      const paymentMethod = item?.paymentMethod;

      const transactionId =
        type == OrderType.Checkout ? item.id : item.paymentLink?.referenceUuid;

      return (
        <ListItem
          key={`${item.id}-${orderNumber}`}
          orderNumber={orderNumber?.toString()}
          type={type}
          transactionId={transactionId ?? ''}
          date={item?.createdAt}
          clientName={clientName}
          paymentMethod={paymentMethod}
          amount={amount}
          status={status}
          isRefundPartial={isRefundPartial}
        />
      );
    });

    return <>{rows}</>;
  }, [isRefundPartial, showOnList]);

  return (
    <DataTable
      data-testid="Dashboard-List"
      footer={
        <DataTable.Footer
          itemCount={t('footer', {
            startIndex: startIndex + 1,
            endIndex: lastIndex,
            total: listItens.length,
          })}
          pagination={{
            activePage: currentPage,
            onPageChange: (page) => setCurrentPage(page),
            pageCount: pages,
          }}
        />
      }
      header={<TableHeader />}
    >
      <ListItens />
    </DataTable>
  );
}

export default PaginateTransactions;
