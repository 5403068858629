enum TransactionStatusFilterEnum {
  ALL = 'all',
  PAID = 'paid',
  CHARGEBACKED = 'chargebacked',
  INPROCESS = 'inProcess',
  REFUND = 'refund',
  PARTIAL_REFUND = 'partialRefund',
  WAITING = 'waiting',
  REJECTED_BY_SECURITY = 'rejectedBySecurity',
  REJECTED_BY_EMISSOR = 'rejectedByEmissor',
  ALL_REJECTED = 'allRejected',
}

export default TransactionStatusFilterEnum;
