/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ROUTES } from 'App/Routes/constants';
import {
  bankAccountService,
  FeatureFlag,
  featureFlagService,
  merchantService,
} from 'commons/services';
import { GetBankAccountDataType } from 'commons/services/bankAccountService/bankAccountService';
import { GetBalanceResponse } from 'commons/services/merchantService/merchantService';
import { MoneyObjectInterface } from 'commons/types';
import { SettlementStatus } from 'commons/types/WithdrawStatusType';
import { useTranslationWithPrefix } from 'commons/utils';
import TransferDetailsSuccess from 'domains/PartialTransfer/TransferDetailsSuccess';
import {
  NotificationBRContext,
  NotificationType,
} from '../Notifications/Notification.context';
import BankAccountInfo from './pages/BankAccount/BankAccountInfo';
import InfoReview from './pages/InfoReview/InfoReview';
import WithdrawAmountForm from './pages/WithdrawAmountForm';

export type WithdrawalPageStep =
  | 'form'
  | 'review'
  | 'result'
  | 'bank-account-form';

export interface WithdrawalPageArgs {
  nextPage?: (nextPage: WithdrawalPageStep) => void;
}

function Withdrawal(): JSX.Element {
  const [page, setPage] = useState<WithdrawalPageStep>('form');
  const [balance, setBalance] = useState<GetBalanceResponse>();
  const [requestError, setRequestError] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState<MoneyObjectInterface>();
  const [bankAccount, setBankAccount] = useState<GetBankAccountDataType>();

  /**CURRENT FLOW */
  const { t } = useTranslationWithPrefix('partialTransfer');

  const notificationContext = useContext(NotificationBRContext);

  const history = useHistory();

  const makeWithdrawal = useCallback(() => {
    merchantService
      .transfer(withdrawAmount)
      .then(() => {
        setPage('result');
      })
      .catch((err) => {
        notificationContext.pushNotification({
          id: `withdrawal-${withdrawAmount?.value}`,
          type: NotificationType.WithdrawalFailed,
          appearance: 'danger',
          title: t('notifications.unexpectedError.title'),
          content: t('notifications.unexpectedError.content', {
            amount: new Intl.NumberFormat().format(withdrawAmount!.value / 100),
          }),
        });

        history.push(ROUTES.DASHBOARD);
      });
  }, [history, notificationContext, t, withdrawAmount]);

  const handleCallback = useCallback(
    (status: SettlementStatus, twoFANewFlow?: boolean) => {
      if (twoFANewFlow && status === SettlementStatus.Approved) {
        setPage('result');
        return;
      }
      makeWithdrawal();
    },
    [makeWithdrawal],
  );
  /** END CURRENT FLOW */

  const goToReview = useCallback((amount: MoneyObjectInterface) => {
    setWithdrawAmount(amount);
    setPage('review');
  }, []);

  const fetch = useCallback(() => {
    Promise.all([
      merchantService.getBalance(),
      bankAccountService.getBankAccount(),
    ])
      .then(([balance, bankAccount]) => {
        setRequestError(false);

        setBalance(balance);
        setBankAccount(bankAccount);
      })
      .catch(() => {
        setRequestError(true);
      });
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const activePage = useCallback(() => {
    switch (page) {
      case 'form':
        return (
          <WithdrawAmountForm
            balance={balance}
            requestError={requestError}
            callBack={goToReview}
            nextPage={setPage}
            refreshHandler={fetch}
          />
        );
      case 'review':
        return (
          <InfoReview
            withdrawAmount={withdrawAmount!}
            bankAccount={bankAccount as any}
            callback={handleCallback}
            nextPage={setPage}
          />
        );

      case 'bank-account-form':
        return (
          <BankAccountInfo
            bankAccountInfo={bankAccount as any}
            nextPage={setPage}
            setBankAccount={setBankAccount}
          />
        );
      case 'result':
        return (
          <TransferDetailsSuccess
            amount={withdrawAmount!}
            createdAt={new Date().toISOString()}
            bankAccountDetails={bankAccount as any}
          />
        );
      default:
        return <></>;
    }
  }, [
    page,
    balance,
    requestError,
    goToReview,
    fetch,
    withdrawAmount,
    bankAccount,
    handleCallback,
  ]);

  return activePage();
}

export default Withdrawal;
