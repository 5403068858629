/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Table } from '@nimbus-ds/components';
import { DataTable } from '@nimbus-ds/patterns';
import {
  ChargesDashboardTransaction,
  Origin,
} from 'commons/services/dashboardServiceV2/dashboard.service';
import { getStatusTagCode } from 'commons/services/dashboardServiceV2/status-tag.service';
import { OrderType } from 'commons/types/OrderType.enum';
import { useTranslationWithPrefix } from 'commons/utils';
import DashboardFiltersContext from '../filter/DashboardFiltersContext';
import ListItem from '../list/ListItem';

function TableHeader() {
  const { t } = useTranslationWithPrefix(
    'dashboard.components.dashboardList.columns',
  );

  return (
    <Table.Head>
      <Table.Row backgroundColor={'neutral-surface'}>
        <DataTable.Cell width="60px">{t('type')}</DataTable.Cell>
        <DataTable.Cell width="100px">{t('date')}</DataTable.Cell>
        <DataTable.Cell width="120px">{t('client')}</DataTable.Cell>
        <DataTable.Cell width="120px">{t('paymentMethod')}</DataTable.Cell>
        <DataTable.Cell width="120px">{t('amount')}</DataTable.Cell>
        <DataTable.Cell width="150px">{t('status')}</DataTable.Cell>
      </Table.Row>
    </Table.Head>
  );
}

interface PaginationArgs {
  listItens: ChargesDashboardTransaction[];
  itemsCount: number;
  isRefundPartial: boolean;
}

function PaginateTransactions({
  listItens,
  itemsCount,
  isRefundPartial,
}: PaginationArgs) {
  const { page, itemsPerPage, setPage } = useContext(DashboardFiltersContext);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [lastIndex, setLastIndex] = useState<number>(startIndex + itemsPerPage);
  const [pages, setPages] = useState<number>(1);
  const [showOnList, setShowOnList] = useState<ChargesDashboardTransaction[]>(
    [],
  );

  const { t } = useTranslationWithPrefix(
    'dashboard.components.paginationTable',
  );

  useEffect(() => {
    setPages(Math.max(Math.ceil(itemsCount / itemsPerPage), 1));
    const start = (page - 1) * itemsPerPage;
    setStartIndex(start);
    setLastIndex(Math.min(itemsCount, start + itemsPerPage));
  }, [listItens, page, itemsCount, itemsPerPage]);

  useEffect(() => {
    setShowOnList(listItens);
  }, [listItens]);

  const ListItens = useCallback(() => {
    const rows = showOnList?.map((item) => {
      const type =
        item?.origin === Origin.PaymentLink
          ? OrderType.PaymentLink
          : OrderType.Checkout;
      const clientName = item?.customer?.name;
      const amount = Number(item?.amount?.value).toFixed(2);
      const status = getStatusTagCode(item.status, item.failureReason);
      const orderNumber = item?.orderNumber > 0 ? item?.orderNumber : undefined;
      const paymentMethod = item?.paymentMethod;
      const orderId = item.id.toString();

      return (
        <ListItem
          key={`${item.id}-${orderNumber}`}
          orderNumber={orderNumber?.toString()}
          type={type}
          transactionId={orderId ?? ''}
          date={item?.createdAt}
          clientName={clientName}
          paymentMethod={paymentMethod ?? ''}
          amount={amount}
          status={status}
          isRefundPartial={isRefundPartial}
        />
      );
    });

    return <>{rows}</>;
  }, [isRefundPartial, showOnList]);

  return (
    <DataTable
      data-testid="Dashboard-List"
      footer={
        <DataTable.Footer
          itemCount={t('footer', {
            startIndex: startIndex + 1,
            endIndex: lastIndex,
            total: itemsCount,
          })}
          pagination={{
            activePage: page,
            onPageChange: (page) => {
              setPage(page);
            },
            pageCount: pages,
          }}
        />
      }
      header={<TableHeader />}
    >
      <ListItens />
    </DataTable>
  );
}

export default PaginateTransactions;
