import React from 'react';
import { Text } from '@nimbus-ds/components';

export function MultilineText(
  props: { children: string } & React.ComponentProps<typeof Text>,
) {
  return (
    <React.Fragment>
      {props.children
        .split('\n')
        .filter((s) => !!s)
        .map((t, i) => (
          <Text key={i}>{t}</Text>
        ))}
    </React.Fragment>
  );
}
