import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Box, Icon, Text, Title } from '@nimbus-ds/components';
import { LightbulbIcon, MobileIcon, UserCircleIcon } from '@nimbus-ds/icons';
import { useQueryClient } from '@tanstack/react-query';
import AppLayout from 'App/components/AppLayout';
import { GetIdValidationResponse } from 'commons/services';
import { useTranslationWithPrefix } from 'commons/utils';
import DesktopCard from '../components/DesktopCard';
import FooterButtons from '../FooterButtons';
import { useIdValidationRoutes } from '../hooks';
import { useProofOfLifeVerification } from './metamap/useProofOfLifeVerification';
import ProofOfLifeInfoSkeleton from './ProofOfLifeInfoSkeleton';

function ProofOfLife(): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation.proofOfLife');
  const { goBack } = useHistory();
  const [startingVerification, setStartingVerification] = useState(false);
  const { goToNextRoute } = useIdValidationRoutes();
  // cant destructure methods like '{ cancelQueries } = useQueryClient()' because of https://github.com/TanStack/query/issues/1575
  const queryClient = useQueryClient();

  const finishVerification = useCallback(async () => {
    track('pago-nube-prueba-de-vida-analisis');

    const queryKey = ['identityValidation'];
    await queryClient.cancelQueries({ queryKey });
    queryClient.setQueryData<GetIdValidationResponse>(queryKey, (old) => ({
      ...old,
      status: 'pending_review',
      updatedAt: new Date().toISOString(),
      kycDocumentErrors: [],
    }));
    queryClient.setQueryDefaults(queryKey, { staleTime: Infinity });

    setStartingVerification(false);
    goToNextRoute();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    goToNextRoute,
    queryClient.cancelQueries,
    queryClient.setQueryData,
    queryClient.setQueryDefaults,
  ]);

  const exitVerification = useCallback(() => {
    setStartingVerification(false);
    goToNextRoute();
  }, [goToNextRoute]);

  const { startVerification, isLoading: integrationLoading } =
    useProofOfLifeVerification({
      onFinish: finishVerification,
      onExit: exitVerification,
    });

  const handleSubmit = () => {
    setStartingVerification(true);
    track('pago-nube-prueba-de-vida-recomendaciones');
    startVerification();
  };

  const footerButtons = (
    <FooterButtons
      onCancelClick={goBack}
      onSubmitClick={handleSubmit}
      isLoading={startingVerification}
    />
  );

  const InfoBox = ({
    icon,
    textKey,
  }: {
    icon: React.ReactNode;
    textKey: string;
  }) => (
    <Box display="flex" flexDirection="row" alignItems="flex-start" gap="3">
      <Icon source={icon} color="primary-interactive" />
      <Text color="neutral-textLow">{t(textKey)}</Text>
    </Box>
  );

  if (integrationLoading) {
    return <ProofOfLifeInfoSkeleton />;
  }

  return (
    <AppLayout title={t('title')} footer={footerButtons}>
      <DesktopCard>
        <Box display="flex" flexDirection="column" gap="6">
          <Title as="h5">{t('subtitle')}</Title>
          <Title as="h5" fontWeight="bold">
            {t('recomendations')}
          </Title>
          <Box display="flex" flexDirection="column" gap="4">
            <InfoBox icon={<LightbulbIcon />} textKey="getGoodlight" />
            <InfoBox icon={<UserCircleIcon />} textKey="dontUseAccessories" />
            <InfoBox icon={<MobileIcon />} textKey="faceInsideCircle" />
          </Box>
        </Box>
      </DesktopCard>
    </AppLayout>
  );
}

export default ProofOfLife;
