/*
 * This component is a copy of the Nimbus V1 Timeline as
 * Nimbus V2 does not provide the same component.
 * It should be removed once Nimbus V2 provides it.
 */

import React from 'react';
import TimelineItem from './TimelineItem';
import './Timeline.css';

interface TimelineInterface {
  children: React.ReactNode;
}

function Timeline({ children }: TimelineInterface): JSX.Element {
  return <div className="custom-pn-timeline-wrapper">{children}</div>;
}

Timeline.Item = TimelineItem;

export default Timeline;
