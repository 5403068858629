import { getSessionToken } from '@tiendanube/nexo/helpers';
import axios, { AxiosResponse } from 'axios';
import { transformToCamel } from 'commons/utils';
import {
  dataDogLogger,
  DataDogLoggerActionTypes,
} from 'commons/utils/dataDogLogger';
import nexo from './nexoClient';

function logFailedRequest(error: unknown) {
  if (axios.isAxiosError(error)) {
    dataDogLogger.generate({
      actionMessage: 'Request failed',
      actionOwner: 'Axios',
      actionType: DataDogLoggerActionTypes.Error,
      error,
      actionData: error.response?.config?.data ?? {},
    });
  } else {
    dataDogLogger.generate({
      actionMessage: 'Request failed',
      actionOwner: 'Axios',
      actionType: DataDogLoggerActionTypes.Error,
      error,
      actionData: {},
    });
  }
}

function transformResponse(response: AxiosResponse<any>) {
  if (!response || !response.data) return response;

  let transformedResponse = {
    ...response,
  };

  try {
    transformedResponse = {
      ...transformedResponse,
      data: transformToCamel(response.data),
    };
  } catch (error: any) {
    dataDogLogger.generate({
      actionMessage: 'Conversion failed',
      actionOwner: 'Axios',
      actionType: DataDogLoggerActionTypes.Error,
      error,
      actionData: {
        response,
      },
    });
  }

  return transformedResponse;
}

function transformRejectedResponse(error: unknown) {
  logFailedRequest(error);
  if (axios.isAxiosError(error) && error.response)
    error.response = transformResponse(error.response);
  throw error;
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BFF,
});

axiosInstance.interceptors.request.use(async (request) => {
  const token = await getSessionToken(nexo);
  const bearerToken = `Bearer ${token}`;
  request.headers.set({ ...request.headers, Authorization: bearerToken });
  return request;
});

axiosInstance.interceptors.response.use(
  transformResponse,
  transformRejectedResponse,
);

export default axiosInstance;
