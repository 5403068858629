import React from 'react';
import {
  Box,
  BoxMarginProperties,
  Card,
  Text,
  Title,
} from '@nimbus-ds/components';
import { Currency } from 'commons/types/Currency.enum';
import { useTranslationWithPrefix } from 'commons/utils';
import MoneyField from 'domains/Brazil/commons/components/MoneyField';
import { TransactionSummaryType } from 'domains/Brazil/commons/types/TransactionsDetailsType';
import { showFeesForStatus } from 'domains/Brazil/commons/utils/status';

interface Props {
  readonly status: string;
  readonly summary: TransactionSummaryType;
}

type RenderIfValueExistsArgs = React.PropsWithChildren<{ value?: number }>;

function RenderIfValueExists({
  value,
  children,
}: Readonly<RenderIfValueExistsArgs>): JSX.Element {
  if (value === 0) return <></>;

  return <>{children}</>;
}

type ValueProps = {
  value?: number;
  isNegative?: boolean;
  fontWeight?: React.ComponentProps<typeof MoneyField>['fontWeight'];
};

function Value({ value, isNegative, fontWeight }: Readonly<ValueProps>) {
  return (
    <MoneyField
      color={isNegative ? 'danger-textLow' : 'neutral-textLow'}
      textAlign="right"
      fontSize="base"
      fontWeight={fontWeight}
      prefixString={isNegative ? '- ' : undefined}
      value={Number(value)}
      currency={Currency.BRL}
      type="cents"
    />
  );
}

type RenderValueRowProps = Pick<ValueProps, 'value' | 'isNegative'> & {
  label: string;
  marginTop?: BoxMarginProperties;
};

function RenderValueRow({
  value,
  label,
  isNegative,
  marginTop,
}: Readonly<RenderValueRowProps>) {
  return (
    <RenderIfValueExists value={value}>
      <Box marginTop={marginTop ?? '2-5'}>
        <Text fontSize="base" textAlign="left" color="neutral-textLow">
          {label}
        </Text>
      </Box>
      <Box marginTop={marginTop ?? '2-5'}>
        <Value value={Number(value)} isNegative={isNegative} />
      </Box>
    </RenderIfValueExists>
  );
}

export function SummaryCard(props: Props): JSX.Element {
  const { t } = useTranslationWithPrefix('transactions.details.summary');

  return (
    <Box minWidth="343px">
      <Card padding="none">
        <Box marginLeft={'4'} marginTop={'4'}>
          <Card.Header title={t('title')} />
        </Box>
        <Card.Body>
          <Box marginLeft={'4'} marginRight={'4'}>
            <Box
              justifyContent={'flex-start'}
              alignItems={'stretch'}
              gridTemplateColumns="1fr 100px"
              gridTemplateRows="1fr"
              display="grid"
            >
              <RenderValueRow
                value={props.summary.paid || props.summary.total}
                label={t('paid')}
                marginTop="none"
              />
              {showFeesForStatus(props.status) && (
                <>
                  <RenderValueRow
                    value={props.summary.fee}
                    label={t('fee')}
                    isNegative
                  />
                  <RenderValueRow
                    value={props.summary.refunded}
                    label={t('refunded')}
                    isNegative
                  />
                  <RenderValueRow
                    value={props.summary.cancelFee}
                    label={t('cancelFee')}
                  />
                </>
              )}
            </Box>
          </Box>
        </Card.Body>
        <Box backgroundColor="neutral-surfaceDisabled" padding="none">
          <Box marginLeft={'4'} marginRight={'4'} borderRadius={'none'}>
            <Box
              justifyContent={'flex-start'}
              alignItems={'center'}
              gridTemplateColumns="1fr 100px"
              gridTemplateRows="50px"
              display="grid"
              borderRadius={'none'}
            >
              <Text
                fontSize="base"
                textAlign="left"
                fontWeight="bold"
                color="neutral-textLow"
              >
                {t('total')}
              </Text>
              <Value value={Number(props.summary.total)} fontWeight="bold" />
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

function Skeleton(): JSX.Element {
  return (
    <Box minWidth="343px">
      <Card padding="none">
        <Box marginX={'4'} marginTop={'4'}>
          <Title.Skeleton width="79px" height="28px" />
        </Box>
        <Card.Body padding="none">
          <Box marginX="4">
            {Array.from({ length: 2 }).map((_, i) => (
              <Box display="flex" gap="16" marginBottom="2-5" key={i}>
                <Box flexGrow="1">
                  <Text.Skeleton width="100%" fontSize="highlight" />
                </Box>
                <Box>
                  <Text.Skeleton width="73px" fontSize="highlight" />
                </Box>
              </Box>
            ))}
            <Box marginTop="4" marginBottom="1">
              <Text.Skeleton width="100%" height="26px" fontSize="highlight" />
            </Box>
          </Box>
        </Card.Body>
        <Box backgroundColor="neutral-surfaceDisabled">
          <Box marginX="4" marginY="4" borderRadius={'none'}>
            <Box display="flex" gap="16" marginBottom="2-5">
              <Box flexGrow="1">
                <Text.Skeleton width="100%" fontSize="highlight" />
              </Box>
              <Box>
                <Text.Skeleton width="104px" fontSize="highlight" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

SummaryCard.Skeleton = Skeleton;

export default SummaryCard;
