import React from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Box, Link, Text } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { ChargebackWithOrderDataType } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';

interface ChargebackListProps {
  chargebacks: ChargebackWithOrderDataType[];
  status: string;
}

export default function ChargebackList({
  chargebacks,
  status,
}: ChargebackListProps): JSX.Element {
  const history = useHistory();
  const { t } = useTranslationWithPrefix('home.chargebackList');

  const redirectToTransactionDetail = (orderId: number) => {
    track('pago-nube-contracargo-click', {
      status,
    });
    history.push(`${ROUTES.TRANSACTION_DETAILS}/${orderId}`);
  };

  return (
    <Box as="span">
      {chargebacks.map((chargeback, index, elements) => (
        <>
          <Link
            key={`chargeback-alert-${chargeback.orderId}`}
            as="a"
            onClick={() => redirectToTransactionDetail(chargeback.orderId)}
          >
            #{chargeback.orderNumber}
          </Link>
          {index < elements.length - 2 ? (
            <Text as="span">{t('comma')}</Text>
          ) : index === elements.length - 2 ? (
            <Text as="span">{t('and')}</Text>
          ) : null}
        </>
      ))}
    </Box>
  );
}
