import axios from 'App/axios';
import { GetRefundReceiptDetailsResponse } from './refundReceiptServiceResponses';

export type GetRefundReceiptDetailsType = (
  id: string,
) => Promise<GetRefundReceiptDetailsResponse>;

const getRefundReceiptDetails: GetRefundReceiptDetailsType = async (
  id: string,
) => {
  const response = await axios.get(`/admin/transactions/${id}/refund-receipt`);
  return response.data;
};

interface RefundReceiptServiceInterface {
  getRefundReceiptDetails: GetRefundReceiptDetailsType;
}

const refundReceiptService: RefundReceiptServiceInterface = {
  getRefundReceiptDetails,
};

export default refundReceiptService;
