import React from 'react';
import { Box, Button, Modal, Spinner, Text } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

interface WarningModalProps {
  exitButtonDisabled: boolean;
  onDismiss: () => void;
  onExit: () => void;
  open: boolean;
}

function WarningModal({
  onDismiss,
  open,
  onExit,
  exitButtonDisabled,
}: WarningModalProps): JSX.Element {
  const { t } = useTranslationWithPrefix('commons.warningModal');

  return (
    <Modal
      open={open}
      onDismiss={onDismiss}
      maxWidth={{ xs: '80%', md: '500px' }}
    >
      <Modal.Header title={t('title')} />
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="6">
        <Text color="neutral-textHigh" fontSize="base">
          {t('description')}
        </Text>
        <Box display="flex" justifyContent="flex-end" gap="4">
          <Button
            onClick={onExit}
            appearance="danger"
            disabled={exitButtonDisabled}
          >
            {exitButtonDisabled && <Spinner size="small" />}
            {t('exit')}
          </Button>
          <Button onClick={onDismiss}>{t('continue')}</Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default WarningModal;
