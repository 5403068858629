import React from 'react';
import { Box, Spinner } from '@nimbus-ds/components';
import { InteractiveList } from '@nimbus-ds/patterns';
import { useIntersectionObserver } from 'usehooks-ts';
import { ErrorMessage } from '../ErrorMessage';
import { useFutureIncomesMobileQuery } from '../hooks';
import IncomesByDateSkeleton from './IncomesByDateSkeleton';
import IncomesByDateTable from './IncomesByDateTable';

function IncomesByDateMobile(): JSX.Element {
  const { data, isError, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useFutureIncomesMobileQuery();

  const handleOnEndScroll = () => {
    if (isError || isLoading || isFetching || !hasNextPage || !data.meta)
      return;

    fetchNextPage();
  };

  const { ref } = useIntersectionObserver({
    onChange: (isIntersecting) => isIntersecting && handleOnEndScroll(),
  });

  if (isLoading) return <IncomesByDateSkeleton />;
  if (isError) return <ErrorMessage />;

  return (
    <div>
      <InteractiveList>
        <IncomesByDateTable incomes={data.items} aRef={ref} />
        {isFetching && (
          <InteractiveList.Structure title="" showTitle={false}>
            <Box display="flex" justifyContent="center" padding="1">
              <Spinner />
            </Box>
          </InteractiveList.Structure>
        )}
      </InteractiveList>
    </div>
  );
}

export default IncomesByDateMobile;
