import React from 'react';
import { Box, Card, Tag, Text, Title } from '@nimbus-ds/components';
import { useStoreInfo } from 'commons/hooks';
import { GetRefundReceiptDetailsResponse } from 'commons/services/refundReceiptService';
import {
  formatCuit,
  formatCurrency,
  formatNumericDate,
  useTranslationWithPrefix,
} from 'commons/utils';
import PaymentDetailSkeleton from './PaymentDetailSkeleton';
import PaymentMethodDetail from './PaymentMethodDetail';

interface PaymentDetailInterface {
  receiptDetails: GetRefundReceiptDetailsResponse;
}

function PaymentDetail({
  receiptDetails,
}: Readonly<PaymentDetailInterface>): JSX.Element {
  const { t } = useTranslationWithPrefix('refundReceipt.paymentDetail');
  const { storeInfo } = useStoreInfo();

  const isValidOrderNumber = (orderNumber: string | number) =>
    Number(orderNumber) >= 0;

  return (
    <Card data-testid="refund-receipt-payment-detail">
      <Card.Header>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          {isValidOrderNumber(receiptDetails.orderNumber) ? (
            <Title as="h4">
              {t('title', {
                orderNumber: receiptDetails.orderNumber,
              })}
            </Title>
          ) : (
            <Title as="h4">{t('titleInvalidOrderNumber')}</Title>
          )}
          <Tag appearance="neutral">
            {formatNumericDate(storeInfo, receiptDetails.createdAt.toString())}
          </Tag>
        </Box>
      </Card.Header>
      <Card.Body>
        <Box
          display="flex"
          alignItems="flex-start"
          alignSelf="stretch"
          flexDirection="column"
          paddingBottom="3"
          gap="1"
        >
          <Text fontSize="base" color="neutral-textLow" textAlign="left">
            {t('amount')}
          </Text>
          <Text color="neutral-textHigh">
            {`${formatCurrency(
              receiptDetails.totalAmountWithInterest,
              storeInfo,
            )}`}
          </Text>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          alignSelf="stretch"
          flexDirection="column"
          paddingBottom="3"
          gap="1"
        >
          <PaymentMethodDetail
            paymentMethod={receiptDetails.paymentMethod}
            installments={receiptDetails.installments}
            totalInterest={receiptDetails.totalInterest}
            card={receiptDetails.card}
            paymentMethodBrand={receiptDetails.paymentMethodBrand}
          />
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          alignSelf="stretch"
          flexDirection="column"
          gap="1"
        >
          <Text
            fontSize="base"
            fontWeight="regular"
            color="neutral-textLow"
            textAlign="left"
          >
            {receiptDetails.payer.name}
          </Text>
          <Text
            fontSize="base"
            fontWeight="regular"
            color="neutral-textLow"
            textAlign="left"
          >
            {t('document', {
              document:
                receiptDetails.payer.document.length > 9
                  ? formatCuit(receiptDetails.payer.document)
                  : receiptDetails.payer.document,
            })}
          </Text>
        </Box>
      </Card.Body>
    </Card>
  );
}

PaymentDetail.Skeleton = PaymentDetailSkeleton;

export default PaymentDetail;
