import React, { useState } from 'react';
import { Box, Spinner, Text } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

interface useLoadingInterface {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  Loading: JSX.Element;
}

export default function useLoading(initialValue = false): useLoadingInterface {
  const { t } = useTranslationWithPrefix('useLoading');
  const [isLoading, setIsLoading] = useState(initialValue);

  const Loading = (
    <Box display="flex" flexDirection="column" gap="2">
      <Spinner size="large" />
      <Text fontWeight="bold">{t('loading')}</Text>
    </Box>
  );

  return { isLoading, setIsLoading, Loading };
}
