import React from 'react';
import { Alert, Box, Card, Tag, Text, Title } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

const PersonalDataSkeleton = (): JSX.Element => {
  const { t } = useTranslationWithPrefix('configuration.personalData');

  return (
    <Card>
      <Card.Header>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Title as="h4">{t('cardTitle')}</Title>
          <Tag.Skeleton />
        </Box>
      </Card.Header>
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="6">
        <Alert.Skeleton />
        <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
          <Text.Skeleton />
          <Text.Skeleton />
        </Box>
      </Box>
    </Card>
  );
};

export default PersonalDataSkeleton;
