import React, { useState } from 'react';
import { Box, Icon, Link, Table, Text } from '@nimbus-ds/components';
import { ChevronDownIcon, ChevronUpIcon } from '@nimbus-ds/icons';
import { useStoreInfo } from 'commons/hooks';
import { WithdrawObjectInterface } from 'commons/types';
import {
  bankAccountIsUnknown,
  formatCurrency,
  formatDate,
  useTranslationWithPrefix,
} from 'commons/utils';
import { getErrorFromStatusCode } from '../getErrorFromStatusCode';
import StatusTag from '../StatusTag';

interface WithdrawsTableRowsInterface {
  withdraw: WithdrawObjectInterface;
}

export default function WithdrawsTableRow({
  withdraw,
}: WithdrawsTableRowsInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('withdraws.withdrawsTable');
  const { storeInfo } = useStoreInfo();
  const [showBank, setShowBank] = useState(false);
  const errorCode = getErrorFromStatusCode(withdraw.statusCode);

  const toggleShowBank = () => setShowBank((prev) => !prev);

  return (
    <>
      <Table.Row id={`${withdraw.id}`}>
        <Table.Cell>
          {bankAccountIsUnknown(withdraw.bankAccount) ? (
            <Text>#{withdraw.withdrawNumber}</Text>
          ) : (
            <Link fontSize="base" appearance="primary" onClick={toggleShowBank}>
              #{withdraw.withdrawNumber}
              <Icon
                color="currentColor"
                source={showBank ? <ChevronUpIcon /> : <ChevronDownIcon />}
              />
            </Link>
          )}
        </Table.Cell>
        <Table.Cell>
          <Text>{formatDate(storeInfo, withdraw.requestedAt)}</Text>
        </Table.Cell>
        <Table.Cell>
          {bankAccountIsUnknown(withdraw.bankAccount) ? (
            <Text>{t('noInformation')}</Text>
          ) : (
            <Text>{withdraw.bankAccount.holderName}</Text>
          )}
        </Table.Cell>
        <Table.Cell>
          <Text>{`${formatCurrency(withdraw.amount, storeInfo)}`}</Text>
        </Table.Cell>
        <Table.Cell>
          <StatusTag tagKey="5" statusType={withdraw.status} />
        </Table.Cell>
      </Table.Row>
      {showBank && (
        <Table.Row>
          {/* TODO: fix with nimbus and change td by Table.Cell */}
          <td colSpan={5}>
            <Box padding="2" backgroundColor="neutral-surface">
              <Text>
                {t('bank')}: {withdraw.bankAccount.bankName}
              </Text>
              <Text>
                {t('cbu')}: {withdraw.bankAccount.accountNumber}
              </Text>
              {errorCode ? (
                <Box marginTop="2">
                  <Text>{t(`statusCode.${errorCode}`)}</Text>
                </Box>
              ) : null}
            </Box>
          </td>
        </Table.Row>
      )}
    </>
  );
}
