import React from 'react';
import { useHistory } from 'react-router';
import { Alert, Button } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { ValidationStatusType } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';

interface SettlementPeriodInfoProps {
  identityValidationStatus: ValidationStatusType;
}

function SettlementPeriodInfo({
  identityValidationStatus,
}: Readonly<SettlementPeriodInfoProps>): JSX.Element {
  const { t } = useTranslationWithPrefix('settlementPeriod.infoAlert');
  const history = useHistory();

  const redirectToValidateIdentity = () => {
    history.push(ROUTES.IDENTITY_VALIDATION);
  };

  const redirectToConfiguration = () => {
    history.push(ROUTES.CONFIG);
  };

  switch (identityValidationStatus) {
    case 'pending':
      return (
        <Alert appearance="warning">
          {t('pending')}
          <Button onClick={redirectToValidateIdentity}>
            {t('validateIdentity')}
          </Button>
        </Alert>
      );

    case 'pending_review':
      return <Alert appearance="neutral">{t('pendingReview')}</Alert>;

    case 'pending_documentation':
      return (
        <Alert appearance="warning">
          {t('pendingDocumentation')}
          <Button onClick={redirectToConfiguration}>
            {t('goToConfiguration')}
          </Button>
        </Alert>
      );

    default:
      return <Alert>{t('delay')}</Alert>;
  }
}

export default SettlementPeriodInfo;
