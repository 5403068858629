import React from 'react';
import { Box } from '@nimbus-ds/components';
import DetailAlert from 'domains/Brazil/PaymentLink/pages/Details/components/DetailAlert';

interface AlertRefundArgs {
  isShowAlert: boolean;
  title: string;
  message: string;
  linkTitle?: string;
  linkUrl?: string;
  appearance:
    | 'primary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'neutral'
    | undefined;
  testid: string;
  handleAlertCloseClick: () => void;
}

export function AlertRefund({
  isShowAlert,
  title,
  message,
  linkTitle,
  linkUrl,
  appearance,
  testid,
  handleAlertCloseClick,
}: AlertRefundArgs): JSX.Element {
  return (
    <Box
      data-testid="Alert-Refund-Box"
      display={isShowAlert ? 'block' : 'none'}
      marginBottom={'2'}
    >
      <DetailAlert
        title={title}
        message={message}
        linkTitle={linkTitle}
        linkUrl={linkUrl}
        appearance={appearance}
        onRemove={handleAlertCloseClick}
        dataTestId={testid}
      />
    </Box>
  );
}
