import React from 'react';
import { Box, Text } from '@nimbus-ds/components';
import { CalendarIcon } from '@nimbus-ds/icons';
import { useStoreInfo } from 'commons/hooks';
import { formatLongDate } from 'commons/utils';

interface DateLabelProps {
  date: string;
}

function DateLabel({ date }: DateLabelProps): JSX.Element {
  const { storeInfo } = useStoreInfo();
  return (
    <Box display="flex" gap="2" alignItems="center" justifyContent="flex-start">
      <CalendarIcon size={16} />
      <Text>{formatLongDate(storeInfo, date)}</Text>
    </Box>
  );
}

export default DateLabel;
