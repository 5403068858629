import React from 'react';
import { Box, Text } from '@nimbus-ds/components';

function BankAccountInfoSkeleton(): JSX.Element {
  return (
    <Box
      backgroundColor="neutral-surfaceDisabled"
      padding="2"
      borderRadius="1"
      display="flex"
      flexDirection="column"
      gap="1"
    >
      <Text.Skeleton />
      <Text.Skeleton />
      <Text.Skeleton />
      <Text.Skeleton />
    </Box>
  );
}

export default BankAccountInfoSkeleton;
