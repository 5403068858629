import React from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Link, Table } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { useStoreInfo } from 'commons/hooks';
import {
  formatCurrency,
  formatTime,
  useTranslationWithPrefix,
} from 'commons/utils';
import { useDateFutureIncomesDesktopQuery } from 'domains/FutureIncomes/hooks';
import Pagination from '../../components/Pagination';
import { ErrorMessage } from '../../ErrorMessage';
import { IncomesByOrderProps } from './IncomesByOrder';
import IncomesByOrderSkeleton from './IncomesByOrderSkeleton';

function IncomesByOrderDesktop({ date }: IncomesByOrderProps): JSX.Element {
  const { t } = useTranslationWithPrefix('futureIncome.incomesByOrder');
  const { push } = useHistory();
  const { storeInfo } = useStoreInfo();
  const { data, isError, isLoading, isFetching, page, setPage } =
    useDateFutureIncomesDesktopQuery(date);

  if (isLoading) return <IncomesByOrderSkeleton />;
  if (isError) return <ErrorMessage />;

  const meta = data.meta;
  const renderPagination = () => (
    <Pagination
      firstItemOfPage={meta.firstItemOfPage}
      lastItemOfPage={meta.lastItemOfPage}
      totalCount={meta.totalCount}
      totalPages={meta.totalPages}
      page={page}
      setPage={setPage}
    />
  );

  if (isFetching) {
    return (
      <>
        <IncomesByOrderSkeleton numberOfRows={data.items.length} />
        {renderPagination()}
      </>
    );
  }

  const goToTransaction = (orderId: number) => {
    track('pago-nube-futuros-ingresos-seleccion-venta');
    push(`${ROUTES.TRANSACTION_DETAILS}/${orderId}`);
  };

  const headers = [
    t('headers.order'),
    t('headers.customer'),
    t('headers.time'),
    t('headers.amount'),
  ];

  return (
    <>
      <Table>
        <Table.Head>
          {headers.map((header) => (
            <Table.Cell backgroundColor="neutral-surface" key={header}>
              {header}
            </Table.Cell>
          ))}
        </Table.Head>
        <Table.Body>
          {data.items.map((income) => (
            <Table.Row key={income.orderId}>
              <Table.Cell>
                <Link
                  appearance="primary"
                  textDecoration="none"
                  onClick={() => goToTransaction(income.orderId)}
                >
                  {t('order', { orderNumber: income.orderNumber })}
                </Link>
              </Table.Cell>
              <Table.Cell>{income.customer}</Table.Cell>
              <Table.Cell>{formatTime(income.date)}</Table.Cell>
              <Table.Cell>
                {formatCurrency(income.netAmount, storeInfo)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {renderPagination()}
    </>
  );
}

export default IncomesByOrderDesktop;
