import React from 'react';
import {
  InstallmentsFeeResponsible,
  MoneyObjectInterface,
  PaymentMethodsType,
} from 'commons/types';
import CardMethod from './CardMethod';
import TicketMethod from './TicketMethod';
import WireTransferMethod from './WireTransferMethod';

interface PaymentMethodDescriptionProps {
  installments?: number;
  installmentsValue?: MoneyObjectInterface;
  card?: {
    brand: string;
    holderName: string;
    lastFourDigits: string;
  };
  installmentsPlan?: string;
  installmentsFeeResponsible?: InstallmentsFeeResponsible;
  paymentMethod?: PaymentMethodsType;
  paymentMethodBrand?: string;
}

function PaymentMethodDescription({
  installments,
  installmentsValue,
  card,
  installmentsPlan,
  installmentsFeeResponsible,
  paymentMethod,
  paymentMethodBrand,
}: Readonly<PaymentMethodDescriptionProps>) {
  switch (paymentMethod) {
    case 'debit_card':
    case 'credit_card':
      return (
        <CardMethod
          card={card}
          installments={installments}
          installmentsValue={installmentsValue}
          installmentsPlan={installmentsPlan}
          installmentsFeeResponsible={installmentsFeeResponsible}
        />
      );

    case 'wire_transfer':
      return <WireTransferMethod />;

    case 'ticket':
      return <TicketMethod brand={paymentMethodBrand} />;

    default:
      return null;
  }
}

export default PaymentMethodDescription;
