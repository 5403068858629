import React from 'react';
import { Text } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

type paymentMethodArgs = {
  paymentMethod: string;
};
function PaymentMethod({ paymentMethod }: paymentMethodArgs): JSX.Element {
  const { t } = useTranslationWithPrefix('paymentMethods');

  return (
    <Text data-testid={`payment-method-field`}>
      {t(paymentMethod === 'payment_link' ? 'credit_card' : paymentMethod)}
    </Text>
  );
}

function Skeleton(): JSX.Element {
  return <Text.Skeleton width="100%" height="20px" />;
}

PaymentMethod.Skeleton = Skeleton;

export default PaymentMethod;
