import { useQuery } from '@tanstack/react-query';
import { chargebackService } from 'commons/services';

export function useChargebacks() {
  const chargebacksQuery = useQuery([`chargebacksQuery`], () =>
    chargebackService.getChargebacks(),
  );

  return {
    chargebacks: chargebacksQuery.isSuccess && chargebacksQuery.data,
    loading: chargebacksQuery.isLoading,
  };
}
