import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@nimbus-ds/components';
import { useIsMobile } from 'commons/hooks';
import { HeaderInterface } from './Header/Header';
import Header from './Header';

interface AppLayoutInterface extends HeaderInterface {
  children: React.ReactNode;
  footer?: React.ReactNode;
  skeleton?: boolean;
}

function AppLayout(props: AppLayoutInterface): JSX.Element {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Box
      minHeight="100vh"
      height="100%"
      backgroundColor={isMobile ? 'neutral-background' : 'neutral-surface'}
    >
      <Box
        maxWidth="1200px"
        height="100%"
        margin="auto"
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        gap="4"
        padding="6"
      >
        <Header
          backLinkPath={props.backLinkPath}
          backLinkText={props.backLinkText ?? t('appLayout.backLinkText')}
          headerItems={props.headerItems}
          backLinkIsOldAdmin={props.backLinkIsOldAdmin}
          hideBackButton={props.hideBackButton}
          title={props.title}
          subtitle={props.subtitle}
          subtitleItems={props.subtitleItems}
          navBarItems={props.navBarItems}
          skeleton={props.skeleton}
        />
        <Box>{props.children}</Box>
        {props.footer && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            gap="4"
          >
            {props.footer}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default AppLayout;
