import React from 'react';
import {
  Alert,
  Box,
  Card,
  Checkbox,
  Icon,
  Link,
  Tag,
  Text,
  Title,
} from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { ErrorMessage } from 'commons/components';
import { InstallmentV2Type, MoneyObjectInterface } from 'commons/types';
import { formatPercentage, useTranslationWithPrefix } from 'commons/utils';
import { toSnake } from 'ts-case-convert';
import { InstallmentSectionInactive } from '../shared/InstallmentSectionInactive';
import { ResponsibleStatus } from '../useInstallmentsV2';
import MinInstallmentInput from './MinInstallmentInput';

function WithoutInterestInactive(
  props: React.PropsWithChildren<{ openSwitchModal?: () => void }>,
) {
  return (
    <InstallmentSectionInactive
      translationPrefix="installments.withoutInterest"
      openSwitchModal={props.openSwitchModal}
    >
      {props.children}
    </InstallmentSectionInactive>
  );
}

interface Installments {
  planAhora: InstallmentV2Type[];
  withoutInterest: InstallmentV2Type[];
  withInterest: InstallmentV2Type[];
}

export interface WithoutInterestInterface {
  installments?: Installments;
  setInstallments: (installments: Installments) => void;
  configDisabled: boolean;
  hasError: boolean;
  openSwitchModal: () => void;
  status: ResponsibleStatus;
  minInstallmentValue?: MoneyObjectInterface;
  setMinInstallmentValue?: (value: MoneyObjectInterface) => void;
}

export function WithoutInterest({
  installments,
  setInstallments,
  minInstallmentValue,
  setMinInstallmentValue,
  configDisabled,
  hasError,
  openSwitchModal,
  status,
}: WithoutInterestInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('installments.withoutInterest');

  if (hasError)
    return (
      <Card>
        <Card.Header>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap="4"
          >
            <Title as="h4">{t('title')}</Title>
          </Box>
        </Card.Header>
        <ErrorMessage />
      </Card>
    );

  const isEnabledIn = (
    installmentList: InstallmentV2Type[],
    installmentQty: number,
  ): boolean => {
    return installmentList.some(
      (item) => item.quantity == installmentQty && item.enabled,
    );
  };

  const couldBeEnabledIn = (
    installmentName: string,
    installmentQty: number,
  ): boolean => {
    if (!installments) return true;

    const installmentTypes = Object.entries(installments);

    const isEnabledInOtherInstallmentType = installmentTypes.some(
      ([name, list]: [string, InstallmentV2Type[]]) =>
        name !== installmentName &&
        name !== toSnake(installmentName) &&
        isEnabledIn(list, installmentQty),
    );

    return !isEnabledInOtherInstallmentType;
  };

  const checkInstallment = (
    name: string,
    quantity: number,
    enabled: boolean,
    installmentList: InstallmentV2Type[],
  ) => {
    if (installments) {
      const index = installmentList.findIndex(
        (installment) => installment.quantity === quantity,
      );
      if (index >= 0) {
        const updatedInstallment = {
          ...installmentList[index],
          enabled,
        };
        const newInstallments = [...installmentList];
        newInstallments[index] = updatedInstallment;
        setInstallments({
          ...installments,
          [name]: newInstallments,
        });
      }
    }
  };

  const installmentLabel = (installment: InstallmentV2Type) => {
    if (installment.quantity === 1) return t('installments.one');

    return installment.interest
      ? t('installments.many', {
          quantity: installment.quantity,
          rate: formatPercentage(installment.interest),
        })
      : t('installments.many_unknown_interest', {
          quantity: installment.quantity,
        });
  };

  const renderInstallments = (
    name: string,
    installmentList: InstallmentV2Type[],
    forceDisable?: boolean,
    helpInfo?: {
      helpText: string;
      helpLink: string;
    },
  ) => (
    <Box display="flex" alignItems="flex-start" flexDirection="column" gap="2">
      <Text color="neutral-textLow" fontWeight="medium">
        {t(`${name}`)}
      </Text>
      <Text fontSize="caption">{t(`${name}Description`)}</Text>
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        gap="2-5"
      >
        {installmentList.length ? (
          installmentList.map((installment, idx) => (
            <Checkbox
              key={idx}
              label={installmentLabel(installment)}
              name={`${name}-${installment.quantity}`}
              checked={installment.enabled}
              onChange={(event) =>
                checkInstallment(
                  name,
                  installment.quantity,
                  event.currentTarget.checked,
                  installmentList,
                )
              }
              disabled={
                forceDisable ||
                configDisabled ||
                !couldBeEnabledIn(name, installment.quantity)
              }
            />
          ))
        ) : (
          <>
            <Text color="neutral-textLow" fontSize="base">
              {' '}
              {t(`empty_${name}`)}{' '}
            </Text>
            {helpInfo && (
              <Link
                as="a"
                href={helpInfo.helpLink}
                appearance="primary"
                target="_blank"
              >
                <Icon color="currentColor" source={<ExternalLinkIcon />} />
                {helpInfo.helpText}
              </Link>
            )}
          </>
        )}
      </Box>
    </Box>
  );

  const InstallmentsSection = (forceDisable?: boolean) => (
    <Box display="flex" alignItems="flex-start" flexDirection="column" gap="4">
      {renderInstallments(
        'planAhora',
        installments?.planAhora || [],
        forceDisable,
        {
          helpText: t('plan_ahora_help_text'),
          helpLink: t('plan_ahora_help_link'),
        },
      )}
      {renderInstallments(
        'withoutInterest',
        installments?.withoutInterest || [],
        forceDisable,
      )}
      {minInstallmentValue && setMinInstallmentValue ? (
        <MinInstallmentInput
          value={minInstallmentValue}
          onChange={setMinInstallmentValue}
          disabled={forceDisable}
        />
      ) : null}
    </Box>
  );

  const WithoutInterestActive = (
    <Card>
      <Card.Header>
        <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
          <Box display="flex" justifyContent="space-between">
            <Title as="h4">{t('title')}</Title>
            <Tag appearance="success">{t('active')}</Tag>
          </Box>
          <Text color="neutral-textLow" fontSize="base">
            {t('activeSubtitle')}
          </Text>
        </Box>
      </Card.Header>
      {InstallmentsSection()}
    </Card>
  );

  if (status === 'changing_to_customer')
    return (
      <WithoutInterestInactive>
        {InstallmentsSection(true)}
      </WithoutInterestInactive>
    );

  if (status === 'changing_to_merchant')
    return (
      <Card>
        <Card.Header>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap="4"
          >
            <Box display="flex" justifyContent="space-between">
              <Title as="h4">{t('title')}</Title>
              <Tag appearance="warning">{t('inProgress')}</Tag>
            </Box>
            <Text color="neutral-textLow" fontSize="base">
              {t('activeSubtitle')}
            </Text>
            <Alert show appearance="neutral">
              {t('changeInProgress')}
            </Alert>
          </Box>
        </Card.Header>
        {InstallmentsSection(true)}
      </Card>
    );

  if (status === 'merchant_active') return WithoutInterestActive;

  return <WithoutInterestInactive openSwitchModal={openSwitchModal} />;
}
