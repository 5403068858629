import React from 'react';
import { Redirect, useParams } from 'react-router';
import { Alert, Box } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { ErrorMessage } from 'commons/components';
import {
  useBankAccount,
  useIsMobile,
  useRefundReceiptDetails,
} from 'commons/hooks';
import { PaymentMethodsType } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import PaymentDetail from './PaymentDetail';
import PDFDownloadLink from './PDFDownloadLink';
import RefundEventCard from './RefundEventCard';
import RefundReceiptSkeleton from './RefundReceiptSkeleton';

interface RefundReceiptParams {
  orderId: string;
}

function RefundReceipt(): JSX.Element | null {
  const { orderId } = useParams<RefundReceiptParams>();
  const { t } = useTranslationWithPrefix('refundReceipt');
  const isMobile = useIsMobile();
  const refundReceiptDetailsQuery = useRefundReceiptDetails(orderId);
  const bankAccountQuery = useBankAccount();

  if (refundReceiptDetailsQuery.isLoading || bankAccountQuery.isLoading) {
    return <RefundReceiptSkeleton />;
  }
  if (
    !refundReceiptDetailsQuery.refundReceiptDetails ||
    bankAccountQuery.isError
  ) {
    return (
      <AppLayout title={t('title')}>
        <Box
          display="flex"
          alignItems="stretch"
          justifyContent="flex-end"
          flexDirection="column"
        >
          <ErrorMessage />
        </Box>
      </AppLayout>
    );
  }

  const refundDetails = refundReceiptDetailsQuery.refundReceiptDetails;
  const storeDocument = bankAccountQuery.data.fiscalDocument;
  const hasSuccessRefund = !!refundDetails.events?.length;
  const isWireTransfer =
    refundDetails.paymentMethod === PaymentMethodsType.WireTransfer;

  return hasSuccessRefund ? (
    <AppLayout
      title={t('title')}
      headerItems={
        isMobile ? null : (
          <PDFDownloadLink
            as="button"
            refundDetails={refundDetails}
            storeDocument={storeDocument}
          />
        )
      }
      navBarItems={
        <PDFDownloadLink
          as="a"
          refundDetails={refundDetails}
          storeDocument={storeDocument}
        />
      }
    >
      <Box display="flex" flexDirection="column" gap="4">
        {refundDetails.paymentMethod === 'credit_card' ? (
          <Box marginTop="4" marginBottom="5">
            <Alert show appearance="neutral" data-testid="refund-receipt-alert">
              {t('alertMessage')}
            </Alert>
          </Box>
        ) : null}
        {isWireTransfer && refundDetails?.pendingEvent ? (
          <RefundEventCard
            key={`refund-event-${refundDetails.pendingEvent.occurredAt}`}
            event={refundDetails.pendingEvent}
            isPartialRefund={refundDetails.pendingEvent.isPartialRefund}
            isTransferPendingRefund
          />
        ) : null}
        {refundDetails?.events?.map((event) => (
          <RefundEventCard
            key={`refund-event-${event.occurredAt}`}
            event={event}
            isPartialRefund={event.isPartialRefund}
          />
        ))}
        <PaymentDetail receiptDetails={refundDetails} />
      </Box>
    </AppLayout>
  ) : (
    <Redirect to="/" />
  );
}

export default RefundReceipt;
