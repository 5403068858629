import React from 'react';
import { Box, Card, Icon, Title } from '@nimbus-ds/components';

function CardCollapsibleSkeleton(): JSX.Element {
  return (
    <Card>
      <Card.Header>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Title.Skeleton as="h3" />
          <Icon.Skeleton />
        </Box>
      </Card.Header>
    </Card>
  );
}

export default CardCollapsibleSkeleton;
