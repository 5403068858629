import React from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Box, Card, Icon, Link, Title } from '@nimbus-ds/components';
import { InvoiceIcon } from '@nimbus-ds/icons';
import { ROUTES } from 'App/Routes/constants';
import { FeatureDiscoveryPopover, LoadingCard } from 'commons/components';
import { FeatureName } from 'commons/services/featureDiscoveryService/featureDiscoveryServiceResponses';
import {
  InstallmentsFeeResponsible,
  MoneyObjectInterface,
  PaymentMethodsType,
} from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import PaymentMethodDescription from './PaymentMethodDescription';

interface InterfacePaymentMethod {
  installments?: number;
  installmentsValue?: MoneyObjectInterface;
  card?: {
    brand: string;
    holderName: string;
    lastFourDigits: string;
  };
  skeleton?: boolean;
  installmentsPlan?: string;
  installmentsFeeResponsible?: InstallmentsFeeResponsible;
  paymentMethod?: PaymentMethodsType;
  paymentMethodBrand?: string;
  orderId?: string;
  hasSuccessRefund?: boolean;
}

function PaymentMethod({
  card,
  installments,
  installmentsValue,
  installmentsPlan,
  skeleton = false,
  installmentsFeeResponsible,
  paymentMethod,
  paymentMethodBrand,
  orderId,
  hasSuccessRefund,
}: InterfacePaymentMethod): JSX.Element | null {
  const { t } = useTranslationWithPrefix('transactionDetails.paymentMethods');
  const history = useHistory();

  if (skeleton) return <LoadingCard withHeaderLabel />;

  const redirectToRefundReceipt = () => {
    track('pago-nube-ver-comprobante');
    history.push(`${ROUTES.REFUND_RECEIPT}/${orderId}`);
  };

  return (
    <Card>
      <Card.Header padding="none">
        <Title as="h3">{t('title')}</Title>
      </Card.Header>
      <PaymentMethodDescription
        card={card}
        installments={installments}
        installmentsValue={installmentsValue}
        installmentsPlan={installmentsPlan}
        installmentsFeeResponsible={installmentsFeeResponsible}
        paymentMethod={paymentMethod}
        paymentMethodBrand={paymentMethodBrand}
      />
      {hasSuccessRefund && (
        <Box width="fit-content">
          <FeatureDiscoveryPopover
            title={t('popover.title')}
            description={t('popover.description')}
            featureName={FeatureName.TransactionDetailsRefundReceiptLink}
          >
            <Link
              onClick={redirectToRefundReceipt}
              appearance="primary"
              textDecoration="none"
            >
              <Icon source={<InvoiceIcon />} color="primary-interactive" />
              {t('seeRefundReceipt')}
            </Link>
          </FeatureDiscoveryPopover>
        </Box>
      )}
    </Card>
  );
}

export default PaymentMethod;
