import React from 'react';
import { Text } from '@nimbus-ds/components';

type DateFieldArgs = {
  date: Date | string | number;
  formatOptions?: Intl.DateTimeFormatOptions;
  postFormat?: (dt: string) => string;
};

function DateField({
  date,
  formatOptions,
  postFormat,
}: DateFieldArgs): JSX.Element {
  const newDate = new Date(date);
  const formattedString = newDate.toLocaleString(
    'pt-br',
    formatOptions ?? { day: 'numeric', month: 'short' },
  );

  return (
    <Text data-testid={`dateField-${newDate.toISOString()}`}>
      {postFormat ? postFormat(formattedString) : formattedString}
    </Text>
  );
}

function Skeleton(): JSX.Element {
  return <Text.Skeleton width={'100%'} height="20px" />;
}

DateField.Skeleton = Skeleton;

export default DateField;
