import React from 'react';
import { Icon, Tag, Text } from '@nimbus-ds/components';
import { MoneyIcon } from '@nimbus-ds/icons';
import { useTranslationWithPrefix } from 'commons/utils';

export enum TypeTag {
  Paid = 'PAID',
  Refunded = 'REFUNDED',
  PartiallyRefunded = 'PARTIALLYREFUNDED',
  Contested = 'CHARGEBACKED',
  RejectedBySecurity = 'REJECTEDBYSECURITY',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Authorized = 'AUTHORIZED',
  NotDefined = 'UNKNOWN',
}

interface StatusParams {
  type: TypeTag | string | undefined;
}

function StatusTag({ type }: StatusParams): JSX.Element {
  const { t } = useTranslationWithPrefix('statusTag');

  function renderTag(
    appearance?:
      | 'danger'
      | 'primary'
      | 'success'
      | 'warning'
      | 'neutral'
      | undefined,
    msg?: string,
  ) {
    return (
      <Tag appearance={appearance} data-testid="status-tag">
        <Icon color="currentColor" source={<MoneyIcon size={14} />} />
        <Text
          as="span"
          color="currentColor"
          fontSize="caption"
          fontWeight="regular"
          lineHeight="caption"
          textAlign="left"
        >
          {msg}
        </Text>
      </Tag>
    );
  }

  return (
    <>
      {
        {
          [`${TypeTag.Paid}`]: renderTag('success', `${t('approved')}`),
          [`${TypeTag.Refunded}`]: renderTag('neutral', `${t('refunded')}`),
          [`${TypeTag.PartiallyRefunded}`]: renderTag(
            'neutral',
            `${t('partiallyRefunded')}`,
          ),
          [`${TypeTag.Contested}`]: renderTag('neutral', `${t('contested')}`),
          [`${TypeTag.RejectedBySecurity}`]: renderTag(
            'danger',
            `${t('rejectedBySecurity')}`,
          ),
          [`${TypeTag.Failed}`]: renderTag(
            'danger',
            `${t('rejectedByIssuer')}`,
          ),
          [`${TypeTag.Pending}`]: renderTag('warning', `${t('pending')}`),
          [`${TypeTag.Authorized}`]: renderTag('warning', `${t('pending')}`),
          [`${TypeTag.NotDefined}`]: <></>,
        }[type != undefined ? type.toUpperCase() : TypeTag.NotDefined]
      }
    </>
  );
}

function Skeleton(): JSX.Element {
  return <Tag.Skeleton width="70%" height="20px" />;
}

StatusTag.Skeleton = Skeleton;

export default StatusTag;
