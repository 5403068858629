export const ROUTES = {
  HOME: '/',
  BANK_ACCOUNT_CONFIG: '/bank-account-config',
  TRANSFER: '/transfer',
  CONFIG: '/config',
  IDENTITY_VALIDATION: '/id-validation',
  TRANSACTION_DETAILS: '/transactions',
  PAYMENT_LINK: '/payment-link',
  PAYMENT_LINK_FORM: '/payment-link-create',
  PAYMENT_LINK_DETAILS: '/payment-link-details',
  LANDING: '/landing',
  INSTALLMENTS: '/installments',
  WITHDRAWS: '/withdraws',
  REFUND: '/refund',
  ORDER_REFUND: '/order-refund',
  PAYMENT_LINK_REFUND: '/payment-link-refund',
  SETTLEMENT_PERIOD: '/settlement-period',
  PAYMENT_METHODS: '/payment-methods',
  DASHBOARD: '/dashboard',
  TRANSFER_SUCCESS: '/transfer/success',
  REFUND_RECEIPT: '/refund-receipt',
  FUTURE_INCOME: '/future-incomes',
  LENDING_PAYMENT: '/lending/payment',
  LIVENESS_VALIDATION: '/liveness/check',
  TRANSACTIONS_REPORT: '/transactions-report',
  IDENTITY: '/identity',
};
