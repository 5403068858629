import {
  dataDogLogger,
  DataDogLoggerActionTypes,
} from 'commons/utils/dataDogLogger';
import { cbuValidator } from 'commons/utils/index';

function sendBlockedBankCodeToDatadog(bankCode: number | string): void {
  dataDogLogger.generate({
    actionMessage: `The merchant wanted to add a cbu/cvu from a blocked bank (bankCode: ${bankCode})`,
    actionOwner: 'BankRetriever',
    actionData: {
      bankCode,
    },
    actionType: DataDogLoggerActionTypes.Info,
  });
}

export function isFromBlockedBank(cbuNumber: string): boolean {
  const blockedCbuBankCodes = [
    143, // Brubank
    384, // Wilobank
  ];
  const cbuCode = parseInt(cbuNumber.substring(0, 3));
  const isBlockedBank = blockedCbuBankCodes.includes(cbuCode);
  if (isBlockedBank) {
    sendBlockedBankCodeToDatadog(cbuCode);
  }
  return isBlockedBank;
}

export function getAssociatedBank(accountNumber: string): string {
  let associatedBank = '';
  if (isFromBlockedBank(accountNumber)) return associatedBank;
  try {
    associatedBank = cbuValidator.getAssociatedBank(accountNumber);
  } catch (e) {
    associatedBank = getCvuBank(accountNumber);
  }
  return associatedBank;
}

export const getCvuBank = function (cvu: string): string {
  const bankId = cvu.substring(0, 8);
  switch (bankId) {
    case '00000031':
      return 'Mercado Pago';
    case '00000079':
      return 'Ualá';
    case '00000406':
      return 'TAP';
    case '00000666':
      return 'Sysworld Digital S.A.';
    case '00000222':
      return 'Satoshitango';
    case '00000345':
      return 'Resimple';
    case '00000013':
      return 'Prex';
    case '00000062':
      return 'Pluspagos';
    case '00002270':
    case '00002271':
    case '00002272':
    case '00002273':
    case '00002274':
    case '00002275':
    case '00002276':
    case '00002277':
    case '00002278':
    case '00002279':
      return 'Paymovil';
    case '00001470':
    case '00001471':
    case '00001472':
    case '00001473':
    case '00001474':
    case '00001475':
    case '00001476':
    case '00001477':
    case '00001478':
    case '00001479':
      return 'Ripio';
    case '00000475':
      return 'Ank';
    case '00000253':
      return 'Bitso';
    case '00000437':
      return 'CuentaDigital.com';
    case '00001680':
    case '00001681':
    case '00001682':
    case '00001683':
    case '00001684':
    case '00001685':
    case '00001686':
    case '00001687':
    case '00001688':
    case '00001689':
      return 'Lemon';
    case '00000029': // Nubi
    case '00001393': // Belo
    case '00000765': //Personal Pay
    case '00000761': //Personal Pay
    default: // unknown
      sendBlockedBankCodeToDatadog(bankId);
      return '';
  }
};
