import axios from 'App/axios';
import {
  DateRangeType,
  TransactionDetailsType,
  TransactionObjectInterface,
} from 'commons/types';

type GetTransactionDetailType = (
  orderId: string,
) => Promise<TransactionDetailsType>;

const getTransactionDetails: GetTransactionDetailType = async (
  orderId: string,
) => {
  const response = await axios.get(`/admin/transactions/${orderId}`);
  return response.data;
};

type GetTransactionsResponse = {
  transactions: TransactionObjectInterface[];
  page: number;
  itemsPerPage: number;
  itemsCount: number;
};

type GetTransactionsType = (
  page: number,
  search?: string,
  status?: string,
  dateRange?: DateRangeType,
  fromDate?: string,
  toDate?: string,
  hasChargeback?: boolean,
) => Promise<GetTransactionsResponse>;

const getTransactions: GetTransactionsType = async (
  page: number,
  search?: string,
  status?: string,
  dateRange?: DateRangeType,
  fromDate?: string,
  toDate?: string,
  hasChargeback?: boolean,
) => {
  const searchQuery = search ? `&search=${search}` : '';
  const statusQuery = status ? `&status=${status}` : '';
  const dateRangeQuery = dateRange ? `&range=${dateRange}` : '';
  const fromDateQuery = fromDate ? `&from_date=${fromDate}` : '';
  const toDateQuery = toDate ? `&to_date=${toDate}` : '';
  const hasChargebackQuery = hasChargeback
    ? `&has_chargeback=${hasChargeback}`
    : '';
  const response = await axios.get(
    `/admin/transactions?page=${page}${searchQuery}${statusQuery}${dateRangeQuery}${fromDateQuery}${toDateQuery}${hasChargebackQuery}`,
  );
  return response.data;
};

interface TransactionsServiceInterface {
  getTransactionDetails: GetTransactionDetailType;
  getTransactions: GetTransactionsType;
}

const transactionsService: TransactionsServiceInterface = {
  getTransactionDetails,
  getTransactions,
};

export default transactionsService;
