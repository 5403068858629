import React, { ChangeEvent } from 'react';
import { Box, Icon, Input, Link, Text } from '@nimbus-ds/components';
import { MoneyIcon } from '@nimbus-ds/icons';
import { MoneyObjectInterface } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';

interface MinInstallmentInputProps {
  value: MoneyObjectInterface;
  onChange: (value: MoneyObjectInterface) => void;
  disabled?: boolean;
}

function MinInstallmentInput(props: MinInstallmentInputProps) {
  const { t } = useTranslationWithPrefix('installments.minInstallmentInput');

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number.parseInt(e.target.value);
    if (!Number.isInteger(value) || value <= 0) {
      props.onChange({
        currency: props.value.currency,
        value: 0,
      });
    } else {
      props.onChange({
        currency: props.value.currency,
        value,
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="2">
      <Box display="flex" flexDirection="column" gap="1">
        <Text>
          <Text as="span" fontWeight="medium">
            {t('title')}
          </Text>
          {t('optional')}
        </Text>
        <Text>
          {t('description')}{' '}
          <Link
            as="a"
            href={t('helpLink.href')}
            appearance="primary"
            target="_blank"
          >
            {t('helpLink.text')}
          </Link>
        </Text>
      </Box>
      <Box display="flex" flexDirection="column" gap="2" width="15rem">
        <Input
          placeholder={t('noAmount')}
          append={<Icon source={<MoneyIcon />} />}
          value={props.value?.value || ''}
          onChange={onInputChange}
          disabled={props.disabled}
        />
      </Box>
    </Box>
  );
}

export default MinInstallmentInput;
