import React from 'react';
import { track } from '@amplitude/analytics-browser';
import { Alert, Button, Spinner } from '@nimbus-ds/components';
import { useIdentityValidation } from 'commons/hooks';
import { KycDocumentErrorsType } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import {
  dataDogLogger,
  DataDogLoggerActionTypes,
} from 'commons/utils/dataDogLogger';
import {
  useIdValidationRoutes,
  useKycInfoMutation,
} from 'domains/IdentityValidation/NewIdentityValidation/hooks';
import { getKycErrorsTranslation, hasKycErrors } from './getKycError';

interface InterfacePendingDocIdentityAlert {
  kycDocumentsErrors: KycDocumentErrorsType[];
}

const PendingDocIdentityAlert = ({
  kycDocumentsErrors,
}: InterfacePendingDocIdentityAlert): JSX.Element | null => {
  const { t } = useTranslationWithPrefix(
    'configuration.personalData.kycV2.pending_documentation',
  );

  const identityValidation = useIdentityValidation();

  const { mutateAsync, isLoading: isMutating } = useKycInfoMutation({});

  const { goToNextRoute } = useIdValidationRoutes();

  const kycDocumentsErrorTranslation =
    getKycErrorsTranslation(kycDocumentsErrors);

  const { hasWebsiteError, hasProofOfLifeError, hasStatuteError } =
    hasKycErrors(kycDocumentsErrors);

  let title = t('generalError.title');
  let description = t('generalError.description');
  let buttonText = t('generalError.validateButton');

  if (kycDocumentsErrorTranslation) {
    description = t(`${kycDocumentsErrorTranslation}.description`);
    title = t(`${kycDocumentsErrorTranslation}.title`);
    buttonText = t(`${kycDocumentsErrorTranslation}.validateButton`);
  } else {
    dataDogLogger.generate({
      actionMessage:
        'Kyc document translation for pending documentation not found',
      actionOwner: 'PendingDocIdentityAlert',
      actionType: DataDogLoggerActionTypes.Error,
      actionData: {
        kycDocumentsErrors,
      },
    });
  }

  const handleOnClick = async () => {
    if (hasWebsiteError && !hasStatuteError) {
      await mutateAsync(1); // numberOfOwners = 1
    }
    if (hasProofOfLifeError || hasStatuteError) {
      goToNextRoute();
      return;
    }
    track('pago-nube-prueba-de-vida-click-cargo-productos');
    identityValidation.refetch();
  };

  const isLoading = isMutating || identityValidation.isRefetching;

  return (
    <Alert show appearance="danger" title={title}>
      {description}
      <Button appearance="neutral" onClick={handleOnClick} disabled={isLoading}>
        {isLoading && <Spinner size="small" />}
        {buttonText}
      </Button>
    </Alert>
  );
};

export default PendingDocIdentityAlert;
