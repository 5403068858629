import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { Box } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { ErrorMessage } from 'commons/components';
import { useIdentityValidation } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import { DocumentsContext } from '../../DocumentsContext';
import StatuteDocument from '../../StatuteDocument';
import FooterButtons from '../FooterButtons';
import { useIdValidationRoutes, useKycInfoMutation } from '../hooks';
import JuridicalValidationSkeleton from './JuridicalValidationSkeleton';

function JuridicalValidation(): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');
  const documents = useContext(DocumentsContext);
  const identityValidation = useIdentityValidation();
  const history = useHistory();
  const { goToNextRoute } = useIdValidationRoutes();

  const [statuteUploadCompleted, setStatuteUploadCompleted] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const { mutate, isLoading } = useKycInfoMutation({
    onSuccess: () => goToNextRoute(),
  });

  const handleSubmit = () => {
    if (statuteUploadCompleted) {
      mutate(1); // numberOfOwners = 1
    } else {
      setShowErrors(true);
    }
  };

  const footerButtons = (
    <FooterButtons
      onCancelClick={history.goBack}
      onSubmitClick={handleSubmit}
      isLoading={isLoading}
    />
  );

  if (identityValidation.isLoading) {
    return <JuridicalValidationSkeleton />;
  }

  if (identityValidation.isError) {
    return (
      <AppLayout title={t('title')} subtitle={t('subtitle')}>
        <Box
          display="flex"
          alignItems="stretch"
          justifyContent="flex-end"
          flexDirection="column"
        >
          <ErrorMessage />
        </Box>
      </AppLayout>
    );
  }

  return (
    <AppLayout title={t('title')} footer={footerButtons}>
      <Box display="flex" flexDirection="column" gap="6">
        <Box>
          <StatuteDocument
            verifyUploadedDocuments={setStatuteUploadCompleted}
            statuteThumbnailFile={documents.statute}
            showErrors={showErrors}
          />
        </Box>
      </Box>
    </AppLayout>
  );
}

export default JuridicalValidation;
