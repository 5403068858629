import React, { useCallback, useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { Box, Card, Checkbox, Input, Text } from '@nimbus-ds/components';
import { ExclamationCircleIcon } from '@nimbus-ds/icons';
import { useTranslationWithPrefix } from 'commons/utils';
import { formatBRL } from 'domains/Brazil/commons/utils/money';

export interface AmountCardProps {
  value: number;
  maxValue: number;
  setValue: (value: number) => void;
  setError: (error: boolean) => void;
  disabled?: boolean;
}

function AmountCard({
  value,
  maxValue,
  setValue,
  setError,
  disabled,
}: Readonly<AmountCardProps>): JSX.Element {
  const { t } = useTranslationWithPrefix('withdraw.transferAmountForm');
  const [validationError, setValidationError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const inputOnChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const amount = Number(event.target.value.replace(/\D/g, ''));

      if (amount > maxValue) {
        setValidationError(true);
        setError(true);
      } else {
        setValidationError(false);
        setError(false);
      }

      setValue(amount);
      setIsChecked(false);
    },
    [maxValue, setValue, setError],
  );

  const checkboxOnChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(event.target.checked);

      if (event.target.checked) {
        track('nuvempago_transfer_all_balance_select');
        setValue(maxValue);
        setError(false);
        setValidationError(false);
      }
    },
    [maxValue, setValue, setError],
  );

  return (
    <Card padding="none" data-testid="WithdrawAmountForm-AmountCard">
      <Card.Body padding="none">
        <Box display="grid" margin="4" gap="5">
          <Box display="grid" gap="2">
            <Text>{t('amount')}</Text>
            <Input
              type="text"
              inputMode="decimal"
              name="withdrawAmount"
              data-testid="withdrawalAmountInput"
              min={0}
              appearance={validationError ? 'danger' : 'neutral'}
              append={<Text color="neutral-textDisabled">R$</Text>}
              onChange={inputOnChangeHandler}
              disabled={disabled}
              value={formatBRL(value, {
                cents: true,
                stripSymbol: true,
              })}
            />
            {validationError && (
              <Text
                color="danger-textLow"
                fontSize="caption"
                lineHeight="caption"
              >
                <Box
                  display="inline-flex"
                  as="span"
                  gap="1"
                  justifyContent="center"
                >
                  <ExclamationCircleIcon width="14px" height="14px" />
                  {t('helpText')}
                </Box>
              </Text>
            )}
          </Box>
          <Checkbox
            name="transferAvailableAmount"
            data-testid="transferAvailableAmount"
            label={t('transferTotalAvailable')}
            onChange={checkboxOnChangeHandler}
            disabled={disabled}
            checked={isChecked}
          />
        </Box>
      </Card.Body>
    </Card>
  );
}

const Skeleton: React.VFC = () => (
  <Card padding="none" data-testid="WithdrawAmountForm-AmountCard-Skeleton">
    <Card.Body padding="none">
      <Box display="grid" margin="4" gap="5">
        <Box display="grid" gap="2" marginBottom="1">
          <Text.Skeleton width="40px" height="18px" />
          <Input.Skeleton width="100%" />
        </Box>
        <Checkbox.Skeleton width="40px" />
      </Box>
    </Card.Body>
  </Card>
);

AmountCard.Skeleton = Skeleton;

export default AmountCard;
