import { InstallmentsFeeResponsible } from 'commons/types';

export function getInstallmentPlan(
  t: (s: string) => string,
  installmentsFeeResponsible?: InstallmentsFeeResponsible,
  installmentsPlan?: string,
): string {
  if (installmentsFeeResponsible === InstallmentsFeeResponsible.CUSTOMER) {
    return t('customer');
  } else if (installmentsPlan === 'Standard') {
    return t('pagonube');
  } else {
    return installmentsPlan || '';
  }
}
