import { Resource } from 'i18next';
import commonLocale from '../locales';
import { Locale } from './enums';
import { DomainTranslations } from './types';

export const languages: Locale[] = [
  Locale.ARGENTINA,
  Locale.MEXICO,
  Locale.BRAZIL,
];

export const domains: DomainTranslations[] = [commonLocale];

function getResources(
  languages: Locale[],
  domains: DomainTranslations[],
): Resource {
  return languages.reduce((current, lang) => {
    const domain = domains.reduce((current, domain) => {
      return {
        ...current,
        [domain.name]: domain.languages[lang],
      };
    }, {});
    return { ...current, [lang]: domain };
  }, {});
}

const resources = getResources(languages, domains);

export default resources;
