import React from 'react';
import { Popover, Text } from '@nimbus-ds/components';

type ClientNameFieldArgs = {
  name: string;
  limit?: number;
};

function ClientNameField({
  name,
  limit = 40,
}: ClientNameFieldArgs): JSX.Element {
  //regex to remove special chars
  const cleanName = name.replace(/[^\w\s]/g, '');

  let shownName = cleanName;

  if (cleanName.length > limit) {
    shownName = `${cleanName.slice(0, limit)}...`;
  }

  const renderText = () => (
    <Text data-testid={`clientNameText-${cleanName}`}>{shownName}</Text>
  );

  const renderWithLimit = () => {
    if (cleanName.length > limit) {
      return (
        <Popover
          data-testid={`clientNamePopover-${cleanName}`}
          enabledHover
          position="right-end"
          content={<Text color="primary-textLow">{cleanName}</Text>}
        >
          {renderText()}
        </Popover>
      );
    } else {
      return renderText();
    }
  };

  return renderWithLimit();
}

function Skeleton(): JSX.Element {
  return <Text.Skeleton width={'100%'} height="20px" />;
}

ClientNameField.Skeleton = Skeleton;

export default ClientNameField;
