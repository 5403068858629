import React from 'react';
import { useHistory } from 'react-router';
import { Alert, Button } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { useIdentityValidation } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';

function PendingDocBanner(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslationWithPrefix('home.pendingDocBanner');
  const { data: identityValidationData } = useIdentityValidation();
  const showAlert =
    identityValidationData?.status === 'pending_documentation' &&
    !identityValidationData.useKycV2;

  const redirectToValidateIdentity = () => {
    history.push(ROUTES.IDENTITY_VALIDATION);
  };

  return (
    <Alert title={t('title')} show={showAlert} appearance="warning">
      {t('description')}
      <Button appearance="neutral" onClick={redirectToValidateIdentity}>
        {t('validateButton')}
      </Button>
    </Alert>
  );
}

export default PendingDocBanner;
