import { useMutation } from '@tanstack/react-query';
import { useToast } from 'commons/hooks';
import { reportService, SendReportArgs } from 'commons/services/reportService';
import { useTranslationWithPrefix } from 'commons/utils';

interface useCreateReportMutationProps {
  onSuccess: (requestId: number) => void;
}

export function useCreateReportMutation({
  onSuccess,
}: useCreateReportMutationProps) {
  const { t } = useTranslationWithPrefix('transactionsReport');
  const { showToastError } = useToast();

  const useMutationResult = useMutation({
    mutationFn: ({ dateRange, fromDate, toDate }: SendReportArgs) =>
      reportService.sendReportV2(dateRange, fromDate, toDate),
    onSuccess: (data) => onSuccess(data.requestId),
    onError: () => showToastError(t('creationError')),
  });

  return useMutationResult;
}
