import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Box } from '@nimbus-ds/components';
import {
  NotificationAlert,
  NotificationBRContext,
  NotificationType,
} from './Notification.context';

type NotificationsArgs = {
  listenToNotificationTypes: NotificationType[];
};

function Notifications({
  listenToNotificationTypes,
}: NotificationsArgs): JSX.Element {
  const [notifications, setNotifications] = useState<NotificationAlert[]>([]);

  const notificationContext = useContext(NotificationBRContext);

  const fetchNotifications = useCallback(() => {
    const notifications = listenToNotificationTypes.flatMap(
      (notificationType) => {
        return notificationContext.findNotificationByType(notificationType);
      },
    );

    setNotifications(notifications);
  }, [listenToNotificationTypes, notificationContext]);

  const onRemoveNotification = useCallback(
    (notificationId: string) => {
      notificationContext.removeNotification(notificationId);
      fetchNotifications();
    },
    [fetchNotifications, notificationContext],
  );

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  return (
    <Box data-testid="">
      {notifications.map((notification) => {
        return (
          <Alert
            key={notification.id}
            appearance={notification.appearance ?? 'neutral'}
            onRemove={() => onRemoveNotification(notification.id)}
            title={notification.title}
            show
          >
            {notification.content}
          </Alert>
        );
      })}
    </Box>
  );
}

export default Notifications;
