import axios from 'App/axios';

export interface LivenessQrCodeResponse {
  readonly base64: string;
  readonly url: string;
  readonly expirationDate: string;
}

const dataUriRegex = /^data:image\/[\w+.-]+(;charset=[\w-]+)?;base64,/;
const defaultDataUriPrefix = 'data:image/svg+xml;base64,';

const normalizeDataUri = (base64: string) =>
  dataUriRegex.test(base64.slice(0, 100))
    ? base64
    : `${defaultDataUriPrefix}${base64}`;

async function generateValidationQrCode(): Promise<LivenessQrCodeResponse> {
  const { data } = await axios.post<LivenessQrCodeResponse>(
    '/admin/liveness/qr-code',
  );

  return {
    ...data,
    base64: normalizeDataUri(data.base64),
  };
}

export default {
  generateValidationQrCode,
};
