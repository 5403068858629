import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Box, Icon, Link, Text, Title } from '@nimbus-ds/components';
import { ChevronLeftIcon } from '@nimbus-ds/icons';
import { goToOldAdmin, navigateHeader } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { useIsMobile } from 'commons/hooks';

export interface HeaderInterface {
  title: string;
  subtitle?: string;
  subtitleItems?: React.ReactNode;
  backLinkPath?: string;
  backLinkText?: string;
  backLinkIsOldAdmin?: boolean;
  headerItems?: React.ReactNode;
  navBarItems?: React.ReactNode;
  hideBackButton?: boolean;
  skeleton?: boolean;
}

function Header({
  title,
  subtitle,
  subtitleItems,
  backLinkPath = 'back',
  backLinkText,
  backLinkIsOldAdmin,
  headerItems,
  navBarItems,
  hideBackButton,
  skeleton,
}: HeaderInterface): JSX.Element {
  const isMobile = useIsMobile();
  const { goBack, push } = useHistory();

  useEffect(() => {
    if (backLinkIsOldAdmin) {
      navigateHeader(nexo, {
        text: backLinkText,
        goToAdmin: backLinkPath,
      });
    } else {
      if (skeleton) return;
      navigateHeader(nexo, {
        text: backLinkText,
        goTo: backLinkPath,
      });
    }
  }, [backLinkIsOldAdmin, backLinkPath, backLinkText, skeleton]);

  const renderBackButton = () => (
    <Link
      as="a"
      onClick={() => {
        if (backLinkIsOldAdmin) goToOldAdmin(nexo, backLinkPath);
        else if (backLinkPath !== 'back') push(backLinkPath);
        else goBack();
      }}
      textDecoration="none"
    >
      <Icon color="currentColor" source={<ChevronLeftIcon />} />
      <Text color="primary-textLow" fontWeight="bold">
        {backLinkText}
      </Text>
    </Link>
  );

  const MobileNavBar = (
    <Box
      display="flex"
      gap="4"
      justifyContent={hideBackButton ? 'flex-end' : 'space-between'}
    >
      {!hideBackButton && renderBackButton()}
      {navBarItems && <Box>{navBarItems}</Box>}
    </Box>
  );

  const HeaderBody = (
    <Box display="flex" gap="4" flexDirection="column" alignItems="stretch">
      <Box display="flex" gap="4" justifyContent="space-between">
        {skeleton ? (
          <Title.Skeleton as="h1" width="162px" />
        ) : (
          <Title as="h1">{title}</Title>
        )}
        {headerItems && <Box>{headerItems}</Box>}
      </Box>
      {subtitle && <Text>{subtitle}</Text>}
      {subtitleItems && (
        <Box display="flex" gap="4">
          {subtitleItems}
        </Box>
      )}
    </Box>
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      {isMobile && MobileNavBar}
      {HeaderBody}
    </Box>
  );
}

export default Header;
