import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import featureDiscoveryService from 'commons/services/featureDiscoveryService/featureDiscoveryService';
import {
  FeatureName,
  GetFeatureDiscoveryResponse,
} from 'commons/services/featureDiscoveryService/featureDiscoveryServiceResponses';

export function useFeatureDiscovery(featureName: FeatureName) {
  const queryKey = `featureDiscovery${featureName}`;
  const queryClient = useQueryClient();
  const featureDiscoveryQuery = useQuery(
    [queryKey],
    () => featureDiscoveryService.getFeatureDiscovery(featureName),
    {
      initialData: () => ({
        featureName,
        discovered: true,
      }),
    },
  );
  const markAsDiscovered = useMutation({
    mutationFn: () => featureDiscoveryService.postFeatureDiscovery(featureName),
    onSuccess: () => {
      queryClient.setQueryData<GetFeatureDiscoveryResponse>(
        [queryKey],
        (old) => ({
          ...(old ?? {}),
          ...{
            featureName,
            discovered: true,
          },
        }),
      );
    },
  });
  // The query can fail if for example the FeatureDiscovery name isn't whitelisted in the BFF. Because of this, we assume the feature has been discovered to avoid screen crashing.
  const discovered = featureDiscoveryQuery.isError
    ? true
    : !!featureDiscoveryQuery.data?.discovered;
  return {
    isLoading: featureDiscoveryQuery.isLoading,
    isMutating: markAsDiscovered.isLoading,
    isDiscovered: discovered,
    markAsDiscovered,
  };
}
