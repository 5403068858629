import React from 'react';
import { Box, Icon, Text, Title } from '@nimbus-ds/components';

export default function TransferedSkeleton(): JSX.Element {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap="4">
      <Box
        height="36px"
        width="36px"
        minHeight="36px"
        minWidth="36px"
        borderRadius="2"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Icon.Skeleton />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" gap="2">
        <Text.Skeleton />
        <Title.Skeleton as="h2" />
      </Box>
    </Box>
  );
}
