import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Tag, Text } from '@nimbus-ds/components';
import { ChargebackType, TransactionStatusType } from 'commons/types';
import { transactionTagProps } from 'commons/utils';

interface StatusLabelInterface {
  statusType: TransactionStatusType;
  labelKey: string;
  chargeback?: ChargebackType;
}

function StatusLabel({
  statusType,
  labelKey,
  chargeback,
}: StatusLabelInterface): JSX.Element {
  const { t } = useTranslation();

  const statusLabel = transactionTagProps(
    statusType,
    t,
    labelKey,
    undefined,
    chargeback,
  );

  return (
    <Tag appearance={statusLabel.appearance}>
      <Icon source={<statusLabel.icon size={12} />} />
      <Text fontSize="caption">{statusLabel.label}</Text>
    </Tag>
  );
}

export default StatusLabel;
