import React from 'react';
import { Link } from '@nimbus-ds/components';
import { ExclamationTriangleIcon, RedoIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';

type RefreshLinkArgs = ErrorHandlerMessageArgs & {
  label: string;
};
function RefreshLink({
  label,
  handleClick,
}: Pick<RefreshLinkArgs, 'handleClick' | 'label'>): JSX.Element {
  return (
    <Link
      appearance="primary"
      onClick={handleClick}
      data-testid="ErrorHandlerRefreshButton"
      textDecoration="none"
    >
      <RedoIcon />
      {label}
    </Link>
  );
}

type ErrorHandlerMessageArgs = {
  handleClick: () => void;
  title?: string;
  message: string;
  label: string;
};
function ErrorHandlerMessage({
  title,
  message,
  label,
  handleClick,
}: ErrorHandlerMessageArgs): JSX.Element {
  return (
    <EmptyMessage
      actions={<RefreshLink label={label} handleClick={handleClick} />}
      text={message}
      title={title ?? ''}
      icon={<ExclamationTriangleIcon size={'medium'} />}
      data-testid="Error-Handler"
    />
  );
}

export default ErrorHandlerMessage;
