import React from 'react';
import { Box } from '@nimbus-ds/components';
import ContentOrSkeleton from './ContentOrSkeleton';

function ContentLine({
  loading,
  children,
  skeletonTestId = 'content-line-skeleton',
}: {
  loading: boolean;
  children?: JSX.Element[];
  skeletonTestId?: string;
}) {
  return (
    <Box
      display="flex"
      paddingBottom={'1'}
      paddingTop={'1'}
      justifyContent="space-between"
      width={'100%'}
    >
      {children?.map((child, index) => (
        <ContentOrSkeleton
          loading={loading}
          skeletonTestId={skeletonTestId}
          skeletonWidth={index % 2 == 0 ? '50%' : '30%'}
          key={`${skeletonTestId}-${index + 1}`}
        >
          {child}
        </ContentOrSkeleton>
      ))}
    </Box>
  );
}

export default ContentLine;
