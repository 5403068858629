import React from 'react';
import { useHistory } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';
import {
  Alert,
  Box,
  Card,
  Icon,
  Link,
  Text,
  Title,
} from '@nimbus-ds/components';
import { InvoiceIcon } from '@nimbus-ds/icons';
import { ROUTES } from 'App/Routes/constants';
import ShowComponent from 'commons/components/FeatureFlag/ShowWithFeature';
import { FeatureFlag } from 'commons/services';
import { OrderType } from 'commons/types/OrderType.enum';
import { useTranslationWithPrefix } from 'commons/utils';
import { TypeTag } from 'domains/Brazil/commons/components/StatusTag';
import { TransactionSummaryType } from 'domains/Brazil/commons/types/TransactionsDetailsType';
import {
  documentMask,
  documentType,
} from 'domains/Brazil/commons/utils/document';
import { isCreditCard } from 'domains/Brazil/commons/utils/paymentMethod';

interface Props {
  readonly summary: TransactionSummaryType;
}

const getRefundReceiptTrackingTag = (
  status?: string,
  orderType?: OrderType,
): string => {
  const refundType = status === TypeTag.PartiallyRefunded ? 'partial' : 'total';
  if (orderType === OrderType.PaymentLink) {
    return `nuvempago_payment_link_detail_${refundType}_refund_voucher_click`;
  }
  return `nuvempago_sale_detail_${refundType}_refund_voucher_click`;
};

export function PaymentCard(props: Props): JSX.Element {
  const { t: paymentMethodsT } = useTranslationWithPrefix('paymentMethods');
  const { t: paymentT } = useTranslationWithPrefix(
    'transactions.details.payment',
  );
  const history = useHistory();

  function renderInstallmentsDescription(
    installments: number,
    consumerInterest: number,
  ) {
    if (installments === 1) return paymentT('singleinstallment');

    const installmentsText = paymentT('installments', {
      installment: installments,
    });
    const interestText =
      consumerInterest > 0 ? paymentT('consumerInterest') : '';

    return `${installmentsText} ${interestText}`;
  }

  function isOrderRefundedOrPartialRefunded(orderStatus: string | TypeTag) {
    return (
      orderStatus === TypeTag.PartiallyRefunded ||
      orderStatus === TypeTag.Refunded
    );
  }

  return (
    <Box minWidth="343px" data-testid="paymentCardTestId">
      <Card padding="none">
        <Box marginLeft={'4'} marginTop={'4'}>
          <Card.Header title={paymentT('title')} />
        </Box>
        <Card.Body>
          <Box>
            <Box
              justifyContent={'flex-start'}
              alignItems={'stretch'}
              marginLeft={'4'}
              marginRight={'4'}
              marginBottom={'4'}
            >
              <Box marginBottom={'2-5'}>
                <Text fontWeight="medium">
                  {paymentMethodsT(`${props.summary.paymentMethod}`)}
                </Text>
              </Box>
              {isCreditCard(props.summary.paymentMethod) && (
                <Box marginBottom={'2-5'}>
                  <Text
                    fontSize="base"
                    textAlign="left"
                    color="neutral-textLow"
                  >
                    {renderInstallmentsDescription(
                      props.summary.installments,
                      props.summary.consumerInterest,
                    )}
                  </Text>
                </Box>
              )}
              {isCreditCard(props.summary.paymentMethod) &&
                (props.summary.brand || props.summary.cardNumber) && (
                  <Box marginBottom={'2-5'}>
                    <Text
                      fontSize="base"
                      textAlign="left"
                      color="neutral-textLow"
                    >
                      {paymentT('creditcard', {
                        brand: props.summary.brand,
                        lastFourDigits: props.summary.cardNumber,
                      })}
                    </Text>
                  </Box>
                )}
              {props.summary.holderName && (
                <Box marginBottom={'2-5'}>
                  <Text>{props.summary.holderName}</Text>
                </Box>
              )}
              {props.summary.document && (
                <Box marginBottom={'2-5'}>
                  <Text>
                    {`${documentType(
                      props.summary.document,
                    ).toUpperCase()} ${documentMask(props.summary.document)}`}
                  </Text>
                </Box>
              )}
              {isOrderRefundedOrPartialRefunded(
                props.summary.orderStatus ?? '',
              ) && (
                <ShowComponent
                  feature={FeatureFlag.FS_NUVEMPAGO_REFUND_RECEIPT_ENABLE}
                >
                  <Box paddingTop={'2'} maxWidth={'182px'}>
                    <Link
                      as="a"
                      appearance="primary"
                      textDecoration="none"
                      role="link_refundreceipt"
                      onClick={() => {
                        track(
                          getRefundReceiptTrackingTag(
                            props.summary.orderStatus,
                            props.summary.orderType,
                          ),
                        );
                        history.push({
                          pathname: `${ROUTES.TRANSACTION_DETAILS}/${props.summary.id}/${props.summary.orderId}${ROUTES.REFUND_RECEIPT}`,
                        });
                      }}
                    >
                      <Icon
                        color="primary-interactive"
                        source={<InvoiceIcon />}
                      />
                      {paymentT(`link_to_receipt`)}
                    </Link>
                  </Box>
                </ShowComponent>
              )}
              {props.summary.failureCode && (
                <Box paddingTop={'2'} marginBottom={'2-5'}>
                  <Alert>
                    {paymentT(`error.${props.summary.failureCode}`)}
                  </Alert>
                </Box>
              )}
            </Box>
          </Box>
        </Card.Body>
      </Card>
    </Box>
  );
}

function Skeleton(): JSX.Element {
  return (
    <Box minWidth="343px">
      <Card padding="none">
        <Box marginLeft={'4'} marginTop={'4'}>
          <Title.Skeleton width="79px" height="28px" />
        </Box>
        <Card.Body padding="none">
          <Box display="grid" marginBottom={'4'} marginX="4">
            <Box marginBottom="2-5">
              <Text.Skeleton width="100%" fontSize="highlight" />
            </Box>
            <Box marginBottom="none">
              <Text.Skeleton width="100%" fontSize="highlight" />
            </Box>
          </Box>
        </Card.Body>
      </Card>
    </Box>
  );
}

PaymentCard.Skeleton = Skeleton;

export default PaymentCard;
