import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Text } from '@nimbus-ds/components';
import { ChevronLeftIcon } from '@nimbus-ds/icons';
import { useIsMobile } from 'commons/hooks';
import { BoxSpacing } from 'commons/types/Spacing';

export interface MobileTopNavBarProps extends BoxSpacing {
  /**
   * Path to go back.
   *
   * `"back"` is a special value to redirect back using `useHistory()` from
   * `react-router`, but only if the `backLinkOnClick` option is not provided,
   * otherwise it is used as the `onClick` listener.
   */
  backLinkPath?: string;
  /**
   * Optional anchor text.
   */
  backLinkText?: string;
  /**
   * `onClick` handler for the back link.
   *
   * Will always be used as the `onClick` listener if provided, even overriding
   * the default behavior if `"back"` is passed as the `backLinkPath` option.
   * Useful for fine-grained control and paths outside of the React app, such
   * as old admin routes.
   */
  backLinkOnClick?: (e?: MouseEvent | React.MouseEvent) => unknown;
}

type BackLinkProps = {
  path: MobileTopNavBarProps['backLinkPath'];
  onClick: MobileTopNavBarProps['backLinkOnClick'];
};

const BackLink: React.FC<Readonly<BackLinkProps>> = ({
  path,
  onClick,
  children,
}) => {
  if (path && path !== 'back')
    return (
      <Link
        textDecoration="none"
        as={RouterLink}
        to={path}
        onClick={onClick}
        data-testid="mobileTopNavBarBackLink"
      >
        <ChevronLeftIcon />
        {children}
      </Link>
    );

  if (path === 'back' || (!path && onClick))
    return (
      <Link
        textDecoration="none"
        onClick={onClick}
        data-testid="mobileTopNavBarBackLink"
      >
        <ChevronLeftIcon />
        {children}
      </Link>
    );

  return null;
};

const MobileTopNavBar: React.FC<Readonly<MobileTopNavBarProps>> = ({
  backLinkPath,
  backLinkText,
  backLinkOnClick,
  children,
  padding,
  ...boxSpacing
}): JSX.Element | null => {
  const isMobile = useIsMobile();
  const { goBack } = useHistory();

  const onClick = useMemo(() => {
    if (backLinkOnClick) {
      return (e?: MouseEvent | React.MouseEvent) => backLinkOnClick(e);
    }

    if (backLinkPath === 'back') {
      return () => goBack();
    }

    return undefined;
  }, [backLinkPath, backLinkOnClick, goBack]);

  if (!isMobile) return null;

  const hasBackLink = !!backLinkPath || !!onClick;

  return (
    <Box
      padding={padding ?? '4'}
      {...boxSpacing}
      data-testid="mobileTopNavBarContainer"
    >
      <Box
        display="flex"
        justifyContent={hasBackLink ? 'space-between' : 'flex-end'}
        alignItems="center"
        gap="2"
        height="34px"
      >
        <BackLink path={backLinkPath} onClick={onClick}>
          {backLinkText && (
            <Text fontSize="base" color="primary-textHigh">
              {backLinkText}
            </Text>
          )}
        </BackLink>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap="2"
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default MobileTopNavBar;
