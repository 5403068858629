import React from 'react';
import { track } from '@amplitude/analytics-browser';
import { Box, Text } from '@nimbus-ds/components';
import CardCollapsible from 'commons/components/CardCollapsible';
import { useStoreInfo } from 'commons/hooks';
import { InstallmentFee } from 'commons/services/futureIncomesService/futureIncomesService';
import { MoneyObjectInterface } from 'commons/types';
import { formatCurrency, useTranslationWithPrefix } from 'commons/utils';
import IncomesSummarySkeleton from './IncomesSummarySkeleton';

interface IncomesSummaryProps {
  total: MoneyObjectInterface;
  processingFee: MoneyObjectInterface;
  installmentsFees?: InstallmentFee[];
  totalTaxes?: MoneyObjectInterface;
}

function IncomesSummary({
  total,
  processingFee,
  installmentsFees,
  totalTaxes,
}: IncomesSummaryProps): JSX.Element {
  const { t } = useTranslationWithPrefix('futureIncome.summary');
  const { storeInfo } = useStoreInfo();

  const handleOnChange = (open: boolean) => {
    if (open) {
      track('pago-nube-futuros-ingresos-agenda-resumen');
    }
  };

  const renderFee = (installmentFee: InstallmentFee) => {
    return (
      installmentFee.fee.value > 0 && (
        <Box
          key={installmentFee.installmentPlan}
          display="flex"
          gap="4"
          justifyContent="space-between"
        >
          <Text>
            {t(`installmentsFee`, {
              installmentsPlan:
                installmentFee.installmentPlan === 'Standard'
                  ? 'Pago Nube'
                  : installmentFee.installmentPlan,
            })}
          </Text>
          <Text color="danger-interactive">
            -{formatCurrency(installmentFee.fee, storeInfo)}
          </Text>
        </Box>
      )
    );
  };

  return (
    <CardCollapsible
      title={
        <Text color={'neutral-textHigh'} fontWeight={'medium'}>
          {t('title')}
        </Text>
      }
      open={false}
      onChange={handleOnChange}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="2"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Box display="flex" gap="4" justifyContent="space-between">
          <Text>{t('total')}</Text>
          <Text>{formatCurrency(total, storeInfo)}</Text>
        </Box>
        {processingFee.value > 0 && (
          <Box display="flex" gap="4" justifyContent="space-between">
            <Text>{t(`processingFee`)}</Text>
            <Text color="danger-interactive">
              -{formatCurrency(processingFee, storeInfo)}
            </Text>
          </Box>
        )}
        {installmentsFees?.map((fee) => renderFee(fee))}
        {totalTaxes && (
          <Box>
            {totalTaxes.value > 0 && (
              <Box display="flex" gap="4" justifyContent="space-between">
                <Text>{t(`totalTaxes`)}</Text>
                <Text color="danger-interactive">
                  -{formatCurrency(totalTaxes, storeInfo)}
                </Text>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </CardCollapsible>
  );
}

IncomesSummary.Skeleton = IncomesSummarySkeleton;
export default IncomesSummary;
