import React from 'react';
import { Select } from '@nimbus-ds/components';
import { ReportDateRangeType } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';

export default function SelectReportDateOptions({
  dateRange,
  setDateRange,
}: {
  dateRange: ReportDateRangeType;
  setDateRange: (event: { target: { value: string } }) => void;
}) {
  const { t } = useTranslationWithPrefix('home.reportSidebar');

  const selectFilterOptions = [
    { label: t('all'), value: 'all' },
    { label: t('last-24-hours'), value: 'last-24-hours' },
    { label: t('last-7-days'), value: 'last-7-days' },
    { label: t('last-month'), value: 'last-month' },
    { label: t('custom'), value: 'custom' },
  ];

  return (
    <Select
      appearance="neutral"
      id="reportFilter"
      name="reportFilter"
      onChange={setDateRange}
    >
      {selectFilterOptions.map((option) => (
        <Select.Option
          key={option.value}
          label={option.label}
          selected={dateRange === option.value}
          value={option.value}
        />
      ))}
    </Select>
  );
}
