import React from 'react';
import { Title } from '@nimbus-ds/components';
import { useStoreInfo } from 'commons/hooks';
import { MoneyObjectInterface } from 'commons/types';
import { formatCurrency } from 'commons/utils';
import MoneySkeleton from './MoneySkeleton';

interface MoneyInterface {
  amount: MoneyObjectInterface;
}

function Money({ amount }: MoneyInterface): JSX.Element {
  const { storeInfo } = useStoreInfo();

  return (
    <Title as="h4" color="primary-textHigh">
      {`${formatCurrency(amount, storeInfo)}`}
    </Title>
  );
}

Money.Skeleton = MoneySkeleton;
export default Money;
