import React from 'react';
import { Alert } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

interface ErrorAlertInterface {
  show?: boolean;
  onRemove: () => void;
}

function ErrorAlert({ show, onRemove }: ErrorAlertInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('refund.errorAlert');

  return (
    <Alert
      appearance="danger"
      title={t('title')}
      onRemove={onRemove}
      show={show}
    >
      {t('description')}
    </Alert>
  );
}

export default ErrorAlert;
