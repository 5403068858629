import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Alert,
  Box,
  Button,
  Sidebar,
  Title,
  ToastProvider,
} from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile, useStoreInfo } from 'commons/hooks';
import {
  bankAccountService,
  FeatureFlag,
  featureFlagService,
} from 'commons/services';
import {
  Event,
  GetRefundReceiptDetailsResponse,
} from 'commons/services/refundReceiptService';
import { OrderType } from 'commons/types/OrderType.enum';
import { useTranslationWithPrefix } from 'commons/utils';
import {
  dataDogLogger,
  DataDogLoggerActionTypes,
} from 'commons/utils/dataDogLogger';
import MobileTopNavBar from '../commons/components/MobileTopNavBar';
import RenderOnError from '../commons/components/RenderOnError';
import DownloadLinkPopover from './components/DownloadLinkPopover/DownloadLinkPopover';
import { PaymentInfoCard } from './components/PaymentInfoCard/PaymentInfoCard';
import { RefundDetail } from './components/RefundDetail/RefundDetail';

const LazyPDFRefundReceiptDownloadLink = lazy(
  () => import('./components/PDFRefundReceipt/PDFRefundReceiptDownloadLink'),
);

interface RefundReceiptInterface {
  details: GetRefundReceiptDetailsResponse;
  isError: boolean;
  isLoading: boolean;
  receiptPaymentMethod: OrderType;
}

function RefundReceipt({
  details,
  isError,
  isLoading,
  receiptPaymentMethod: orderType,
}: Partial<RefundReceiptInterface>): JSX.Element {
  const { t } = useTranslationWithPrefix('refund.receipt');
  const { t: navT } = useTranslationWithPrefix('navigation');
  const [showAlert, setShowAlert] = useState<boolean>(true);
  const isMobile = useIsMobile();
  const { storeInfo } = useStoreInfo();
  const [storeDocument, setStoreDocument] = useState<string>();

  const backLinkUrl = useMemo(() => {
    const basePath =
      orderType === OrderType.PaymentLink
        ? ROUTES.PAYMENT_LINK_DETAILS
        : ROUTES.TRANSACTION_DETAILS;
    const identifier =
      orderType === OrderType.PaymentLink
        ? details?.referenceUUID
        : details?.transactionId;

    return !!identifier ? `${basePath}/${identifier}` : ROUTES.DASHBOARD;
  }, [details, orderType]);

  useEffect(() => {
    navigateHeader(nexo, {
      goTo: backLinkUrl,
      text: navT('back'),
    });
  }, [backLinkUrl, navT]);

  useEffect(() => {
    if (!details || storeDocument) return;

    const fetchStoreDocument = async () => {
      try {
        const isEnabled = await featureFlagService.isFeatureFlagEnabled(
          FeatureFlag.FS_NUVEMPAGO_REFUND_RECEIPT_FRONTEND_PDF_EXPORT,
        );
        if (!isEnabled) return;

        const { holderDocument } = await bankAccountService.getBankAccount();
        setStoreDocument(holderDocument);
      } catch (error) {
        dataDogLogger.generate({
          error,
          actionMessage: 'Error fetching store document',
          actionOwner: 'RefundReceipt',
          actionType: DataDogLoggerActionTypes.Error,
          actionData: {
            orderType,
            orderId: details.orderId,
            orderNumber: details.orderNumber,
            transactionId: details.transactionId,
            referenceUUID: details.referenceUUID,
          },
        });
      }
    };

    fetchStoreDocument();
  }, [details, orderType, storeDocument]);

  const renderPDFDownloadLink = useCallback(
    (as: 'a' | 'button') => {
      if (!storeDocument) return;

      return (
        <Suspense fallback={null}>
          <DownloadLinkPopover>
            <LazyPDFRefundReceiptDownloadLink
              storeInfo={{ ...storeInfo, document: storeDocument }}
              refundDetails={details!}
              orderType={orderType!}
              as={as}
              trackingTag={`nuvempago_${
                orderType === OrderType.PaymentLink ? 'payment_link' : 'sale'
              }_voucher_refund_download_click`}
            />
          </DownloadLinkPopover>
        </Suspense>
      );
    },
    [details, orderType, storeDocument, storeInfo],
  );

  if (isLoading) {
    return (
      <Box>
        {isMobile ? (
          <Box data-testid="Refund-Skeleton-Mobile">
            <RefundReceipt.SkeletonMobile />
          </Box>
        ) : (
          <Box data-testid="Refund-Skeleton">
            <RefundReceipt.SkeletonDesktop />
          </Box>
        )}
      </Box>
    );
  }

  const renderEvent = (event: Event) => (
    <Box my={'4'} key={crypto.randomUUID()}>
      <RefundDetail
        createdAt={event?.occurredAt}
        totalRefunded={event?.amount}
      />
    </Box>
  );

  const render = () => (
    <Box
      width={'100%'}
      padding={'4'}
      backgroundColor="neutral-surface"
      data-testid="Refund-Page-Component-Desktop"
    >
      {isMobile && (
        <MobileTopNavBar
          backLinkPath={backLinkUrl}
          padding="none"
          marginBottom={'4'}
        >
          {renderPDFDownloadLink('a')}
        </MobileTopNavBar>
      )}

      <Box
        width={{ xs: '100%', md: '44rem', lg: '44rem', xl: '44rem' }}
        marginX={'auto'}
        marginY={'none'}
      >
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Title as="h2">{t('title')}</Title>
            {!isMobile && renderPDFDownloadLink('button')}
          </Box>
          {details?.paymentMethod != 'pix' && (
            <Box marginTop={'4'} marginBottom={'5'}>
              <Alert
                show={showAlert}
                appearance="neutral"
                onRemove={() => setShowAlert(false)}
                data-testid="alert-refundReceipt"
              >
                {t('alertMessage')}
              </Alert>
            </Box>
          )}
        </Box>
        {details?.events?.map((event: Event) => renderEvent(event))}
        <PaymentInfoCard
          {...{
            ...details,
            totalAmount: details?.totalAmount,
            brand: details?.card?.brand,
            lastFourDigits: details?.card?.lastFourDigits,
            holderName: details?.payer?.name,
            document: details?.payer?.document,
            orderType: orderType,
          }}
        />
      </Box>
    </Box>
  );

  return (
    <Box data-testid="Refund-test-id">
      <ToastProvider>
        <RenderOnError
          error={isError!}
          title={t('error.error_get_details_title')}
          message={''}
          label={t('error.error_get_details_label')}
          refreshHandler={() => null}
        >
          {render()}
        </RenderOnError>
      </ToastProvider>
    </Box>
  );
}

export function SkeletonMobile(): JSX.Element {
  return (
    <Box
      gap={'4'}
      padding={'4'}
      alignItems={'flex-start'}
      display="flex"
      flexDirection="column"
      data-testid="Refund-Skeleton"
    >
      <Box>
        <Title.Skeleton width="100%" height="2.75rem" />
        <Title.Skeleton width="15rem" height="2.75rem" />
        <Alert.Skeleton width="100%" height="2.75rem" />
      </Box>
      <RefundDetail.Skeleton />
      <PaymentInfoCard.Skeleton />
      <Sidebar.Footer>
        <Button.Skeleton />
        <Button.Skeleton />
      </Sidebar.Footer>
    </Box>
  );
}

export function SkeletonDesktop(): JSX.Element {
  return (
    <Box width={'100%'} padding={'4'} backgroundColor="neutral-surface">
      <Box>
        <Title.Skeleton width="100%" height="2.75rem" />
        <Alert.Skeleton width="100%" height="2.75rem" />
      </Box>
      <RefundDetail.Skeleton />
      <PaymentInfoCard.Skeleton />
    </Box>
  );
}
RefundReceipt.SkeletonMobile = SkeletonMobile;

RefundReceipt.SkeletonDesktop = SkeletonDesktop;

export default RefundReceipt;
