import React from 'react';
import {
  Box,
  Icon,
  IconButton,
  Link,
  Popover,
  PopoverPlacement,
  Text,
} from '@nimbus-ds/components';
import { CloseIcon } from '@nimbus-ds/icons';
import { useFeatureDiscovery } from 'commons/hooks';
import { FeatureName } from 'commons/services/featureDiscoveryService/featureDiscoveryServiceResponses';

interface FeatureDiscoveryPopoverProps {
  featureName: FeatureName;
  children: JSX.Element;
  title?: string;
  description?: string;
  linkText?: string;
  onLinkClick?: React.MouseEventHandler<HTMLElement>;
  onDismissCallback?: () => void;
  position?: PopoverPlacement;
  zIndex?: React.ComponentProps<typeof Popover>['zIndex'];
}

export default function FeatureDiscoveryPopover({
  children,
  featureName,
  title,
  description,
  linkText,
  onLinkClick,
  onDismissCallback,
  position,
  zIndex,
}: Readonly<FeatureDiscoveryPopoverProps>) {
  const featureDiscovery = useFeatureDiscovery(featureName);

  const handleOnDismiss = () => {
    featureDiscovery.markAsDiscovered.mutate();
    onDismissCallback?.();
  };

  const popoverContent = (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Text color="neutral-surface" fontSize="base" fontWeight="bold">
          {title}
        </Text>
        <Box position="absolute" top="4px" right="4px">
          <IconButton
            onClick={handleOnDismiss}
            size="2.75rem"
            source={<Icon color="neutral-background" source={<CloseIcon />} />}
            borderColor="transparent"
            backgroundColor="transparent"
          />
        </Box>
      </Box>
      <Text color="neutral-surface" fontSize="base">
        {description}
      </Text>
      {linkText && onLinkClick ? (
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Link onClick={onLinkClick} appearance="neutral-background">
            {linkText}
          </Link>
        </Box>
      ) : null}
    </Box>
  );

  return (
    <Popover
      content={popoverContent}
      backgroundColor="primary-interactiveHover"
      visible={!featureDiscovery.isDiscovered}
      position={position ?? 'top-end'}
      maxWidth="300px"
      zIndex={zIndex}
    >
      {children}
    </Popover>
  );
}
