import React from 'react';
import { Box, Input, Text, Tooltip } from '@nimbus-ds/components';
import { QuestionCircleIcon } from '@nimbus-ds/icons';

export type InputWithTitleArgs = {
  title: string;
  text: string;
  handler: (value: string) => void;
  tooltipText?: string;
  disable?: boolean;
  testId: string;
  maxLength?: number;
};

function InputWithTitle({
  title,
  text,
  handler,
  tooltipText,
  disable = false,
  testId,
  maxLength,
}: InputWithTitleArgs): JSX.Element {
  const handlerInputValue = (event: any) => {
    const value = event.target.value as string;
    handler(value);
  };
  return (
    <Box data-testid="input-with-text">
      <Box display={'inline-flex'} gap={'1'} paddingBottom={'2'}>
        <Text>{title}</Text>
        {tooltipText && (
          <Tooltip content={tooltipText} data-testid="tooltip" position="right">
            <QuestionCircleIcon width="14px" height="14px" />
          </Tooltip>
        )}
      </Box>
      <Input
        data-testid={testId}
        disabled={disable}
        value={text}
        onChange={handlerInputValue}
        maxLength={maxLength}
      />
    </Box>
  );
}

export default InputWithTitle;
