import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Icon,
  Link,
  Spinner,
  Text,
} from '@nimbus-ds/components';
import { CreditCardIcon, MoneyIcon, TransferPesoIcon } from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';
import { useMutation, useQuery } from '@tanstack/react-query';
import AppLayout from 'App/components/AppLayout';
import { ROUTES } from 'App/Routes/constants';
import { ErrorMessage, WarningModal } from 'commons/components';
import { useIsMobile, useToast } from 'commons/hooks';
import { configurationService } from 'commons/services';
import { DiscountType, PaymentMethodsType } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import { BankTransferDiscount } from './BankTransferDiscount';
import PaymentMethodsSkeleton from './PaymentMethodsSkeleton';

type PaymentMethod = {
  enabled: boolean;
  paymentMethod: PaymentMethodsType;
};

function PaymentMethods(): JSX.Element {
  const { t } = useTranslationWithPrefix('paymentMethods');
  const { showToastSuccess, showToastError } = useToast();
  const history = useHistory();
  const isMobile = useIsMobile();
  const [changed, setChanged] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();
  const [discounts, setDiscounts] = useState<DiscountType[]>([]);
  const [bankTransferIsEnabled, setBankTransferIsEnabled] = useState(false);
  const [showNoActiveError, setShowNoActiveError] = useState(false);

  const paymentMethodsQuery = useQuery(
    ['paymentMethods'],
    () => configurationService.getPreferences(),
    {
      retry: false,
      onSuccess: (preferences) => {
        const activePaymentMethods = preferences.activePaymentMethods.map(
          (paymentMethod) => ({
            enabled: true,
            paymentMethod: paymentMethod,
          }),
        );
        const inactivePaymentMethods = preferences.inactivePaymentMethods.map(
          (paymentMethod) => ({
            enabled: false,
            paymentMethod: paymentMethod,
          }),
        );
        setPaymentMethods(activePaymentMethods.concat(inactivePaymentMethods));
        setDiscounts(preferences.discounts);
      },
    },
  );

  const updatePaymentMethods = useMutation({
    mutationFn: ({
      activePaymentMethods,
      inactivePaymentMethods,
      discounts,
    }: any) => {
      setButtonDisabled(true);
      return configurationService.patchPreferences(
        activePaymentMethods,
        inactivePaymentMethods,
        discounts,
      );
    },
    onSuccess: () => {
      showToastSuccess(t('success'));
      history.push(ROUTES.CONFIG);
    },
    onError: () => {
      setButtonDisabled(false);
      showToastError(t('error'));
    },
  });

  useEffect(() => {
    if (
      paymentMethods &&
      paymentMethods.filter((method) => method.enabled).length == 0
    ) {
      setShowNoActiveError(true);
    } else {
      setShowNoActiveError(false);
    }
    const wireTransfer = paymentMethods?.find(
      (method) => method.paymentMethod === 'wire_transfer',
    );
    setBankTransferIsEnabled(wireTransfer ? wireTransfer.enabled : false);
  }, [paymentMethods]);

  if (paymentMethodsQuery.isLoading) {
    return <PaymentMethodsSkeleton />;
  }
  if (paymentMethodsQuery.isError) {
    return (
      <AppLayout title={t('title')} backLinkPath={ROUTES.CONFIG}>
        <ErrorMessage />
      </AppLayout>
    );
  }

  const checkPaymentMethod = (
    enabled: boolean,
    paymentMethod: PaymentMethodsType,
  ) => {
    if (paymentMethods) {
      const index = paymentMethods.findIndex(
        (method) => method.paymentMethod === paymentMethod,
      );
      if (index >= 0) {
        const updatedPaymentMethods = [...paymentMethods];
        updatedPaymentMethods[index] = {
          ...paymentMethods[index],
          enabled,
        };
        setChanged(true);
        setPaymentMethods(updatedPaymentMethods);
      }
    }
  };

  const redirectBack = () => {
    history.push(ROUTES.CONFIG);
  };

  const submit = () => {
    if (!changed) {
      showToastSuccess(t('success'));
      redirectBack();
      return;
    }

    if (paymentMethods) {
      const activeMethods = paymentMethods.filter((method) => method.enabled);
      if (activeMethods.length == 0) return;

      const activePaymentMethods = activeMethods.map(
        (method) => method.paymentMethod,
      );
      const inactiveMethods = paymentMethods.filter(
        (method) => !method.enabled,
      );
      const inactivePaymentMethods =
        activeMethods.length == 0
          ? []
          : inactiveMethods.map((method) => method.paymentMethod);
      updatePaymentMethods.mutate({
        activePaymentMethods,
        inactivePaymentMethods,
        discounts,
      });
    }
  };

  const redirectOrWarningModal = () => {
    if (changed) {
      setWarningModalOpen(true);
    } else {
      redirectBack();
    }
  };

  const getIconForPaymentMethod = (method: PaymentMethodsType) => {
    switch (method) {
      case 'credit_card':
      case 'debit_card':
        return <CreditCardIcon />;

      case 'ticket':
        return <MoneyIcon />;

      case 'wire_transfer':
        return <TransferPesoIcon />;

      default:
        return;
    }
  };

  const Buttons = (
    <Box gap="4" display="flex" flexDirection="row">
      <Button onClick={redirectOrWarningModal} disabled={buttonDisabled}>
        {t('cancel')}
      </Button>
      <Button
        appearance="primary"
        onClick={submit}
        disabled={buttonDisabled || showNoActiveError}
      >
        {buttonDisabled && <Spinner size="small" />}
        {t('save')}
      </Button>
    </Box>
  );

  const MobileSaveLink = (
    <Link
      appearance="neutral"
      onClick={submit}
      disabled={buttonDisabled || showNoActiveError}
      as="button"
      textDecoration="none"
    >
      {buttonDisabled && <Spinner size="small" />}
      {t('save')}
    </Link>
  );

  const BankTransferContent = (
    <Box display="flex" flexDirection="column" gap="2" marginTop="2">
      <Box display="flex" flexDirection="column">
        <Text>{t('transferDescription')}</Text>
        <Link as="a" href={t('knowMoreLink')} target="_blank">
          {t('knowMore')}
        </Link>
      </Box>
      {bankTransferIsEnabled && (
        <BankTransferDiscount
          discounts={discounts}
          setDiscounts={setDiscounts}
          setDiscountChanged={setChanged}
        />
      )}
    </Box>
  );

  return (
    <AppLayout
      title={t('title')}
      subtitle={t('subtitle')}
      footer={Buttons}
      navBarItems={isMobile ? MobileSaveLink : undefined}
      backLinkPath={ROUTES.CONFIG}
    >
      <Card padding="none">
        <Box paddingY="1">
          {paymentMethods && paymentMethods.length > 0 && (
            <DataList bottomDivider={false}>
              {paymentMethods.map((method, index) => (
                <DataList.Row
                  key={method.paymentMethod}
                  topDivider={index !== 0}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Box display="flex" flexDirection="row" gap="1">
                      <Icon
                        source={getIconForPaymentMethod(method.paymentMethod)}
                      />
                      <Text color="neutral-textHigh">
                        {t(method.paymentMethod)}
                      </Text>
                    </Box>
                    <Checkbox
                      checked={method.enabled}
                      onChange={(event) =>
                        checkPaymentMethod(
                          event.currentTarget.checked,
                          method.paymentMethod,
                        )
                      }
                      name={method.paymentMethod}
                    />
                  </Box>
                  {method.paymentMethod === 'wire_transfer' &&
                    BankTransferContent}
                </DataList.Row>
              ))}
            </DataList>
          )}
          {showNoActiveError && (
            <Box marginLeft="4" marginBottom="2">
              <Text color="danger-interactive">{t('noActiveDescription')}</Text>
            </Box>
          )}
        </Box>
      </Card>
      <WarningModal
        exitButtonDisabled={buttonDisabled}
        onDismiss={() => setWarningModalOpen(false)}
        onExit={redirectBack}
        open={warningModalOpen}
      />
    </AppLayout>
  );
}

PaymentMethods.Skeleton = PaymentMethodsSkeleton;
export default PaymentMethods;
