import React from 'react';
import { Box, Button } from '@nimbus-ds/components';

function FooterButtonsSkeleton(): JSX.Element {
  return (
    <Box gap="4" display="flex" flexDirection="row">
      <Button.Skeleton />
      <Button.Skeleton />
    </Box>
  );
}

export default FooterButtonsSkeleton;
