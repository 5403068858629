import React from 'react';
import { Box } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { useTranslationWithPrefix } from 'commons/utils';
import StatuteDocument from '../../StatuteDocument';
import FooterButtons from '../FooterButtons';

function JuridicalValidationSkeleton(): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');

  const footerButtons = <FooterButtons.Skeleton />;

  return (
    <AppLayout title={t('title')} footer={footerButtons} skeleton>
      <Box display="flex" flexDirection="column" gap="6">
        <Box>
          <StatuteDocument.Skeleton />
        </Box>
      </Box>
    </AppLayout>
  );
}

export default JuridicalValidationSkeleton;
