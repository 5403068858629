import React from 'react';
import { Box, Card, Icon, Link, Text, Title } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { LoadingCard } from 'commons/components';
import { useTranslationWithPrefix } from 'commons/utils';

interface InterfaceTrackingPage {
  url?: string;
  skeleton?: boolean;
}

function TrackingPage({
  url,
  skeleton = false,
}: InterfaceTrackingPage): JSX.Element | null {
  const { t } = useTranslationWithPrefix('transactionDetails.trackingPage');

  if (skeleton) return <LoadingCard withHeaderLabel />;
  if (!url) return null;

  return (
    <Card>
      <Card.Header padding="none">
        <Title as="h3">{t('title')}</Title>
      </Card.Header>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap="4"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="2"
          alignItems="flex-start"
        >
          <Text>{t('description')}</Text>
          <Link
            as="a"
            href={url}
            appearance="primary"
            textDecoration="none"
            target="_blank"
          >
            <Icon color="currentColor" source={<ExternalLinkIcon />} />
            {t('access')}
          </Link>
        </Box>
      </Box>
    </Card>
  );
}

export default TrackingPage;
