import React from 'react';

export interface DashboardContextArgs {
  showSkeleton: boolean;
  setShowSkeleton: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingTransactions: boolean;
  setLoadingTransactions: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingKyc: boolean;
  setLoadingKyc: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingBalance: boolean;
  setLoadingBalance: React.Dispatch<React.SetStateAction<boolean>>;
  hasTransactionWithoutFilters: boolean;
  setHasTransactionWithoutFilters: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

export default React.createContext<DashboardContextArgs>({
  showSkeleton: true,
  setShowSkeleton: () => {},
  isLoadingTransactions: true,
  setLoadingTransactions: () => {},
  isLoadingKyc: true,
  setLoadingKyc: () => {},
  isLoadingBalance: true,
  setLoadingBalance: () => {},
  hasTransactionWithoutFilters: false,
  setHasTransactionWithoutFilters: () => {},
});
