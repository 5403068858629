import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export enum DataDogLoggerActionTypes {
  Info = 'info',
  Warn = 'warn',
  Debug = 'debug',
  Error = 'error',
}

export type DataDogLoggerGenerateArgs = {
  actionMessage: string;
  actionData: Record<string, unknown>;
  actionOwner: string;
  actionType: DataDogLoggerActionTypes;
  error?: unknown;
};

const generate = (args: DataDogLoggerGenerateArgs): void => {
  const context = {
    ...args.actionData,
    owner: args.actionOwner,
  };
  if (args.error instanceof Error) {
    datadogLogs.logger[args.actionType](
      args.actionMessage,
      context,
      args.error,
    );
  } else {
    datadogLogs.logger[args.actionType](args.actionMessage, context);
  }
};

const setUser = (storeId: string): void => {
  datadogLogs.setUser({ id: storeId });
  datadogRum.setUser({ id: storeId });
};

interface DataDogLogger {
  generate: (args: DataDogLoggerGenerateArgs) => void;
  setUser: (storeId: string) => void;
}

export const dataDogLogger: DataDogLogger = {
  generate,
  setUser,
};
