import React from 'react';
import { Box, Card, Text, Title } from '@nimbus-ds/components';
import ThumbnailFile from 'commons/components/ThumbnailFile/ThumbnailFile';
import './MultipleDniDocument.css';

function StatuteDocumentSkeleton(): JSX.Element {
  return (
    <Card>
      <Card.Header>
        <Title.Skeleton as="h4" />
      </Card.Header>
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <Text.Skeleton />
        <Box display="flex" maxWidth="400px">
          <ThumbnailFile.Skeleton aspectRatio="1/1" />
        </Box>
      </Box>
    </Card>
  );
}

export default StatuteDocumentSkeleton;
