import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { track } from '@amplitude/analytics-browser';
import { goToOldAdmin, navigateHeaderRemove } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { EmptyStatePagoNube } from 'commons/components';

function Landing(): JSX.Element {
  const { t } = useTranslation();

  useEffect(() => {
    navigateHeaderRemove(nexo);
    track('nuvem-pago-conversion-empty-state');
  }, []);

  const activate = () => {
    track('click-activate-conversion-empty-state');
    goToOldAdmin(nexo, 'payments?focus_on=nuvempago');
  };

  const openAboutPage = () => {
    track('click-CAN-conversion-empty-state');
    window.open(t('aboutPage'));
  };

  return (
    <EmptyStatePagoNube
      title={t('landing.message.title')}
      primaryActionLabel={t('landing.message.action')}
      onClickPrimary={activate}
      descriptionFirstParagraph={t('landing.message.description1')}
      descriptionSecondParagraph={t('landing.message.description2')}
      secondaryActionLabel={t('landing.message.about')}
      onClickSecondary={openAboutPage}
    />
  );
}

export default Landing;
