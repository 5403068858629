import { useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ROUTES } from 'App/Routes/constants';
import { useBankAccount, useIdentityValidation } from 'commons/hooks';
import { isJuridicalPerson } from 'commons/utils';
import { hasKycErrors } from 'domains/Configuration/PersonalData/IdentityAlert/NewIdentityAlert/getKycError';
import { ID_VALIDATION_ROUTES } from '../constants';

const introductoryInfoRoute = `${ROUTES.IDENTITY_VALIDATION}`;
const isPepOrSoRoute = `${ROUTES.IDENTITY_VALIDATION}${ID_VALIDATION_ROUTES.IS_PEP_OR_SO}`;
const pepOrSoContactRoute = `${ROUTES.IDENTITY_VALIDATION}${ID_VALIDATION_ROUTES.PEP_OR_SO_CONTACT}`;
const juridicalValidationRoute = `${ROUTES.IDENTITY_VALIDATION}${ID_VALIDATION_ROUTES.JURIDICAL_VALIDATION}`;
const proofOfLifeRoute = `${ROUTES.IDENTITY_VALIDATION}${ID_VALIDATION_ROUTES.PROOF_OF_LIFE}`;
const configRoute = `${ROUTES.CONFIG}`;

export function useIdValidationRoutes() {
  const { push } = useHistory();

  const { path: currentRoute } = useRouteMatch();

  const bankAccount = useBankAccount();
  const isJuridical = isJuridicalPerson(bankAccount.data?.fiscalDocument);

  const identityValidation = useIdentityValidation();
  const isInPendingDoc =
    identityValidation.data?.status === 'pending_documentation';

  const { hasProofOfLifeError, hasStatuteError } = hasKycErrors(
    identityValidation.data?.kycDocumentErrors || [],
  );

  const redirectionRules = useMemo(() => {
    return [
      {
        route: introductoryInfoRoute,
        getNextRoute: () => isPepOrSoRoute,
      },
      {
        route: isPepOrSoRoute,
        getNextRoute: (isPepOrSo: boolean) => {
          if (isPepOrSo) return pepOrSoContactRoute;
          return isJuridical ? juridicalValidationRoute : proofOfLifeRoute;
        },
      },
      {
        route: pepOrSoContactRoute,
        getNextRoute: () => configRoute,
      },
      {
        route: juridicalValidationRoute,
        getNextRoute: () => {
          if (isInPendingDoc && !hasProofOfLifeError) {
            return configRoute;
          }
          return proofOfLifeRoute;
        },
      },
      {
        route: proofOfLifeRoute,
        getNextRoute: () => configRoute,
      },
      {
        route: configRoute,
        getNextRoute: () => {
          if (isInPendingDoc) {
            if (hasStatuteError) return juridicalValidationRoute;
            if (hasProofOfLifeError) return proofOfLifeRoute;
          }
          return introductoryInfoRoute;
        },
      },
    ];
  }, [hasProofOfLifeError, hasStatuteError, isInPendingDoc, isJuridical]);

  const goToNextRoute = useMemo(() => {
    return (isPepOrSo = false) => {
      const redirection = redirectionRules.find(
        (rule) => rule.route === currentRoute,
      );
      if (redirection) {
        const nextRoute = redirection.getNextRoute(isPepOrSo);
        push(nextRoute);
      }
    };
  }, [redirectionRules, currentRoute, push]);

  return { goToNextRoute };
}
