import React from 'react';
import { Box, Card, Text, Thumbnail, Title } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';

function OrderDetailCardSkeleton(): JSX.Element {
  return (
    <Card padding="none">
      <Card.Header padding="base">
        <Title.Skeleton as="h3" />
      </Card.Header>
      <Card.Body padding="none">
        <DataList bottomDivider={false}>
          <DataList.Row
            gap="1"
            topDivider={false}
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="row" gap="2">
              <Thumbnail.Skeleton />
              <Box display="flex" flexDirection="column" gap="2">
                <Text.Skeleton />
                <Text.Skeleton />
                <Text.Skeleton />
              </Box>
            </Box>
            <Text.Skeleton />
          </DataList.Row>
          <DataList.Row
            paddingY="2"
            topDivider={false}
            backgroundColor="neutral-surface"
          >
            <Box
              display="flex"
              flexDirection="row"
              gap="2"
              justifyContent="space-between"
            >
              <Text.Skeleton />
              <Text.Skeleton />
            </Box>
          </DataList.Row>
          <DataList.Row paddingY="2" topDivider={false}>
            <Box
              display="flex"
              flexDirection="row"
              gap="2"
              justifyContent="space-between"
            >
              <Text.Skeleton />
              <Text.Skeleton />
            </Box>
          </DataList.Row>
          <DataList.Row topDivider={false} backgroundColor="neutral-surface">
            <Box
              display="flex"
              flexDirection="row"
              gap="2"
              justifyContent="space-between"
            >
              <Text.Skeleton />
              <Text.Skeleton />
            </Box>
          </DataList.Row>
        </DataList>
      </Card.Body>
    </Card>
  );
}

export default OrderDetailCardSkeleton;
