/* eslint-disable max-len */
import React from 'react';
import { Box } from '@nimbus-ds/components';
import { DashboardTransaction } from 'commons/services/dashboardService/dashboard.service';
import { PagarmeStatusTranslation } from 'commons/services/dashboardService/pagarme-status-translation.service';
import { OrderType } from 'commons/types/OrderType.enum';
import MobileListItem from './ListItem';

interface listArgs {
  listItens: Omit<
    DashboardTransaction,
    'updatedAt' | 'installments' | 'failureCode'
  >[];
  isRefundPartial: boolean;
}

function MobileList({ listItens, isRefundPartial }: listArgs): JSX.Element {
  return (
    <Box data-testid="MobileList">
      {listItens.map((item) => {
        const type =
          item?.paymentMethod === 'payment_link'
            ? OrderType.PaymentLink
            : OrderType.Checkout;

        const clientName = item?.customer?.name;

        const amount = Number(item?.amounts?.total / 100).toFixed(2);

        const status: string = PagarmeStatusTranslation(
          item.status,
          item.rejectedBy,
        );

        const orderNumber = item?.orderNumber ?? item?.paymentLink?.linkNumber;

        const transactionId =
          type == OrderType.Checkout
            ? item.id
            : item.paymentLink?.referenceUuid;

        return (
          <MobileListItem
            key={item.id}
            type={type}
            date={item.createdAt}
            transactionId={transactionId ?? ''}
            orderNumber={orderNumber?.toString()}
            clientName={clientName}
            amount={amount}
            status={status}
            isRefundPartial={isRefundPartial}
          />
        );
      })}
    </Box>
  );
}

export default MobileList;
