import React from 'react';
import { Box, Icon } from '@nimbus-ds/components';
import { ArrowsVerticalIcon } from '@nimbus-ds/icons';

interface BaseMoneyCardProps {
  children: React.ReactNode;
}

function BaseMoneyCard({ children }: BaseMoneyCardProps): JSX.Element {
  return (
    <Box
      display="flex"
      backgroundColor="primary-textLow"
      borderRadius="2"
      flex="0 1 auto"
      gap="4"
      p="4"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        backgroundColor="primary-surfaceHighlight"
        height="36px"
        width="36px"
        minHeight="36px"
        minWidth="36px"
        borderRadius="2"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Icon
          data-testid="callout-card-icon"
          color="primary-textLow"
          source={<ArrowsVerticalIcon size="medium" />}
        />
      </Box>
      <Box display="flex" flex="1 1 auto" flexDirection="column" gap="1">
        {children}
      </Box>
    </Box>
  );
}

export default BaseMoneyCard;
