import React from 'react';
import { useTranslationWithPrefix } from 'commons/utils';
import RenderOnError from 'domains/Brazil/commons/components/RenderOnError';

export const defaultRefreshOnError = () => {};

type ContentOrErrorProps = {
  error: boolean;
  children: JSX.Element;
  refreshHandler?: () => void;
};

function ContentOrError({
  error,
  refreshHandler,
  children,
}: ContentOrErrorProps) {
  const { t } = useTranslationWithPrefix(
    'lending.installment.paymentInfo.loadingError',
  );

  return (
    <RenderOnError
      title={t('errorMessage')}
      message=""
      label={t('tryAgain')}
      error={!!error}
      refreshHandler={refreshHandler ?? defaultRefreshOnError}
    >
      {children}
    </RenderOnError>
  );
}

export default ContentOrError;
