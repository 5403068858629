import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/ReduxProvider/store';
import { getBackLink } from 'redux/selectors/backLinkSelector';
import { clearBackLink, setBackLink } from 'redux/slices/backLinkSlice';
import { IBackLinkState, ISetBackLinkActionArgs } from 'redux/slices/types';

export interface IUseBackLink {
  backLink: IBackLinkState;
  setBackLink: (args: ISetBackLinkActionArgs) => any;
  clearBackLink: () => void;
}

function useBackLink(): IUseBackLink {
  const backLink = useSelector(getBackLink);
  const dispatch = useAppDispatch();
  const dispatchSetBackLink = useCallback(
    (args: ISetBackLinkActionArgs) => dispatch(setBackLink(args)),
    [dispatch],
  );
  const dispatchClearBackLink = useCallback(
    () => dispatch(clearBackLink()),
    [dispatch],
  );

  return {
    backLink,
    setBackLink: dispatchSetBackLink,
    clearBackLink: dispatchClearBackLink,
  };
}

export default useBackLink;
