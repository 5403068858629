import React, { useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { Box, Card, Icon, Link, Text, useToast } from '@nimbus-ds/components';
import { CopyIcon, ExternalLinkIcon } from '@nimbus-ds/icons';
import { copyToClipboard } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { paymentLinkInfo } from 'commons/services/paymentLink/paymentLinkService';
import { useTranslationWithPrefix } from 'commons/utils';

interface Props {
  link: string | undefined;
}

function FooterButtons(props: Pick<paymentLinkInfo, 'paymentUrl'>) {
  const [toastLinkShow, setToastLinkShow] = useState(true);
  const { addToast } = useToast();
  const { t } = useTranslationWithPrefix('paymentLink.result');

  function copyLink() {
    if (toastLinkShow) {
      track('nuvempago_link_detail_copy_link_click');
      copyToClipboard(nexo, props.paymentUrl);
      addToast({
        type: 'success',
        text: t('linkCopied'),
        duration: 4000,
        id: 'payment-link-copy-toast',
      });
    }
    setToastLinkShow(false);

    setTimeout(() => {
      setToastLinkShow(true);
    }, 4000);
  }

  return (
    <Box>
      <Link appearance="primary" onClick={copyLink}>
        <Icon source={<CopyIcon />} color="currentColor" />
        {t('copyButton')}
      </Link>
      {'\u00A0'}
      {'\u00A0'}
      <Link
        as="a"
        appearance="primary"
        href={props.paymentUrl}
        onClick={() => track('nuvempago_link_detail_learn_access_link_click')}
      >
        <Icon source={<ExternalLinkIcon />} color="currentColor" />
        {t('accessButton')}
      </Link>
    </Box>
  );
}
export default function ShareLinkCard(props: Props): JSX.Element {
  const { t } = useTranslationWithPrefix('paymentLink.details.sharelink');

  return (
    <Card padding="none">
      <Box marginLeft={'4'} marginTop={'4'}>
        <Card.Header title={t('title')} />
      </Box>
      <Card.Body>
        <Box marginLeft={'4'} marginRight={'4'} marginBottom={'4'}>
          <Box justifyContent={'flex-start'} alignItems={'stretch'}>
            <Box marginBottom="2-5">
              <Text fontSize="base" color="neutral-textLow" lineHeight="base">
                {t('paymentLinkShareNotification')}
              </Text>
            </Box>
            <Box>
              <FooterButtons
                paymentUrl={props.link == undefined ? '' : props.link}
              />
            </Box>
          </Box>
        </Box>
      </Card.Body>
    </Card>
  );
}
