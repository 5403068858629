import React from 'react';
import { HelpLink } from 'commons/components';
import { useTranslationWithPrefix } from 'commons/utils';

function FutureIncomesHelpLink(): JSX.Element {
  const { t } = useTranslationWithPrefix('futureIncome.helpLink');

  return (
    <HelpLink
      href={t('href')}
      text={t('text')}
      appearance="primary"
      textDecoration="none"
    />
  );
}

export default FutureIncomesHelpLink;
