import React from 'react';
import { Box, Button, Card, Input, Text, Title } from '@nimbus-ds/components';
import { Layout, Page } from '@nimbus-ds/patterns';

const FormSkeleton = (): JSX.Element => (
  <Page maxWidth="800px" data-testid="register-identity-info-form-skeleton">
    <Box padding="4">
      <Title.Skeleton width="126px" />
    </Box>
    <Page.Body paddingX="4" paddingTop="none">
      <Layout>
        <Layout.Section>
          <Card>
            <Title.Skeleton as="h4" width="100%" />
            <Card.Body>
              <Box display="flex" flexDirection="column" gap="4">
                {Array.from({ length: 5 }).map((_, i) => (
                  <Box
                    key={`card1-${i}`}
                    display="flex"
                    flexDirection="column"
                    gap="2"
                  >
                    <Text.Skeleton width="40px" height="14px" />
                    <Input.Skeleton width="100%" />
                  </Box>
                ))}
              </Box>
            </Card.Body>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <Title.Skeleton as="h4" width="100%" />
            <Card.Body>
              <Box display="flex" flexDirection="column" gap="4">
                {Array.from({ length: 4 }).map((_, i) => (
                  <Box
                    key={`card2-${i}`}
                    display="flex"
                    flexDirection="column"
                    gap="2"
                  >
                    <Text.Skeleton width="40px" height="14px" />
                    <Input.Skeleton width="100%" />
                  </Box>
                ))}
              </Box>
            </Card.Body>
          </Card>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            gap="2"
          >
            <Button.Skeleton width="77px" />
            <Button.Skeleton width="77px" />
          </Box>
        </Layout.Section>
      </Layout>
    </Page.Body>
  </Page>
);

export default FormSkeleton;
