import {
  Namespace,
  useTranslation,
  UseTranslationResponse,
} from 'react-i18next';

const useTranslationWithPrefix = (
  keyPrefix: string,
): UseTranslationResponse<Namespace<string>, string> => {
  return useTranslation(undefined, { keyPrefix });
};

export default useTranslationWithPrefix;
