import { Locale } from 'App/i18n/enums';
import { Currency } from 'commons/types/Currency.enum';

const formatter = Intl.NumberFormat(Locale.BRAZIL, {
  style: 'currency',
  currency: Currency.BRL,
});

interface FormatBRLOptions {
  /**
   * Whether `amount` is represented in fractional/subunit (cents) or
   * decimal (money). If true, `amount` is divided by 100.
   */
  cents?: boolean;
  /**
   * Remove the currency symbol (`R$`) from the formatted string.
   */
  stripSymbol?: boolean;
}

export function formatBRL(amount: number, options?: FormatBRLOptions): string {
  const result = formatter.format(options?.cents ? amount / 100 : amount);

  return options?.stripSymbol ? stripCurrencySymbol(result) : result;
}

export const stripCurrencySymbol = (str: string): string =>
  str.replace('R$', '').trim();
