import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import paymentLinkService from 'commons/services/paymentLink/paymentLinkService';
import { GetRefundReceiptDetailsResponse } from 'commons/services/refundReceiptService';
import { OrderType } from 'commons/types/OrderType.enum';
import RefundReceipt from './RefundReceipt';

interface PaymentLinkDetailParams {
  referenceUUID: string;
}

function PaymentLinkRefundReceipt(): JSX.Element {
  const { referenceUUID } = useParams<PaymentLinkDetailParams>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [details, setDetails] = useState<GetRefundReceiptDetailsResponse>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const details =
          await paymentLinkService.getRefundReceiptDetails(referenceUUID);

        details.referenceUUID = referenceUUID;
        setDetails(details);
      } catch (error: any) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [referenceUUID]);

  return (
    <RefundReceipt
      details={details}
      isError={isError}
      isLoading={isLoading}
      receiptPaymentMethod={OrderType.PaymentLink}
    />
  );
}

export default PaymentLinkRefundReceipt;
