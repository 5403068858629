import React from 'react';
import { Box, Table, Text } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';
import TransactionsFilter from '../TransactionsFilter';
import { HomeTableRows } from './HomeTableRows';

function HomeTableDesktopSkeleton(): JSX.Element {
  const { t } = useTranslationWithPrefix('home.homeTable');

  const headers = [
    t('headers.orders'),
    t('headers.date'),
    t('headers.status'),
    t('headers.value'),
    t('headers.client'),
  ].map((header, index) => {
    return (
      <Table.Cell key={index}>
        <Text fontSize="highlight">{header}</Text>
      </Table.Cell>
    );
  });

  const renderDataTable = () => (
    <Table>
      <Table.Head>{headers}</Table.Head>
      <HomeTableRows.Skeleton />
    </Table>
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <Text.Skeleton />
      <TransactionsFilter.Skeleton />
      {renderDataTable()}
    </Box>
  );
}

export default HomeTableDesktopSkeleton;
