import React from 'react';
import { Box, Button, Link } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import HelpText from './HelpText';
import HomeCards from './HomeCards';
import { HomeTableDesktop, HomeTableMobile } from './HomeTable';

function HomeSkeleton(): JSX.Element {
  const isMobile = useIsMobile();
  const { t } = useTranslationWithPrefix('home');

  const desktopConfigButton = <Button.Skeleton />;

  const mobileConfigButton = <Link.Skeleton />;

  return (
    <AppLayout
      hideBackButton
      title={t('title')}
      headerItems={isMobile ? null : desktopConfigButton}
      navBarItems={mobileConfigButton}
    >
      <Box gap="6" display="flex" flexDirection="column" alignItems="stretch">
        <HomeCards.Skeleton />
        {isMobile ? (
          <HomeTableMobile.Skeleton />
        ) : (
          <>
            <HomeTableDesktop.Skeleton />
            <HelpText.Skeleton />
          </>
        )}
      </Box>
    </AppLayout>
  );
}

export default HomeSkeleton;
