// eslint-disable-next-line simple-import-sort/imports
import './injectDataDog';
import React from 'react';
import { render } from 'react-dom';
import App from 'App';
import { init } from 'App/fingerprintClient';

window.addEventListener('DOMContentLoaded', () => {
  init(process.env.REACT_APP_FINGERPRINT_APP_KEY as string);
});

const rootElement = document.getElementById('root');
render(<App />, rootElement);
