import React from 'react';
import { Box } from '@nimbus-ds/components';
import { DiscountType, PaymentMethodsType } from 'commons/types';
import DiscountInput from './DiscountInput';

interface InterfaceDiscount {
  discounts: DiscountType[];
  setDiscounts: React.Dispatch<React.SetStateAction<DiscountType[]>>;
  setDiscountChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

function BankTransferDiscount({
  discounts,
  setDiscounts,
  setDiscountChanged,
}: InterfaceDiscount): JSX.Element {
  const bankTransferDiscount = discounts.find(
    (discount) =>
      discount.paymentMethodType === PaymentMethodsType.WireTransfer,
  );

  const updateDiscount = (value: number, paymentMethod: PaymentMethodsType) => {
    const index = discounts.findIndex(
      (method) => method.paymentMethodType === paymentMethod,
    );
    const updatedDiscounts = [...discounts];
    if (index >= 0) {
      updatedDiscounts[index] = {
        paymentMethodType: PaymentMethodsType.WireTransfer,
        percentage: value,
      };
    } else {
      updatedDiscounts.push({
        paymentMethodType: PaymentMethodsType.WireTransfer,
        percentage: value,
      });
    }
    setDiscountChanged(true);
    setDiscounts(updatedDiscounts);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <DiscountInput
        name={PaymentMethodsType.WireTransfer}
        discount={bankTransferDiscount?.percentage || 0}
        updateDiscount={updateDiscount}
      />
    </Box>
  );
}

export default BankTransferDiscount;
