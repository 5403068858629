import React from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { ArrowsHorizontalIcon } from '@nimbus-ds/icons';
import { CalloutCard } from '@nimbus-ds/patterns';
import { ROUTES } from 'App/Routes/constants';
import { useTranslationWithPrefix } from 'commons/utils';

function WithdrawsCard(): JSX.Element {
  const { t } = useTranslationWithPrefix('home.withdrawsCard');
  const history = useHistory();

  const redirectToWithdraws = () => {
    track('nuvempago_withdrawals_list_click');
    history.push(ROUTES.WITHDRAWS);
  };

  return (
    <CalloutCard
      appearance="neutral"
      icon={ArrowsHorizontalIcon}
      title={t('title')}
      subtitle={t('subtitle')}
      onClick={redirectToWithdraws}
    />
  );
}

export default WithdrawsCard;
