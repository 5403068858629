import axios from 'App/axios';
import { NotificationStyleType } from 'commons/types';
import {
  GetNotificationsResponse,
  NotificationMetadataResponse,
} from './notificationServiceResponses';

type GetNotificationsType = () => Promise<GetNotificationsResponse[]>;

const getNotifications: GetNotificationsType = async () => {
  const response = await axios.get('/admin/notifications');
  return response.data;
};

type SendNotificationMetadata = NotificationMetadataResponse;

type SendNotificationArgs = {
  title: string;
  message: string;
  titleCode?: string;
  messageCode?: string;
  style: NotificationStyleType;
  linkName?: string;
  linkUrl?: string;
  metadata?: SendNotificationMetadata;
};

type SendNotificationType = (args: SendNotificationArgs) => Promise<void>;

const sendNotification: SendNotificationType = async ({
  title,
  message,
  style,
  linkName,
  linkUrl,
  messageCode,
  titleCode,
  metadata,
}: SendNotificationArgs) => {
  const body = {
    title,
    message,
    style,
    title_code: titleCode,
    message_code: messageCode,
    link_name: linkName,
    link_url: linkUrl,
    metadata,
  };
  const response = await axios.post('/admin/notifications', body);
  return response.data;
};

type DeleteNotificationType = (id: string) => Promise<void>;

const deleteNotification: DeleteNotificationType = async (id: string) => {
  const response = await axios.delete(`/admin/notifications/${id}`);
  return response.data;
};

interface NotificationServicesInterface {
  getNotifications: GetNotificationsType;
  sendNotification: SendNotificationType;
  deleteNotification: DeleteNotificationType;
}

const notificationService: NotificationServicesInterface = {
  getNotifications,
  sendNotification,
  deleteNotification,
};

export default notificationService;
