import React from 'react';
import { Alert } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

interface CancelledAlertInterface {
  show?: boolean;
}

function CancelledAlert({ show }: CancelledAlertInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('refund.cancelledAlert');

  return (
    <Alert appearance="neutral" title={t('title')} show={show}>
      {t('description')}
    </Alert>
  );
}

export default CancelledAlert;
