import React from 'react';
import { Box, Button } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { ROUTES } from 'App/Routes/constants';
import { RedirectCard } from 'commons/components';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import { PersonalDataSkeleton } from './PersonalData';

// TODO: check if is still necessary
function ConfigurationSkeleton(): JSX.Element {
  const { t } = useTranslationWithPrefix('configuration');
  const isMobile = useIsMobile();

  const Buttons = (
    <Box gap="4" display="flex">
      <Button.Skeleton />
      <Button.Skeleton />
    </Box>
  );

  return (
    <AppLayout
      title={t('title')}
      subtitle={t('subtitle')}
      backLinkPath={ROUTES.HOME}
      headerItems={isMobile ? undefined : Buttons}
    >
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <PersonalDataSkeleton />
        <RedirectCard.Skeleton />
        <RedirectCard.Skeleton />
        <RedirectCard.Skeleton />
        {isMobile && Buttons}
      </Box>
    </AppLayout>
  );
}

export default ConfigurationSkeleton;
