import {
  usePaginatedDesktopQuery,
  usePaginatedMobileQuery,
} from 'commons/hooks';
import futureIncomesService, {
  DateFutureIncomeDetails,
  FutureIncomeDetails,
} from 'commons/services/futureIncomesService/futureIncomesService';

export function useFutureIncomesDesktopQuery() {
  return usePaginatedDesktopQuery<FutureIncomeDetails>(
    ['futureIncomesDesktop'],
    ({ page }) => futureIncomesService.getFutureIncomes({ page }),
  );
}

export function useFutureIncomesMobileQuery() {
  return usePaginatedMobileQuery<FutureIncomeDetails>(
    ['futureIncomesMobile'],
    ({ page }) => futureIncomesService.getFutureIncomes({ page }),
  );
}

export function useDateFutureIncomesDesktopQuery(date: string) {
  return usePaginatedDesktopQuery<DateFutureIncomeDetails>(
    ['dateFutureIncomesDesktop', date],
    ({ page }) => futureIncomesService.getDateFutureIncomes(date, { page }),
  );
}

export function useDateFutureIncomesMobileQuery(date: string) {
  return usePaginatedMobileQuery<DateFutureIncomeDetails>(
    ['dateFutureIncomesMobile', date],
    ({ page }) => futureIncomesService.getDateFutureIncomes(date, { page }),
  );
}
