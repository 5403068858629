import React from 'react';
import { Provider } from 'react-redux';
import store from './store';

interface ReduxProviderInterface {
  children: React.ReactNode;
}

function ReduxProvider({ children }: ReduxProviderInterface): JSX.Element {
  return <Provider store={store}>{children}</Provider>;
}

export default ReduxProvider;
