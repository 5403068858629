import React from 'react';
import { Alert, Box, Card, Tag, Text, Title } from '@nimbus-ds/components';
import { ErrorMessage, MultilineText } from 'commons/components';
import { useTranslationWithPrefix } from 'commons/utils';
import { InstallmentSectionInactive } from '../shared/InstallmentSectionInactive';
import { ResponsibleStatus } from '../useInstallmentsV2';
import WithInterestSkeleton from './WithInterestSkeleton';

function WithInterestInactive(props: { openSwitchModal?: () => void }) {
  return (
    <InstallmentSectionInactive
      translationPrefix="installments.withInterest"
      openSwitchModal={props.openSwitchModal}
    />
  );
}

interface WithInterestInterface {
  hasError: boolean;
  openSwitchModal: () => void;
  status: ResponsibleStatus;
}

function WithInterest({
  hasError,
  openSwitchModal,
  status,
}: WithInterestInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('installments.withInterest');

  if (hasError)
    return (
      <Card>
        <Card.Header>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap="4"
          >
            <Title as="h4">{t('title')}</Title>
          </Box>
        </Card.Header>
        <ErrorMessage />
      </Card>
    );

  const WithInterestActive = (
    <Card>
      <Card.Header>
        <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
          <Box display="flex" justifyContent="space-between">
            <Title as="h4">{t('title')}</Title>
            <Tag appearance="success">{t('active')}</Tag>
          </Box>
          <MultilineText color="neutral-textLow">
            {t('activeSubtitle')}
          </MultilineText>
        </Box>
      </Card.Header>
    </Card>
  );

  if (status === 'changing_to_customer') {
    return (
      <Card>
        <Card.Header>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap="4"
          >
            <Box display="flex" justifyContent="space-between">
              <Title as="h4">{t('title')}</Title>
              <Tag appearance="warning">{t('inProgress')}</Tag>
            </Box>
            <Text color="neutral-textLow">{t('activeSubtitle')}</Text>
            <Alert show appearance="neutral">
              {t('changeInProgress')}
            </Alert>
          </Box>
        </Card.Header>
      </Card>
    );
  }

  if (status === 'changing_to_merchant') {
    return <WithInterestInactive />;
  }

  if (status === 'customer_active') return WithInterestActive;

  return <WithInterestInactive openSwitchModal={openSwitchModal} />;
}

WithInterest.Skeleton = WithInterestSkeleton;
export default WithInterest;
