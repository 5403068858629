import React, { createContext, useMemo, useState } from 'react';
import {
  GetNotificationsResponse,
  notificationService,
} from 'commons/services';

interface NotificationContextInterface {
  notifications: GetNotificationsResponse[];
  fetchNotifications: () => Promise<void>;
  removeNotification: (id: string) => Promise<void>;
}

export const NotificationContext = createContext(
  {} as NotificationContextInterface,
);

interface InterfaceDocumentsProvider {
  children: React.ReactNode;
}

function NotificationProvider({
  children,
}: InterfaceDocumentsProvider): JSX.Element {
  const [notifications, setNotifications] = useState<
    GetNotificationsResponse[]
  >([]);

  const fetchNotifications = useMemo(
    () => async () => {
      const notifications = await notificationService.getNotifications();
      setNotifications(notifications);
    },
    [],
  );

  const removeNotification = useMemo(
    () => async (id: string) => {
      await notificationService.deleteNotification(id);
    },
    [],
  );

  const context: NotificationContextInterface = useMemo(() => {
    return {
      notifications,
      fetchNotifications,
      removeNotification,
    };
  }, [fetchNotifications, notifications, removeNotification]);

  return (
    <NotificationContext.Provider value={context}>
      {children}
    </NotificationContext.Provider>
  );
}

export default NotificationProvider;
