import React from 'react';
import { Box, Input, Label } from '@nimbus-ds/components';
import { fieldHandler } from './FieldHandler.interface';

type clientNameInput = fieldHandler & { title: string };

function ClientNameInput({
  handler,
  title,
  value,
}: clientNameInput): JSX.Element {
  const handleClientNameField = (event: any) => {
    const value: string = event.target.value;

    handler(value?.trimStart()?.replaceAll(/[^a-zA-Z ]/g, ''));
  };

  return (
    <Box display="grid" gridGap="2">
      <Label htmlFor="customerName">{title}</Label>
      <Input
        id="customerName"
        name="customerName"
        min="3"
        value={value}
        onChange={handleClientNameField}
      />
    </Box>
  );
}

function ClientNameFieldSkeleton(): JSX.Element {
  return (
    <Box display="grid" gridGap="2">
      <Label.Skeleton />
      <Input.Skeleton />
    </Box>
  );
}

ClientNameInput.Skeleton = ClientNameFieldSkeleton;

export default ClientNameInput;
