import React from 'react';
import { useHistory } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';
import {
  Alert,
  Box,
  Card,
  Icon,
  Link,
  Text,
  Title,
} from '@nimbus-ds/components';
import { InvoiceIcon } from '@nimbus-ds/icons';
import { ROUTES } from 'App/Routes/constants';
import ShowComponent from 'commons/components/FeatureFlag/ShowWithFeature';
import { FeatureFlag } from 'commons/services';
import { PaymentLinkSummaryType } from 'commons/types/PaymentLinkDetailsType';
import { useTranslationWithPrefix } from 'commons/utils';
import { TypeTag } from 'domains/Brazil/commons/components/StatusTag';
import { documentAnonimize } from 'domains/Brazil/commons/utils/document';

const getRefundReceiptTrackingTag = (status?: string): string => {
  const refundType = status === TypeTag.PartiallyRefunded ? 'partial' : 'total';

  return `nuvempago_payment_link_detail_${refundType}_refund_voucher_click`;
};

interface Props {
  summary: PaymentLinkSummaryType;
}

export default function PaymentCard(props: Partial<Props>): JSX.Element {
  const { t } = useTranslationWithPrefix('paymentLink.details.payment');
  const history = useHistory();

  function renderInstallmentsDescription(installments: number | undefined) {
    if (installments == undefined) return <Text.Skeleton width="90%" />;
    if (installments <= 1) return `${t('singleinstallment')}`;
    return `${t('installments', { installment: installments })}`;
  }

  function renderRejectedMessageAlert(
    rejectedMessage: string | undefined,
  ): JSX.Element | undefined {
    return rejectedMessage ? (
      <Alert>{t(`error.${rejectedMessage}`)}</Alert>
    ) : (
      <></>
    );
  }

  function isOrderRefundedOrPartialRefunded(orderStatus: string | TypeTag) {
    return (
      orderStatus === TypeTag.PartiallyRefunded ||
      orderStatus === TypeTag.Refunded
    );
  }

  return (
    <Box data-testid="paymentCardTestId">
      <Card padding="none">
        <Box marginLeft={'4'} marginTop={'4'}>
          {props?.summary ? (
            <Card.Header title={t('title')} />
          ) : (
            <Title.Skeleton />
          )}
        </Box>
        <Card.Body>
          <Box>
            <Box
              justifyContent={'flex-start'}
              alignItems={'stretch'}
              marginLeft={'4'}
              marginRight={'4'}
            >
              <Box marginBottom={'2-5'}>
                {props.summary?.brand || props.summary?.cardNumber ? (
                  <Text
                    fontSize="base"
                    textAlign="left"
                    color="neutral-textLow"
                  >
                    {t('creditcard', {
                      brand: props.summary?.brand?.toLowerCase(),
                      lastFourDigits: props.summary?.cardNumber,
                    })}
                  </Text>
                ) : (
                  <Text.Skeleton width="90%" />
                )}
              </Box>
              <Box>
                <Text fontSize="base" textAlign="left" color="neutral-textLow">
                  {renderInstallmentsDescription(props.summary?.installments)}
                </Text>
              </Box>
              <Box paddingTop={'2-5'}>
                <Text>{props.summary?.holderName}</Text>
              </Box>
              {props.summary?.document && (
                <Box paddingTop={'2-5'}>
                  <Text>{documentAnonimize(props.summary?.document)}</Text>
                </Box>
              )}
              {isOrderRefundedOrPartialRefunded(
                props.summary?.orderStatus ?? '',
              ) && (
                <ShowComponent
                  feature={FeatureFlag.FS_NUVEMPAGO_REFUND_RECEIPT_ENABLE}
                >
                  <Box paddingTop={'2'} maxWidth={'182px'}>
                    <Link
                      as="a"
                      appearance="primary"
                      textDecoration="none"
                      role="link_refundreceipt"
                      onClick={() => {
                        track(
                          getRefundReceiptTrackingTag(
                            props.summary?.orderStatus,
                          ),
                        );

                        history.push(
                          `${ROUTES.PAYMENT_LINK}/${props.summary?.referenceUUID}${ROUTES.REFUND_RECEIPT}`,
                        );
                      }}
                    >
                      <Icon
                        color="primary-interactive"
                        source={<InvoiceIcon />}
                      />
                      {t(`link_to_receipt`)}
                    </Link>
                  </Box>
                </ShowComponent>
              )}
              <Box paddingTop={'2'} marginBottom={'4'}>
                {renderRejectedMessageAlert(props.summary?.rejectedMessage)}
              </Box>
            </Box>
          </Box>
        </Card.Body>
      </Card>
    </Box>
  );
}
