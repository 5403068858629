import React, { ReactText, useEffect, useState } from 'react';
import { Box, Title } from '@nimbus-ds/components';
import { ErrorMessage } from 'commons/components';
import { usePrevious } from 'commons/hooks';
import { transactionsService } from 'commons/services';
import { DateRangeType, TransactionObjectInterface } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import { transactionStatusLabelMap } from 'domains/Home/StatusLabel';
import EmptyTransactions from '../EmptyTransactions';
import TransactionsFilter from '../TransactionsFilter';
import DataTableDesktop from './DataTableDesktop';
import HomeTableDesktopSkeleton from './HomeTableDesktopSkeleton';

function HomeTableDesktop(): JSX.Element | null {
  const [transactions, setTransactions] = useState(
    [] as TransactionObjectInterface[],
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFirstMount, setIsLoadingFirstMount] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRangeType>();
  const [hasEmptyTransactions, setHasEmptyTransactions] = useState(false);

  const previousFilters = usePrevious({
    searchFilter,
    statusFilter,
    fromDateFilter,
    toDateFilter,
    dateRangeFilter,
  });

  const { t } = useTranslationWithPrefix('home.homeTable');

  useEffect(() => {
    const verifyEmptyTransactions = (
      transactions: TransactionObjectInterface[],
    ) => {
      const searchPerformed = searchFilter || statusFilter || dateRangeFilter;
      if (!transactions.length && !searchPerformed) {
        setHasEmptyTransactions(true);
      }
    };
    const filtersChanged = (): boolean => {
      return (
        searchFilter !== previousFilters?.searchFilter ||
        statusFilter !== previousFilters?.statusFilter ||
        fromDateFilter !== previousFilters?.fromDateFilter ||
        toDateFilter !== previousFilters?.toDateFilter ||
        dateRangeFilter !== previousFilters?.dateRangeFilter
      );
    };

    async function fetchTransactions() {
      setIsLoading(true);
      try {
        if (filtersChanged() && pageNumber !== 1) {
          setPageNumber(1);
          return;
        }
        const hasChargeback =
          statusFilter === transactionStatusLabelMap.chargebacked;
        const response = await transactionsService.getTransactions(
          pageNumber,
          searchFilter,
          statusFilter,
          dateRangeFilter,
          fromDateFilter,
          toDateFilter,
          hasChargeback,
        );
        verifyEmptyTransactions(response.transactions);
        setTransactions(response.transactions);
        setTotalPages(Math.ceil(response.itemsCount / response.itemsPerPage));
        setTotalItems(response.itemsCount);
        setItemsPerPage(response.itemsPerPage);
      } catch (error) {
        setHasError(true);
      }
      setIsLoading(false);
      setIsLoadingFirstMount(false);
    }
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    setIsLoading,
    searchFilter,
    statusFilter,
    dateRangeFilter,
    fromDateFilter,
    toDateFilter,
  ]);

  if (hasError) {
    return (
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <Title as="h2">{t('title') as ReactText}</Title>
        <ErrorMessage />
      </Box>
    );
  }

  if (isLoadingFirstMount) return <HomeTableDesktopSkeleton />;

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <Title as="h2">{t('title') as ReactText}</Title>
      <TransactionsFilter
        skeleton={isLoadingFirstMount}
        setSearchFilter={setSearchFilter}
        setStatusFilter={setStatusFilter}
        setDateRangeFilter={setDateRangeFilter}
        setFromDateFilter={setFromDateFilter}
        setToDateFilter={setToDateFilter}
        transactionsCount={searchFilter && !isLoading ? totalItems : undefined}
      />
      {hasEmptyTransactions ? (
        <EmptyTransactions />
      ) : (
        <DataTableDesktop
          transactions={transactions}
          pageNumber={pageNumber}
          totalPages={totalPages}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={(page) => setPageNumber(page)}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
}

HomeTableDesktop.Skeleton = HomeTableDesktopSkeleton;
export default HomeTableDesktop;
