import React from 'react';
import { Alert, Box, Card, Tag, Text, Title } from '@nimbus-ds/components';
import { useStoreInfo } from 'commons/hooks';
import { Event as RefundEvent } from 'commons/services/refundReceiptService';
import {
  formatCurrency,
  formatNumericDate,
  useTranslationWithPrefix,
} from 'commons/utils';
import RefundEventCardSkeleton from './RefundEventCardSkeleton';

interface RefundEventCardProps {
  event: RefundEvent;
  isPartialRefund: boolean;
  isTransferPendingRefund?: boolean;
}

function RefundEventCard({
  event,
  isPartialRefund,
  isTransferPendingRefund,
}: Readonly<RefundEventCardProps>): JSX.Element {
  const { t } = useTranslationWithPrefix('refundReceipt.refundEventCard');
  const { storeInfo } = useStoreInfo();

  const totalRefunded = {
    value: event.interest?.value
      ? event.amount.value + event.interest.value
      : event.amount.value,
    currency: event.amount.currency,
  };

  return (
    <Card data-testid={`refund-event-${event.occurredAt}`}>
      <Card.Header color={'neutral-textHigh'}>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Title as="h4">
            {isPartialRefund ? t('titlePartial') : t('titleTotal')}
          </Title>
          <Tag appearance="neutral">
            {formatNumericDate(storeInfo, event.occurredAt.toString())}
          </Tag>
        </Box>
      </Card.Header>
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="4">
          <Box display="flex" flexDirection="column" gap="2">
            {event.interest?.value ? (
              <>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text>{t('amountRefunded')}</Text>
                  <Text color="neutral-textHigh">
                    {`${formatCurrency(event.amount, storeInfo)}`}
                  </Text>
                </Box>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Text>{t('interestRefunded')}</Text>
                  <Text color="neutral-textHigh">
                    {`${formatCurrency(event.interest, storeInfo)}`}
                  </Text>
                </Box>
              </>
            ) : null}
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
            >
              <Text fontWeight="medium">{t('totalRefunded')}</Text>
              <Text fontWeight="medium" color="neutral-textHigh">
                {`${formatCurrency(totalRefunded, storeInfo)}`}
              </Text>
            </Box>
          </Box>
          {isTransferPendingRefund && (
            <Alert appearance="warning">{t('transferPendingRefund')}</Alert>
          )}
        </Box>
      </Card.Body>
    </Card>
  );
}

RefundEventCard.Skeleton = RefundEventCardSkeleton;

export default RefundEventCard;
