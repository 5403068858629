import React from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Alert, Button } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { KycDocumentErrorsType, ValidationStatusType } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import { KYC_ANALYSIS_DAYS } from 'commons/utils/constants';
import moment from 'moment';
import PendingDocIdentityAlert from './PendingDocIdentityAlert';
import { useTransactionsQuery } from './useTransactionsQuery';

interface InterfaceNewIdentityAlert {
  identityStatus: ValidationStatusType;
  updatedAt: string;
  kycDocumentsErrors: KycDocumentErrorsType[];
}

const NewIdentityAlert = ({
  identityStatus,
  updatedAt,
  kycDocumentsErrors,
}: InterfaceNewIdentityAlert): JSX.Element | null => {
  const history = useHistory();
  const { t } = useTranslationWithPrefix('configuration.personalData.kycV2');
  const { isLoading, hasTransactions } = useTransactionsQuery({ page: 1 });

  if (identityStatus === 'active') return null;

  if (isLoading) {
    return <Alert.Skeleton />;
  }

  if (identityStatus === 'pending_documentation') {
    return <PendingDocIdentityAlert kycDocumentsErrors={kycDocumentsErrors} />;
  }

  const appearanceByStatus = () => {
    switch (identityStatus) {
      case 'pending':
        return 'warning';
      case 'pending_review':
        return 'neutral';
      case 'denied':
      case 'blocked':
        return 'danger';
      default:
        return 'neutral';
    }
  };

  let statusDescription = t(`${identityStatus}.description`, {
    interpolation: { escapeValue: false },
    updatedDate: moment(updatedAt).format('DD/MM'),
    kycAnalysisDays: KYC_ANALYSIS_DAYS,
  });

  const isPending = identityStatus === 'pending';

  if (isPending && hasTransactions) {
    statusDescription = t(`${identityStatus}.descriptionWithTransactions`);
  }

  const isPendingOrBlocked = isPending || identityStatus === 'blocked';

  const redirectToValidateIdentity = () => {
    track('pago-nube-prueba-de-vida-view');
    history.push(ROUTES.IDENTITY_VALIDATION);
  };

  return (
    <Alert
      show
      appearance={appearanceByStatus()}
      title={t(`${identityStatus}.title`)}
    >
      {statusDescription}
      {isPendingOrBlocked && (
        <Button appearance="neutral" onClick={redirectToValidateIdentity}>
          {t(`${identityStatus}.validateButton`)}
        </Button>
      )}
    </Alert>
  );
};

export default NewIdentityAlert;
