type Appearance = 'primary' | 'success' | 'warning' | 'danger' | 'neutral';

export function dueDateTranslationInfo(dueAt: Date): [string, Appearance] {
  const installmentOverdue = new Date(dueAt).getTime() < new Date().getTime();

  if (installmentOverdue) {
    return ['installmentOverdue', 'danger'];
  }

  return ['installmentDueAt', 'success'];
}
