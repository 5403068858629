import React from 'react';
import { useFeatureDiscovery } from 'commons/hooks';
import { FeatureName } from 'commons/services/featureDiscoveryService/featureDiscoveryServiceResponses';
import HomeMockProvider from './HomeMock/HomeMockContext';
import Home from './Home';
import HomeMock from './HomeMock';
import HomeSkeleton from './HomeSkeleton';

function HomeInit(): JSX.Element {
  const featureDiscovery = useFeatureDiscovery(FeatureName.OnBoarding);

  if (featureDiscovery.isLoading) return <HomeSkeleton />;
  if (featureDiscovery.isDiscovered) return <Home />;

  return (
    <HomeMockProvider>
      <HomeMock />
    </HomeMockProvider>
  );
}

HomeInit.Skeleton = HomeSkeleton;
export default HomeInit;
