export enum i18nConfig {
  STORAGE = 'locale',
}

export enum Locale {
  ARGENTINA = 'es-AR',
  BRAZIL = 'pt-BR',
  MEXICO = 'es-MX',
}

export enum Country {
  ARGENTINA = 'AR',
  BRAZIL = 'BR',
  MEXICO = 'MX',
}
