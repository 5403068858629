import { useCallback, useState } from 'react';
import {
  ReportStatusDataType,
  SendReportArgs,
} from 'commons/services/reportService';
import { useCreateReportMutation } from './useCreateReportMutation';
import { useGetReportStatusQueryPolling } from './useGetReportStatusQueryPolling';

export function useBackgroundReportGeneration({
  onFinished,
}: {
  onFinished: (args: ReportStatusDataType) => void;
}) {
  const [requestId, setRequestId] = useState<number | undefined>();
  const [report, setReport] = useState<ReportStatusDataType | undefined>();

  const createReportMutation = useCreateReportMutation({
    onSuccess: (requestId) => {
      setRequestId(requestId); // after the requestId is setted, the polling starts
    },
  });

  const getReportStatusPolling = useGetReportStatusQueryPolling({
    onFinished: ({ status, temporaryDownloadUrl }: ReportStatusDataType) => {
      setRequestId(undefined);
      setReport({ status, temporaryDownloadUrl });
      return onFinished({ status, temporaryDownloadUrl });
    },
    requestId,
  });

  const startReportGeneration = useCallback(
    ({ dateRange, fromDate, toDate }: SendReportArgs) => {
      setReport(undefined);
      createReportMutation.mutate({
        dateRange,
        fromDate,
        toDate,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createReportMutation.mutate],
  );

  const isLoading =
    getReportStatusPolling.isPolling || createReportMutation.isLoading;

  return {
    startReportGeneration,
    isLoading,
    isError: getReportStatusPolling.query.isError,
    report,
  };
}
