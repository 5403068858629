import React, { useEffect } from 'react';
import { Box, Button, Modal, Text, Title } from '@nimbus-ds/components';
import { goToOldAdmin } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { useIdentityValidation } from 'commons/hooks';
import useSettings from 'commons/hooks/useSettingsInLocalStorage';
import { useTranslationWithPrefix } from 'commons/utils';
import { usePreferences } from 'domains/Installments/WithoutInterest/usePreferences';

function FreemiumWarningModal(): JSX.Element {
  const FREEMIUM_PLAN_ID = 1;
  const BLOCKED_IDENTITY_STATUS = 'blocked';
  const { t } = useTranslationWithPrefix('home');
  const { fetchPreferences, preferences } = usePreferences();
  const identityValidation = useIdentityValidation();
  const {
    settings: { hideBlockedFreemiumModal },
    setHideBlockedFreemiumModal,
  } = useSettings();
  const isADeniedKycStore =
    identityValidation.data?.status === BLOCKED_IDENTITY_STATUS &&
    preferences?.planId == FREEMIUM_PLAN_ID;

  useEffect(() => {
    fetchPreferences();
  }, [fetchPreferences]);

  const closeBlockedFreemiumModal = () => {
    setHideBlockedFreemiumModal({ newSpecificSettingChange: true });
  };

  const redirectToPlans = () => {
    goToOldAdmin(nexo, `/account/plans`);
  };

  const modalContent = () => {
    return (
      <Box margin="10">
        <Box display="flex" flexDirection="column" gap="4" alignItems="center">
          <Modal.Header>
            <Title textAlign="center" as="h2">
              {t('freemiumDeniedModal.title')}
            </Title>
          </Modal.Header>
          <Box borderStyle="none">
            <Modal.Body>
              <Text
                as="p"
                textAlign="center"
                fontSize="highlight"
                lineHeight="highlight"
                fontWeight="regular"
              >
                {t('freemiumDeniedModal.first_description')}
              </Text>
              <br />
              <Text
                as="p"
                textAlign="center"
                fontSize="highlight"
                lineHeight="highlight"
                fontWeight="regular"
              >
                {t('freemiumDeniedModal.second_description')}
              </Text>
            </Modal.Body>
          </Box>
          <Modal.Footer>
            <Button appearance="primary" onClick={redirectToPlans}>
              {t('freemiumDeniedModal.redirectButton')}
            </Button>
          </Modal.Footer>
        </Box>
      </Box>
    );
  };

  return (
    <Modal
      open={!hideBlockedFreemiumModal && isADeniedKycStore}
      padding="base"
      onDismiss={closeBlockedFreemiumModal}
      maxWidth={{ xs: '90%', md: '60%', lg: '35%' }}
    >
      {modalContent()}
    </Modal>
  );
}

export default FreemiumWarningModal;
