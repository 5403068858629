export function getErrorFromStatusCode(statusCode?: string): string | null {
  switch (statusCode) {
    case 'REPROVED_BY_BEHAVIOR':
    case 'REPROVED_BY_RULE':
    case 'REPROVED_BY_RULE_WITH_WARNING':
    case 'REPROVED_BY_RULE_WITH_MANUAL_REVIEW':
    case 'REPROVED_BY_BLOCKLIST':
    case 'REPROVED_BY_AMOUNT':
    case 'REPROVED_BY_IP':
    case 'REPROVED_BY_TIME':
    case 'REJECTED_BY_COMPLIANCE':
    case 'compliance_error':
      return 'securityError';

    case 'ERROR_ACCOUNT_INCORRECT':
      return 'accountIncorrect';

    case 'ERROR_ACCOUNT_NOT_ACCEPT_TRANSFERS':
      return 'accountNotAcceptTransfer';

    case 'ERROR_ACCOUNT_OF_OTHER_CURRENCY':
      return 'accountOtherCurrency';

    case 'ERROR_OTHER':
    case 'invalid_payout_data':
    case 'payout_transfer_error':
    case 'bank_error':
      return 'errorOther';

    case 'ERROR_REJECTED_BY_BANK':
      return 'errorRejectedByBank';

    case 'REJECTED_POST_APPROVAL':
      return 'errorRejectedPostApproval';

    case 'insufficient_funds':
      return 'insufficientFunds';

    default:
      return null;
  }
}
