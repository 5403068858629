import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import RequirePermission from 'App/components/RequirePermission/RequirePermission';
import RequireRole from 'App/components/RequireRole';
import RequireVerification from 'App/components/RequireVerification/RequireVerification';
import NotificationProvider from 'commons/components/NotificationProvider/NotificationProvider';
import { Roles } from 'commons/components/Roles/Roles.enum';
import { PermissionFeature } from 'commons/hooks';
import useStoreInfo from 'commons/hooks/useStoreInfo';
import {
  BankAccountRegistration,
  Configuration,
  FutureIncomes,
  Home,
  IdentityValidation,
  Installments,
  Landing,
  PaymentMethods,
  Refund,
  RefundReceipt,
  SettlementPeriod,
  TransactionDetail,
  TransactionsReport,
  Transfer,
  Withdraws,
} from 'domains';
import { DateFutureIncomes } from 'domains/FutureIncomes';
import DocumentsProvider from 'domains/IdentityValidation/DocumentsContext';
import { ROUTES } from '../constants';

function ArgentinaRoutes(): JSX.Element {
  const { storeInfo } = useStoreInfo();
  const activeStoreRoutes = (
    <Switch>
      <Route exact path={ROUTES.HOME}>
        <Suspense fallback={null}>
          <NotificationProvider>
            <Home />
          </NotificationProvider>
        </Suspense>
      </Route>

      <Route exact path={ROUTES.CONFIG}>
        <Suspense fallback={null}>
          <Configuration />
        </Suspense>
      </Route>

      <Route exact path={ROUTES.INSTALLMENTS}>
        <Suspense fallback={null}>
          <Installments />
        </Suspense>
      </Route>

      <Route exact path={ROUTES.SETTLEMENT_PERIOD}>
        <Suspense fallback={null}>
          <SettlementPeriod />
        </Suspense>
      </Route>

      <Route exact path={ROUTES.PAYMENT_METHODS}>
        <Suspense fallback={null}>
          <PaymentMethods />
        </Suspense>
      </Route>

      <Route exact path={ROUTES.WITHDRAWS}>
        <Suspense fallback={null}>
          <Withdraws />
        </Suspense>
      </Route>

      <Route exact path={ROUTES.BANK_ACCOUNT_CONFIG}>
        <Suspense fallback={null}>
          <BankAccountRegistration />
        </Suspense>
      </Route>

      <Route
        exact
        path={ROUTES.TRANSFER}
        render={() => (
          <RequireVerification needsValidationActive needsBankRegistered>
            <RequirePermission permissionFeature={PermissionFeature.Withdraw}>
              <Transfer />
            </RequirePermission>
          </RequireVerification>
        )}
      />

      <Route
        path={ROUTES.IDENTITY_VALIDATION}
        render={() => (
          <RequireVerification
            needsValidationPending
            redirectPath={ROUTES.CONFIG}
          >
            <DocumentsProvider>
              <IdentityValidation />
            </DocumentsProvider>
          </RequireVerification>
        )}
      />

      <Route exact path={`${ROUTES.TRANSACTION_DETAILS}/:orderId`}>
        <Suspense fallback={null}>
          <TransactionDetail />
        </Suspense>
      </Route>

      <Route exact path={`${ROUTES.REFUND_RECEIPT}/:orderId`}>
        <Suspense fallback={null}>
          <RefundReceipt />
        </Suspense>
      </Route>

      <Route
        exact
        path={`${ROUTES.REFUND}/:orderId`}
        render={() => (
          <RequireRole role={Roles.REFUND}>
            <Refund />
          </RequireRole>
        )}
      />

      <Route exact path={`${ROUTES.FUTURE_INCOME}`}>
        <Suspense fallback={null}>
          <FutureIncomes />
        </Suspense>
      </Route>

      <Route exact path={`${ROUTES.FUTURE_INCOME}/:date`}>
        <Suspense fallback={null}>
          <DateFutureIncomes />
        </Suspense>
      </Route>

      <Route exact path={ROUTES.TRANSACTIONS_REPORT}>
        <Suspense fallback={null}>
          <TransactionsReport />
        </Suspense>
      </Route>

      <Route path="*" render={() => <Redirect to={ROUTES.HOME} />} />
    </Switch>
  );

  const inactiveStoreRoutes = (
    <Switch>
      <Route exact path={`${ROUTES.LANDING}`}>
        <Suspense fallback={null}>
          <Landing />
        </Suspense>
      </Route>

      <Route path="*" render={() => <Redirect to={ROUTES.LANDING} />} />
    </Switch>
  );

  return <>{storeInfo.active ? activeStoreRoutes : inactiveStoreRoutes}</>;
}

export default ArgentinaRoutes;
