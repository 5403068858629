import axios from 'App/axios';
import { AxiosResponse } from 'axios';
import { MoneyObjectInterface } from 'commons/types';
import { PaymentLinkDetailsType } from 'commons/types/PaymentLinkDetailsType';
import { TypeTag } from 'domains/Brazil/commons/components/StatusTag';
import { GetRefundReceiptDetailsResponse } from '../refundReceiptService';
import { GetRefundReceiptDetailsType } from '../refundReceiptService/refundReceiptService';
import { GetRefundDetailsResponse } from '../refundService';
import { GetRefundDetailsType } from '../refundService/refundService';

export enum ExpirationTime {
  FifteenMinutes = 900,
  ThirtyMinutes = 1_800,
  OneHour = 3_600,
  TwoHours = 7_200,
  OneDay = 86_400,
  TwoDays = 172_800,
}

export interface paymentLinkInfo {
  customerName: string;
  amount: number;
  expirationTime: ExpirationTime;
  description: string;
  linkNumber: number;
  paymentUrl: string;
  paymentSuccessUrl: string;
}

export interface PaymentLinkRefundArgs {
  referenceUUID: string;
  amount: MoneyObjectInterface;
}

export interface RefundTransactions {
  id: string;
  amount: {
    currency: string;
    value: number;
  };
}

const ExpirationTimeMap = new Map([
  ['FifteenMinutes', 900],
  ['ThirtyMinutes', 1800],
  ['OneHour', 3600],
  ['TwoHours', 7200],
  ['OneDay', 86400],
  ['TwoDays', 172800],
]);

const getAvailablePaymentMethods = (): string[] => {
  return ['credit-card'];
};

const getExpirationOptions = (): Map<string, any> => {
  return ExpirationTimeMap;
};

const createPaymentLink = async (
  data: Omit<paymentLinkInfo, 'linkNumber' | 'paymentUrl'>,
): Promise<paymentLinkInfo> => {
  const response = await axios.post(`/admin/payment-links`, data);

  return response.data;
};

const getDetails = async (id: string): Promise<PaymentLinkDetailsType> => {
  const response = (await axios.get(`/admin/payment-links/${id}`)).data;

  const getRejectedMessage = (data: any) => {
    if (data.status == TypeTag.RejectedBySecurity)
      return 'card_rejected_by_security';
    else if (data.status == TypeTag.Failed) return data?.rejectedMessage;
    else return undefined;
  };

  return {
    id: response?.id,
    customer: {
      ...response?.customer,
      phone: `${response?.customer?.phone?.countryCode} ${response?.customer?.phone?.areaCode} ${response?.customer?.phone?.number}`,
    },
    description: response?.descriptions,
    linkNumber: response?.linkNumber,
    payment: {
      ...response?.payment,
      holderName: response.payment.holderName,
      cardNumber: Number(response?.payment?.cardNumber),
      cancelFee: response?.payment?.cancelFee,
      rejectedMessage: getRejectedMessage(response),
    },
    paymentUrl: response?.paymentUrl,
    shippingAddress: {
      ...response?.shippingAddress,
      zipCode: response?.shippingAddress.zipCode,
    },
    status: response?.status,
    createdAt: response?.createdAt,
    referenceUUID: response?.referenceUUID,
  };
};

const validateAmount = async (amount: number): Promise<boolean> => {
  try {
    const response: AxiosResponse = await axios.get(
      `/admin/validate-amount?amount=${amount}`,
    );
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const refundLegacy = async (
  transactionId: string,
  storeId: string,
): Promise<boolean> => {
  try {
    const response: AxiosResponse = await axios.post(`/orders/refund`, {
      storeId: parseInt(storeId),
      transactionId: `${transactionId}`,
      origin: 'PaymentLink',
    });
    if (response.status >= 200 && response.status < 300) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const refund = async ({
  referenceUUID,
  amount,
}: PaymentLinkRefundArgs): Promise<unknown> => {
  const response: AxiosResponse = await axios.post(
    `/admin/payment-link/${referenceUUID}/refund`,
    {
      amount,
      origin: 'PaymentLink',
    },
  );
  return response.data;
};

const getRefundDetails: GetRefundDetailsType = async (
  referenceUUID: string,
): Promise<GetRefundDetailsResponse> => {
  try {
    const response = await axios.get(
      `/admin/payment-link/${referenceUUID}/refund-details`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getRefundReceiptDetails: GetRefundReceiptDetailsType = async (
  referenceUUID: string,
) => {
  try {
    const response = await axios.get(
      `/admin/payment-link/${referenceUUID}/refund-receipt`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

interface PaymentLinkServiceInterface {
  getAvailablePaymentMethods: () => string[];
  getExpirationOptions: () => Map<string, any>;
  createPaymentLink: (
    data: Omit<paymentLinkInfo, 'linkNumber' | 'paymentUrl'>,
  ) => Promise<paymentLinkInfo>;
  getDetails: (id: string) => Promise<PaymentLinkDetailsType>;
  validateAmount: (amount: number) => Promise<boolean>;
  refundLegacy: (transactionId: string, storeId: string) => Promise<boolean>;
  refund: (req: PaymentLinkRefundArgs) => Promise<unknown>;
  getRefundDetails: (
    referenceUUID: string,
  ) => Promise<GetRefundDetailsResponse>;
  getRefundReceiptDetails: (
    referenceUUID: string,
  ) => Promise<GetRefundReceiptDetailsResponse>;
}

const paymentLinkService: PaymentLinkServiceInterface = {
  getAvailablePaymentMethods,
  getExpirationOptions,
  createPaymentLink,
  getDetails,
  validateAmount,
  refundLegacy,
  refund,
  getRefundDetails,
  getRefundReceiptDetails,
};

export default paymentLinkService;
