import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text, Title } from '@nimbus-ds/components';
import ErrorImage from './error.png';

interface ErrorScreenInterface {
  onReload: () => void;
}

function ErrorScreen({ onReload }: ErrorScreenInterface): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      gap="4"
      paddingX="0-5"
    >
      <Box>
        <img src={ErrorImage} width="100%" style={{ maxWidth: '480px' }} />
      </Box>
      <Title as="h2">{t('errorPage.title')}</Title>
      <Text color="neutral-textHigh">{t('errorPage.text')}</Text>
      <Button appearance="primary" onClick={onReload}>
        {t('errorPage.action')}
      </Button>
    </Box>
  );
}

export default ErrorScreen;
