import React from 'react';
import { Table } from '@nimbus-ds/components';
import { DataTable } from '@nimbus-ds/patterns';
import { Currency } from 'commons/types/Currency.enum';
import { OrderType } from 'commons/types/OrderType.enum';
import ClientNameField from 'domains/Brazil/commons/components/ClientName';
import DateField from 'domains/Brazil/commons/components/DateField';
import MoneyField from 'domains/Brazil/commons/components/MoneyField';
import PaymentMethod from 'domains/Brazil/commons/components/PaymentMethod';
import StatusTag, {
  TypeTag,
} from 'domains/Brazil/commons/components/StatusTag';
import DashboardLinkField from './Link';

const datePostFormat = (dt: string) =>
  dt.replaceAll(' de ', ' ').replaceAll('.', '');

type listItemArgs = {
  type: OrderType;
  transactionId: string;
  orderNumber?: string;
  date: string;
  clientName: string;
  paymentMethod: string;
  amount: string | number;
  status: TypeTag;
  isRefundPartial: boolean;
};

function ListItem({
  type,
  date,
  clientName,
  amount,
  status,
  transactionId,
  paymentMethod,
  orderNumber,
  isRefundPartial,
}: listItemArgs): JSX.Element {
  return (
    <Table.Row
      data-testid={`dashboard-list-row-${orderNumber}-${isRefundPartial}`}
    >
      <DataTable.Cell>
        <DashboardLinkField
          type={type}
          orderNumber={orderNumber}
          transactionId={transactionId}
          status={status}
          isRefundPartial={isRefundPartial}
        />
      </DataTable.Cell>
      <DataTable.Cell>
        <DateField date={date} postFormat={datePostFormat} />
      </DataTable.Cell>
      <DataTable.Cell>
        <ClientNameField name={clientName} />
      </DataTable.Cell>
      <DataTable.Cell>
        <PaymentMethod paymentMethod={paymentMethod} />
      </DataTable.Cell>
      <DataTable.Cell>
        <MoneyField value={amount} currency={Currency.BRL} />
      </DataTable.Cell>
      <DataTable.Cell>
        <StatusTag type={status} />
      </DataTable.Cell>
    </Table.Row>
  );
}

function Skeleton(): JSX.Element {
  return (
    <Table.Row data-testid={`dashboard-list-row-skeleton`}>
      <DataTable.Cell>
        <DashboardLinkField.Skeleton />
      </DataTable.Cell>
      <DataTable.Cell>
        <DateField.Skeleton />
      </DataTable.Cell>
      <DataTable.Cell>
        <ClientNameField.Skeleton />
      </DataTable.Cell>
      <DataTable.Cell>
        <PaymentMethod.Skeleton />
      </DataTable.Cell>
      <DataTable.Cell>
        <MoneyField.Skeleton />
      </DataTable.Cell>
      <DataTable.Cell>
        <StatusTag.Skeleton />
      </DataTable.Cell>
    </Table.Row>
  );
}

ListItem.Skeleton = Skeleton;

export default ListItem;
