const aDayInSeconds = 86400;
const aHourInSeconds = 3600;
const aMinuteInSeconds = 60;

export function pixExpirationTitle(timeInSeconds: number): [string, number] {
  const days = Math.floor(timeInSeconds / aDayInSeconds);
  if (days > 1) {
    return ['titleDays', days];
  }

  if (timeInSeconds > aDayInSeconds) {
    return ['titleDay', days];
  }

  const hours = Math.floor(timeInSeconds / aHourInSeconds);
  if (hours > 1) {
    return ['titleHours', hours];
  }

  if (timeInSeconds > aHourInSeconds) {
    return ['titleHour', hours];
  }

  const minutes = Math.floor(timeInSeconds / aMinuteInSeconds);
  if (minutes > 1) {
    return ['titleMinutes', minutes];
  }

  return ['titleMinute', 0];
}
