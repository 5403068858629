import React from 'react';
import { Box, Card, Tag, Text, Title } from '@nimbus-ds/components';

function PaymentDetailSkeleton(): JSX.Element {
  return (
    <Card>
      <Card.Header>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Title.Skeleton as="h4" />
          <Tag.Skeleton />
        </Box>
      </Card.Header>
      <Card.Body>
        <Box
          display="flex"
          alignItems="flex-start"
          alignSelf="stretch"
          flexDirection="column"
          paddingBottom="3"
          gap="1"
        >
          <Text.Skeleton />
          <Text.Skeleton />
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          alignSelf="stretch"
          flexDirection="column"
          paddingBottom="3"
          gap="1"
        >
          <Text.Skeleton />
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          alignSelf="stretch"
          flexDirection="column"
          gap="1"
        >
          <Text.Skeleton />
          <Text.Skeleton />
        </Box>
      </Card.Body>
    </Card>
  );
}

export default PaymentDetailSkeleton;
