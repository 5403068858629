import React from 'react';
import { useChargebacks } from 'commons/hooks';
import ChargebackAlert from './ChargebackAlert';
import DisputeRejectedChargebackAlert from './DisputeRejectedChargebackAlert';
import PendingChargebackAlert from './PendingChargebackAlert';

function ChargebackAlerts(): JSX.Element | null {
  const { chargebacks, loading } = useChargebacks();

  if (loading || !chargebacks) return null;

  return (
    <>
      <DisputeRejectedChargebackAlert
        chargebacks={chargebacks.disputeRejected}
      />
      <PendingChargebackAlert chargebacks={chargebacks.pending} />
      <ChargebackAlert
        chargebacks={chargebacks.resolvedMerchant}
        status="resolvedMerchant"
        appearence="success"
      />
      <ChargebackAlert
        chargebacks={chargebacks.resolvedCustomer}
        status="resolvedCustomer"
        appearence="neutral"
      />
      <ChargebackAlert
        chargebacks={chargebacks.resolvedWithoutDispute}
        status="resolvedWithoutDispute"
        appearence="neutral"
      />
    </>
  );
}

export default ChargebackAlerts;
