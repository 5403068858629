import React, { useEffect } from 'react';
import { Alert, Box, Card, Text, Title } from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import AppLayout from 'domains/Brazil/AppLayout/AppLayout';
import MobileTopNavBar from 'domains/Brazil/commons/components/MobileTopNavBar';
import AmountInput from './components/AmountField';
import ClientNameInput from './components/ClientNameField';
import DescriptionInput from './components/DescriptionField';
import ExpirationField from './components/ExpirationField';
import FormButtons from './components/FormButtons';

function MobileForm() {
  return (
    <Box display="grid" gridGap="4">
      <Title.Skeleton />
      <Box
        boxSizing="content-box"
        padding="4"
        borderStyle="solid"
        borderRadius="2"
        borderWidth="1"
        borderColor="neutral-surfaceHighlight"
      >
        <Box display="grid" gridGap="4">
          <ClientNameInput.Skeleton />
          <AmountInput.Skeleton />
          <ExpirationField.Skeleton />
          <DescriptionInput.Skeleton />
        </Box>
      </Box>
      <Alert.Skeleton />
      <FormButtons.Skeleton />
    </Box>
  );
}

function DesktopForm() {
  return (
    <Box
      display="grid"
      gap="4"
      justifyContent="center"
      alignItems="stretch"
      width="100%"
      gridTemplateRows="50px auto auto"
      gridTemplateColumns="0.7fr"
    >
      <Title.Skeleton />
      <Card>
        <Card.Header>
          <Title.Skeleton />
        </Card.Header>
        <Box>
          <ClientNameInput.Skeleton />
        </Box>
      </Card>
      <Card>
        <Card.Header>
          <Title.Skeleton />
        </Card.Header>
        <Box display="flex" flexDirection="column" gap="4">
          <AmountInput.Skeleton />
          <ExpirationField.Skeleton />
          <Alert.Skeleton />
        </Box>
      </Card>
      <Card>
        <Card.Header>
          <Box display="flex" flexDirection="row" gap="1" alignItems="center">
            <Title.Skeleton />
            <Text.Skeleton />
          </Box>
        </Card.Header>
        <Box>
          <DescriptionInput.Skeleton />
        </Box>
      </Card>
      <FormButtons.Skeleton />
    </Box>
  );
}

function PaymentLinkFormSkeleton(): JSX.Element {
  const { t: navT } = useTranslationWithPrefix('navigation');
  const isMobile = useIsMobile();

  useEffect(() => {
    navigateHeader(nexo, { goTo: ROUTES.DASHBOARD, text: navT('back') });
  }, [navT]);

  return (
    <AppLayout>
      <MobileTopNavBar
        paddingTop="none"
        paddingX="none"
        backLinkPath={ROUTES.DASHBOARD}
        backLinkText={navT('back')}
      />
      <Box display="grid" gridGap="4">
        {isMobile ? MobileForm() : DesktopForm()}
      </Box>
    </AppLayout>
  );
}

export default PaymentLinkFormSkeleton;
