import React from 'react';
import { track } from '@amplitude/analytics-browser';
import { Box, Label, Select } from '@nimbus-ds/components';
import paymentLinkService from 'commons/services/paymentLink/paymentLinkService';
import { useTranslationWithPrefix } from 'commons/utils';
import { fieldHandler } from './FieldHandler.interface';

type expirationField = fieldHandler & { title: string };

const amplitudeExpiration: Map<number, string> = new Map([
  [900, 'nuvempago_link_de_pagamento_deadline_selected_15_minute'],
  [1800, 'nuvempago_link_de_pagamento_deadline_selected_30_minute'],
  [3600, 'nuvempago_link_de_pagamento_deadline_selected_1_hour'],
  [7200, 'nuvempago_link_de_pagamento_deadline_selected_2_hours'],
  [86400, 'nuvempago_link_de_pagamento_deadline_selected_1_day'],
  [172800, 'nuvempago_link_de_pagamento_deadline_selected_2_days'],
]);

function ExpirationField({
  handler,
  value,
  title,
}: expirationField): JSX.Element {
  const { t } = useTranslationWithPrefix('paymentLink.form.expirationOptions');
  const expirationOptions = paymentLinkService.getExpirationOptions();

  const dateRangeOptions: any = [];

  expirationOptions.forEach((v) => {
    dateRangeOptions.push({ label: t(`${v}`), value: v });
  });

  const handlerExpirationField = (event: any) => {
    const expirationTime = event.target?.value;

    track(amplitudeExpiration.get(Number(expirationTime)) as string);

    handler(expirationTime);
  };

  return (
    <Box display="grid" gridGap="2">
      <Label htmlFor="payment-link-expiration">{title}</Label>
      <Select
        name={'expiration'}
        onChange={handlerExpirationField}
        value={value}
        id={'payment-link-expiration'}
      >
        {dateRangeOptions.map((date: any, index: number) => {
          return (
            <Select.Option key={index} label={date.label} value={date.value} />
          );
        })}
      </Select>
    </Box>
  );
}

function ExpirationFieldSkeleton(): JSX.Element {
  return (
    <Box display="grid" gridGap="2">
      <Select.Skeleton />
    </Box>
  );
}

ExpirationField.Skeleton = ExpirationFieldSkeleton;

export default ExpirationField;
