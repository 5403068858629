import React, { useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { Alert, Button } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

interface InterfacePendingProofOfLifeAlert {
  proofOfLifeUrl?: string;
  errorCode?: string;
}

const PendingProofOfLifeAlert = ({
  proofOfLifeUrl,
  errorCode,
}: InterfacePendingProofOfLifeAlert): JSX.Element | null => {
  const { t } = useTranslationWithPrefix(
    'configuration.personalData.kycV2.pending_documentation',
  );
  const [showAlert, setShowAlert] = useState(true);
  const translationPrefix =
    errorCode === 'documents_missmatch'
      ? 'documentsMissmatchError'
      : 'proofOfLifeError';

  const handleOnClick = () => {
    track('pago-nube-prueba-de-vida-remediancion-reintentar');
    window.open(proofOfLifeUrl, '_blank');
    setShowAlert(false);
  };

  return (
    <Alert
      show={showAlert}
      appearance="danger"
      title={t(`${translationPrefix}.title`)}
    >
      {t(`${translationPrefix}.description`)}
      <Button appearance="neutral" onClick={handleOnClick}>
        {t(`${translationPrefix}.validateButton`)}
      </Button>
    </Alert>
  );
};

export default PendingProofOfLifeAlert;
