import React from 'react';
import { Box, Radio } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';
import DateRangeFilterEnum from 'domains/Brazil/commons/types/DateRangeFilterEnum';

interface DateRadioFilterInterface {
  options: DateRangeFilterEnum[];
  dateRangeOption: DateRangeFilterEnum;
  setRangeDateOption: React.Dispatch<React.SetStateAction<DateRangeFilterEnum>>;
}

function DateRadioFilter({
  options,
  dateRangeOption,
  setRangeDateOption,
}: DateRadioFilterInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('home');
  const filterOptions = options.map((o) => ({
    label: t(`dateRangeOptions.${o}`),
    value: o,
  }));

  return (
    <Box
      display="flex"
      align-items="flex-start"
      flexWrap="wrap"
      flex-direction="column"
      align-self="stretch"
      gap="2-5"
    >
      {filterOptions.map(({ label, value }) => {
        return (
          <Radio
            id={value}
            as="button"
            name="dataRangeOptions"
            label={label}
            key={value}
            checked={dateRangeOption === value}
            value={value}
            onChange={() => setRangeDateOption(value)}
          />
        );
      })}
    </Box>
  );
}

export default DateRadioFilter;
