import React, { lazy, Suspense } from 'react';
import { FeatureDiscoveryPopover } from 'commons/components';
import { useStoreInfo } from 'commons/hooks';
import { FeatureName } from 'commons/services/featureDiscoveryService/featureDiscoveryServiceResponses';
import { GetRefundReceiptDetailsResponse } from 'commons/services/refundReceiptService';
import { useTranslationWithPrefix } from 'commons/utils';

const LazyPDFRefundReceiptDownloadLink = lazy(
  () => import('./PDFRefundReceiptDownloadLink'),
);

interface PDFDownloadLinkProps {
  storeDocument: string;
  refundDetails: GetRefundReceiptDetailsResponse;
  as?: 'a' | 'button';
}

function PDFDownloadLink({
  storeDocument,
  refundDetails,
  as,
}: Readonly<PDFDownloadLinkProps>) {
  const { t } = useTranslationWithPrefix('refundReceipt.popover');
  const { storeInfo } = useStoreInfo();

  return (
    <Suspense fallback={null}>
      <FeatureDiscoveryPopover
        title={t('title')}
        description={t('description')}
        featureName={FeatureName.RefundReceiptDownload}
      >
        <LazyPDFRefundReceiptDownloadLink
          as={as}
          refundDetails={refundDetails}
          storeDocument={storeDocument}
          storeInfo={storeInfo}
        />
      </FeatureDiscoveryPopover>
    </Suspense>
  );
}

export default PDFDownloadLink;
