export enum PaymentMethodsType {
  BankDebit = 'bank_debit',
  Boleto = 'boleto',
  Cash = 'cash',
  CreditCard = 'credit_card',
  DebitCard = 'debit_card',
  Pix = 'pix',
  Ticket = 'ticket',
  Wallet = 'wallet',
  WireTransfer = 'wire_transfer',
  Unknown = 'unknown',
}
