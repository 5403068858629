import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import ErrorScreen from './ErrorScreen';

interface InterfaceProps {
  children: React.ReactNode;
}

const reload = () => {
  window.location.href = '/';
};

function ErrorBoundary({ children }: InterfaceProps): JSX.Element {
  return (
    <ReactErrorBoundary
      FallbackComponent={() => <ErrorScreen onReload={reload} />}
    >
      {children}
    </ReactErrorBoundary>
  );
}

export default ErrorBoundary;
