import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Box, Button, Link, Popover, Text } from '@nimbus-ds/components';
import { DownloadIcon, EllipsisIcon } from '@nimbus-ds/icons';
import { navigateHeaderRemove } from '@tiendanube/nexo/helpers';
import AppLayout from 'App/components/AppLayout';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { RestrictVisibility } from 'commons/components';
import { useIsMobile } from 'commons/hooks';
import useSettings from 'commons/hooks/useSettingsInLocalStorage';
import { useTranslationWithPrefix } from 'commons/utils';
import WithdrawsCard from './WithdrawsCard/WithdrawsCard';
import BlockedBanner from './BlockedBanner';
import ChargebackAlerts from './ChargebackAlerts';
import { ConfigButtonHeader, ConfigLink } from './ConfigLink';
import DisabledBanner from './DisabledBanner';
import FreemiumWarningModal from './FreemiumWarningModal';
import HomeCards from './HomeCards';
import HomeNotifications from './HomeNotifications';
import HomeSkeleton from './HomeSkeleton';
import { HomeTableDesktop, HomeTableMobile } from './HomeTable';
import PendingDocBanner from './PendingDocBanner';
import ProofOfLifeErrorHomeAlert from './ProofOfLifeErrorHomeAlert';
import ProofOfLifePendingBanner from './ProofOfLifePendingBanner';
import ReportSidebar from './ReportSidebar';

function Home(): JSX.Element {
  const { t } = useTranslationWithPrefix('home');
  const isMobile = useIsMobile();
  const [reportSidebarOpen, setReportSidebarOpen] = useState(false);
  const {
    settings: { hideReportPopover },
    setHideReportPopover,
  } = useSettings();

  const history = useHistory();

  useEffect(() => {
    navigateHeaderRemove(nexo);
  }, []);

  function openReportSidebar() {
    track('nuvempago_dashboard_exportar_listado_click');
    history.push(ROUTES.TRANSACTIONS_REPORT);
  }

  function closeReportSidebar() {
    setReportSidebarOpen(false);
  }

  function closeReportPopover() {
    setHideReportPopover({ newSpecificSettingChange: true });
  }

  const popoverContent = (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <Text color="neutral-surface" fontSize="base" fontWeight="bold">
        {t('reportPopover.title')}
      </Text>
      <Text color="neutral-surface" fontSize="base">
        {t('reportPopover.description')}
      </Text>
      <Link onClick={closeReportPopover} appearance="neutral-background">
        {t('reportPopover.understood')}
      </Link>
    </Box>
  );

  const homeMenuContent = (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <ConfigLink />
    </Box>
  );

  const desktopHeaderItems = (
    <Box display="flex" gap="4">
      <ConfigButtonHeader />
      <RestrictVisibility view="report">
        <Popover
          content={popoverContent}
          backgroundColor="primary-interactiveHover"
          visible={!hideReportPopover}
          position="bottom-end"
        >
          <Button onClick={openReportSidebar} appearance="primary">
            <DownloadIcon />
            {t('report')}
          </Button>
        </Popover>
      </RestrictVisibility>
    </Box>
  );

  const mobileNavBarItems = (
    <Box display="flex" gap="4">
      <RestrictVisibility view="report">
        <Popover
          content={popoverContent}
          backgroundColor="primary-interactiveHover"
          visible={!hideReportPopover}
          position="bottom-end"
        >
          <Link onClick={openReportSidebar} textDecoration="none">
            <DownloadIcon />
            <Text color="primary-textHigh" fontSize="base">
              {t('report')}
            </Text>
          </Link>
        </Popover>
        <Popover content={homeMenuContent} position="bottom-end" arrow={false}>
          <Link textDecoration="none">
            <EllipsisIcon />
          </Link>
        </Popover>
      </RestrictVisibility>
    </Box>
  );

  return (
    <AppLayout
      hideBackButton
      title={t('title')}
      headerItems={isMobile ? null : desktopHeaderItems}
      navBarItems={mobileNavBarItems}
    >
      <Box display="flex" flexDirection="column" gap="6" alignItems="stretch">
        <ProofOfLifeErrorHomeAlert />
        <HomeNotifications />
        <DisabledBanner />
        <BlockedBanner />
        <PendingDocBanner />
        <ProofOfLifePendingBanner />
        <ChargebackAlerts />
        <HomeCards />
        <RestrictVisibility view="withdraws">
          <WithdrawsCard />
        </RestrictVisibility>
        {isMobile ? <HomeTableMobile /> : <HomeTableDesktop />}
      </Box>
      <FreemiumWarningModal />
      <ReportSidebar open={reportSidebarOpen} onRemove={closeReportSidebar} />
    </AppLayout>
  );
}

Home.Skeleton = HomeSkeleton;
export default Home;
