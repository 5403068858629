import React, { useContext, useEffect } from 'react';
import { Box, Button, Link, Popover, Text } from '@nimbus-ds/components';
import { CogIcon, DownloadIcon, EllipsisIcon } from '@nimbus-ds/icons';
import { navigateHeaderRemove } from '@tiendanube/nexo/helpers';
import AppLayout from 'App/components/AppLayout';
import nexo from 'App/nexoClient';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import HomeCardsMock from './HomeCardsMock';
import { HomeMockContext } from './HomeMockContext';
import HomeMockSkeleton from './HomeMockSkeleton';
import HomeTableDesktopMock from './HomeTableDesktopMock';
import HomeTableMobileMock from './HomeTableMobileMock';
import OnBoardingPopover from './OnBoardingPopover';
import { OnBoardingSteps } from './OnBoardingSteps';

function HomeMock(): JSX.Element {
  const { t } = useTranslationWithPrefix('home');
  const isMobile = useIsMobile();
  const { step, nextStep, finishOnBoarding, closeOnBoarding, isMutating } =
    useContext(HomeMockContext);

  useEffect(() => {
    navigateHeaderRemove(nexo);
  }, []);

  if (isMutating) return <HomeMockSkeleton />;

  const homeMenuContent = (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <OnBoardingPopover
        visible={step === OnBoardingSteps.Configuration}
        title={t('mock.configPopover.title')}
        description={t('mock.configPopover.description')}
        linkText={t('mock.configure')}
        onLinkClick={finishOnBoarding}
        onDismiss={closeOnBoarding}
      >
        <Link textDecoration="none">
          <CogIcon />
          <Text color="primary-textHigh" fontSize="base">
            {t('setting')}
          </Text>
        </Link>
      </OnBoardingPopover>
    </Box>
  );

  const desktopHeaderItems = (
    <Box display="flex" gap="4">
      <OnBoardingPopover
        visible={step === OnBoardingSteps.Configuration}
        title={t('mock.configPopover.title')}
        description={t('mock.configPopover.description')}
        linkText={t('mock.configure')}
        onLinkClick={finishOnBoarding}
        onDismiss={closeOnBoarding}
      >
        <Button>
          <CogIcon />
          {t('setting')}
        </Button>
      </OnBoardingPopover>
      <OnBoardingPopover
        visible={step === OnBoardingSteps.Report}
        title={t('mock.reportPopover.title')}
        description={t('mock.reportPopover.description')}
        linkText={t('mock.next')}
        onLinkClick={nextStep}
        onDismiss={closeOnBoarding}
      >
        <Button appearance="primary">
          <DownloadIcon />
          {t('report')}
        </Button>
      </OnBoardingPopover>
    </Box>
  );

  const mobileNavBarItems = (
    <Box display="flex" gap="4">
      <OnBoardingPopover
        visible={step === OnBoardingSteps.Report}
        title={t('mock.reportPopover.title')}
        description={t('mock.reportPopover.description')}
        linkText={t('mock.next')}
        onLinkClick={nextStep}
        onDismiss={closeOnBoarding}
      >
        <Link textDecoration="none">
          <DownloadIcon />
          <Text color="primary-textHigh" fontSize="base">
            {t('report')}
          </Text>
        </Link>
      </OnBoardingPopover>
      <Popover
        content={homeMenuContent}
        position="bottom-end"
        arrow={false}
        visible={step === OnBoardingSteps.Configuration}
      >
        <Link textDecoration="none">
          <EllipsisIcon />
        </Link>
      </Popover>
    </Box>
  );

  return (
    <AppLayout
      hideBackButton
      title={t('title')}
      headerItems={isMobile ? null : desktopHeaderItems}
      navBarItems={mobileNavBarItems}
    >
      <Box display="flex" flexDirection="column" gap="6" alignItems="stretch">
        <HomeCardsMock />
        {isMobile ? <HomeTableMobileMock /> : <HomeTableDesktopMock />}
      </Box>
    </AppLayout>
  );
}

export default HomeMock;
