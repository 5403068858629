import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';
import {
  Box,
  Button,
  Icon,
  Link,
  Tag,
  Text,
  Title,
} from '@nimbus-ds/components';
import { RedoIcon } from '@nimbus-ds/icons';
import { CalendarIcon } from '@nimbus-ds/icons';
import { ROUTES } from 'App/Routes/constants';
import { Roles } from 'commons/components/Roles/Roles.enum';
import ShowWithRole from 'commons/components/Roles/ShowWithRoles';
import { useIsMobile, useStoreURL, useUnobstructiveClick } from 'commons/hooks';
import { FeatureFlag, featureFlagService } from 'commons/services';
import { redirectToOrder, useTranslationWithPrefix } from 'commons/utils';
import MobileTopNavBar from 'domains/Brazil/commons/components/MobileTopNavBar';
import StatusTag, {
  TypeTag,
} from 'domains/Brazil/commons/components/StatusTag';
import { TransactionDetailsType } from 'domains/Brazil/commons/types/TransactionsDetailsType';
import {
  isCreditCard,
  isPix,
  isRefundablePaymentMethod,
} from 'domains/Brazil/commons/utils/paymentMethod';
import { isRefundableStatus } from 'domains/Brazil/commons/utils/status';

interface Props {
  readonly details: TransactionDetailsType;
}

function renderPtBrDate(date: string) {
  // eslint-disable-next-line max-len
  return new Date(date).toLocaleDateString('pt-br', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  });
}

const padOrderNumber = (o?: string) => String(o).padStart(3, '0');

interface RefundButtonProps {
  readonly label: string;
}

function NuvemshopOrderLink({
  orderId,
  orderNumber,
}: Readonly<{
  orderId?: string;
  orderNumber?: string;
}>): JSX.Element {
  const { t } = useTranslationWithPrefix('transactions.details.header');
  const { buildAbsoluteStoreURL } = useStoreURL();

  const isAbandonnedCart = orderNumber === '---';
  const absoluteOrderUrl = buildAbsoluteStoreURL(
    `/admin/v2/${isAbandonnedCart ? 'abandoned-carts' : 'orders'}/${orderId}`,
  );

  const redirectToNsOrderDetail = useCallback(() => {
    redirectToOrder(orderId!, isAbandonnedCart);
  }, [isAbandonnedCart, orderId]);
  const onClick = useUnobstructiveClick(redirectToNsOrderDetail);

  return orderId ? (
    <Link
      appearance="primary"
      as="a"
      data-testid="moreInfoLink"
      onClick={onClick}
      href={absoluteOrderUrl}
    >
      {t(isAbandonnedCart ? 'abandonedCartLinkText' : 'orderLinkText')}
    </Link>
  ) : (
    <></>
  );
}

export function HeaderBox(props: Props): JSX.Element {
  const isMobile = useIsMobile();
  const [isPixRefundableByFlag, setIsPixRefundableByFlag] =
    useState<boolean>(false);
  const { t: transactionsT } = useTranslationWithPrefix('transactions');
  const { t: refundT } = useTranslationWithPrefix('refund');
  const history = useHistory();

  const navigateToRefundDetail = () => {
    const transactionId = props.details?.id;
    const orderId = props.details?.orderId;

    if (amplitudeTag) track(amplitudeTag);

    if (transactionId && orderId) {
      history.push(`${ROUTES.ORDER_REFUND}/${transactionId}/${orderId}`);
    }
  };

  const amplitudeTag = useMemo(() => {
    switch (props.details.status) {
      case TypeTag.Paid:
        if (isPix(props.details.payment.paymentMethod))
          return 'nuvem_pago_payment_pix_approved_detail_refund_button_click';
        else if (isCreditCard(props.details.payment.paymentMethod))
          return 'nuvem_pago_payment_credit_card_approved_detail_refund_button_click';

        return;
      case TypeTag.PartiallyRefunded:
        if (isPix(props.details.payment.paymentMethod))
          return 'nuvem_pago_payment_pix_partial_refunded_detail_refund_button_click';
        else if (isCreditCard(props.details.payment.paymentMethod))
          return 'nuvem_pago_payment_credit_card_partial_refunded_detail_refund_button_click';
    }
  }, [props.details.payment.paymentMethod, props.details.status]);

  useEffect(() => {
    async function checkPixRefundFlag() {
      try {
        const enabled = await featureFlagService.isFeatureFlagEnabled(
          FeatureFlag.FS_NUVEMPAGO_REFUND_PIX,
        );
        setIsPixRefundableByFlag(enabled);
      } catch (error) {
        setIsPixRefundableByFlag(false);
      }
    }

    if (
      isPix(props.details.payment.paymentMethod) &&
      isRefundableStatus(props.details.status)
    ) {
      checkPixRefundFlag();
    }
  }, [props.details.payment.paymentMethod, props.details.status]);

  const isRefundable = useMemo(() => {
    if (
      !isRefundablePaymentMethod(props.details.payment.paymentMethod) ||
      !isRefundableStatus(props.details.status)
    ) {
      return false;
    }

    if (isPix(props.details.payment.paymentMethod) && !isPixRefundableByFlag) {
      return false;
    }

    return true;
  }, [
    isPixRefundableByFlag,
    props.details.payment.paymentMethod,
    props.details.status,
  ]);

  function MobileRefundLink({ label }: RefundButtonProps) {
    if (!isRefundable) return <></>;

    return (
      <ShowWithRole role={Roles.REFUND}>
        <Box>
          <Link textDecoration="none">
            <Text
              fontSize="base"
              color="primary-textHigh"
              data-testid="refundButtonMobile"
              as="p"
              onClick={navigateToRefundDetail}
            >
              {label}
            </Text>
          </Link>
        </Box>
      </ShowWithRole>
    );
  }

  function DesktopRefundButton({ label }: RefundButtonProps) {
    if (!isRefundable) return <></>;

    return (
      <ShowWithRole role={Roles.REFUND}>
        <Box justifyContent="flex-end">
          <Button
            data-testid="refundButtonDesktop"
            onClick={navigateToRefundDetail}
          >
            <RedoIcon color="primary-textLow" size={16} />
            {label}
          </Button>
        </Box>
      </ShowWithRole>
    );
  }

  return (
    <Box borderColor="neutral-interactive" display="grid">
      <MobileTopNavBar backLinkPath={ROUTES.DASHBOARD} padding="none">
        {isMobile && <MobileRefundLink label={refundT('refund')} />}
      </MobileTopNavBar>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        marginTop="4"
        marginBottom="2"
      >
        <Title color="primary-textHigh">
          {transactionsT('details.header.title', {
            transactionNumber: padOrderNumber(props.details.orderNumber),
          })}
        </Title>
        {!isMobile && <DesktopRefundButton label={refundT('refund')} />}
      </Box>
      <Box marginTop="2" marginBottom="4">
        <StatusTag type={props.details.status} />
      </Box>
      <Box
        gridTemplateColumns="auto 1fr"
        display="grid"
        marginTop="2"
        marginBottom="1"
      >
        <Box display="flex" gap="3-5" alignItems="center">
          <Box display="flex" gap="2" alignItems="center">
            <Icon source={<CalendarIcon />} />
            <Text fontSize="caption" textAlign="left" color="neutral-textLow">
              {renderPtBrDate(props.details.createdAt)}
            </Text>
          </Box>
          <NuvemshopOrderLink
            orderId={props.details.orderId}
            orderNumber={props.details.orderNumber}
          />
        </Box>
      </Box>
    </Box>
  );
}

function Skeleton(): JSX.Element {
  const isMobile = useIsMobile();

  return (
    <Box borderColor="neutral-interactive" display="grid">
      <MobileTopNavBar backLinkPath={ROUTES.DASHBOARD} padding="none">
        {isMobile && <Link.Skeleton />}
      </MobileTopNavBar>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        marginTop="4"
        marginBottom="2"
      >
        <Title.Skeleton width="208px" />
        {!isMobile && <Button.Skeleton width="145px" />}
      </Box>
      <Box marginTop="2" marginBottom="4">
        <Tag.Skeleton width={isMobile ? '58px' : '104px'} height="18px" />
      </Box>
      <Box marginY="2">
        <Text.Skeleton width="144px" />
      </Box>
    </Box>
  );
}

HeaderBox.Skeleton = Skeleton;

export default HeaderBox;
