import React from 'react';
import { Link } from '@nimbus-ds/components';
import { RestrictedAction } from './RestrictedAction';
import { Views } from './useShouldLinkTo';

type Props = {
  view: Views;
  whenRestricted: 'dummy' | 'hide';
} & React.ComponentProps<typeof Link>;

export function RestrictedLink(props: Props) {
  return <RestrictedAction {...props} renderAs="link" />;
}
