import React, { useContext } from 'react';
import { Box, Title } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';
import DataTableDesktop from '../HomeTable/HomeTableDesktop/DataTableDesktop';
import { HomeMockContext } from './HomeMockContext';
import OnBoardingPopover from './OnBoardingPopover';
import { OnBoardingSteps } from './OnBoardingSteps';
import TransactionsFilterMock from './TransactionsFilterMock';
import { transactionsMock } from './transactionsMock';

function HomeTableDesktopMock(): JSX.Element | null {
  const { t } = useTranslationWithPrefix('home');
  const { step, nextStep, closeOnBoarding } = useContext(HomeMockContext);
  const transactions = transactionsMock;
  const pageNumber = 1;
  const totalPages = 0;
  const totalItems = 0;
  const itemsPerPage = 0;

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <Box display="inline-flex">
        <OnBoardingPopover
          visible={step === OnBoardingSteps.Transactions}
          title={t('mock.transactionsPopover.title')}
          description={t('mock.transactionsPopover.description')}
          linkText={t('mock.next')}
          onLinkClick={nextStep}
          onDismiss={closeOnBoarding}
        >
          <Title as="h2">{t('homeTable.title')}</Title>
        </OnBoardingPopover>
      </Box>
      <TransactionsFilterMock />
      <DataTableDesktop
        transactions={transactions}
        pageNumber={pageNumber}
        totalPages={totalPages}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        onPageChange={() => {}}
        mock
      />
    </Box>
  );
}

export default HomeTableDesktopMock;
