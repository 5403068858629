import React, { useState } from 'react';
import { Box, Button, Modal, Text } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

type KycModalArgs = { title: string; description: string; content: string };
function KycModal({ title, description, content }: KycModalArgs): JSX.Element {
  const [open, setOpen] = useState<boolean>(true);

  const { t } = useTranslationWithPrefix('dashboard.components.kycModal');

  return (
    <Modal open={open} onDismiss={() => setOpen(false)} data-testid="kyc-modal">
      <Modal.Header title={title} />
      <Modal.Body padding="none">
        <Box marginY={'4'} display={'flex'} flexDirection={'column'} gap={'5'}>
          <Text as="p" fontSize={'highlight'} lineHeight={'highlight'}>
            {description}
          </Text>
          <Text as="p" fontSize={'highlight'} lineHeight={'highlight'}>
            {content}
          </Text>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid={'modal-button'}
          onClick={() => setOpen(false)}
          appearance="primary"
        >
          {t('confirmationButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default KycModal;
