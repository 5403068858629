import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import {
  Alert,
  Box,
  Button,
  Card,
  Icon,
  Link,
  Modal,
  Skeleton,
  Text,
  Title,
} from '@nimbus-ds/components';
import {
  CameraIcon,
  ExternalLinkIcon,
  QrCodeIcon,
  UploadIcon,
} from '@nimbus-ds/icons';
import { Layout, Page } from '@nimbus-ds/patterns';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile } from 'commons/hooks';
import livenessService, {
  LivenessQrCodeResponse,
} from 'commons/services/liveness/livenessService';
import { useTranslationWithPrefix } from 'commons/utils';
import {
  dataDogLogger,
  DataDogLoggerActionTypes,
} from 'commons/utils/dataDogLogger';
import MobileTopNavBar from '../commons/components/MobileTopNavBar';
import RenderOnError from '../commons/components/RenderOnError';
import EmptyStateBanner from './liveness-empty-state.png';

interface LivenessValidationPageContentProps {
  readonly data: LivenessQrCodeResponse;
}

function LivenessValidationPageContent({
  data: { base64, url },
}: LivenessValidationPageContentProps): JSX.Element {
  const { t } = useTranslationWithPrefix('liveness.identityValidation');
  const isMobile = useIsMobile();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const onCancelClick = () => history.push(ROUTES.DASHBOARD);
  const onStartClick = () => {
    track('nuvem_pago_validation_id_start_click');
    setShowModal(true);
  };
  const onValidationLinkClick = () => {
    track('nuvem_pago_validation_id_access_validation_click');
  };
  const onModalDismiss = () => setShowModal(false);

  return (
    <Page
      data-testid="liveness-identity-validation-page"
      maxWidth="1020px"
      height="100vh"
    >
      <Page.Header paddingX="4" title={t('title')} subtitle={t('subtitle')} />
      <Page.Body paddingX="4" paddingTop={isMobile ? '4' : '16'}>
        <Layout
          columns={isMobile ? '1' : '2 - symmetric'}
          alignItems="center"
          justifyContent="center"
          padding="4"
          gap="4"
          minWidth="342px"
          maxWidth="956px"
        >
          <Layout.Section alignItems="center" justifyContent="center">
            <img src={EmptyStateBanner} width="auto" height="240px" alt="" />
          </Layout.Section>
          <Layout.Section gap="4">
            <Title as={isMobile ? 'h4' : 'h2'}>{t('instructions.title')}</Title>
            <Box display="flex" flexDirection="row" alignItems="center" gap="2">
              <Icon
                color="primary-interactive"
                source={<QrCodeIcon size="medium" />}
              />
              <Text
                fontSize={isMobile ? 'caption' : 'highlight'}
                lineHeight={isMobile ? 'caption' : 'highlight'}
              >
                {t('instructions.scanQrCode')}
              </Text>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" gap="2">
              <Icon
                color="primary-interactive"
                source={<CameraIcon size="medium" />}
              />
              <Text
                fontSize={isMobile ? 'caption' : 'highlight'}
                lineHeight={isMobile ? 'caption' : 'highlight'}
              >
                {t('instructions.takeSelfie')}
              </Text>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" gap="2">
              <Icon
                color="primary-interactive"
                source={<UploadIcon size="medium" />}
              />
              <Text
                fontSize={isMobile ? 'caption' : 'highlight'}
                lineHeight={isMobile ? 'caption' : 'highlight'}
              >
                {t('instructions.uploadDocument')}
              </Text>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              gap="2"
            >
              <Button onClick={onCancelClick}>{t('controls.cancel')}</Button>
              <Button appearance="primary" onClick={onStartClick}>
                {t('controls.start')}
              </Button>
            </Box>
          </Layout.Section>
        </Layout>

        <Modal
          open={showModal}
          onDismiss={onModalDismiss}
          maxWidth="340px"
          data-testid="liveness-identity-validation-modal"
        >
          <Modal.Header title={t('qrCodeModal.title')} />
          <Modal.Body padding="none">
            <Box display="flex" flexDirection="column" gap="4">
              <Card>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  gap="4"
                >
                  <img
                    src={base64}
                    width="175px"
                    alt=""
                    data-testid="liveness-identity-validation-qrcode"
                  />
                  <Link
                    as="a"
                    appearance="primary"
                    textDecoration="none"
                    href={url}
                    target="_blank"
                    onClick={onValidationLinkClick}
                  >
                    {t('qrCodeModal.linkText')}
                    <Icon color="currentColor" source={<ExternalLinkIcon />} />
                  </Link>
                </Box>
              </Card>
              <Alert title={t('maxAttemptsAlert.title')}>
                {t('maxAttemptsAlert.message')}
              </Alert>
            </Box>
          </Modal.Body>
        </Modal>
      </Page.Body>
    </Page>
  );
}

function LivenessValidationPageSkeleton(): JSX.Element {
  const isMobile = useIsMobile();

  return (
    <Page
      data-testid="liveness-identity-validation-page-skeleton"
      maxWidth="1020px"
      height="100vh"
    >
      <Box display="flex" flexDirection="column" gap="4" padding="4">
        <Title.Skeleton width="100%" />
        <Text.Skeleton width="100%" />
      </Box>
      <Page.Body paddingX="4" paddingTop={isMobile ? '4' : '16'}>
        <Layout
          columns={isMobile ? '1' : '2 - symmetric'}
          alignItems="center"
          justifyContent="center"
          gap="4"
          minWidth="342px"
          maxWidth="956px"
        >
          <Layout.Section
            alignItems="center"
            justifyContent="center"
            minHeight="240px"
          >
            <Skeleton borderRadius="4px" width="100%" height="240px" />
          </Layout.Section>
          <Layout.Section gap="4">
            <Title.Skeleton width="100%" />
            <Text.Skeleton fontSize="base" width="100%" />
            <Text.Skeleton fontSize="base" width="100%" />
            <Text.Skeleton fontSize="base" width="100%" />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              gap="2"
            >
              <Button.Skeleton width="94px" />
              <Button.Skeleton width="94px" />
            </Box>
          </Layout.Section>
        </Layout>
      </Page.Body>
    </Page>
  );
}

export default function LivenessValidation(): JSX.Element {
  const { t } = useTranslationWithPrefix('liveness.identityValidation');
  const { t: navT } = useTranslationWithPrefix('navigation');
  const isMobile = useIsMobile();
  const [data, setData] = useState<LivenessQrCodeResponse | undefined>();
  const [hasError, setHasError] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setHasError(false);
      const qrCodeData = await livenessService.generateValidationQrCode();
      setData(qrCodeData);
    } catch (error) {
      setHasError(true);
      dataDogLogger.generate({
        actionMessage: `Error generating QR code and URL for liveness identity validation`,
        actionOwner: 'Liveness',
        actionType: DataDogLoggerActionTypes.Error,
        actionData: {},
        error,
      });
    }
  }, []);

  useEffect(() => {
    navigateHeader(nexo, { goTo: ROUTES.DASHBOARD, text: navT('back') });
  }, [navT]);

  useEffect(() => {
    if (data) return;

    fetchData();
  }, [data, fetchData]);

  return (
    <Box
      backgroundColor={isMobile ? 'neutral-background' : 'neutral-surface'}
      maxWidth="100vw"
      minHeight="100vh"
    >
      {isMobile && <MobileTopNavBar backLinkPath={ROUTES.DASHBOARD} />}
      <RenderOnError
        title={t('onError.message')}
        message=""
        label={t('onError.label')}
        error={hasError}
        refreshHandler={fetchData}
      >
        {data ? (
          <LivenessValidationPageContent data={data} />
        ) : (
          <LivenessValidationPageSkeleton />
        )}
      </RenderOnError>
    </Box>
  );
}
