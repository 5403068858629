import React from 'react';
import { useHistory } from 'react-router';
import { Text } from '@nimbus-ds/components';
import { CogIcon } from '@nimbus-ds/icons';
import { ROUTES } from 'App/Routes/constants';
import { RestrictedButton, RestrictedLink } from 'commons/components';
import { useBackLink } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';

export function useConfigLink() {
  const { t } = useTranslationWithPrefix('home');
  const history = useHistory();
  const { setBackLink } = useBackLink();

  function redirectToConfig() {
    setBackLink({
      backLinkPath: ROUTES.HOME,
      backLinkText: t('nuvemshopPanel'),
    });
    history.push(ROUTES.CONFIG);
  }

  return { redirectToConfig };
}

export function ConfigLink() {
  const { t } = useTranslationWithPrefix('home');
  const { redirectToConfig } = useConfigLink();

  return (
    <RestrictedLink
      view="configuration"
      whenRestricted="hide"
      onClick={redirectToConfig}
      textDecoration="none"
    >
      <CogIcon />
      <Text color="primary-textHigh" fontSize="base">
        {t('setting')}
      </Text>
    </RestrictedLink>
  );
}

export function ConfigButtonHeader() {
  const { t } = useTranslationWithPrefix('home');
  const { redirectToConfig } = useConfigLink();
  return (
    <RestrictedButton
      view="configuration"
      whenRestricted="hide"
      onClick={redirectToConfig}
    >
      <CogIcon />
      {t('setting')}
    </RestrictedButton>
  );
}
