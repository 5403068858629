import { DocumentType } from '../types/DocumentType.enum';

export const formatCPF = (document: string): string =>
  document
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+$/, '$1');

export const formatCNPJ = (document: string): string =>
  document
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+$/, '$1');

export function documentMask(document: string): string {
  const digits = document.replace(/\D/g, '');

  if (digits.length <= 11) return formatCPF(digits);

  return formatCNPJ(digits);
}

export function documentAnonimize(document: string): string {
  const digits = document.replace(/[^0-9|*]/g, '');

  if (digits.length <= 11) {
    return digits.replace(
      /(\d{3}|\*{3})(\d{3})(\d{3})(\d{2}|\*{2})/,
      '***.$2.$3-**',
    );
  }

  return formatCNPJ(digits);
}

export const documentType = (document: string): DocumentType =>
  document.replace(/\D/g, '').length <= 11
    ? DocumentType.CPF
    : DocumentType.CNPJ;
