import axios from 'App/axios';

export enum ChargesDashboardStatus {
  Pending = 'pending',
  Authorized = 'authorized',
  FraudAnalysisApproved = 'fraud_analysis_approved',
  Paid = 'paid',
  Refunded = 'refunded',
  PartiallyRefunded = 'partially_refunded',
  Failed = 'failed',
  Unknown = 'unknown',
  Chargebacked = 'chargebacked',
  ChargebackDispute = 'chargeback_dispute',
  Expired = 'expired',
}

export enum FailureReason {
  rejectedBySecurity = 'rejected_by_security',
  rejectedByEmissor = 'rejected_by_emissor',
}

export enum Origin {
  NuvemShop = 'Nuvemshop',
  PaymentLink = 'PaymentLink',
}

export interface ChargesDashboardTransaction {
  id: number;
  orderNumber: number;
  status: ChargesDashboardStatus;
  amount: {
    currency: string;
    value: number;
  };
  remainingAmountToRefund: {
    currency: string;
    value: number;
  };
  customer: {
    name: string;
    email: string;
    document: string;
    phone: {
      countryCode: string;
      areaCode: string;
      number: string;
    };
    type: string;
  };
  paymentMethod?: string;
  failureReason?: FailureReason;
  origin?: Origin;
  createdAt: string;
}

export interface ChargesDashboardResponse {
  transactions: ChargesDashboardTransaction[];
  page: number;
  itemsPerPage: number;
  itemsCount: number;
}

export interface KycInfo {
  blocked: boolean;
  approvedOnKyc: boolean;
  isAllowedToWithdraw: boolean;
}

const getTransactions = async (
  params?: unknown,
): Promise<ChargesDashboardResponse> => {
  const { data } = await axios.get<ChargesDashboardResponse>(
    '/admin/transactions',
    { params },
  );
  return data;
};

const getKycInfo = async (): Promise<KycInfo> => {
  const { data } = await axios.get<KycInfo>('/admin/kyc-info');
  return data;
};

interface DashboardServiceInterface {
  getTransactions: (params?: unknown) => Promise<ChargesDashboardResponse>;
  getKycInfo: () => Promise<KycInfo>;
}

const dashboardService: DashboardServiceInterface = {
  getTransactions,
  getKycInfo,
};

export default dashboardService;
