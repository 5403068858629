import React, { useState } from 'react';
import { Link, Popover, Text } from '@nimbus-ds/components';
import { Layout } from '@nimbus-ds/patterns';
import {
  safelyGetFromLocalStorage,
  safelySetIntoLocalStorage,
} from 'App/storage';
import { useTranslationWithPrefix } from 'commons/utils';

const HIDE_POPOVER = 'nuvempagoBrHideRefundReceiptDownloadLinkPopover';

const DownloadLinkPopover: React.FC = ({ children }) => {
  const wasDismissedBefore = safelyGetFromLocalStorage(HIDE_POPOVER) !== 'true';
  const [isVisible, setIsVisible] = useState(wasDismissedBefore);
  const { t } = useTranslationWithPrefix('refund.receipt.download.popover');

  const dismissCallback = () => {
    setIsVisible(false);
    safelySetIntoLocalStorage(HIDE_POPOVER, 'true');
  };

  return (
    <Popover
      visible={isVisible}
      enabledDismiss={true}
      enabledClick={true}
      backgroundColor={'primary-interactiveHover'}
      position="bottom-end"
      maxWidth={'280px'}
      data-testid="refundReceiptDownloadLinkPopover"
      content={
        <Layout>
          <Layout.Section>
            <Text color="neutral-background">{t('description')}</Text>
          </Layout.Section>
          <Layout.Section alignItems={'flex-end'}>
            <Link
              appearance="neutral-background"
              onClick={dismissCallback}
              data-testid="refundReceiptDownloadLinkPopoverDismiss"
            >
              {t('dismiss')}
            </Link>
          </Layout.Section>
        </Layout>
      }
    >
      {children}
    </Popover>
  );
};

export default DownloadLinkPopover;
