import { useCallback } from 'react';
import {
  safelyGetFromLocalStorageWithTTL,
  safelyRemoveFromLocalStorage,
  safelySetIntoLocalStorageWithTTL,
} from 'App/storage';
import { MoneyObjectInterface } from 'commons/types';
import {
  dataDogLogger,
  DataDogLoggerActionTypes,
} from 'commons/utils/dataDogLogger';

export interface IUseLastBalanceBeforeTransfer {
  getLastBalanceValue: () => number | null;
  setLastBalance: (availableToWithdraw: MoneyObjectInterface) => void;
  removeLastBalance: () => void;
}

function useLastBalanceBeforeTransfer(): IUseLastBalanceBeforeTransfer {
  const TTL_IN_HOURS = 12;

  const getLastBalanceValue = useCallback(() => {
    const balance = safelyGetFromLocalStorageWithTTL(
      'lastBalanceBeforeTransfer',
    );
    if (balance !== null) {
      const parsedValue = JSON.parse(balance).value;

      dataDogLogger.generate({
        actionMessage: `merchant has a saved balance in local storage: ${parsedValue}`,
        actionOwner: 'useLastBalanceBeforeTransfer',
        actionData: {
          balanceValue: parsedValue,
          balanceFromLocalStorage: balance,
        },
        actionType: DataDogLoggerActionTypes.Info,
      });

      return parsedValue;
    }
    return null;
  }, []);

  const setLastBalance = useCallback(
    (availableToWithdraw: MoneyObjectInterface) => {
      const availableToWithdrawString = JSON.stringify(availableToWithdraw);

      safelySetIntoLocalStorageWithTTL(
        'lastBalanceBeforeTransfer',
        availableToWithdrawString,
        TTL_IN_HOURS,
      );

      dataDogLogger.generate({
        actionMessage: `merchant saved balance in local storage: ${availableToWithdrawString}`,
        actionOwner: 'useLastBalanceBeforeTransfer',
        actionData: {
          balanceValue: availableToWithdrawString,
        },
        actionType: DataDogLoggerActionTypes.Info,
      });
    },
    [],
  );

  const removeLastBalance = useCallback(() => {
    safelyRemoveFromLocalStorage('lastBalanceBeforeTransfer');

    dataDogLogger.generate({
      actionMessage: 'merchant removed saved balance in local storage',
      actionOwner: 'useLastBalanceBeforeTransfer',
      actionData: {},
      actionType: DataDogLoggerActionTypes.Info,
    });
  }, []);

  return {
    getLastBalanceValue,
    setLastBalance,
    removeLastBalance,
  };
}

export default useLastBalanceBeforeTransfer;
