import { TransferErrorType } from './TransferErrorType';

function getTransferErrorTitle(errorName: TransferErrorType): string {
  switch (errorName) {
    case 'invalid_payout_data':
    case 'payout_transfer_error':
    case 'bank_error':
      return 'transferError';
    case 'compliance_error':
    case 'insufficient_funds':
      return 'transferNotMade';
    default:
      return 'transferError';
  }
}

function getTransferErrorDescription(errorName: TransferErrorType): string {
  switch (errorName) {
    case 'invalid_payout_data':
      return 'contactSupportForReview';
    case 'compliance_error':
      return 'contactSupport';
    case 'payout_transfer_error':
      return 'retry';
    case 'insufficient_funds':
      return 'transferAlreadyInProccess';
    case 'bank_error':
      return 'reviewBankInfo';
    default:
      return 'contactSupport';
  }
}

type TransferErrorParsed = {
  title: string;
  description: string;
};

export function getTransferErrorParsed(
  errorName: TransferErrorType,
): TransferErrorParsed {
  return {
    title: getTransferErrorTitle(errorName),
    description: getTransferErrorDescription(errorName),
  };
}
