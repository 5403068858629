import React from 'react';
import { Text } from '@nimbus-ds/components';
import { Card } from 'commons/services/refundReceiptService';
import { MoneyObjectInterface, PaymentMethodsType } from 'commons/types';
import { capitalize, useTranslationWithPrefix } from 'commons/utils';

interface PaymentMethodDetailProps {
  paymentMethod: PaymentMethodsType;
  installments: number;
  totalInterest: MoneyObjectInterface;
  card?: Card;
  paymentMethodBrand?: string;
}

function PaymentMethodDetail({
  paymentMethod,
  installments,
  totalInterest,
  card,
  paymentMethodBrand,
}: Readonly<PaymentMethodDetailProps>) {
  const { t } = useTranslationWithPrefix('refundReceipt.paymentDetail');

  switch (paymentMethod) {
    case 'debit_card':
    case 'credit_card':
      return (
        <>
          <Text
            fontSize="base"
            fontWeight="regular"
            color="neutral-textLow"
            textAlign="left"
          >
            {t('card', {
              brand: card?.brand ? capitalize(card.brand) : '',
              lastFourDigits: card?.lastFourDigits,
            })}
          </Text>
          <Text
            fontSize="base"
            fontWeight="regular"
            color="neutral-textLow"
            textAlign="left"
          >
            {t('installments', {
              installments: installments,
              count: installments,
            })}{' '}
            {totalInterest?.value ? t('withInterest') : t('withoutInterest')}
          </Text>
        </>
      );

    case 'wire_transfer':
      return (
        <Text
          fontSize="base"
          fontWeight="regular"
          color="neutral-textLow"
          textAlign="left"
        >
          {t('wireTransfer')}
        </Text>
      );

    case 'ticket':
      return (
        <Text
          fontSize="base"
          fontWeight="regular"
          color="neutral-textLow"
          textAlign="left"
        >
          {t('ticket', {
            brand: paymentMethodBrand ? capitalize(paymentMethodBrand) : '',
          })}
        </Text>
      );

    default:
      return null;
  }
}

export default PaymentMethodDetail;
