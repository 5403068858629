import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/ReduxProvider/store';
import { getStoreInfo } from 'redux/selectors';
import { setStoreInfoAction } from 'redux/slices';
import { IStoreInfo } from 'redux/slices/types';
import { IUseStoreInfo } from './types';

function useStoreInfo(): IUseStoreInfo {
  const dispatch = useAppDispatch();
  const storeInfo = useSelector(getStoreInfo);
  const dispatchCallback = useCallback(
    (storeInfo: IStoreInfo) => dispatch(setStoreInfoAction(storeInfo)),
    [dispatch],
  );

  return {
    storeInfo,
    setStoreInfo: dispatchCallback,
  };
}

export default useStoreInfo;
