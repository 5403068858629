import { useQuery } from '@tanstack/react-query';
import { merchantService } from 'commons/services';

export enum PermissionFeature {
  Withdraw = 'withdraw',
}

export function usePermission(feature: PermissionFeature) {
  const hasPermissionQuery = useQuery(['hasPermission', feature], () =>
    merchantService.getPermission(feature),
  );

  return hasPermissionQuery;
}
