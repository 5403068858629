import { useCallback } from 'react';
import { IUseStoreURL, StoreRelativePathBuilderFn } from './types';
import useStoreInfo from './useStoreInfo';

/**
 * Utility hook to work with current store URLs.
 *
 * Returns the following helpers:
 * - `storeURL`: base URL of the current store.
 * - `buildAbsoluteStoreURL`: generates an absolute URL from a relative path.
 *
 * Read the helpers inline docs for more details.
 */
export function useStoreURL(): IUseStoreURL {
  const { storeInfo } = useStoreInfo();
  const storeURL = new URL(storeInfo.url).origin;

  const buildAbsoluteStoreURL = useCallback(
    (path: string | StoreRelativePathBuilderFn) => {
      const relativePath = typeof path === 'string' ? path : path(storeInfo);

      return new URL(relativePath, storeURL).href;
    },
    [storeInfo, storeURL],
  );

  return {
    storeURL,
    buildAbsoluteStoreURL,
  };
}
