import React from 'react';
import { Icon } from '@nimbus-ds/components';
import { MoneyIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { useTranslationWithPrefix } from 'commons/utils';

export default function EmptyState(): JSX.Element {
  const { t } = useTranslationWithPrefix('dashboard.emptyState');

  return (
    <EmptyMessage
      text={t('description')}
      title={t('title')}
      icon={
        <Icon color="neutral-textHigh" source={<MoneyIcon size="large" />} />
      }
      data-testid="Dashboard-EmptyState"
    />
  );
}
