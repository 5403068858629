import React, { useState } from 'react';
import {
  Box,
  Button,
  Link,
  Radio,
  Select,
  Sidebar,
  Text,
  Title,
} from '@nimbus-ds/components';
import { ChevronLeftIcon } from '@nimbus-ds/icons';
import CustomRangeSearch from 'commons/components/CustomRangeSearch/CustomRangeSearch';
import { DateRangeType } from 'commons/types';
import {
  getRelativeDateFromToday,
  todayDate,
  useTranslationWithPrefix,
} from 'commons/utils';
import { transactionStatusLabelMap } from 'domains/Home/StatusLabel';
import SearchFilter from './SearchFilter';
import TransactionsFilterSkeleton from './TransactionsFilterSkeleton';

interface TransactionsFilterInterface {
  setSearchFilter: React.Dispatch<React.SetStateAction<string>>;
  setStatusFilter: React.Dispatch<React.SetStateAction<string>>;
  setDateRangeFilter: React.Dispatch<
    React.SetStateAction<DateRangeType | undefined>
  >;
  setFromDateFilter: React.Dispatch<React.SetStateAction<string>>;
  setToDateFilter: React.Dispatch<React.SetStateAction<string>>;
  transactionsCount?: number;
  skeleton?: boolean;
}

function TransactionsFilter({
  setSearchFilter,
  setStatusFilter,
  setDateRangeFilter,
  setFromDateFilter,
  setToDateFilter,
  transactionsCount,
  skeleton = false,
}: TransactionsFilterInterface): JSX.Element {
  const [appliedFilter, setAppliedFilter] = useState('');
  const [status, setStatus] = useState('');
  const [dateRange, setDateRange] = useState<DateRangeType>();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [fromDate, setFromDate] = useState(getRelativeDateFromToday(-30));
  const [toDate, setToDate] = useState(todayDate());
  const { t } = useTranslationWithPrefix('home');

  const dateRangeOptions = [
    'all',
    'yesterday',
    'seven_days',
    'thirty_days',
    'custom',
  ].map((dateRangeOption) => ({
    label: t(`dateRangeOptions.${dateRangeOption}`),
    value: dateRangeOption as DateRangeType,
  }));

  const statusFilterOptions = Object.entries(transactionStatusLabelMap).map(
    ([statusName, statusMap]) => ({
      label: t(`status.${statusName}`),
      value: statusMap,
    }),
  );

  const setAllFilters = () => {
    if (dateRange !== 'custom') {
      setFromDateFilter('');
      setToDateFilter('');
    } else {
      setFromDateFilter(fromDate);
      setToDateFilter(toDate);
    }
    setDateRangeFilter(dateRange);
    setStatusFilter(status);
    setSidebarOpen(false);
  };

  const clearAllFilters = () => {
    setStatusFilter('');
    setDateRangeFilter(undefined);
    setStatus('');
    setDateRange(undefined);
    setSidebarOpen(false);
    setFromDateFilter('');
    setToDateFilter('');
  };

  if (skeleton) return <TransactionsFilterSkeleton />;

  return (
    <Box display="flex" gap="4" flexDirection="column" alignItems="stretch">
      <SearchFilter
        onSubmit={(value) => {
          setSearchFilter(value);
          setAppliedFilter(value);
        }}
        onClick={() => setSidebarOpen(true)}
        appliedFilter={appliedFilter}
        onDismiss={() => {
          setSearchFilter('');
          setAppliedFilter('');
        }}
        resultCount={
          transactionsCount
            ? `${transactionsCount} ${t('transactionsSearch.payments')}`
            : ''
        }
      />

      <Sidebar
        open={sidebarOpen}
        onRemove={() => setSidebarOpen(false)}
        padding="base"
        maxWidth="480px"
      >
        <Sidebar.Header>
          <Box display="flex" flexDirection="column" gap="6">
            <Link onClick={() => setSidebarOpen(false)} textDecoration="none">
              <ChevronLeftIcon />
            </Link>
            <Title>{t('transactionsSearch.title')}</Title>
          </Box>
        </Sidebar.Header>
        <Sidebar.Body>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            alignItems="stretch"
            gap="4"
          >
            <Box display="flex" flexDirection="column" gap="1">
              <Text fontSize="caption">
                {t('transactionsSearch.selectStatus')}
              </Text>
              <Box display="flex" flexDirection="row" gap="1" flexWrap="wrap">
                {statusFilterOptions.map((option) => (
                  <Radio
                    key={`transaction-filter-status-${option.value}`}
                    name={`transaction-filter-status-${option.value}`}
                    as="button"
                    label={option.label}
                    value={option.value}
                    checked={status === option.value}
                    onChange={(e) => setStatus(e.target.value)}
                  />
                ))}
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap="1">
              <Text fontSize="caption">{t('transactionsSearch.date')}</Text>
              <Select
                name="selectDate"
                id="selectDate"
                value={dateRange}
                onChange={(e) => setDateRange(e.target.value as DateRangeType)}
              >
                {dateRangeOptions.map((opt) => (
                  <Select.Option key={opt.value} {...opt} />
                ))}
              </Select>
            </Box>
            {dateRange === 'custom' && (
              <CustomRangeSearch
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
              />
            )}
          </Box>
        </Sidebar.Body>
        <Sidebar.Footer>
          <Box display="flex" justifyContent="flex-end" gap="2">
            <Button onClick={clearAllFilters}>
              {t('transactionsSearch.cleanFilter')}
            </Button>
            <Button appearance="primary" onClick={setAllFilters}>
              {t('transactionsSearch.filter')}
            </Button>
          </Box>
        </Sidebar.Footer>
      </Sidebar>
    </Box>
  );
}

TransactionsFilter.Skeleton = TransactionsFilterSkeleton;
export default TransactionsFilter;
