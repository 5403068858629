import { getSessionToken } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import axios from 'axios';
import { transformToCamel } from 'commons/utils';

const axiosIntance = axios.create({
  baseURL: process.env.REACT_APP_LENDING_API_URL,
});

axiosIntance.interceptors.request.use(async (request) => {
  const token = await getSessionToken(nexo);
  const bearerToken = `Bearer ${token}`;
  request.headers.set({ ...request.headers, Authorization: bearerToken });
  return request;
});

const transformResponse = (response: any) => {
  if (!response.data) return response;
  return {
    ...response,
    data: transformToCamel(response.data),
  };
};

axiosIntance.interceptors.response.use(transformResponse, (error) => {
  error.response = transformResponse(error.response);
  throw error;
});

export const setTokenToAxiosIntance = (token: string): void => {
  axiosIntance.defaults.headers['x-access-token'] = token;
};

export default axiosIntance;
