import { toCamel } from 'ts-case-convert';

export const transformToCamel = (input: any): any => {
  if (Array.isArray(input)) {
    return input.map(transformToCamel);
  } else if (typeof input === 'object' && !!input) {
    return Object.fromEntries(
      Object.entries(input).map(([key, value]) => [
        toCamel(key),
        transformToCamel(value),
      ]),
    );
  } else {
    return input;
  }
};
