import React from 'react';
import { Box, Icon, Text, Title } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { useTranslationWithPrefix } from 'commons/utils';

function IntroductoryInfoSkeleton(): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');

  const InfoBox = () => (
    <Box display="flex" flexDirection="row" alignItems="flex-start" gap="3">
      <Icon.Skeleton />
      <Text.Skeleton />
    </Box>
  );

  return (
    <AppLayout skeleton title={t('title')}>
      <Box display="flex" flexDirection="column" gap="6">
        <Title.Skeleton as="h4" />
        <Box display="flex" flexDirection="column" gap="4">
          <InfoBox />
          <InfoBox />
          <InfoBox />
        </Box>
      </Box>
    </AppLayout>
  );
}

export default IntroductoryInfoSkeleton;
