import React, { useEffect, useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { Box, ToastProvider } from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import AppLayout from 'App/components/AppLayout';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile } from 'commons/hooks';
import { InstallmentPaymentInfoDto } from 'commons/services/lending/dtos/installment-payment-info.dto';
import lendingService from 'commons/services/lending/lendingService';
import { useTranslationWithPrefix } from 'commons/utils';
import ContentOrError from './components/ContentOrError';
import InstallmentInfo from './components/InstallmentInfo';
import PixExpirationAlert from './components/PixExpirationAlert';
import PixQrCode from './components/PixQrCode';
import Summary from './components/Summary';

function loadPaymentInfo({
  callbackSuccess,
  callbackError,
}: {
  callbackSuccess: (data?: InstallmentPaymentInfoDto) => void;
  callbackError: () => void;
}) {
  lendingService.getPaymentInfo().then(callbackSuccess).catch(callbackError);
}

function LendingPayment(): JSX.Element | null {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState<InstallmentPaymentInfoDto>();
  const isMobile = useIsMobile();
  const { t: navT } = useTranslationWithPrefix('navigation');

  const { t } = useTranslationWithPrefix('lending.installment.paymentInfo');

  const isLoading = loading || !loaded;

  useEffect(() => {
    navigateHeader(nexo, { goTo: ROUTES.DASHBOARD, text: navT('back') });
    track('nuvempago_lending_payment_installments_access');

    if (!loading) return;
    if (loaded) return;
    if (error) return;

    setLoading(true);
    setError(false);

    loadPaymentInfo({
      callbackSuccess: (data) => {
        setLoaded(true);
        setLoading(false);
        setPaymentInfo(data);
      },
      callbackError: () => {
        setError(true);
        setLoaded(false);
        setLoading(false);
      },
    });
  }, [loading, loaded, error, navT]);

  if (!loaded && !loading && !error) setLoading(true);

  const refreshOnError = () => {
    setError(false);
  };

  const isOverdue = new Date(paymentInfo?.dueAt ?? 0) < new Date();

  return (
    <Box
      display="grid"
      gap="4"
      padding="none"
      justifyContent="center"
      alignItems="stretch"
      width="100%"
      minHeight="100%"
      gridTemplateColumns={{ xl: '0.7fr', md: 'none' }}
      backgroundColor={isMobile ? 'neutral-background' : undefined}
    >
      <AppLayout
        title={t('pageTitle')}
        backLinkText={isMobile ? '' : undefined}
        backLinkPath={ROUTES.DASHBOARD}
      >
        <ToastProvider>
          <ContentOrError error={error} refreshHandler={refreshOnError}>
            <React.Fragment>
              {!isMobile && !error && (
                <PixExpirationAlert
                  loading={isLoading}
                  paymentInfo={paymentInfo}
                />
              )}

              <Box
                display={'flex'}
                justifyContent={'flex-start'}
                flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                gap={'4'}
                marginBottom={'4'}
                alignItems={'flex-start'}
              >
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap="4"
                  width={'100%'}
                >
                  <InstallmentInfo
                    loading={isLoading}
                    paymentInfo={paymentInfo}
                  />

                  <Summary loading={isLoading} paymentInfo={paymentInfo} />
                </Box>

                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap="4"
                  width={'100%'}
                >
                  <PixQrCode
                    loading={isLoading}
                    qrCodeStr={paymentInfo?.pixCode || ''}
                    qrCodeImg={paymentInfo?.pixCodeUrl || ''}
                    minHeight={isOverdue && !isMobile ? '186px' : '80px'}
                  />
                </Box>
              </Box>

              {isMobile && !error && (
                <PixExpirationAlert
                  loading={isLoading}
                  paymentInfo={paymentInfo}
                />
              )}
            </React.Fragment>
          </ContentOrError>
        </ToastProvider>
      </AppLayout>
    </Box>
  );
}

export default LendingPayment;
