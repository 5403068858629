import React from 'react';
import { Box } from '@nimbus-ds/components';
import { useIsMobile } from 'commons/hooks';
import { MoneyCard } from './MoneyCard';

function HomeCardsSkeleton(): JSX.Element {
  const isMobile = useIsMobile();

  return (
    <Box display="flex" alignItems="stretch" flexDirection="column" gap="4">
      <Box width="100%">
        <Box
          display="grid"
          gridGap="4"
          gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
        >
          <MoneyCard.Skeleton color="primary" withPrimaryButton />
          <MoneyCard.Skeleton color="primary" />
        </Box>
      </Box>
    </Box>
  );
}

export default HomeCardsSkeleton;
