import React from 'react';
import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { Currency } from 'commons/types/Currency.enum';
import { PaymentLinkSummaryType } from 'commons/types/PaymentLinkDetailsType';
import { useTranslationWithPrefix } from 'commons/utils';
import MoneyField from 'domains/Brazil/commons/components/MoneyField';
import SkeletonHandler from './Skeleton';

interface Props {
  summary: PaymentLinkSummaryType | undefined;
}

type RenderIfValueExistsArgs = {
  value: number | undefined;
  children: JSX.Element | JSX.Element[];
};
function RenderIfValueExists({
  value,
  children,
}: RenderIfValueExistsArgs): JSX.Element {
  if (value === 0) return <></>;

  return <>{children}</>;
}

type renderFieldArgs = { value: number | undefined; label: string };

export default function SummaryCard(props: Props): JSX.Element {
  const { t } = useTranslationWithPrefix('paymentLink.details.summary');

  function RenderNegativeValue({ value, label }: renderFieldArgs) {
    return (
      <RenderIfValueExists value={value}>
        <Box>
          <SkeletonHandler
            skeletonComponent={<Text.Skeleton width="80%" />}
            trigger={!value}
          >
            <Text fontSize="base" textAlign="left" color="neutral-textLow">
              {label}
            </Text>
          </SkeletonHandler>
        </Box>
        <Box marginBottom="2-5">
          <SkeletonHandler
            skeletonComponent={<Text.Skeleton width="100%" />}
            trigger={!value}
          >
            <MoneyField
              color="danger-textLow"
              textAlign="right"
              fontSize="base"
              prefixString="- "
              value={Number(value)}
              currency={Currency.BRL}
              type="cents"
            />
          </SkeletonHandler>
        </Box>
      </RenderIfValueExists>
    );
  }

  function RenderPositiveValue({ value, label }: renderFieldArgs) {
    return (
      <RenderIfValueExists value={value}>
        <Box>
          <SkeletonHandler
            skeletonComponent={<Text.Skeleton width="80%" />}
            trigger={!value}
          >
            <Text fontSize="base" textAlign="left" color="neutral-textLow">
              {label}
            </Text>
          </SkeletonHandler>
        </Box>
        <Box marginBottom="2-5">
          <SkeletonHandler
            skeletonComponent={<Text.Skeleton width="100%" />}
            trigger={!value}
          >
            <MoneyField
              color="neutral-textLow"
              textAlign="right"
              fontSize="base"
              value={Number(value)}
              currency={Currency.BRL}
              type="cents"
            />
          </SkeletonHandler>
        </Box>
      </RenderIfValueExists>
    );
  }

  return (
    <Box>
      <Card padding="none">
        <Box marginLeft={'4'} marginTop={'4'}>
          <SkeletonHandler
            skeletonComponent={<Title.Skeleton />}
            trigger={!props.summary}
          >
            <Card.Header title={t('title')} />
          </SkeletonHandler>
        </Box>
        <Card.Body>
          <Box marginLeft={'4'} marginRight={'4'}>
            <Box
              justifyContent={'flex-start'}
              alignItems={'stretch'}
              gridTemplateColumns="1fr 100px"
              gridTemplateRows="1fr"
              display="grid"
            >
              <RenderPositiveValue
                value={props.summary?.paid}
                label={t('paid')}
              />
              <RenderNegativeValue
                value={props.summary?.fee}
                label={t('fee')}
              />
              {props.summary?.cancelFee !== undefined && (
                <RenderPositiveValue
                  value={props.summary?.cancelFee}
                  label={t('cancelFee')}
                />
              )}
              {props.summary?.refunded !== undefined && (
                <RenderNegativeValue
                  value={props.summary?.refunded}
                  label={t('refunded')}
                />
              )}
            </Box>
          </Box>
        </Card.Body>
        <Box backgroundColor="neutral-surface" padding="none">
          <Box marginLeft={'4'} marginRight={'4'} borderRadius={'none'}>
            <Box
              justifyContent={'flex-start'}
              alignItems={'center'}
              gridTemplateColumns="1fr 100px"
              gridTemplateRows="50px"
              display="grid"
              borderRadius={'none'}
            >
              <SkeletonHandler
                skeletonComponent={<Text.Skeleton width="100px" />}
                trigger={!props.summary}
              >
                <Text
                  fontSize="base"
                  textAlign="left"
                  fontWeight="bold"
                  color="neutral-textLow"
                >
                  {t('total')}
                </Text>
              </SkeletonHandler>
              <SkeletonHandler
                skeletonComponent={<Text.Skeleton width="100%" />}
                trigger={!props.summary}
              >
                <MoneyField
                  color="neutral-textLow"
                  textAlign="right"
                  fontSize="base"
                  fontWeight="bold"
                  value={Number(props.summary?.total)}
                  currency={Currency.BRL}
                  type="cents"
                />
              </SkeletonHandler>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
