import { useQuery } from '@tanstack/react-query';
import { FeatureFlag, featureFlagService } from 'commons/services';

export function useFeatureFlag(featureFlag: FeatureFlag) {
  const featureFlagQuery = useQuery([`${featureFlag}EnabledQuery`], () =>
    featureFlagService.isFeatureFlagEnabled(featureFlag),
  );
  return {
    enabled: featureFlagQuery.isSuccess && featureFlagQuery.data,
    loading: featureFlagQuery.isLoading,
  };
}
