import axios from 'App/axios';

type BankAccountDataType = {
  holderName?: string;
  holderDocument?: string;
  bankName?: string;
  accountNumber?: string;
  bank?: string;
  branchNumber?: string;
  branchCheckDigit?: string;
  accountCheckDigit?: string;
  holderType?: string;
  type?: string;
};

type PatchBankAccountType = (
  data: BankAccountDataType,
) => Promise<BankAccountDataType>;

const patchBankAccount: PatchBankAccountType = async (
  data: BankAccountDataType,
) => {
  const response = await axios.patch('/admin/bank-account', {
    regionalInfo: {
      bankAccount: {
        ...data,
      },
    },
  });
  return response.data;
};

export type GetBankAccountDataType = {
  fiscalDocument: string;
  holderName: string;
  holderDocument: string;
  bankCode: string;
  accountNumber: string;
  bankName: string;
  branchNumber: string;
  branchCheckDigit: string;
  accountCheckDigit: string;
  holderType: string;
  type: string;
  isRegistered: boolean;
};

type GetBankAccountType = () => Promise<GetBankAccountDataType>;

const getBankAccount: GetBankAccountType = async () => {
  const response = await axios.get<GetBankAccountDataType>(
    '/admin/bank-account',
  );

  return response.data;
};

export interface GetAvailiableBanksResponse {
  bank: string;
  bankDescription: string;
}

const getAvailableBanks = async (): Promise<GetAvailiableBanksResponse[]> => {
  try {
    const { data } = await axios.get('/admin/available-banks');
    return data;
  } catch (error: any) {
    throw error;
  }
};

interface bankAccountServiceInterface {
  patchBankAccount: PatchBankAccountType;
  getBankAccount: GetBankAccountType;
  getAvailableBanks: () => Promise<GetAvailiableBanksResponse[]>;
}

const bankAccountService: bankAccountServiceInterface = {
  patchBankAccount,
  getBankAccount,
  getAvailableBanks,
};

export default bankAccountService;
