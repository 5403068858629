import React from 'react';
import { Box, Text } from '@nimbus-ds/components';
import TransactionsFilter from '../TransactionsFilter';

function HomeTableMobileSkeleton(): JSX.Element {
  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <Text.Skeleton />
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <TransactionsFilter.Skeleton />
      </Box>
    </Box>
  );
}

export default HomeTableMobileSkeleton;
