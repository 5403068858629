import React from 'react';
import { Box, Card, Icon, Link, Text, Title } from '@nimbus-ds/components';
import { MailIcon, WhatsappIcon } from '@nimbus-ds/icons';
import { useTranslationWithPrefix } from 'commons/utils';
import { TransactionCustomerType } from 'domains/Brazil/commons/types/TransactionsDetailsType';
import { documentAnonimize } from 'domains/Brazil/commons/utils/document';

interface Props {
  readonly customer: TransactionCustomerType;
}

function CustomerCard(props: Props): JSX.Element {
  const { t } = useTranslationWithPrefix('transactions.details.customer');

  function renderCustomerName(customerName: string) {
    return (
      <Box marginBottom="2-5">
        <Text fontSize="base" textAlign="left" color="neutral-textLow">
          {customerName}
        </Text>
      </Box>
    );
  }

  function renderCustomerEmail(email: string | undefined) {
    return (
      <Box marginBottom="2-5">
        <Box
          display="grid"
          gridTemplateRows="1fr"
          gridTemplateColumns="18px 1fr"
          alignItems="stretch"
        >
          <Link
            textDecoration="none"
            appearance="primary"
            as="a"
            target="_blank"
            href={`mailto:${email}`}
          >
            <Icon color="primary-interactive" source={<MailIcon />} />
            {email}
          </Link>
        </Box>
      </Box>
    );
  }

  function renderCustomerPhone(phone: string) {
    const phoneNumber = phone.replace(/\D/g, '');

    return (
      <Box marginBottom="2-5">
        <Box
          display="grid"
          gridTemplateRows="1fr"
          gridTemplateColumns="300px 1fr"
          alignItems="stretch"
        >
          <Link
            textDecoration="none"
            appearance="primary"
            as="a"
            href={`https://wa.me/${phoneNumber}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon color="primary-interactive" source={<WhatsappIcon />} />
            {phone}
          </Link>
        </Box>
      </Box>
    );
  }

  function renderCustomerDocument(type: string, document: string) {
    return (
      <Box>
        <Text fontSize="base" textAlign="left" color="neutral-textLow">
          {t('document', {
            type: type.toUpperCase(),
            document: documentAnonimize(document),
          })}
        </Text>
      </Box>
    );
  }

  return (
    <Box minWidth="343px">
      <Card padding="none">
        <Box marginLeft={'4'} marginTop={'4'}>
          <Card.Header title={t('title')} />
        </Box>
        <Card.Body padding="none">
          <Box display="grid" marginLeft={'4'} marginBottom={'4'}>
            {renderCustomerName(props.customer.name)}
            {renderCustomerEmail(props.customer.email)}
            {renderCustomerPhone(props.customer.phone)}
            {renderCustomerDocument(
              props.customer.documentType,
              props.customer.document,
            )}
          </Box>
        </Card.Body>
      </Card>
    </Box>
  );
}

function Skeleton(): JSX.Element {
  return (
    <Box minWidth="343px">
      <Card padding="none">
        <Box marginLeft={'4'} marginTop={'4'}>
          <Title.Skeleton width="79px" height="28px" />
        </Box>
        <Card.Body padding="none">
          <Box display="grid" marginBottom={'4'} marginX="4">
            {Array.from({ length: 4 }).map((_, index) => (
              <Box marginBottom={index === 3 ? 'none' : '2-5'} key={index}>
                <Text.Skeleton width="100%" fontSize="highlight" />
              </Box>
            ))}
          </Box>
        </Card.Body>
      </Card>
    </Box>
  );
}

CustomerCard.Skeleton = Skeleton;

export default CustomerCard;
