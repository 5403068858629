import { TransactionObjectInterface } from 'commons/types';

export const transactionsMock = [
  {
    id: 1,
    orderNumber: 1,
    status: 'paid',
    amount: { value: 10, currency: 'ARS' },
    remainingAmountToRefund: { value: 300, currency: 'ARS' },
    customer: {
      document: '',
      email: '',
      name: 'Juan Gomez',
      phone: {
        areaCode: '',
        countryCode: '',
        number: '',
      },
      card: {
        brand: '',
        holderName: '',
        lastFourDigits: '',
      },
      type: '',
    },
    createdAt: '2020-01-01T06:00:00Z',
  },
] as TransactionObjectInterface[];
