import React from 'react';
import { Box, Checkbox, Text, Title } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { useTranslationWithPrefix } from 'commons/utils';

function IsPepOrSoSkeleton(): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');

  const InfoBox = () => (
    <Box display="flex" flexDirection="column" gap="3">
      <Title.Skeleton as="h5" />
      <Text.Skeleton fontSize="highlight" />
    </Box>
  );

  return (
    <AppLayout title={t('title')} skeleton>
      <Box display="flex" flexDirection="column" gap="7">
        <Box display="flex" flexDirection="column" gap="3">
          <InfoBox />
          <InfoBox />
        </Box>
        <Checkbox.Skeleton />
      </Box>
    </AppLayout>
  );
}

export default IsPepOrSoSkeleton;
