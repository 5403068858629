import React from 'react';
import {
  Box,
  Icon,
  IconButton,
  Link,
  Popover,
  PopoverPlacement,
  Text,
} from '@nimbus-ds/components';
import { CloseIcon } from '@nimbus-ds/icons';

interface OnBoardingPopoverInterace {
  visible: boolean;
  title: string;
  description: string;
  linkText: string;
  onLinkClick: React.MouseEventHandler<HTMLElement>;
  onDismiss?: () => void;
  children: JSX.Element;
  position?: PopoverPlacement;
  zIndex?: React.ComponentProps<typeof Popover>['zIndex'];
}

function OnBoardingPopover({
  visible,
  title,
  description,
  linkText,
  onLinkClick,
  onDismiss,
  position,
  children,
  zIndex,
}: OnBoardingPopoverInterace): JSX.Element | null {
  const popoverContent = (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Text color="neutral-surface" fontSize="base" fontWeight="bold">
          {title}
        </Text>
        {onDismiss ? (
          <Box position="absolute" top="4px" right="4px">
            <IconButton
              onClick={onDismiss}
              size="2.75rem"
              source={
                <Icon color="neutral-background" source={<CloseIcon />} />
              }
              borderColor="transparent"
              backgroundColor="transparent"
            />
          </Box>
        ) : null}
      </Box>
      <Text color="neutral-surface" fontSize="base">
        {description}
      </Text>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Link onClick={onLinkClick} appearance="neutral-background">
          {linkText}
        </Link>
      </Box>
    </Box>
  );

  return (
    <Popover
      content={popoverContent}
      backgroundColor="primary-interactiveHover"
      visible={visible}
      position={position ?? 'top-end'}
      maxWidth="300px"
      zIndex={zIndex}
    >
      {children}
    </Popover>
  );
}

export default OnBoardingPopover;
