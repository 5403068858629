import React from 'react';
import { Link, Text } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

function NotEligibleMessage(): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation.notEligible');

  return (
    <>
      <Text>{t('inCaseOf')}</Text>
      <Text>
        <Text as="span" fontWeight="bold">
          {t('obligatedSubject')}
        </Text>
        {t('comma')}
        {t('obligatedSubjectDescription')}
      </Text>
      <Text>
        <Text as="span" fontWeight="bold">
          {t('exposedPerson')}
        </Text>
        {t('comma')}
        {t('exposedPersonDescription')}
      </Text>
      <Text>
        {t('contact1')}
        <Link as="a" href={`mailto: ${t('email')}`}>
          {t('email')}
        </Link>
        {t('contact2')}
      </Text>
    </>
  );
}

export default NotEligibleMessage;
