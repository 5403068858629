/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Box, Card, Text } from '@nimbus-ds/components';
import { ChevronDownIcon, ChevronUpIcon } from '@nimbus-ds/icons';
import { Currency } from 'commons/types/Currency.enum';
import { useTranslationWithPrefix } from 'commons/utils';
import MoneyField from 'domains/Brazil/commons/components/MoneyField';
import RenderOnError from 'domains/Brazil/commons/components/RenderOnError';
interface ValueRowProps {
  label: string;
  value: number;
  isNegative?: boolean;
  color?: 'danger-textLow' | 'neutral-textLow' | 'neutral-textHigh';
  fontWeight?: React.ComponentProps<typeof MoneyField>['fontWeight'];
}

const ValueRow: React.VFC<Readonly<ValueRowProps>> = ({
  label,
  value,
  isNegative,
  fontWeight,
  color,
}) => (
  <Box
    display="flex"
    justifyContent="space-between"
    width={'100%'}
    data-testid="WithdrawAmountForm-BalanceCard"
  >
    <Text textAlign="left" fontWeight={fontWeight} color={color}>
      {label}
    </Text>
    <MoneyField
      color={color ?? (isNegative ? 'danger-textLow' : 'neutral-textLow')}
      textAlign="right"
      fontSize="base"
      fontWeight={fontWeight}
      prefixString={isNegative ? '- ' : undefined}
      value={Number(value)}
      currency={Currency.BRL}
      type="cents"
    />
  </Box>
);

export interface BalanceSummary {
  balance: number;
  fee: number;
  available: number;
}

export interface BalanceCardProps {
  summary: BalanceSummary;
  error?: boolean;
  refreshHandler: (...args: unknown[]) => unknown;
}

function BalanceCard({
  summary,
  error,
  refreshHandler,
}: Readonly<BalanceCardProps>): JSX.Element {
  const [collapsed, isCollapsed] = useState<boolean>(true);
  const { t } = useTranslationWithPrefix('withdraw');

  return (
    <Card data-testid="WithdrawAmountForm-BalanceCard">
      <Card.Body>
        <RenderOnError
          message={t('error.getBalance')}
          label={t('error.retry')}
          error={!!error}
          refreshHandler={refreshHandler}
        >
          <Box
            width="100%"
            backgroundColor="neutral-background"
            display="flex"
            justifyContent="flex-start"
            flexDirection="column"
            gap="6"
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              onClick={() => isCollapsed(!collapsed)}
              gap={'2'}
              data-testid="balanceCardCollapsed"
            >
              <ValueRow
                label={t('transferAmountForm.availableBalance')}
                value={summary.available}
                fontWeight="bold"
                color="neutral-textHigh"
              />
              {collapsed ? (
                <ChevronDownIcon color="neutral-textHigh" />
              ) : (
                <ChevronUpIcon color="neutral-textHigh" />
              )}
            </Box>
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
                gap="2-5"
              >
                <ValueRow
                  label={t('transferAmountForm.balance')}
                  value={summary.balance}
                />
                <ValueRow
                  label={t('transferAmountForm.fee')}
                  value={summary.fee}
                  isNegative
                />
              </Box>
            )}
          </Box>
        </RenderOnError>
      </Card.Body>
    </Card>
  );
}

const Skeleton: React.VFC = () => (
  <Card data-testid="WithdrawAmountForm-BalanceCard-Skeleton">
    <Card.Body>
      <Box
        width="100%"
        backgroundColor="neutral-background"
        display="flex"
        justifyContent="flex-start"
        flexDirection="column"
        gap="6"
      >
        <Box display="flex" gap="4" justifyContent="flex-start">
          <Text.Skeleton width="100%" fontSize="highlight" />
          <Text.Skeleton width="80px" fontSize="highlight" />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          gap="2-5"
        >
          {Array.from({ length: 2 }).map((_, i) => (
            <Box display="flex" gap="4" justifyContent="flex-start" key={i}>
              <Text.Skeleton width="100%" fontSize="highlight" />
              <Text.Skeleton width="80px" fontSize="highlight" />
            </Box>
          ))}
        </Box>
      </Box>
    </Card.Body>
  </Card>
);

BalanceCard.Skeleton = Skeleton;

export default BalanceCard;
