import React from 'react';
import { Box, Card, Tag, Text, Title } from '@nimbus-ds/components';
import { MoneyObjectInterface } from 'commons/types';
import { Currency } from 'commons/types/Currency.enum';
import { useTranslationWithPrefix } from 'commons/utils';
import DateField from 'domains/Brazil/commons/components/DateField';
import MoneyField from 'domains/Brazil/commons/components/MoneyField';

interface RefundDetailInterface {
  createdAt: string | Date;
  totalRefunded: MoneyObjectInterface;
}

export function RefundDetail({
  createdAt,
  totalRefunded,
}: Partial<RefundDetailInterface>): JSX.Element {
  const { t } = useTranslationWithPrefix('refund.receipt.refundDetail');

  return (
    <Card data-testid="PaymentCard">
      <Card.Header color={'neutral-textHigh'}>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Title as="h4">{t('title')}</Title>
          <Tag appearance="neutral">
            <DateField
              date={createdAt ?? ''}
              formatOptions={{
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }}
            />
          </Tag>
        </Box>
      </Card.Header>
      <Card.Body>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Text fontSize="base" color={'neutral-textLow'} textAlign="left">
            {t('total')}
          </Text>
          <MoneyField
            color="neutral-textLow"
            textAlign="left"
            fontWeight="medium"
            lineHeight="base"
            value={Number(totalRefunded?.value ?? 0)}
            currency={Currency.BRL}
          />
        </Box>
      </Card.Body>
    </Card>
  );
}

export function Skeleton(): JSX.Element {
  return (
    <Card data-testid="PaymentCard-Skeleton">
      <Card.Header>
        <Box>
          <Title.Skeleton width="208px" height="20px" />
        </Box>
      </Card.Header>
      <Card.Body>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Text.Skeleton width="100%" height="20px" />
          <Text.Skeleton width="208px" height="20px" />
        </Box>
      </Card.Body>
    </Card>
  );
}

RefundDetail.Skeleton = Skeleton;
