import React from 'react';
import { Box, Icon, Text, Tooltip } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import { Layout } from '@nimbus-ds/patterns';
import { useStoreInfo } from 'commons/hooks';
import { InstallmentFee } from 'commons/services/futureIncomesService/futureIncomesService';
import { MoneyObjectInterface } from 'commons/types';
import { formatLongDateTime, useTranslationWithPrefix } from 'commons/utils';
import { MoneyCard } from 'domains/Home/HomeCards/MoneyCard';
import { IncomesSummary } from '../IncomesSummary';

interface SummarySectionProps {
  updatedAt: string;
  waitingFunds: MoneyObjectInterface;
  grossFunds: MoneyObjectInterface;
  processingFee: MoneyObjectInterface;
  installmentsFees: InstallmentFee[];
  totalTaxes: MoneyObjectInterface;
}

function SummarySection({
  updatedAt,
  waitingFunds,
  grossFunds,
  processingFee,
  installmentsFees,
  totalTaxes,
}: SummarySectionProps): JSX.Element {
  const { t } = useTranslationWithPrefix('futureIncome');
  const { storeInfo } = useStoreInfo();

  return (
    <>
      <Box flexDirection="row" display="flex" alignItems="center" gap="1">
        <Text>
          {t('updatedAt', {
            date: formatLongDateTime(storeInfo, updatedAt),
          })}
        </Text>
        <Tooltip arrow content={t('tooltipDisclamer')}>
          <Icon source={<InfoCircleIcon />} />
        </Tooltip>
      </Box>
      <Layout.Section>
        <MoneyCard
          title={t('moneyToReceive')}
          amount={waitingFunds}
          color="primary"
        />
      </Layout.Section>
      <Layout.Section>
        <IncomesSummary
          total={grossFunds}
          processingFee={processingFee}
          installmentsFees={installmentsFees}
          totalTaxes={totalTaxes}
        />
      </Layout.Section>
    </>
  );
}

export default SummarySection;
