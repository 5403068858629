import { useEffect } from 'react';
import { init, reset, Types } from '@amplitude/analytics-browser';
import { useStoreInfo } from 'commons/hooks';
import { config } from './config';

function Amplitude(): null {
  const { storeInfo } = useStoreInfo();

  useEffect(() => {
    init(config.apiKey, storeInfo.id, {
      logLevel:
        process.env.NODE_ENV === 'development'
          ? Types.LogLevel.Debug
          : undefined,
    });
    return () => {
      reset();
    };
  }, [storeInfo.id]);

  return null;
}

export default Amplitude;
