import { KycDocumentErrorsType } from 'commons/types';
import { KycDocumentNames } from './KycDocumentNames.enum';

function hasKycErrors(kycDocumentsError: KycDocumentErrorsType[]) {
  const hasProofOfLifeError = kycDocumentsError.some(
    (error) =>
      error.documentName === KycDocumentNames.ProofOfLife ||
      error.errorCode.includes(KycDocumentNames.ProofOfLife),
  );

  const hasStatuteError = kycDocumentsError.some(
    (error) =>
      error.documentName === KycDocumentNames.CompanyComposition ||
      error.documentName === KycDocumentNames.CertificateOfIncorporation,
  );

  const hasWebsiteError = kycDocumentsError.some(
    (error) => error.errorCode === 'website_under_construction',
  );

  const hasDocumentsMissmatchError = kycDocumentsError.some(
    (error) => error.errorCode === 'documents_missmatch',
  );

  const onlyHasWebsiteError = hasWebsiteError && kycDocumentsError.length === 1;

  return {
    hasProofOfLifeError,
    hasStatuteError,
    hasWebsiteError,
    hasDocumentsMissmatchError,
    onlyHasWebsiteError,
  };
}

function getKycErrorsTranslation(kycDocumentsErrors: KycDocumentErrorsType[]) {
  const {
    hasStatuteError,
    hasProofOfLifeError,
    onlyHasWebsiteError,
    hasDocumentsMissmatchError,
  } = hasKycErrors(kycDocumentsErrors);

  if (onlyHasWebsiteError) return 'websiteUnderConstructionError';
  if (hasStatuteError && hasProofOfLifeError)
    return 'statuteAndProofOfLifeError';
  if (hasStatuteError) return 'statuteError';
  if (hasDocumentsMissmatchError) return 'documentsMissmatchError';
  else if (hasProofOfLifeError) return 'proofOfLifeError';
  return '';
}

export { hasKycErrors, getKycErrorsTranslation };
