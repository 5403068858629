import { useQuery } from '@tanstack/react-query';
import { refundReceiptService } from 'commons/services';

export function useRefundReceiptDetails(orderId: string) {
  const refundReceiptDetailsQuery = useQuery(
    ['refundReceiptDetails', orderId],
    () => refundReceiptService.getRefundReceiptDetails(orderId),
  );

  return {
    isLoading: refundReceiptDetailsQuery.isLoading,
    refundReceiptDetails: refundReceiptDetailsQuery.data,
  };
}
