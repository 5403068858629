import React from 'react';
import { Box, Icon, Link, Text } from '@nimbus-ds/components';
import { CalendarIcon, ExternalLinkIcon } from '@nimbus-ds/icons';
import { useStoreInfo } from 'commons/hooks';
import {
  formatLongDate,
  redirectToOrder,
  useTranslationWithPrefix,
} from 'commons/utils';

interface InterfaceHeader {
  isAbandonedCart?: boolean;
  orderId: string;
  createdAt?: string;
  skeleton?: boolean;
}

function Header({
  isAbandonedCart = false,
  orderId,
  createdAt,
  skeleton = false,
}: InterfaceHeader): JSX.Element {
  const { t } = useTranslationWithPrefix('transactionDetails.header');
  const { storeInfo } = useStoreInfo();

  if (skeleton)
    return (
      <Box display="flex" gap="2" alignItems="flex-start">
        <Text.Skeleton />
        <Link.Skeleton />
      </Box>
    );

  return (
    <Box display="flex" gap="4" alignItems="center" justifyContent="flex-start">
      <Box
        display="flex"
        gap="2"
        alignItems="center"
        justifyContent="flex-start"
      >
        <CalendarIcon size={16} />
        <Text>{formatLongDate(storeInfo, createdAt || '')}</Text>
      </Box>
      <Link
        onClick={() => redirectToOrder(orderId, isAbandonedCart)}
        appearance="primary"
      >
        <Icon color="currentColor" source={<ExternalLinkIcon size={16} />} />
        {isAbandonedCart ? t('abandonedCartLink') : t('orderLink')}
      </Link>
    </Box>
  );
}

export default Header;
