import React from 'react';
import { Box, Button, Spinner } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';
import FooterButtonsSkeleton from './FooterButtonsSkeleton';

interface FooterButtonsProps {
  onCancelClick: () => void;
  onSubmitClick: () => void;
  isLoading?: boolean;
}

function FooterButtons({
  onCancelClick,
  onSubmitClick,
  isLoading = false,
}: FooterButtonsProps): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');

  return (
    <Box gap="4" display="flex" flexDirection="row">
      <Button onClick={onCancelClick}>{t('buttons.cancel')}</Button>
      <Button appearance="primary" onClick={onSubmitClick} disabled={isLoading}>
        {isLoading && <Spinner size="small" />}
        {t('buttons.continue')}
      </Button>
    </Box>
  );
}

FooterButtons.Skeleton = FooterButtonsSkeleton;
export default FooterButtons;
