import { goTo } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';

const redirectToOrder = (
  orderId: string | number,
  isAbandonedCart = false,
): void =>
  goTo(nexo, `/${isAbandonedCart ? 'abandoned-carts' : 'orders'}/${orderId}`);

export default redirectToOrder;
