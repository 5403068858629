import React from 'react';
import { useHistory } from 'react-router';
import { Box, Button, Spinner } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { useTranslationWithPrefix } from 'commons/utils';

export interface formButtons {
  submitHandler: () => any;
  submittingFlag: boolean;
  disabledFlag: boolean;
}

function FormButtons({
  submitHandler,
  submittingFlag,
  disabledFlag,
}: formButtons): JSX.Element {
  const { t } = useTranslationWithPrefix('paymentLink.form');
  const history = useHistory();

  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-end" gap="2">
      <Button onClick={() => history.push(ROUTES.DASHBOARD)}>
        {t('cancel')}
      </Button>
      <Button
        appearance={'primary'}
        onClick={submitHandler}
        disabled={disabledFlag || submittingFlag}
      >
        {submittingFlag && <Spinner size="small" />}
        {t('create')}
      </Button>
    </Box>
  );
}

function FormButtonsSkeleton(): JSX.Element {
  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-end" gap="2">
      <Button.Skeleton />
      <Button.Skeleton />
    </Box>
  );
}

FormButtons.Skeleton = FormButtonsSkeleton;

export default FormButtons;
