import React, { useState } from 'react';
import { Alert, Box, Text } from '@nimbus-ds/components';
import { useIsMobile } from 'commons/hooks';
import { InstallmentPaymentInfoDto } from 'commons/services/lending/dtos/installment-payment-info.dto';
import { useTranslationWithPrefix } from 'commons/utils';
import { pixExpirationTitle } from '../helpers/pix-expiration-title';

type PixExpirationAlertProps = {
  loading: boolean;
  paymentInfo?: InstallmentPaymentInfoDto;
};

function PixExpirationAlert({
  loading,
  paymentInfo,
}: PixExpirationAlertProps): JSX.Element {
  const { t } = useTranslationWithPrefix(
    'lending.installment.paymentInfo.pixExpirationAlert',
  );
  const isMobile = useIsMobile();
  const [title, time] = pixExpirationTitle(paymentInfo?.expiration ?? 0);
  const [showAlert, setShowAlert] = useState(true);

  return (
    <Box display={'grid'} marginBottom={'4'} width={'100%'}>
      {loading ? (
        <Alert.Skeleton data-testid="pix-expiration-alert-skeleton" />
      ) : (
        <Alert
          show={showAlert}
          appearance={'neutral'}
          onRemove={isMobile ? undefined : () => setShowAlert(false)}
        >
          <Box display={'grid'}>
            <Text fontWeight={'bold'}>{t(title, { time })}</Text>
          </Box>
          <Box display={'grid'}>
            <Text>{t('message')}</Text>
          </Box>
        </Alert>
      )}
    </Box>
  );
}

export default PixExpirationAlert;
