import React from 'react';
import { Button, Link } from '@nimbus-ds/components';
import { useShouldLinkTo, Views } from './useShouldLinkTo';

type CommonProps = {
  view: Views;
  whenRestricted: 'dummy' | 'hide';
};

type Props =
  | (CommonProps & { renderAs: 'link' } & React.ComponentProps<typeof Link>)
  | (CommonProps & { renderAs: 'button' } & React.ComponentProps<
        typeof Button
      >);

export function RestrictedAction(props: Props) {
  const { children, view, whenRestricted, renderAs, ...restProps } = props;
  const { shouldLinkTo } = useShouldLinkTo();

  if (shouldLinkTo(view)) {
    const Component = renderAs === 'link' ? Link : Button;

    return (
      // @ts-expect-error fix this, gets confused with type union
      <Component {...restProps}>{children}</Component>
    );
  } else {
    if (whenRestricted === 'hide') return null;

    return <>{children}</>;
  }
}
