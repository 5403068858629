import React from 'react';
import { Box, Card, Title } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { useTranslationWithPrefix } from 'commons/utils';
import MoneyField from 'domains/Brazil/commons/components/MoneyField';
import { RefundMoneyField } from '../RefundMoneyField/RefundMoneyField';

type ResumeCardArgs = {
  paid: number;
  totalRefunded: number;
  total: number;
};

export function ResumeCard({
  paid,
  totalRefunded,
  total,
}: ResumeCardArgs): JSX.Element {
  const { t } = useTranslationWithPrefix('refund.resumeCard');

  return (
    <Card data-testid="ResumeCard">
      <Card.Header color={'neutral-textHigh'} title={t('title')} />
      <Card.Body>
        <DataList bottomDivider={false}>
          <DataList.Row topDivider={false}>
            <RefundMoneyField label={t('paid')} value={paid} />
          </DataList.Row>
          <DataList.Row paddingY="2" topDivider={false}>
            <RefundMoneyField label={t('refunded')} value={totalRefunded} />
          </DataList.Row>
          <DataList.Row
            paddingY="2"
            paddingTop={'4'}
            paddingBottom={'4'}
            topDivider={false}
            backgroundColor="neutral-surface"
          >
            <RefundMoneyField
              label={t('total')}
              value={total}
              fontWeight="bold"
            />
          </DataList.Row>
        </DataList>
      </Card.Body>
    </Card>
  );
}

export function Skeleton(): JSX.Element {
  return (
    <Card data-testid="ResumeCard-Skeleton">
      <Card.Header>
        <Box>
          <Title.Skeleton width="208px" height="20px" />
        </Box>
      </Card.Header>
      <Card.Body>
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          alignSelf={'stretch'}
          flexDirection={'column'}
          borderWidth="1"
          padding="2"
          gap="2"
        >
          <MoneyField.Skeleton />
          <MoneyField.Skeleton />
          <MoneyField.Skeleton />
        </Box>
      </Card.Body>
    </Card>
  );
}

ResumeCard.Skeleton = Skeleton;
