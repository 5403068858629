import React from 'react';
import { useHistory } from 'react-router';
import {
  Alert,
  Box,
  Button,
  Card,
  Link,
  Tag,
  Title,
} from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { MultilineText } from 'commons/components/MultilineText';
import { useIdentityValidation } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';

export function InstallmentSectionInactive(
  props: React.PropsWithChildren<{
    translationPrefix:
      | 'installments.withInterest'
      | 'installments.withoutInterest';
    openSwitchModal?: () => void;
  }>,
) {
  const { t } = useTranslationWithPrefix(props.translationPrefix);
  const history = useHistory();
  const identityValidation = useIdentityValidation();

  const redirectToValidateIdentity = () => {
    history.push(ROUTES.CONFIG);
  };

  const appearanceByStatus = () => {
    switch (identityValidation.data?.status) {
      case 'pending_review':
        return 'neutral';
      default:
        return 'warning';
    }
  };
  return (
    <Card>
      <Card.Header>
        <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
          <Box display="flex" justifyContent="space-between">
            <Title as="h4">{t('title')}</Title>
            <Tag>{t('inactive')}</Tag>
          </Box>
          <MultilineText color="neutral-textLow">
            {t('inactiveSubtitle')}
          </MultilineText>
        </Box>
      </Card.Header>
      {props.children}
      {props.openSwitchModal &&
        identityValidation.data &&
        identityValidation.data.status === 'active' && (
          <Link
            textDecoration="none"
            fontSize="highlight"
            appearance="primary"
            onClick={props.openSwitchModal}
            as="button"
          >
            {t('activate')}
          </Link>
        )}
      {identityValidation.data?.status &&
        identityValidation.data.status !== 'active' && (
          <Alert show appearance={appearanceByStatus()}>
            {t(`${appearanceByStatus()}.description`)}
            {appearanceByStatus() === 'warning' && (
              <Button appearance="neutral" onClick={redirectToValidateIdentity}>
                {t(`${appearanceByStatus()}.validateButton`)}
              </Button>
            )}
          </Alert>
        )}
    </Card>
  );
}
