import React, { useEffect, useState } from 'react';
import { Box, Card, Checkbox, Link, Text, Title } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import ThumbnailFile from 'commons/components/ThumbnailFile/ThumbnailFile';
import { DocumentsInterface } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import './MultipleDniDocument.css';

interface MultipleDniDocumentInterface {
  dniThumbnailFiles: DocumentsInterface;
  showErrors: boolean;
  subtitle: string;
  title: string;
  verifyUploadedDocuments: React.Dispatch<React.SetStateAction<boolean>>;
  setNumberOfOwners: React.Dispatch<React.SetStateAction<number>>;
  eligiblePerson?: boolean;
  toggleCheckbox?: () => void;
}

function MultipleDniDocument({
  dniThumbnailFiles = {},
  showErrors = false,
  subtitle,
  title,
  verifyUploadedDocuments,
  setNumberOfOwners,
  eligiblePerson,
  toggleCheckbox,
}: MultipleDniDocumentInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');
  const [dniRowsQty, setDniRowsQty] = useState(1);
  const [uploadedDocuments, setUploadedDocuments] = useState(0);
  const [errorText, setErrorText] = useState({
    show: false,
    text: '',
  });

  useEffect(() => {
    const dniFilesList = Object.entries(dniThumbnailFiles);

    if (dniFilesList.length) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const values = dniFilesList.map(([key, _value]) => {
        if (!key.match(/^d/)) return 0;
        const matches = key.match(/(\d+)$/);
        return matches ? parseInt(matches[0]) : 0;
      });
      const maxValue = Math.max(...values);
      setDniRowsQty(maxValue);
      setUploadedDocuments(dniFilesList.length);
    }
  }, [dniThumbnailFiles]);

  useEffect(() => {
    verifyUploadedDocuments(dniRowsQty * 2 === uploadedDocuments);
  }, [dniRowsQty, uploadedDocuments, verifyUploadedDocuments]);

  useEffect(() => {
    setNumberOfOwners(dniRowsQty);
  }, [dniRowsQty, setNumberOfOwners]);

  useEffect(() => {
    if (showErrors && dniRowsQty * 2 !== uploadedDocuments) {
      setErrorText({
        show: true,
        text: t('errors.uploadEveryFile'),
      });
    } else {
      setErrorText({
        show: false,
        text: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dniRowsQty, showErrors, uploadedDocuments]);

  const addDniRow = () => {
    setDniRowsQty((qty) => qty + 1);
  };

  const renderDni = (
    side: string,
    beneficiaryNumber: number,
    thumbnailFile: string,
  ) => (
    <ThumbnailFile
      aspectRatio="1/1"
      documentName={`dni${side}${beneficiaryNumber}`}
      onSuccess={() => setUploadedDocuments((uplDocs) => uplDocs + 1)}
      onError={(text: string) => setErrorText({ show: true, text })}
      ariaLabel={`dni${side}`}
      helperText={t(`dniFile.${side}`)}
      accept="image/jpeg,image/png,application/pdf"
      initialThumbnailFile={thumbnailFile}
      onDelete={() => setUploadedDocuments((uplDocs) => uplDocs - 1)}
      onRow
    />
  );

  const renderDniRow = (beneficiaryNumber: number) => {
    const thumbnailFrontFile = `dniFront${beneficiaryNumber}`;
    const thumbnailBackFile = `dniBack${beneficiaryNumber}`;

    return (
      <div className="multiple-dni-wrapper" key={beneficiaryNumber}>
        <Box display="flex" gap="4" key={beneficiaryNumber}>
          {renderDni(
            'Front',
            beneficiaryNumber,
            dniThumbnailFiles[thumbnailFrontFile],
          )}
          {renderDni(
            'Back',
            beneficiaryNumber,
            dniThumbnailFiles[thumbnailBackFile],
          )}
        </Box>
      </div>
    );
  };

  const rows = Array.from(Array(dniRowsQty).keys());
  const dniRows: JSX.Element[] = [];
  rows.map((key) => {
    dniRows.push(renderDniRow(key + 1));
  });

  return (
    <Card>
      <Card.Header>
        <Title as="h4">{title}</Title>
      </Card.Header>
      <Box display="flex" flexDirection="column" gap="4">
        <Text>{subtitle}</Text>
        {dniRows}
        <Link appearance="primary" onClick={addDniRow} textDecoration="none">
          <PlusCircleIcon />
          {t('juridical.dni.addDni')}
        </Link>
        {errorText.show && (
          <Text color="danger-interactive">{errorText.text}</Text>
        )}
        <Checkbox
          label={t('physical.checkbox')}
          name="checkbox"
          checked={eligiblePerson}
          onChange={toggleCheckbox}
        />
      </Box>
    </Card>
  );
}

export default MultipleDniDocument;
