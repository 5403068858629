import { Country } from 'App/i18n/enums';
import { Currency } from 'commons/types/Currency.enum';

const countryToCurrencyMap = new Map<Country, Currency>([
  [Country.ARGENTINA, Currency.ARS],
  [Country.BRAZIL, Currency.BRL],
  [Country.MEXICO, Currency.MXN],
]);

export function CountryToCurrency(country: Country) {
  const currency = countryToCurrencyMap.get(country);

  if (!currency)
    throw new Error(
      `Could not find a currency for country provided, country provided: ${country}`,
    );

  return currency;
}
