import React from 'react';
import { Redirect } from 'react-router-dom';
import { Box, Spinner } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { ErrorMessage } from 'commons/components';
import { PermissionFeature, usePermission } from 'commons/hooks';

export interface InterfaceRequireVerificationProps {
  children: React.ReactNode;
  permissionFeature: PermissionFeature;
  redirectPath?: string;
}

function RequirePermission({
  children,
  permissionFeature,
  redirectPath = ROUTES.HOME,
}: InterfaceRequireVerificationProps): JSX.Element | null {
  const permissionQuery = usePermission(permissionFeature);

  if (permissionQuery.isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        height="100vh"
        justifyContent="center"
      >
        <Spinner />
      </Box>
    );
  }
  if (permissionQuery.isError) {
    return (
      <Box
        display="flex"
        alignItems="center"
        height="100vh"
        justifyContent="center"
      >
        <ErrorMessage />
      </Box>
    );
  }

  return (
    <>
      {permissionQuery.data.permitted ? (
        children
      ) : (
        <Redirect to={redirectPath} />
      )}
    </>
  );
}

export default RequirePermission;
