import React from 'react';
import { Alert, Text } from '@nimbus-ds/components';
import { useStoreInfo } from 'commons/hooks';
import { EventHistoryType, PaymentMethodsType } from 'commons/types';
import { formatCurrency, useTranslationWithPrefix } from 'commons/utils';

interface RefundStatusAlertProps {
  lastEvent?: EventHistoryType;
  paymentMethod?: PaymentMethodsType;
  isTotalRefund?: boolean;
}

function RefundStatusAlert({
  lastEvent,
  paymentMethod,
  isTotalRefund,
}: RefundStatusAlertProps): JSX.Element | null {
  const { t } = useTranslationWithPrefix(
    'transactionDetails.refundStatusAlert',
  );
  const { storeInfo } = useStoreInfo();

  const title = t(
    `${lastEvent?.status}${isTotalRefund ? 'Total' : 'Partial'}Title`,
  );

  if (lastEvent?.status === 'failure')
    return (
      <Alert show title={title} appearance="danger">
        <Text>
          {t('failureDescription', {
            amount: lastEvent.value
              ? formatCurrency(lastEvent.value, storeInfo)
              : 0,
          })}
        </Text>
      </Alert>
    );

  switch (paymentMethod) {
    case PaymentMethodsType.WireTransfer:
      return (
        <Alert show title={title} appearance="success">
          <Text>{t(`transfer.${lastEvent?.status}Description`)}</Text>
        </Alert>
      );

    case PaymentMethodsType.CreditCard:
    case PaymentMethodsType.DebitCard:
      return (
        <Alert show title={title} appearance="success">
          <Text>{t('card.description')}</Text>
        </Alert>
      );

    default:
      return null;
  }
}

export default RefundStatusAlert;
