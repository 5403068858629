import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Title } from '@nimbus-ds/components';
import { LoadingCard, Timeline } from 'commons/components';
import { useStoreInfo } from 'commons/hooks';
import {
  EventHistoryType,
  MoneyObjectInterface,
  TransactionHistoryType,
} from 'commons/types';
import { formatDate, formatTime, transactionLabelProps } from 'commons/utils';
import {
  getEventDescription,
  getTransactionDescription,
} from 'commons/utils/eventDescriptionLabel';
import useEventLabelProps from 'commons/utils/eventStatusLabelProps';
import chargebackLabelProps from './chargebackLabelProps';

interface InterfacePaymentHistory {
  transactions?: TransactionHistoryType[];
  customerRefunds?: MoneyObjectInterface;
  events?: EventHistoryType[];
  skeleton?: boolean;
}

function PaymentHistory({
  transactions,
  customerRefunds,
  events,
  skeleton = false,
}: InterfacePaymentHistory): JSX.Element {
  const { t } = useTranslation();
  const { storeInfo } = useStoreInfo();
  const labelProps = useEventLabelProps(t);

  const timelineItems = transactions
    ?.map((transaction, index) => {
      const statusLabel = transactionLabelProps(
        transaction.status,
        t,
        `${index}`,
        undefined,
        transaction.chargeback,
      );
      if (
        transaction.status === 'refunded' ||
        transaction.status === 'partially_refunded'
      ) {
        return events?.map((event) => {
          const eventStatusLabel = labelProps[`${event.type}_${event.status}`];
          return (
            <Timeline.Item
              key={index}
              icon={event.status != 'failure' ? statusLabel.icon : undefined}
              appearance={eventStatusLabel.appearance}
              title={eventStatusLabel.label}
              description={getEventDescription(
                event.type,
                event.status,
                t,
                storeInfo,
                event.value,
              )}
              date={formatDate(storeInfo, event.occurredAt)}
              time={formatTime(event.occurredAt)}
            />
          );
        });
      }
      if (transaction.chargeback) {
        return events?.map((event) => {
          const isChargebackEvent =
            event.type === 'chargeback' || event.type === 'chargeback_dispute';
          const eventStatusLabel = isChargebackEvent
            ? chargebackLabelProps(
                t,
                event.type,
                event.status,
                transaction.chargeback!.status,
              )
            : labelProps[`${event.type}_${event.status}`];
          return (
            <Timeline.Item
              key={index}
              icon={eventStatusLabel.icon}
              appearance={eventStatusLabel.appearance}
              title={eventStatusLabel.label}
              description={getEventDescription(
                event.type,
                event.status,
                t,
                storeInfo,
                undefined,
                transaction.chargeback?.status,
                transaction.chargeback?.reason,
              )}
              date={formatDate(storeInfo, event.occurredAt)}
              time={formatTime(event.occurredAt)}
            />
          );
        });
      }
      return (
        <Timeline.Item
          key={index}
          icon={statusLabel.icon}
          appearance={statusLabel.appearance}
          title={statusLabel.label}
          description={getTransactionDescription(
            t,
            storeInfo,
            transaction.status,
            customerRefunds,
            transaction.reason,
          )}
          date={formatDate(storeInfo, transaction.happenedAt)}
          time={formatTime(transaction.happenedAt)}
        />
      );
    })
    .flat();

  if (skeleton) return <LoadingCard withHeaderLabel />;

  return (
    <Card>
      <Card.Header padding="none">
        <Title as="h3">{t('transactionDetails.paymentHistory.title')}</Title>
      </Card.Header>
      <Timeline>{timelineItems?.reverse()}</Timeline>
    </Card>
  );
}

export default PaymentHistory;
