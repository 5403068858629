import React, { ChangeEvent, useState } from 'react';
import { Box, Input, Text } from '@nimbus-ds/components';
import { PaymentMethodsType } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';

type appearanceTypes = 'danger' | 'neutral';

interface InterfaceDiscountInput {
  name: PaymentMethodsType;
  discount: number;
  updateDiscount: (value: number, paymentMethod: PaymentMethodsType) => void;
}

function DiscountInput({
  name,
  discount,
  updateDiscount,
}: InterfaceDiscountInput): JSX.Element {
  const { t } = useTranslationWithPrefix('configuration.discount');
  const [appearance, setAppearance] = useState<appearanceTypes>('neutral');

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateDiscount(parseInt(event.target.value || '0'), name);
    if (parseInt(event.target.value) > 100) {
      setAppearance('danger');
    } else {
      setAppearance('neutral');
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="1">
      <Text fontSize="caption">{t('label')}</Text>
      <Input
        name={name}
        type="number"
        max={100}
        min={0}
        value={discount ? discount.toString() : undefined}
        onChange={onInputChange}
        appearance={appearance}
        append={<Text>%</Text>}
        appendPosition="end"
        placeholder={t('placeholder')}
      />
      {appearance === 'danger' && (
        <Text fontSize="caption" color="danger-interactive">
          {t('error')}
        </Text>
      )}
    </Box>
  );
}

export default DiscountInput;
