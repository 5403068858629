enum DateRangeFilterEnum {
  ALL = 'all',
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  SEVEN_DAYS = 'seven_days',
  THIRTY_DAYS = 'thirty_days',
  CUSTOM = 'custom',
}

export default DateRangeFilterEnum;
