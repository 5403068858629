import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Alert, Box, Button, Modal, Text } from '@nimbus-ds/components';
import { useQuery } from '@tanstack/react-query';
import { ROUTES } from 'App/Routes/constants';
import { ProofOfLifePendingModal, RestrictedButton } from 'commons/components';
import {
  PermissionFeature,
  useBankAccount,
  useFeatureFlag,
  useIdentityValidation,
  useIsMobile,
  useLastBalanceBeforeTransfer,
  usePermission,
} from 'commons/hooks';
import {
  configurationService,
  FeatureFlag,
  merchantService,
} from 'commons/services';
import { ValidationStatusType } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import { FutureIncomesCard } from './FutureIncomesCard';
import HomeCardsSkeleton from './HomeCardsSkeleton';
import { MoneyCard } from './MoneyCard';

interface stateType {
  showLoadingDelay: boolean;
}
function HomeCards(): JSX.Element {
  const { t } = useTranslationWithPrefix('home');
  const history = useHistory();
  const bankAccount = useBankAccount();
  const isMobile = useIsMobile();
  const [isTransfering, setIsTransfering] = useState(false);
  const location = useLocation<stateType>();
  const { getLastBalanceValue, removeLastBalance } =
    useLastBalanceBeforeTransfer();
  const [showErrorAlert, setShowAlertState] = useState(false);
  const [showLoadingDelay, setShowLoadingDelay] = useState(
    location.state?.showLoadingDelay ?? false,
  );
  const [showProofOfLifePendingModal, setShowProofOfLifePendingModal] =
    useState(false);
  const identityValidation = useIdentityValidation();

  const withdrawPermission = usePermission(PermissionFeature.Withdraw);

  const [showValidationModal, setShowValidationModal] = useState({
    title: '',
    open: false,
    children: <></>,
  });
  const { enabled: transferFlagDisabled } = useFeatureFlag(
    FeatureFlag.FS_PAGONUBE_TRANSFER_DISABLED,
  );
  const {
    isLoading,
    isError: balanceError,
    data,
  } = useQuery(['balance'], () => merchantService.getBalance(), {
    refetchInterval: 60 * 1000,
    retry: 0,
    onSuccess: (balance) => {
      setShowLoadingDelay(isBalanceOutdated(balance.availableToWithdraw.value));
    },
  });

  const isError = balanceError || withdrawPermission.isError;

  const defaultMoneyValue = { value: 0, currency: '' };
  const waitingFunds = data ? data.waitingFunds : defaultMoneyValue;
  const availableToWithdraw = data
    ? data.availableToWithdraw
    : defaultMoneyValue;

  function isBalanceOutdated(actualValue: number): boolean {
    const lastBalanceValue = getLastBalanceValue();
    if (lastBalanceValue !== null) {
      if (lastBalanceValue === actualValue) {
        return true;
      } else {
        removeLastBalance();
        return false;
      }
    } else {
      return false;
    }
  }

  const redirectBankAccountRegistration = () => {
    history.push(ROUTES.BANK_ACCOUNT_CONFIG);
  };

  const renderValidationModal = (status: ValidationStatusType) => {
    switch (status) {
      case 'pending':
      case 'blocked':
        setShowValidationModal({
          title: t('limitModal.pending.title'),
          open: true,
          children: (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="stretch"
              gap="6"
            >
              <Text>{t('limitModal.pending.firstPar')}</Text>

              <Text>{t('limitModal.pending.secondPar')}</Text>
              <Box display="flex" justifyContent="flex-end" gap="4">
                <Button onClick={validateIdentity} appearance="primary">
                  {t('limitModal.pending.validateId')}
                </Button>
              </Box>
            </Box>
          ),
        });
        break;

      case 'pending_review':
      case 'pending_documentation':
        setShowValidationModal({
          title: t('limitModal.pendingReview.title'),
          open: true,
          children: <Text>{t('limitModal.pendingReview.firstPar')}</Text>,
        });
        break;

      default:
        break;
    }
  };

  const closeValidationModal = () => {
    setShowValidationModal({
      title: '',
      open: false,
      children: <></>,
    });
  };

  const transferMoney = () => {
    setIsTransfering(true);

    // TODO: change getIdValidation by useIdentityValidation
    configurationService
      .getIdValidation()
      .then((response) => {
        if (response.status == 'active' || response.status == 'denied') {
          if (response.status == 'active' && response.isProofOfLifePending) {
            setShowProofOfLifePendingModal(true);
          } else if (bankAccount.isError || !bankAccount.data?.fiscalDocument) {
            setShowAlertState(true);
          } else if (!bankAccount.data?.isRegistered) {
            redirectBankAccountRegistration();
          } else {
            history.push(ROUTES.TRANSFER);
          }
        } else {
          renderValidationModal(response.status);
        }
      })
      .catch(() => setShowAlertState(true))
      .finally(() => setIsTransfering(false));
  };

  function validateIdentity() {
    history.push(ROUTES.IDENTITY_VALIDATION);
  }

  const disableTransfer =
    availableToWithdraw.value <= 0 ||
    isTransfering ||
    bankAccount.isLoading ||
    !withdrawPermission.data?.permitted ||
    transferFlagDisabled;

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <Alert
        appearance="danger"
        show={showErrorAlert}
        title={t('alert.errorTitle')}
        onRemove={() => setShowAlertState(false)}
      >
        {t('alert.errorDescription')}
      </Alert>
      <Modal open={showValidationModal.open} onDismiss={closeValidationModal}>
        <Modal.Header title={showValidationModal.title} />
        {showValidationModal.children}
      </Modal>
      <ProofOfLifePendingModal
        onDismiss={() => setShowProofOfLifePendingModal(false)}
        open={showProofOfLifePendingModal}
        proofOfLifeUrl={identityValidation.data?.proofOfLifeUrl}
      />
      <Box>
        <Box
          display="grid"
          gridGap="4"
          gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
        >
          <MoneyCard
            title={t('descriptions.availableMoney')}
            amount={availableToWithdraw}
            skeleton={isLoading}
            showLoadingDelay={showLoadingDelay}
            loadingText={t('updatingAvailableMoney')}
            hasError={isError}
            color="primary"
            primaryButton={
              <RestrictedButton
                view="report"
                whenRestricted="hide"
                appearance="neutral"
                disabled={disableTransfer}
                onClick={transferMoney}
              >
                <Text>{t('buttons.transferMoney')}</Text>
              </RestrictedButton>
            }
          />
          <FutureIncomesCard
            amount={waitingFunds}
            skeleton={isLoading}
            showLoadingDelay={showLoadingDelay}
            hasError={isError}
          />
        </Box>
      </Box>
    </Box>
  );
}

HomeCards.Skeleton = HomeCardsSkeleton;
export default HomeCards;
