import { createSlice } from '@reduxjs/toolkit';
import { IStoreInfo } from './types';

const setStoreInfo = (state: IStoreInfo, action: any) =>
  (state = action.payload);

const storeInfoInitialState: IStoreInfo = {
  id: '1',
  name: '1',
  url: '1',
  country: 'AR',
  language: 'es',
  currency: 'ARS',
  flags: [],
};

const storeInfoSlice = createSlice({
  name: 'storeInfo',
  initialState: storeInfoInitialState,
  reducers: {
    setStoreInfoAction: setStoreInfo,
  },
});

export const { setStoreInfoAction } = storeInfoSlice.actions;

export default storeInfoSlice.reducer;
