import React from 'react';
import { Text } from '@nimbus-ds/components';
import BaseMoneyCard from './BaseMoneyCard';
import MoneyCardSkeleton from './MoneyCardSkeleton';

interface MoneyCardProps {
  title: string;
  subtitle: string;
}

function MoneyCard({ title, subtitle }: MoneyCardProps): JSX.Element {
  return (
    <BaseMoneyCard>
      <Text color="neutral-background" fontWeight="medium">
        {title}
      </Text>
      <Text color="neutral-background">{subtitle}</Text>
    </BaseMoneyCard>
  );
}

MoneyCard.Skeleton = MoneyCardSkeleton;
export default MoneyCard;
