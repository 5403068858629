import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Alert, Box, Button, Link, Spinner } from '@nimbus-ds/components';
import { useMutation } from '@tanstack/react-query';
import AppLayout from 'App/components/AppLayout';
import { ROUTES } from 'App/Routes/constants';
import { ErrorMessage, WarningModal } from 'commons/components';
import { useBankAccount, useIsMobile, useToast } from 'commons/hooks';
import { bankAccountService } from 'commons/services';
import { useTranslationWithPrefix } from 'commons/utils';
import { cbuValidator } from 'commons/utils/index';
import BankAccountInputs from './BankAccountInputs/BankAccountInputs';
import BankAccountRegistrationSkeleton from './BankAccountRegistrationSkeleton';
import { getAssociatedBank } from './BankNameRetriever';
import ConfirmationModal from './ConfirmationModal';

function BankAccountRegistration(): JSX.Element {
  const { t } = useTranslationWithPrefix('bankAccountRegistration');
  const { showToastSuccess, showToastError } = useToast();
  const history = useHistory();
  const isMobile = useIsMobile();
  const [changed, setChanged] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [bank, setBank] = useState('');
  const [beneficiaryName, setBeneficiaryName] = useState('');
  const [cbu, setCbu] = useState('');
  const [error, setError] = useState({
    beneficiaryName: '',
    cbu: '',
    bankName: '',
  });
  const bankAccount = useBankAccount();

  const updateBankAccountRegistration = useMutation({
    mutationFn: () => {
      return bankAccountService.patchBankAccount({
        holderName: beneficiaryName,
        bankName: bank,
        accountNumber: cbu,
      });
    },
    onSuccess: () => {
      bankAccount.refetch();
      showToastSuccess(t('success'));
      setConfirmationModalOpen(false);
      history.goBack();
    },
    onError: () => {
      setConfirmationModalOpen(false);
      showToastError(t('error'));
    },
  });

  useEffect(() => {
    setBeneficiaryName(bankAccount.data?.holderName ?? '');
    setCbu(bankAccount.data?.accountNumber ?? '');
    setBank(bankAccount.data?.bankName ?? '');
  }, [bankAccount.data]);

  const validateAccountNumber = useCallback(
    (cbu: string) => {
      if (cbuValidator.isValid(cbu)) {
        const associatedBank = getAssociatedBank(cbu);
        setBank(associatedBank);
        setError((prevError) => ({
          ...prevError,
          bankName: '',
        }));
      } else {
        setBank('');
      }
    },
    [setBank, setError],
  );

  if (bankAccount.isLoading) {
    return <BankAccountRegistrationSkeleton />;
  }
  if (bankAccount.isError) {
    return (
      <AppLayout title={t('title')} subtitle={t('subtitle')}>
        <Box
          display="flex"
          alignItems="stretch"
          justifyContent="flex-end"
          flexDirection="column"
        >
          <ErrorMessage />
        </Box>
      </AppLayout>
    );
  }

  const bankAccountRegistered = bankAccount.data.isRegistered;
  const fiscalNumber = bankAccount.data.fiscalDocument;

  const handleChangeCbu = (event: ChangeEvent<HTMLInputElement>) => {
    const cbu = event.target.value;
    setCbu(cbu);

    setError((prevError) => ({
      ...prevError,
      cbu: '',
    }));

    validateAccountNumber(cbu);

    if (!changed) setChanged(true);
  };

  const handleChangeBeneficiaryName = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setBeneficiaryName(event.target.value);

    setError((prevError) => ({
      ...prevError,
      beneficiaryName: '',
    }));

    if (!changed) setChanged(true);
  };

  const redirectBack = () => {
    history.goBack();
  };

  const getCbuError = () => {
    if (!cbu) return t('errors.noCbu');
    if (!cbuValidator.isValid(cbu)) return t('errors.invalidCbu');
    if (cbuValidator.isValid(cbu) && !bank) return t('errors.blockedBank');
    return '';
  };

  const getNameError = () => {
    if (!beneficiaryName) return t('errors.noName');
    if (beneficiaryName.split(' ').length < 2) return t('errors.noLastName');
    return '';
  };

  const getBankNameError = () => {
    if (bank === '') return t('errors.noBankName');
    return '';
  };

  const verifyFields = () => {
    const cbuError = getCbuError();
    const beneficiaryError = getNameError();
    const bankNameError = getBankNameError();
    if (!cbuError && !beneficiaryError && !bankNameError) {
      setConfirmationModalOpen(true);
    } else {
      setError({
        cbu: cbuError,
        beneficiaryName: beneficiaryError,
        bankName: bankNameError,
      });
    }
  };

  const dismissComfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const submit = () => {
    if (!changed) {
      showToastSuccess(t('success'));
      redirectBack();
      return;
    }

    updateBankAccountRegistration.mutate();
  };

  const redirectOrWarningModal = () => {
    if (changed) {
      setWarningModalOpen(true);
    } else {
      redirectBack();
    }
  };

  const Buttons = (
    <Box gap="4" display="flex" flexDirection="row">
      <Button
        onClick={redirectOrWarningModal}
        disabled={updateBankAccountRegistration.isLoading}
      >
        {t('cancel')}
      </Button>
      <Button
        appearance="primary"
        onClick={verifyFields}
        disabled={updateBankAccountRegistration.isLoading}
      >
        {updateBankAccountRegistration.isLoading && <Spinner size="small" />}
        {t('save')}
      </Button>
    </Box>
  );

  const MobileSaveLink = (
    <Link
      appearance="neutral"
      onClick={verifyFields}
      disabled={updateBankAccountRegistration.isLoading}
      as="button"
      textDecoration="none"
    >
      {updateBankAccountRegistration.isLoading && <Spinner size="small" />}
      {t('save')}
    </Link>
  );

  return (
    <AppLayout
      title={t('title')}
      footer={Buttons}
      navBarItems={isMobile ? MobileSaveLink : undefined}
      backLinkPath={ROUTES.CONFIG}
    >
      <Box display="flex" flexDirection="column" gap="4">
        <Alert show appearance="neutral">
          {t('cuitMustMatch')}
        </Alert>
        <BankAccountInputs
          isRegistered={bankAccountRegistered}
          fiscalNumber={fiscalNumber}
          bank={bank}
          beneficiaryName={beneficiaryName}
          cbu={cbu}
          handleChangeCbu={handleChangeCbu}
          handleChangeBeneficiaryName={handleChangeBeneficiaryName}
          error={error}
        />
      </Box>
      <ConfirmationModal
        bankAccountInfo={{
          cbu: cbu,
          bank: bank,
          beneficiary: beneficiaryName,
          fiscalNumber: fiscalNumber,
        }}
        buttonDisabled={updateBankAccountRegistration.isLoading}
        open={confirmationModalOpen}
        onDismiss={dismissComfirmationModal}
        onConfirm={submit}
      />
      <WarningModal
        exitButtonDisabled={updateBankAccountRegistration.isLoading}
        onDismiss={() => setWarningModalOpen(false)}
        onExit={redirectBack}
        open={warningModalOpen}
      />
    </AppLayout>
  );
}

BankAccountRegistration.Skeleton = BankAccountRegistrationSkeleton;
export default BankAccountRegistration;
