import React from 'react';
import { Alert, Button } from '@nimbus-ds/components';
import { useIdentityValidation } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';

function ProofOfLifePendingBanner(): JSX.Element {
  const { t } = useTranslationWithPrefix('home.proofOfLifePendingBanner');
  const { data: identityValidationData } = useIdentityValidation();
  const showAlert = Boolean(
    identityValidationData?.status === 'active' &&
      identityValidationData.isProofOfLifePending,
  );
  const metamapUrl = identityValidationData?.proofOfLifeUrl;

  const onUpdate = () => {
    window.open(metamapUrl, '_blank');
  };

  return (
    <Alert title={t('title')} show={showAlert} appearance="warning">
      {t('description')}
      <Button appearance="neutral" onClick={onUpdate}>
        {t('update')}
      </Button>
    </Alert>
  );
}

export default ProofOfLifePendingBanner;
