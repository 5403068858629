import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';
import { Button } from '@nimbus-ds/components';
import { goToOldAdmin } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { useTranslationWithPrefix } from 'commons/utils';
import BalanceCard, { BalanceCardInterface } from './BalanceCard';

type availableAmountArgs = Pick<BalanceCardInterface, 'balance'> & {
  disabled: boolean;
};
function AvailableAmountCard(props: availableAmountArgs): JSX.Element {
  const [withdrawButtonEnable, setWithdrawButtonEnable] = useState<boolean>();
  const { t } = useTranslationWithPrefix('dashboard.cards.availableAmount');
  const history = useHistory();

  const redirectToWithdraw = useCallback(() => {
    track('nuvem_pago_dasboard_transfer_click');
    history.push(`${ROUTES.WITHDRAWS}`);
  }, [history]);

  const redirectToStatement = useCallback(() => {
    track('nuvempago_dashboard_available_balance_details_click');

    goToOldAdmin(nexo, '/nuvempago/activities/');
  }, []);

  const redirectToOldOnlyOnce = useCallback(
    (event, redirectFunction: () => void) => {
      event.stopPropagation();

      redirectFunction();
    },
    [],
  );

  useEffect(() => {
    const hasAvailableAmount = Boolean(props.balance && props.balance > 0);

    setWithdrawButtonEnable(!props.disabled && hasAvailableAmount);
  }, [props.balance, props.disabled]);

  return (
    <BalanceCard
      title={t('title')}
      {...props}
      onClick={(e) => redirectToOldOnlyOnce(e, redirectToStatement)}
      highlight
    >
      <Button
        data-testid="withdraw-button"
        appearance="primary"
        onClick={(e) => redirectToOldOnlyOnce(e, redirectToWithdraw)}
        disabled={!withdrawButtonEnable}
      >
        {t('transferButton')}
      </Button>
    </BalanceCard>
  );
}

export default AvailableAmountCard;
