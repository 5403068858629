import { DateRangeType } from 'commons/types';
import moment from 'moment';

export default function getToDate(
  dateRange?: DateRangeType,
  to?: string,
): string {
  if (dateRange === 'custom') return moment(to).format('DD/MM/YYYY');

  return moment().format('DD/MM/YYYY');
}
