import React from 'react';
import { Box, Card, Tag, Text, Title } from '@nimbus-ds/components';
import { MoneyObjectInterface, PaymentMethodsType } from 'commons/types';
import { Currency } from 'commons/types/Currency.enum';
import { OrderType } from 'commons/types/OrderType.enum';
import { capitalize, useTranslationWithPrefix } from 'commons/utils';
import DateField from 'domains/Brazil/commons/components/DateField';
import MoneyField from 'domains/Brazil/commons/components/MoneyField';
import PaymentMethod from 'domains/Brazil/commons/components/PaymentMethod';
import {
  documentAnonimize,
  documentType,
} from 'domains/Brazil/commons/utils/document';

interface PaymentInfoCardInterface {
  orderNumber: string | number;
  createdAt: string | Date;
  totalAmount: MoneyObjectInterface;
  totalInterest: MoneyObjectInterface;
  holderName: string;
  document: string;
  brand: string;
  lastFourDigits: string;
  installments: number;
  paymentMethod: PaymentMethodsType;
  orderType: OrderType;
}

export function PaymentInfoCard({
  orderNumber,
  createdAt,
  totalAmount,
  totalInterest,
  holderName,
  document,
  lastFourDigits,
  brand,
  installments,
  paymentMethod,
  orderType,
}: Partial<PaymentInfoCardInterface>): JSX.Element {
  const { t } = useTranslationWithPrefix('refund.receipt.paymentCard');
  const orderTypeKey = `${orderType?.[0].toLowerCase()}${orderType?.slice(1)}`;

  const isValidOrderNumber = (orderNumber: string | number) =>
    Number(orderNumber) >= 0;

  return (
    <Card data-testid="PaymentCard">
      <Card.Header color={'neutral-textHigh'}>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          {isValidOrderNumber(orderNumber!) && (
            <Title as="h4">
              {t(`title.${orderTypeKey}`, {
                orderNumber: orderNumber,
                count: Number(orderNumber),
              })}
            </Title>
          )}
          <Tag appearance="neutral">
            <DateField
              date={createdAt ?? ''}
              formatOptions={{
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }}
            />
          </Tag>
        </Box>
      </Card.Header>
      <Card.Body>
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          alignSelf={'stretch'}
          flexDirection={'column'}
          paddingBottom={'3'}
          gap="1"
        >
          <Text fontSize="base" color={'neutral-textLow'} textAlign="left">
            {t('total')}
          </Text>
          <MoneyField
            color="neutral-textLow"
            textAlign="left"
            lineHeight="base"
            value={Number(totalAmount?.value ?? 0)}
            currency={Currency.BRL}
          />
        </Box>
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          alignSelf={'stretch'}
          flexDirection={'column'}
          paddingBottom={'3'}
          gap="1"
        >
          {paymentMethod == 'pix' && (
            <Text
              fontSize="base"
              fontWeight={'regular'}
              color={'neutral-textLow'}
              textAlign="left"
            >
              <PaymentMethod paymentMethod={paymentMethod} />
            </Text>
          )}
          {paymentMethod != 'pix' && (
            <>
              <Text
                fontSize="base"
                fontWeight={'regular'}
                color={'neutral-textLow'}
                textAlign="left"
              >
                {t('brand', {
                  brand: brand ? capitalize(brand) : '',
                  lastFourDigits: lastFourDigits,
                })}
              </Text>
              <Text
                fontSize="base"
                fontWeight={'regular'}
                color={'neutral-textLow'}
                textAlign="left"
              >
                {t('installments', {
                  installments: installments,
                  count: installments,
                })}{' '}
                {totalInterest &&
                  totalInterest?.value > 0 &&
                  t('consumerInterest')}
              </Text>
            </>
          )}
        </Box>
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          alignSelf={'stretch'}
          flexDirection={'column'}
          gap="1"
        >
          <Text
            fontSize="base"
            fontWeight={'regular'}
            color={'neutral-textLow'}
            textAlign="left"
          >
            {holderName}
          </Text>
          <Text
            fontSize="base"
            fontWeight={'regular'}
            color={'neutral-textLow'}
            textAlign="left"
          >
            {t('document', {
              type: documentType(document ?? '').toUpperCase(),
              document: documentAnonimize(document ?? ''),
              interpolation: { escapeValue: false },
            })}
          </Text>
        </Box>
      </Card.Body>
    </Card>
  );
}

export function Skeleton(): JSX.Element {
  return (
    <Card data-testid="PaymentCard-Skeleton">
      <Card.Header>
        <Box>
          <Title.Skeleton width="208px" height="20px" />
        </Box>
      </Card.Header>
      <Card.Body>
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          alignSelf={'stretch'}
          flexDirection={'column'}
          paddingBottom={'3'}
          gap="1"
        >
          <Text.Skeleton width="100%" height="20px" />
          <Text.Skeleton width="100%" height="20px" />
        </Box>
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          alignSelf={'stretch'}
          flexDirection={'column'}
          paddingBottom={'3'}
          gap="1"
        >
          <Text.Skeleton width="100%" height="20px" />
        </Box>
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          alignSelf={'stretch'}
          flexDirection={'column'}
          gap="1"
        >
          <Text.Skeleton width="100%" height="20px" />
          <Text.Skeleton width="100%" height="20px" />
        </Box>
      </Card.Body>
    </Card>
  );
}

PaymentInfoCard.Skeleton = Skeleton;
