import React from 'react';
import { Box, Card, Tag, Text } from '@nimbus-ds/components';
import { InstallmentPaymentInfoDto } from 'commons/services/lending/dtos/installment-payment-info.dto';
import { useTranslationWithPrefix } from 'commons/utils';
import { dueDateTranslationInfo } from '../helpers/due-date-translation-info';
import ContentOrSkeleton from './ContentOrSkeleton';

type InstallmentInfoProps = {
  loading: boolean;
  paymentInfo?: InstallmentPaymentInfoDto;
};

type PropChildren = { children: JSX.Element };

function InstallmentInfo({
  loading,
  paymentInfo,
}: InstallmentInfoProps): JSX.Element {
  const { t } = useTranslationWithPrefix(
    'lending.installment.paymentInfo.installmentInfo',
  );

  const TagSkeleton = ({ children }: PropChildren) =>
    loading ? (
      <Tag.Skeleton data-testid="tag-skeleton" width="80%" />
    ) : (
      children
    );

  const [i18nInstallmentTranslation, i18nInstallmentApperance] =
    dueDateTranslationInfo(new Date(paymentInfo?.dueAt ?? 0));

  return (
    <Card>
      <Card.Body>
        <Box
          backgroundColor="neutral-background"
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          gap="4"
        >
          <Box display="flex" gap="none" justifyContent="space-between">
            <Box
              display={'flex'}
              flexDirection={'row'}
              padding={'none'}
              width={'60%'}
            >
              <ContentOrSkeleton
                loading={loading}
                skeletonTestId="contract-skeleton"
              >
                <Text>
                  {t('contract')}&nbsp;{paymentInfo?.hubContractId}
                </Text>
              </ContentOrSkeleton>
            </Box>

            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-end'}
              padding={'none'}
              width={'40%'}
            >
              <TagSkeleton>
                <Tag appearance={i18nInstallmentApperance}>
                  {t(i18nInstallmentTranslation)}&nbsp;
                  {new Date(paymentInfo?.dueAt ?? 0).toLocaleString('pt-br', {
                    day: '2-digit',
                    month: '2-digit',
                  })}
                </Tag>
              </TagSkeleton>
            </Box>
          </Box>
        </Box>
      </Card.Body>
    </Card>
  );
}

export default InstallmentInfo;
