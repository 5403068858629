import axios from 'App/axios';
import { Country } from 'App/i18n/enums';

interface Amount {
  total: number;
  paid: number;
  authorized: number;
  refunded: number;
  fee: number;
  net: number;
}

interface AdjustedAmounts {
  paid: number;
  fee: number;
  cancelFee: number;
  refunded: number;
  total: number;
  consumerInterest: number;
}

interface Customer {
  name: string;
  email: string;
  phones: string[];
  country: Country;
  documentType: 'cpf' | 'cnpj';
  document: string;
}

export interface PaymentLink {
  linkNumber: string;
  referenceUuid: string;
  paymentMethod: string;
  paymentUrl: string;
  description: string;
}

export enum OrderType {
  Link = 'payment_link',
  Checkout = 'checkout',
}

export enum RejectedBy {
  AntiFraud = 'anti_fraud',
  Issuer = 'issuer',
}

export enum PagarmeTransactionStatus {
  Processing = 'processing',
  Authorized = 'authorized',
  Paid = 'paid',
  Refunded = 'refunded',
  PartiallyRefunded = 'partially_refunded',
  WaitingPayment = 'waiting_payment',
  PendingRefund = 'pending_refund',
  Refused = 'refused',
  Chargedback = 'chargedback',
  Failed = 'failed',
  Pending = 'pending',
}

export interface DashboardTransaction {
  id: string;
  orderId?: string;
  orderNumber?: number;
  amounts: Amount;
  adjustedAmounts: AdjustedAmounts;
  customer: Customer;
  createdAt: string;
  updatedAt: string;
  installments: number;
  status: PagarmeTransactionStatus;
  failureCode: string;
  rejectedBy: string;
  paymentMethod: string;
  paymentLink?: PaymentLink;
}

export interface KycInfo {
  blocked: boolean;
  approvedOnKyc: boolean;
  isAllowedToWithdraw: boolean;
}

const getTransactions = async (
  filters?: string,
): Promise<DashboardTransaction[]> => {
  const queryString = filters ? `?${filters}` : '';

  const transactions: DashboardTransaction[] = (
    await axios.get(`/dashboard/activities${queryString}`)
  ).data;

  return transactions;
};

const getKycInfo = async (): Promise<KycInfo> => {
  const kycInfo = (await axios.get('/admin/kyc-info')).data;

  return kycInfo;
};

interface DashboardServiceInterface {
  getTransactions: (filters?: string) => Promise<DashboardTransaction[]>;
  getKycInfo: () => Promise<KycInfo>;
}

const dashboardService: DashboardServiceInterface = {
  getTransactions,
  getKycInfo,
};

export default dashboardService;
