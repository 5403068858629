import React, { useState } from 'react';
import { Box, Icon, Link, Text } from '@nimbus-ds/components';
import { ChevronDownIcon, ChevronUpIcon } from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';
import { useStoreInfo } from 'commons/hooks';
import { WithdrawObjectInterface } from 'commons/types';
import {
  bankAccountIsUnknown,
  formatCurrency,
  formatDate,
  useTranslationWithPrefix,
} from 'commons/utils';
import { getErrorFromStatusCode } from '../getErrorFromStatusCode';
import StatusTag from '../StatusTag';

interface WithdrawsTableMobileRowsInterface {
  withdraw: WithdrawObjectInterface;
  aRef?: (node?: Element | null) => void;
}

export default function WithdrawsTableMobileRow({
  withdraw,
  aRef,
}: WithdrawsTableMobileRowsInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('withdraws.withdrawsTable');
  const { storeInfo } = useStoreInfo();
  const [showBank, setShowBank] = useState(false);
  const errorCode = getErrorFromStatusCode(withdraw.statusCode);

  const toggleShowBank = () => setShowBank((prev) => !prev);

  const rowWithBankAccount = (
    <DataList.Row gap="3">
      <Box display="flex" justifyContent="space-between" ref={aRef}>
        <Link
          fontSize="highlight"
          onClick={toggleShowBank}
          appearance="primary"
        >
          {t('headers.withdraw')} {withdraw.withdrawNumber}
          <Icon
            color="currentColor"
            source={showBank ? <ChevronUpIcon /> : <ChevronDownIcon />}
          />
        </Link>
        <Text fontSize="highlight">
          {formatDate(storeInfo, withdraw.requestedAt)}
        </Text>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Text fontSize="highlight">{withdraw.bankAccount.holderName}</Text>
        <Text fontSize="highlight">
          {`${formatCurrency(withdraw.amount, storeInfo)}`}
        </Text>
      </Box>
      <StatusTag tagKey="1" statusType={withdraw.status} />
      {showBank && (
        <Box display="flex" flexDirection="column" gap="3" width="100%" mt="3">
          <Text>
            {t('bank')} {withdraw.bankAccount.bankName}
          </Text>
          <Text>
            {t('cbu')}: {withdraw.bankAccount.accountNumber}
          </Text>
          {errorCode ? (
            <Box marginTop="2">
              <Text>{t(`statusCode.${errorCode}`)}</Text>
            </Box>
          ) : null}
        </Box>
      )}
    </DataList.Row>
  );

  const rowWithoutBankAccount = (
    <DataList.Row gap="3">
      <Box display="flex" justifyContent="space-between">
        <Text fontSize="highlight" fontWeight="medium">
          {t('headers.withdraw')} {withdraw.withdrawNumber}
        </Text>
        <Text fontSize="highlight">
          {formatDate(storeInfo, withdraw.requestedAt)}
        </Text>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <StatusTag tagKey="1" statusType={withdraw.status} />
        <Text fontSize="highlight">
          {`${formatCurrency(withdraw.amount, storeInfo)}`}
        </Text>
      </Box>
    </DataList.Row>
  );

  return bankAccountIsUnknown(withdraw.bankAccount)
    ? rowWithoutBankAccount
    : rowWithBankAccount;
}
