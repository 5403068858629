import React, { useEffect } from 'react';
import { useState } from 'react';
import { ToastProvider } from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import {
  ExpirationTime,
  paymentLinkInfo,
} from 'commons/services/paymentLink/paymentLinkService';
import { useTranslationWithPrefix } from 'commons/utils';
import PaymentLinkForm from './pages/Form/paymentLinkForm';
import PaymentLinkResult from './pages/Result/PaymentLinkResult';

function PaymentLink(): JSX.Element {
  const { t: navT } = useTranslationWithPrefix('navigation');
  const [paymentLinkInfo, setPaymentLinkInfo] = useState<paymentLinkInfo>();
  const [showForm, setShowForm] = useState<boolean>(true);

  useEffect(() => {
    navigateHeader(nexo, { goTo: ROUTES.DASHBOARD, text: navT('back') });
  }, [navT]);

  const onSubmitSuccess = (paymentLinkResult: paymentLinkInfo) => {
    setPaymentLinkInfo(paymentLinkResult);
    setShowForm(false);
  };

  const onSubmitError = () => {
    return;
  };

  return (
    <ToastProvider>
      {showForm && (
        <PaymentLinkForm
          onSuccess={onSubmitSuccess}
          onFailure={onSubmitError}
        />
      )}
      {paymentLinkInfo && (
        <PaymentLinkResult
          customerName={paymentLinkInfo.customerName as string}
          description={paymentLinkInfo.description as string}
          amount={paymentLinkInfo.amount as number}
          linkNumber={paymentLinkInfo.linkNumber as number}
          paymentUrl={paymentLinkInfo.paymentUrl}
          expirationTime={paymentLinkInfo.expirationTime as ExpirationTime}
        />
      )}
    </ToastProvider>
  );
}

function PaymentLinkSkeleton(): JSX.Element {
  return (
    <ToastProvider>
      <PaymentLinkForm.Skeleton />
    </ToastProvider>
  );
}

PaymentLink.Skeleton = PaymentLinkSkeleton;

export default PaymentLink;
