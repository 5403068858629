import React from 'react';
import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';
import {
  documentMask,
  documentType,
} from 'domains/Brazil/commons/utils/document';

interface BankAccountDetailsProps {
  holderName: string;
  document: string;
  bank: string;
  branchNumber: string;
  branchCheckDigit?: string;
  accountNumber: string;
  accountCheckDigit?: string;
}

function BankAccountDetails({
  holderName,
  document,
  bank,
  branchNumber,
  branchCheckDigit,
  accountNumber,
  accountCheckDigit,
}: BankAccountDetailsProps): JSX.Element {
  const { t } = useTranslationWithPrefix('partialTransfer.bankAccountDetails');
  return (
    <Card>
      <Title as="h3">{holderName}</Title>
      <Box display="flex" flexDirection="column" gap="1">
        <Text>
          {t('document', {
            type: documentType(document).toUpperCase(),
            document: documentMask(document),
            interpolation: { escapeValue: false },
          })}
        </Text>
        <Text>{t('bank', { bank })}</Text>
        <Text>
          {t('branchNumber', {
            branchNumber: `${branchNumber}${
              branchCheckDigit ? `-${branchCheckDigit}` : ''
            }`,
          })}
        </Text>
        <Text>
          {t('accountNumber', {
            accountNumber: `${accountNumber}${
              accountCheckDigit ? `-${accountCheckDigit}` : ''
            }`,
          })}
        </Text>
      </Box>
    </Card>
  );
}

export default BankAccountDetails;
