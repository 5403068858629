import React, { useEffect } from 'react';
import { track } from '@amplitude/analytics-browser';
import { CalendarDaysIcon } from '@nimbus-ds/icons';
import { EmptyMessage, Layout } from '@nimbus-ds/patterns';
import { useQuery } from '@tanstack/react-query';
import AppLayout from 'App/components/AppLayout';
import { futureIncomesService } from 'commons/services';
import { useTranslationWithPrefix } from 'commons/utils';
import SummarySection from './components/SummarySection';
import IncomesByDate from './IncomesByDate/IncomesByDate';
import { ErrorMessage } from './ErrorMessage';
import { FutureIncomesHelpLink } from './FutureIncomesHelpLink';
import FutureIncomeSkeleton from './FutureIncomesSkeleton';

function FutureIncomes(): JSX.Element {
  const { t } = useTranslationWithPrefix('futureIncome');

  const { data, isError, isLoading, isSuccess } = useQuery(
    ['futureIncomeSummary'],
    () => futureIncomesService.getFutureIncomesSummary(),
  );

  const showEmptyState = isSuccess && data.waitingFunds.value === 0;

  useEffect(() => {
    if (showEmptyState) {
      track('pago-nube-futuros-ingresos-empty-state');
    } else if (isError) {
      track('pago-nube-futuros-ingresos-error-loading');
    }
  }, [isSuccess, isError, showEmptyState]);

  if (isLoading) return <FutureIncomeSkeleton />;

  if (isError) {
    return (
      <AppLayout title={t('title')}>
        <ErrorMessage />
        <FutureIncomesHelpLink />
      </AppLayout>
    );
  }

  if (showEmptyState) {
    return (
      <AppLayout title={t('title')}>
        <EmptyMessage
          title={t('emptyState.title')}
          text={t('emptyState.message')}
          icon={<CalendarDaysIcon size={32} />}
          actions={<FutureIncomesHelpLink />}
        />
      </AppLayout>
    );
  }

  return (
    <AppLayout title={t('title')}>
      <Layout columns="1">
        <SummarySection
          updatedAt={data.updatedAt}
          waitingFunds={data.waitingFunds}
          grossFunds={data.grossFunds}
          processingFee={data.processingFee}
          installmentsFees={data.installmentsFees}
          totalTaxes={data.totalTaxes}
        />
        <Layout.Section>
          <IncomesByDate />
        </Layout.Section>
      </Layout>
      <FutureIncomesHelpLink />
    </AppLayout>
  );
}

export default FutureIncomes;
