import React from 'react';
import { Box, Text } from '@nimbus-ds/components';
import { capitalize, useTranslationWithPrefix } from 'commons/utils';

interface InterfaceTicketMethod {
  brand?: string;
}

export default function TicketMethod({ brand }: InterfaceTicketMethod) {
  const { t } = useTranslationWithPrefix('transactionDetails.paymentMethods');

  return (
    <Box display="flex" gap="1" flexDirection="row">
      <Text>{t('ticket')}</Text>
      {brand && <Text>{`${capitalize(brand)}`}</Text>}
    </Box>
  );
}
