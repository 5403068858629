import React from 'react';
import { Button } from '@nimbus-ds/components';
import { RestrictedAction } from './RestrictedAction';
import { Views } from './useShouldLinkTo';

type Props = {
  view: Views;
  whenRestricted: 'dummy' | 'hide';
} & React.ComponentProps<typeof Button>;

export function RestrictedButton(props: Props) {
  return <RestrictedAction {...props} renderAs="button" />;
}
