import { useQuery } from '@tanstack/react-query';
import { transactionsService } from 'commons/services';
import { DateRangeType } from 'commons/types';

interface TransactionsQueryParams {
  page: number;
  search?: string;
  status?: string;
  dateRange?: DateRangeType;
  fromDate?: string;
  toDate?: string;
}

export function useTransactionsQuery({
  page,
  search,
  status,
  dateRange,
  fromDate,
  toDate,
}: TransactionsQueryParams) {
  const queryResult = useQuery(['transactionsQuery'], () =>
    transactionsService.getTransactions(
      page,
      search,
      status,
      dateRange,
      fromDate,
      toDate,
    ),
  );

  const hasTransactions =
    queryResult.data?.itemsCount && queryResult.data.itemsCount > 0;

  return {
    ...queryResult,
    hasTransactions,
  };
}
