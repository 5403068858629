import React, { useContext } from 'react';
import { Box, Button, Text } from '@nimbus-ds/components';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import { MoneyCard } from '../HomeCards/MoneyCard';
import { HomeMockContext } from './HomeMockContext';
import OnBoardingPopover from './OnBoardingPopover';
import { OnBoardingSteps } from './OnBoardingSteps';

function HomeCardsMock(): JSX.Element {
  const { t } = useTranslationWithPrefix('home');
  const isMobile = useIsMobile();
  const { step, nextStep, closeOnBoarding } = useContext(HomeMockContext);

  const waitingFunds = { value: 0, currency: '' };
  const availableToWithdraw = { value: 0, currency: '' };

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <Box>
        <Box
          display="grid"
          gridGap="4"
          gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
        >
          <MoneyCard
            title={t('descriptions.availableMoney')}
            amount={availableToWithdraw}
            color="primary"
            primaryButton={
              <OnBoardingPopover
                visible={step === OnBoardingSteps.Transfer}
                title={t('mock.transferPopover.title')}
                description={t('mock.transferPopover.description')}
                linkText={t('mock.next')}
                onLinkClick={nextStep}
                onDismiss={closeOnBoarding}
              >
                <Button appearance="neutral">
                  <Text>{t('buttons.transferMoney')}</Text>
                </Button>
              </OnBoardingPopover>
            }
          />
          <MoneyCard
            title={t('descriptions.moneyToReceive')}
            amount={waitingFunds}
            color="primary"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default HomeCardsMock;
