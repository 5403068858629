import React from 'react';
import { Box } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { ErrorMessage } from 'commons/components';
import { useTranslationWithPrefix } from 'commons/utils';

function IdentityValidationError(): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');
  return (
    <AppLayout title={t('title')}>
      <Box
        display="flex"
        alignItems="stretch"
        justifyContent="flex-end"
        flexDirection="column"
      >
        <ErrorMessage />
      </Box>
    </AppLayout>
  );
}

export default IdentityValidationError;
