import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Landing } from 'domains';
import { ROUTES } from './constants';

function NotFoundRoutes(): JSX.Element {
  return (
    <Switch>
      <Route exact path={ROUTES.HOME}>
        <Suspense fallback={null}>
          <Landing />
        </Suspense>
      </Route>
    </Switch>
  );
}

export default NotFoundRoutes;
