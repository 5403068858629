/*
 * This component is a copy of the Nimbus V1 TimelineItemItem as
 * Nimbus V2 does not provide the same component.
 * It should be removed once Nimbus V2 provides it.
 */

import React from 'react';
import { Icon, Text } from '@nimbus-ds/components';
import './Timeline.css';

interface TimelineItemInterface {
  icon?: any;
  appearance?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'warning'
    | 'success';
  title: string;
  description?: string;
  date: string;
  time: string;
}

function TimelineItem({
  icon: Source,
  appearance = 'default',
  title,
  description,
  date,
  time,
}: TimelineItemInterface): JSX.Element {
  return (
    <div className="custom-pn-timeline-item">
      {Source ? (
        <div
          className={`custom-pn-timeline-icon custom-pn-timeline-icon--${appearance}`}
        >
          <Icon source={<Source />} color="neutral-textHigh" />
        </div>
      ) : (
        <div className="custom-pn-timeline-no-icon" />
      )}
      <div className="custom-pn-timeline-item__details">
        <div className="custom-pn-timeline-item__info">
          <Text color="neutral-textHigh">{title}</Text>
          {description ? <Text>{description}</Text> : null}
        </div>
        <div className="custom-pn-timeline-item__timestamp">
          <Text textAlign="right">{date}</Text>
          <Text textAlign="right">{time}</Text>
        </div>
      </div>
    </div>
  );
}

export default TimelineItem;
