import React from 'react';
import ErrorHandlerMessage from '../../Dashboard/components/list/ErrorHandling';

function RenderOnError(props: {
  children: JSX.Element;
  error: boolean;
  title?: string;
  message: string;
  label: string;
  refreshHandler: () => void;
}): JSX.Element {
  if (props.error)
    return (
      <ErrorHandlerMessage {...props} handleClick={props.refreshHandler} />
    );
  return props.children;
}

export default RenderOnError;
