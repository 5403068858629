import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import {
  Box,
  Button,
  Card,
  Link,
  Radio,
  Spinner,
  Text,
} from '@nimbus-ds/components';
import { useMutation, useQuery } from '@tanstack/react-query';
import AppLayout from 'App/components/AppLayout';
import { ROUTES } from 'App/Routes/constants';
import { ErrorMessage, WarningModal } from 'commons/components';
import {
  useIdentityValidation,
  useIsMobile,
  useSettlementFees,
  useToast,
} from 'commons/hooks';
import { configurationService, merchantService } from 'commons/services';
import { SettlementPeriodType } from 'commons/types';
import { formatPercentage, useTranslationWithPrefix } from 'commons/utils';
import SettlementPeriodInfo from './SettlementPeriodInfo';
import SettlementPeriodSkeleton from './SettlementPeriodSkeleton';

function SettlementPeriod(): JSX.Element {
  const { t } = useTranslationWithPrefix('settlementPeriod');
  const { showToastSuccess, showToastError } = useToast();
  const history = useHistory();
  const isMobile = useIsMobile();
  const [changed, setChanged] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [settlementPeriod, setSettlementPeriod] =
    useState<SettlementPeriodType>(0);
  const identityValidation = useIdentityValidation();
  const {
    loading: settlementFeesLoading,
    settlementFees,
    wireTransferSettlementFee,
  } = useSettlementFees();
  const settlementPeriodQuery = useQuery(
    ['settlementPeriod'],
    () => configurationService.getSettlementPeriod(),
    {
      retry: false,
      onSuccess: (data) => setSettlementPeriod(data),
    },
  );
  const balanceQuery = useQuery(
    ['balance'],
    () => merchantService.getBalance(),
    {
      retry: false,
    },
  );
  const updateSettlementPeriod = useMutation({
    mutationFn: () => {
      setButtonLoading(true);
      return configurationService.putSettlementPeriod(settlementPeriod);
    },
    onSuccess: () => {
      showToastSuccess(t('success'));
      track('pago-nube-guardar-plazo-de-retiro', {
        settlement: settlementPeriod,
      });
      history.push(ROUTES.CONFIG);
    },
    onError: () => {
      setButtonLoading(false);
      showToastError(t('error'));
    },
  });

  if (
    settlementFeesLoading ||
    settlementPeriodQuery.isLoading ||
    balanceQuery.isLoading ||
    identityValidation.isLoading
  ) {
    return <SettlementPeriodSkeleton />;
  }
  if (settlementPeriodQuery.isError || identityValidation.isError) {
    return (
      <AppLayout title={t('title')} backLinkPath={ROUTES.CONFIG}>
        <ErrorMessage />
      </AppLayout>
    );
  }

  const changeSettlementPeriod = (aSettlementPeriod: SettlementPeriodType) => {
    setSettlementPeriod(aSettlementPeriod);
    if (!changed) setChanged(true);
  };

  const redirectBack = () => {
    history.push(ROUTES.CONFIG);
  };

  const submit = () => {
    if (!changed) {
      showToastSuccess(t('success'));
      redirectBack();
      return;
    }

    updateSettlementPeriod.mutate();
  };

  const redirectOrWarningModal = () => {
    if (changed) {
      setWarningModalOpen(true);
    } else {
      redirectBack();
    }
  };

  const blockButtons =
    identityValidation.data.status === 'pending' ||
    identityValidation.data.status === 'pending_documentation' ||
    identityValidation.data.status === 'pending_review';

  const Buttons = (
    <Box gap="4" display="flex" flexDirection="row">
      <Button onClick={redirectOrWarningModal} disabled={buttonLoading}>
        {t('cancel')}
      </Button>
      <Button
        appearance="primary"
        onClick={submit}
        disabled={buttonLoading || blockButtons}
      >
        {buttonLoading && <Spinner size="small" />}
        {t('save')}
      </Button>
    </Box>
  );

  const MobileSaveLink = (
    <Link
      appearance="neutral"
      onClick={submit}
      disabled={buttonLoading || blockButtons}
      as="button"
      textDecoration="none"
    >
      {buttonLoading && <Spinner size="small" />}
      {t('save')}
    </Link>
  );

  const settlementRadios = settlementFees.map(
    ({ fee, settlementPeriod: feeSettlementPeriod }) => {
      const label =
        feeSettlementPeriod === 0
          ? t('withdraw-1-day', { fee: formatPercentage(fee) })
          : t('withdraw-n-days', {
              fee: formatPercentage(fee),
              days: feeSettlementPeriod,
            });

      return (
        <Radio
          label={label}
          name={feeSettlementPeriod.toString()}
          checked={feeSettlementPeriod === settlementPeriod}
          onChange={() => changeSettlementPeriod(feeSettlementPeriod)}
          key={feeSettlementPeriod}
          disabled={blockButtons}
        />
      );
    },
  );

  return (
    <AppLayout
      title={t('title')}
      subtitle={t('subtitle')}
      footer={Buttons}
      navBarItems={isMobile ? MobileSaveLink : undefined}
      backLinkPath={ROUTES.CONFIG}
    >
      <Box display="flex" flexDirection="column" gap="4">
        <SettlementPeriodInfo
          identityValidationStatus={identityValidation.data.status}
        />
        <Card>
          <Box display="flex" flexDirection="column" gap="6">
            <Box display="flex" flexDirection="column" gap="4">
              <Text fontWeight="medium" color="neutral-textHigh">
                {t('creditDebitCard')}
              </Text>
              {settlementRadios}
            </Box>
            {wireTransferSettlementFee ? (
              <Box display="flex" flexDirection="column" gap="4">
                <Text fontWeight="medium" color="neutral-textHigh">
                  {t('transfer')}
                </Text>
                <Text color="neutral-textHigh">
                  {t('transferSettlement', {
                    fee: formatPercentage(wireTransferSettlementFee.fee),
                  })}
                </Text>
              </Box>
            ) : null}
          </Box>
        </Card>
      </Box>
      <WarningModal
        exitButtonDisabled={buttonLoading}
        onDismiss={() => setWarningModalOpen(false)}
        onExit={redirectBack}
        open={warningModalOpen}
      />
    </AppLayout>
  );
}

SettlementPeriod.Skeleton = SettlementPeriodSkeleton;
export default SettlementPeriod;
