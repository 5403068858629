export const queryClientConfig = {
  defaultOptions: {
    queries: {
      // we are having too many refetchings, this could be because the window focus events inside an iframe
      // https://tanstack.com/query/v4/docs/react/guides/window-focus-refetching#ignoring-iframe-focus-events
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
};
