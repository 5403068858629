import React from 'react';
import { Redirect } from 'react-router-dom';
import { Box, Spinner } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { useBankAccount, useIdentityValidation } from 'commons/hooks';

export interface InterfaceRequireVerificationProps {
  children?: React.ReactNode;
  redirectPath?: string;
  needsValidationActive?: boolean;
  needsValidationPending?: boolean;
  needsBankRegistered?: boolean;
  needsBankEmpty?: boolean;
}

function RequireVerification({
  children,
  redirectPath = ROUTES.HOME,
  needsValidationActive,
  needsValidationPending,
  needsBankRegistered,
  needsBankEmpty,
}: InterfaceRequireVerificationProps): JSX.Element | null {
  const bankAccount = useBankAccount();
  const identityValidation = useIdentityValidation();

  if (needsBankRegistered) {
    if (bankAccount.isLoading) {
      return (
        <Box
          display="flex"
          alignItems="center"
          height="100vh"
          justifyContent="center"
        >
          <Spinner />
        </Box>
      );
    }
    if (bankAccount.isError) {
      throw new Error();
    }
  }

  if (needsValidationActive || needsValidationPending) {
    if (identityValidation.isLoading) {
      return (
        <Box
          display="flex"
          alignItems="center"
          height="100vh"
          justifyContent="center"
        >
          <Spinner />
        </Box>
      );
    }
    if (identityValidation.isError) {
      throw new Error();
    }
  }

  const verified =
    (!needsValidationActive ||
      identityValidation.data?.status === 'active' ||
      identityValidation.data?.status === 'denied') &&
    (!needsValidationPending ||
      identityValidation.data?.status === 'pending' ||
      identityValidation.data?.status === 'blocked' ||
      identityValidation.data?.status === 'pending_documentation') &&
    (!needsBankRegistered || bankAccount.data?.isRegistered) &&
    (!needsBankEmpty || !bankAccount.data?.isRegistered);

  return <>{verified ? children : <Redirect to={redirectPath} />}</>;
}

export default RequireVerification;
