import React from 'react';
import { useHistory } from 'react-router';
import { Box, Table, Text } from '@nimbus-ds/components';
import { RestrictedLink } from 'commons/components';
import { useStoreInfo } from 'commons/hooks';
import { TransactionObjectInterface } from 'commons/types';
import {
  formatCurrency,
  formatDate,
  isOldTransaction,
  redirectToTransaction,
} from 'commons/utils';
import StatusLabel from '../../StatusLabel';
import { HomeTableRowsSkeleton } from './HomeTableRowsSkeleton';

interface HomeTableRowsInterface {
  transactions: TransactionObjectInterface[];
  mock?: boolean;
}

export function HomeTableRows({
  transactions,
  mock,
}: HomeTableRowsInterface): JSX.Element {
  const { storeInfo } = useStoreInfo();
  const history = useHistory();

  const rowsMapped = transactions.map((order, index) => {
    const isAbandonedCart = order.orderNumber === -1;
    const orderNumber = isAbandonedCart ? '---' : `#${order.orderNumber}`;
    return (
      <Table.Row key={index} id={`${order.id}`}>
        <Table.Cell>
          <Box padding="0-5">
            {mock ? (
              <Text fontSize="highlight">Ejemplo</Text>
            ) : (
              <RestrictedLink
                view="transferDetails"
                whenRestricted="dummy"
                textDecoration="none"
                onClick={() =>
                  redirectToTransaction(
                    order.id,
                    isOldTransaction(order.createdAt),
                    isAbandonedCart,
                    history,
                  )
                }
                appearance="primary"
                fontSize="highlight"
              >
                {orderNumber}
              </RestrictedLink>
            )}
          </Box>
        </Table.Cell>
        <Table.Cell>
          <Box padding="0-5">
            <Text fontSize="highlight">
              {formatDate(storeInfo, order.createdAt)}
            </Text>
          </Box>
        </Table.Cell>
        <Table.Cell>
          <Box padding="0-5">
            <StatusLabel
              labelKey="3"
              statusType={order.status}
              chargeback={order.chargeback}
            />
          </Box>
        </Table.Cell>
        <Table.Cell>
          <Box padding="0-5">
            <Text fontSize="highlight">
              {`${formatCurrency(order.remainingAmountToRefund, storeInfo)}`}
            </Text>
          </Box>
        </Table.Cell>
        <Table.Cell>
          <Box padding="0-5">
            <Text fontSize="highlight">{order.customer.name}</Text>
          </Box>
        </Table.Cell>
      </Table.Row>
    );
  });

  return <>{rowsMapped}</>;
}

HomeTableRows.Skeleton = HomeTableRowsSkeleton;
