import React from 'react';
import { track } from '@amplitude/analytics-browser';
import { Box, Button, Modal, Spinner, Text } from '@nimbus-ds/components';
import { useStoreInfo } from 'commons/hooks';
import {
  formatCurrencyWithStoreInfo,
  useTranslationWithPrefix,
} from 'commons/utils';

interface RefundModalProps {
  amountToRefund: number;
  isTotalRefund: boolean;
  open: boolean;
  loading: boolean;
  onDismiss: () => void;
  onRefund: () => void;
}

function RefundModal({
  amountToRefund,
  isTotalRefund,
  open,
  loading,
  onDismiss,
  onRefund,
}: RefundModalProps): JSX.Element {
  const { t } = useTranslationWithPrefix(
    `refund.refundModal.${isTotalRefund ? 'total' : 'partial'}`,
  );
  const { storeInfo } = useStoreInfo();

  const handleOnDismiss = () => {
    if (isTotalRefund) {
      track('refund-nuvempago-total-without-canceling');
    }

    onDismiss();
  };

  return (
    <Modal
      open={open}
      onDismiss={loading ? () => {} : handleOnDismiss}
      maxWidth={{ xs: '80%', md: '500px' }}
    >
      <Modal.Header
        title={t('title', {
          amount: formatCurrencyWithStoreInfo(amountToRefund, storeInfo),
        })}
      />
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="6">
        <Text color="neutral-textHigh" fontSize="base">
          {t('description')}
        </Text>
        <Box display="flex" justifyContent="flex-end" gap="4">
          <Button
            onClick={handleOnDismiss}
            appearance="neutral"
            disabled={loading}
          >
            {t('cancel')}
          </Button>
          <Button onClick={onRefund} disabled={loading} appearance="primary">
            {loading && <Spinner size="small" />}
            {t('refund')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default RefundModal;
