import React from 'react';
import { Box, Button, Modal, Spinner, Text } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

interface SwitchModalProps {
  open: boolean;
  activateWithInterest: boolean;
  loading: boolean;
  onActivate: () => void;
  onDismiss: () => void;
}

function SwitchModal({
  open,
  activateWithInterest,
  loading,
  onActivate,
  onDismiss,
}: SwitchModalProps): JSX.Element {
  const { t } = useTranslationWithPrefix('installments.switchModal');

  return (
    <Modal open={open} onDismiss={onDismiss}>
      <Modal.Header
        title={
          activateWithInterest
            ? t('withInterest.title')
            : t('withoutInterest.title')
        }
      />
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="6">
        <Text color="neutral-textHigh" fontSize="base">
          {activateWithInterest
            ? t('withInterest.firstDescription')
            : t('withoutInterest.firstDescription')}
        </Text>
        <Text color="neutral-textHigh" fontSize="base">
          {activateWithInterest
            ? t('withInterest.secondDescription')
            : t('withoutInterest.secondDescription')}
        </Text>
        <Box display="flex" justifyContent="flex-end" gap="4">
          <Button onClick={onDismiss} appearance="neutral">
            {t('cancel')}
          </Button>
          <Button onClick={onActivate} appearance="primary" disabled={loading}>
            {loading && <Spinner size="small" />}
            {t('activate')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default SwitchModal;
