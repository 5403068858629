import React from 'react';
import { Box, Card, Input } from '@nimbus-ds/components';

export default function BankAccountInputsSkeleton() {
  return (
    <Card>
      <Box display="flex" flexDirection="column" gap="4">
        <Input.Skeleton />
        <Input.Skeleton />
        <Input.Skeleton />
        <Input.Skeleton />
      </Box>
    </Card>
  );
}
