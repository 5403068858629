import React from 'react';
import { Alert, Title } from '@nimbus-ds/components';
import { ValidationStatusType } from 'commons/types';

interface ErrorBannerInterface {
  description: string;
  status: ValidationStatusType;
}

function ErrorBanner({
  description,
  status,
}: ErrorBannerInterface): JSX.Element {
  const showBanner = status === 'pending_documentation';

  return (
    <Alert show={showBanner} appearance="danger">
      {/*
        TODO: move title to prop title in Alert when leaving children
              empty stops adding an extra margin
      */}
      <Title as="h6" color="danger-textLow">
        {description}
      </Title>
    </Alert>
  );
}

export default ErrorBanner;
