import React, { useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { Box, Card, Link, Skeleton, useToast } from '@nimbus-ds/components';
import { CopyIcon } from '@nimbus-ds/icons';
import { copyToClipboard } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { useTranslationWithPrefix } from 'commons/utils';

type PixQrCodeProps = {
  loading: boolean;
  qrCodeStr: string;
  qrCodeImg: string;
  minHeight?: string;
};

type PropChildren = { children: JSX.Element };

function PixQrCode({
  loading,
  qrCodeStr,
  qrCodeImg,
  minHeight,
}: PixQrCodeProps): JSX.Element {
  const [toastShow, setToastShow] = useState(true);
  const { addToast } = useToast();

  const { t } = useTranslationWithPrefix(
    'lending.installment.paymentInfo.pixQrCode',
  );

  async function copyLink() {
    if (toastShow) {
      track('nuvempago_lending_payment_installments_copy_qr_pix_click');

      let copied = false;
      try {
        copied = await copyToClipboard(nexo, qrCodeStr);
      } catch (error) {}

      if (!copied) {
        return addToast({
          type: 'danger',
          text: t('errorOnCopy'),
          duration: 4000,
          id: 'payment-link-copy-toast',
        });
      }

      addToast({
        type: 'success',
        text: t('linkCopied'),
        duration: 4000,
        id: 'payment-link-copy-toast',
      });
    }
    setToastShow(false);

    setTimeout(() => {
      setToastShow(true);
    }, 4000);
  }

  const ImgSkeleton = ({ children }: PropChildren) =>
    loading ? (
      <Skeleton
        data-testid="pix-code-img-skeleton"
        width="165px"
        height="165px"
        borderRadius="4px"
      />
    ) : (
      children
    );
  const LinkSkeleton = ({ children }: PropChildren) =>
    loading ? <Link.Skeleton data-testid="pix-code-link-skeleton" /> : children;

  return (
    <Card>
      <Card.Body>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={'4'}
          minHeight={minHeight}
          paddingBottom={loading ? '4' : undefined}
        >
          <ImgSkeleton>
            <img
              src={qrCodeImg!}
              width={'165px'}
              height={'165px'}
              data-testid="pix-code-img"
            />
          </ImgSkeleton>
        </Box>

        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          padding={'2'}
        >
          {loading && (
            <Box marginRight={'1'} padding="none">
              <Skeleton borderRadius="50%" width="20px" height="1.2rem" />
            </Box>
          )}

          <LinkSkeleton>
            <Link
              appearance="primary"
              onClick={copyLink}
              data-testid="pix-code-link"
              textDecoration="none"
              fontSize="highlight"
            >
              <CopyIcon />

              {t('copyButton')}
            </Link>
          </LinkSkeleton>
        </Box>
      </Card.Body>
    </Card>
  );
}

export default PixQrCode;
