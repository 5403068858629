/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-key */

import React, { useCallback, useContext } from 'react';
import { useEffect, useState } from 'react';
import { Box, Table, Text } from '@nimbus-ds/components';
import { DataTable } from '@nimbus-ds/patterns';
import { useIsMobile } from 'commons/hooks';
import dashboardService, {
  DashboardTransaction,
  PagarmeTransactionStatus,
} from 'commons/services/dashboardService/dashboard.service';
import { FilterBuilder } from 'commons/services/dashboardService/filter.builder';
import { useTranslationWithPrefix } from 'commons/utils';
import TransactionTypeFilterEnum from 'domains/Brazil/commons/types/TransactionTypeFilterEnum';
import { objectToQuery } from 'domains/Brazil/commons/utils/ObjectToQuery';
import RenderOnError from '../../../commons/components/RenderOnError';
import DashboardContext, {
  DashboardContextArgs,
} from '../../Dashboard.context';
import { filterObject } from '../search/SearchBar';
import MobileListItem from './mobile/ListItem';
import MobileList from './mobile/MobileList';
import EmptyList from './EmptyList';
import EmptyState from './EmptyState';
import ListItem from './ListItem';
import PaginateTransactions from './PaginateTransactions';

function filterData(
  filters: filters & filterObject,
  data: DashboardTransaction[],
) {
  if (filters.paymentAmount?.length) {
    const amount = (Number(filters.paymentAmount) / 100).toFixed(0);
    data = data.filter((item) => item.amounts.total.toString() == amount);
  }
  if (filters.customer?.length) {
    data = data.filter((item) =>
      item.customer.name
        .toLowerCase()
        .includes(filters.customer?.toLowerCase() as string),
    );
  }
  if (filters.paymentMethod?.length && filters.paymentMethod !== 'all') {
    if (filters.paymentMethod === TransactionTypeFilterEnum.PAYMENT_LINK) {
      data = data.filter(
        (item) => item.paymentMethod === TransactionTypeFilterEnum.PAYMENT_LINK,
      );
    } else if (filters.paymentMethod === TransactionTypeFilterEnum.CHECKOUT) {
      data = data.filter(
        (item) => item.paymentMethod !== TransactionTypeFilterEnum.PAYMENT_LINK,
      );
    }
  }
  if (filters.status?.length) {
    if (filters.status == 'rejectedByEmissor') {
      data = data.filter(
        (item) => item.status === PagarmeTransactionStatus.Failed,
      );
    } else if (filters.status == 'allRejected') {
      data = data.filter((item) => {
        if (item.status === PagarmeTransactionStatus.Refused) return true;
        if (item.status === PagarmeTransactionStatus.Failed) return true;
        return false;
      });
    } else if (filters.status == 'rejectedBySecurity') {
      data = data.filter(
        (item) => item.status === PagarmeTransactionStatus.Refused,
      );
    } else if (filters.status == 'refund') {
      data = data.filter(
        (item) => item.status === PagarmeTransactionStatus.Refunded,
      );
    } else if (filters.status == 'partialRefund') {
      data = data.filter(
        (item) => item.status === PagarmeTransactionStatus.PartiallyRefunded,
      );
    } else if (filters.status == 'chargebacked') {
      data = data.filter(
        (item) => item.status === PagarmeTransactionStatus.Chargedback,
      );
    } else if (filters.status == 'paid') {
      data = data.filter(
        (item) => item.status === PagarmeTransactionStatus.Paid,
      );
    } else if (filters.status == 'waiting') {
      data = data.filter((item) => {
        if (item.status === PagarmeTransactionStatus.WaitingPayment)
          return true;
        if (item.status === PagarmeTransactionStatus.Authorized) return true;
        if (item.status === PagarmeTransactionStatus.Pending) return true;
        return false;
      });
    } else if (filters.status == 'inProcess') {
      data = data.filter(
        (item) => item.status === PagarmeTransactionStatus.Processing,
      );
    }
  }
  if (filters.orderNumber && filters.orderNumber?.length) {
    data = data.filter((item) => {
      if (item.orderNumber?.toString()?.includes(filters.orderNumber as string))
        return true;
      if (item.paymentLink?.linkNumber?.includes(filters.orderNumber as string))
        return true;
      return false;
    });
  }
  if (filters.date?.from && filters.date?.to) {
    const toDate = new Date(filters.date.to);
    const fromDate = new Date(filters.date.from);
    data = data.filter((item) => {
      return (
        new Date(item.createdAt) >= fromDate &&
        new Date(item.createdAt) <= toDate
      );
    });
  }
  if (filters?.orderType === 'payment_link') {
    data = data.filter((item) => item.paymentMethod === 'payment_link');
  }
  if (filters?.orderType === 'order') {
    data = data.filter((item) => item.paymentMethod !== 'payment_link');
  }

  return data;
}

function RenderOnLoad(props: {
  children: JSX.Element;
  onLoad: boolean;
  isMobile: boolean;
}) {
  if (!props.onLoad) return props.children;

  return (
    <Box>
      {props.isMobile ? (
        <DashboardListMobileSkeleton />
      ) : (
        <DashboardListDesktopSkeleton />
      )}
    </Box>
  );
}

export type filters = {
  date?: {
    from: string | undefined;
    to: string | undefined;
  };
  paymentMethod?: string;
  status?: string;
  withOrderNumber?: boolean;
  count?: number;
  stringQuery?: string;
};

type dashboardListArgs = {
  filters?: filters & filterObject;
  isRefundPartial: boolean;
};

function DashboardList({
  filters,
  isRefundPartial,
}: dashboardListArgs): JSX.Element {
  const [listItens, setListItens] = useState<DashboardTransaction[]>([]);
  const [transactions, setTransactions] = useState<DashboardTransaction[]>([]);
  const [errorOnLoad, setError] = useState<boolean>(false);
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);

  const isMobile = useIsMobile();

  const {
    showSkeleton,
    setLoadingTransactions,
    setHasTransactionWithoutFilters,
  }: DashboardContextArgs = useContext(DashboardContext);

  const { t } = useTranslationWithPrefix('dashboard.errorHandler');

  const fetch = useCallback(
    (
      setCallback: (transactions: DashboardTransaction[]) => void,
      filters?: filters & filterObject,
    ) => {
      const apiFilters = FilterBuilder(filters as any);

      setLoadingFilters(true);

      dashboardService
        .getTransactions(objectToQuery(apiFilters))
        .then((transactions) => {
          if (transactions.length) {
            setHasTransactionWithoutFilters(true);
          }

          setCallback(transactions);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoadingFilters(false);

          setLoadingTransactions(false);
        });
    },
    [setHasTransactionWithoutFilters, setLoadingTransactions],
  );

  const refreshHandler = useCallback(() => {
    setError(false);
    fetch(setTransactions, {
      withOrderNumber: true,
      count: 100, //initial load, load only 100 most recents transactions
    });
  }, [fetch]);

  const filterStrategy = useCallback(
    (filters, transactions: DashboardTransaction[]) => {
      const filterInMemoryIfLess = () => {
        const limitToFilterMemory = 100;

        return transactions.length < limitToFilterMemory;
      };

      if (filterInMemoryIfLess()) {
        const data = filterData(filters, transactions);
        setListItens(data);
      } else {
        fetch(setListItens, filters);
      }
    },
    [fetch],
  );

  useEffect(() => {
    fetch(setTransactions, {
      withOrderNumber: true,
      count: 100,
    });
  }, [fetch]);

  useEffect(() => {
    if (filters && transactions?.length) {
      filterStrategy(filters, transactions);
    } else {
      setListItens(transactions);
    }
  }, [filterStrategy, filters, transactions]);

  const RenderEmptyIfNoTransactions = useCallback(
    (props: { listItens: DashboardTransaction[] }): JSX.Element => {
      if (props.listItens.length) {
        if (isMobile) {
          return (
            <MobileList
              listItens={props.listItens}
              isRefundPartial={isRefundPartial}
            />
          );
        }
        return (
          <PaginateTransactions
            listItens={props.listItens}
            isRefundPartial={isRefundPartial}
          />
        );
      }
      return !filters ? <EmptyState /> : <EmptyList />;
    },
    [filters, isMobile, isRefundPartial],
  );

  return (
    <Box data-testid="dashboard-list">
      <RenderOnError
        message={t('message')}
        label={t('label')}
        error={errorOnLoad}
        refreshHandler={refreshHandler}
      >
        <RenderOnLoad
          onLoad={showSkeleton || loadingFilters}
          isMobile={isMobile}
        >
          <RenderEmptyIfNoTransactions listItens={listItens} />
        </RenderOnLoad>
      </RenderOnError>
    </Box>
  );
}

function DashboardListDesktopSkeleton(): JSX.Element {
  return (
    <Box
      width={'fill'}
      height={'hug'}
      display={'grid'}
      justifyContent={'center'}
      alignItems={'center'}
      data-testid="Dashboard-List-Skeleton"
    >
      <DataTable header={<TableHeaderSkeleton />}>
        {GenerateSkeletonList(ListItem.Skeleton)}
      </DataTable>
    </Box>
  );
}

function DashboardListMobileSkeleton(): JSX.Element {
  return (
    <Box justifyContent={'space-between'} data-testid="Dashboard-List-Skeleton">
      {GenerateSkeletonList(MobileListItem.Skeleton, 4)}
    </Box>
  );
}

function GenerateSkeletonList(
  SkeletonComponent: () => JSX.Element,
  count = 10,
): JSX.Element[] {
  const itensMap: JSX.Element[] = [];
  for (let i = 0; i < count; i++) {
    itensMap.push(<SkeletonComponent key={`list-item-skeleton:${i}`} />);
  }
  return itensMap;
}

function TableHeaderSkeleton(): JSX.Element {
  return (
    <Table.Head>
      <Table.Row width={'fill'}>
        <DataTable.Cell width={'10%'}>
          <Text.Skeleton width="90%" height="20px" />
        </DataTable.Cell>
        <DataTable.Cell width={'6%'}>
          <Text.Skeleton width="90%" height="20px" />
        </DataTable.Cell>
        <DataTable.Cell width={'22%'}>
          <Text.Skeleton width="50%" height="20px" />
        </DataTable.Cell>
        <DataTable.Cell width={'12%'}>
          <Text.Skeleton width="80%" height="20px" />
        </DataTable.Cell>
        <DataTable.Cell width={'15%'}>
          <Text.Skeleton width="60%" height="20px" />
        </DataTable.Cell>
        <DataTable.Cell width={'20%'}>
          <Text.Skeleton width="40%" height="20px" />
        </DataTable.Cell>
      </Table.Row>
    </Table.Head>
  );
}

export default DashboardList;
