import React, { useState } from 'react';
import { Box, Button, Chip, Icon, Input, Text } from '@nimbus-ds/components';
import { SlidersIcon } from '@nimbus-ds/icons';
import { useTranslationWithPrefix } from 'commons/utils';

interface SearchFilterProps {
  onSubmit: (v: string) => void;
  onClick: () => void;
  onDismiss: () => void;
  appliedFilter?: string;
  resultCount?: string;
}

function SearchFilter({
  onSubmit,
  onClick,
  onDismiss,
  appliedFilter,
  resultCount,
}: SearchFilterProps): JSX.Element {
  const { t } = useTranslationWithPrefix('home.transactionsSearch');
  const [searchValue, setSearchValue] = useState('');

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit(e.currentTarget.value);
      setSearchValue('');
    }
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target.value);
  };

  const handleDismiss = () => {
    onDismiss();
    setSearchValue('');
  };

  return (
    <Box display="flex" flexDirection="column" gap="4">
      <Box display="flex" gap="1">
        <Input.Search
          placeholder={t('search')}
          value={searchValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <Button onClick={onClick}>
          <Icon color="currentColor" source={<SlidersIcon />} />
          {t('button')}
        </Button>
      </Box>
      <Box alignItems="center" display="flex" gap="2">
        {resultCount ? <Text>{resultCount}</Text> : null}
        {appliedFilter ? (
          <Chip removable onClick={handleDismiss} text={appliedFilter} />
        ) : null}
      </Box>
    </Box>
  );
}

export default SearchFilter;
