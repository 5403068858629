import React, { useEffect, useState } from 'react';
import { Card, Checkbox } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

interface RestockInterface {
  setRestock: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function RestockCard({
  setRestock,
}: RestockInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('refund.restockCard');
  const [restock, setRestockOption] = useState(true);

  useEffect(() => setRestock(restock), [restock, setRestock]);

  const handleRestockCheck = (event: React.ChangeEvent<HTMLInputElement>) =>
    setRestockOption(event.target.checked);

  return (
    <Card data-testid="RestockCard">
      <Card.Header title={t('title')} />
      <Card.Body>
        <Checkbox
          label={t('checkboxTitle')}
          name="restock"
          onChange={handleRestockCheck}
          checked={restock}
          data-testid="restockCheckbox"
        />
      </Card.Body>
    </Card>
  );
}
