import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { Locale } from './enums';
import resources from './resources';

function i18nInit(): void {
  i18n.use(initReactI18next).init({
    resources,
    ns: 'common',
    defaultNS: 'common',
    load: 'currentOnly',
    fallbackLng: false,
    lng: 'es-AR',
  });

  i18n.services.pluralResolver.addRule(Locale.BRAZIL, {
    numbers: [1, 2],
    plurals: (n: number) => {
      return Number(n !== 1);
    },
  });

  i18n.services.formatter!.add('datetime', (value, lng, opt) => {
    const formatter = new Intl.DateTimeFormat(lng, opt);
    return opt.formatParams ? formatter.format(new Date(value)) : value;
  });
}

export default i18nInit;
