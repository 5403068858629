import React from 'react';
import { Box, Icon, Popover, Text } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import { useTranslationWithPrefix } from 'commons/utils';

export function FeesInfoPopover(): JSX.Element {
  const { t } = useTranslationWithPrefix('transactionDetails.paymentDetails');
  return (
    <Popover
      content={
        <Box>
          <Text color="neutral-background">{t('feesInfo')}</Text>
        </Box>
      }
      backgroundColor="primary-interactiveHover"
      width="25em"
      enabledHover
      arrow
    >
      <Icon source={<InfoCircleIcon />} />
    </Popover>
  );
}
