import { useCallback, useState } from 'react';
import axios from 'axios';
import { configurationService } from 'commons/services';

type Preference = Awaited<
  ReturnType<(typeof configurationService)['getPreferences']>
>;

type UsePreferences = {
  loading: boolean;
  preferences?: Preference;
  error?: Error;
  fetchPreferences: () => void;
};

export function usePreferences(): UsePreferences {
  const [loading, setLoading] = useState(true);
  const [preferences, setPreference] = useState<Preference>();
  const [error, setError] = useState<Error>();
  const fetchPreferences = useCallback(() => {
    const tryFetchSettlementFees = async () => {
      setLoading(true);

      try {
        const preference = await configurationService.getPreferences();
        setPreference(preference);
        setLoading(false);
        setError(undefined);
      } catch (error: any) {
        setError(error);
        setLoading(false);
        // It's quite possible that this API can't respond - yet - the plans fees for a Store, therefore we fallback to hardcoded fees.
        if (!axios.isAxiosError(error)) throw error;
      }
    };

    tryFetchSettlementFees();
  }, [setLoading, setPreference]);

  return {
    loading,
    error,
    preferences,
    fetchPreferences,
  };
}
