import { useMutation } from '@tanstack/react-query';
import { useToast } from 'commons/hooks';
import { documentsService } from 'commons/services';
import { useTranslationWithPrefix } from 'commons/utils';

interface useKycInfoMutationProps {
  onSuccess?: () => void;
}

export function useKycInfoMutation({ onSuccess }: useKycInfoMutationProps) {
  const { t } = useTranslationWithPrefix('identityValidation');
  const { showToastError } = useToast();

  const useMutationResult = useMutation({
    mutationFn: (numberOfOwners: number) =>
      documentsService.postKycInfo(numberOfOwners),
    onError: () => showToastError(t('error')),
    onSuccess: onSuccess,
  });

  return useMutationResult;
}
