import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box } from '@nimbus-ds/components';
import { useIsMobile } from 'commons/hooks';
import { KycInfo } from 'commons/services/dashboardService/dashboard.service';
import merchantService, {
  GetBalanceResponse,
} from 'commons/services/merchantService/merchantService';
import { useTranslationWithPrefix } from 'commons/utils';
import RenderOnError from '../../../commons/components/RenderOnError';
import DashboardContext, {
  DashboardContextArgs,
} from '../../Dashboard.context';
import AvailableAmountCard from './cards/AvailableAmount';
import BalanceCard from './cards/BalanceCard';
import WaitingFundsCard from './cards/WaitingFunds';

type dashboardCardsArgs = {
  kycInfo: Pick<KycInfo, 'isAllowedToWithdraw' | 'approvedOnKyc'>;
};
function DashboardCards({ kycInfo }: dashboardCardsArgs): JSX.Element {
  const [balance, setBalance] = useState<GetBalanceResponse>();
  const [onFetchError, setOnFetchError] = useState<boolean>(false);
  const { showSkeleton, setLoadingBalance }: DashboardContextArgs =
    useContext(DashboardContext);
  const isMobile = useIsMobile();

  const { t } = useTranslationWithPrefix('dashboard.cards');

  const withdrawDissabled = useMemo(() => {
    return !kycInfo.isAllowedToWithdraw || !kycInfo.approvedOnKyc;
  }, [kycInfo.approvedOnKyc, kycInfo.isAllowedToWithdraw]);

  const fetch = useCallback(() => {
    return merchantService
      .getBalance()
      .then((balance) => {
        setBalance(balance);
        setOnFetchError(false);
      })
      .catch(() => {
        setOnFetchError(true);
        setBalance(undefined);
      })
      .finally(() => {
        setLoadingBalance(false);
      });
  }, [setLoadingBalance]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Box>
      {showSkeleton ? (
        <DashboardCardsSkeleton isMobile={isMobile} />
      ) : (
        <Box
          display={'flex'}
          gap={'4'}
          flexDirection={'row'}
          flexWrap={{ xs: 'wrap', md: 'nowrap' }}
          data-testid="dashboard-cards"
          justifyContent={'space-around'}
          marginBottom={'4'}
        >
          <RenderOnError
            message={t('availableAmount.onError.message')}
            label={t('availableAmount.onError.label')}
            error={onFetchError}
            refreshHandler={fetch}
          >
            <AvailableAmountCard
              disabled={withdrawDissabled}
              balance={balance?.availableToWithdraw.value}
            />
          </RenderOnError>
          <RenderOnError
            error={onFetchError}
            message={t('waitingFunds.onError.message')}
            label={t('waitingFunds.onError.label')}
            refreshHandler={fetch}
          >
            <WaitingFundsCard balance={balance?.waitingFunds.value} />
          </RenderOnError>
        </Box>
      )}
    </Box>
  );
}

function DashboardCardsSkeleton(props: { isMobile: boolean }): JSX.Element {
  return (
    <Box>
      {props.isMobile ? (
        <Box
          data-testid="DashboardCardsSkeleton"
          gap={'4'}
          display={'grid'}
          marginBottom={'8'}
        >
          <BalanceCard.Skeleton />
          <BalanceCard.Skeleton />
        </Box>
      ) : (
        <Box
          data-testid="DashboardCardsSkeleton"
          display={'flex'}
          gap={'4'}
          marginBottom={'8'}
          justifyContent={'space-between'}
          alignItems={'stretch'}
        >
          <BalanceCard.Skeleton />
          <BalanceCard.Skeleton />
        </Box>
      )}
    </Box>
  );
}

export default DashboardCards;
