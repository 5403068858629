import { useMemo } from 'react';
import { TFunction } from 'react-i18next';
import { IconProps, MoneyIcon, UndoIcon } from '@nimbus-ds/icons';

type appearanceTypes =
  | 'danger'
  | 'primary'
  | 'secondary'
  | 'default'
  | 'warning'
  | 'success'
  | undefined;

export interface useEventLabelPropsValue {
  label: string;
  icon: React.FC<IconProps>;
  appearance: appearanceTypes;
}

const useEventLabelProps = (
  t: TFunction<string, undefined>,
): Record<string, useEventLabelPropsValue> => {
  const eventStatusLabelProps = useMemo(
    () => {
      const paidSuccessLabel = {
        label: t('home.status.approved'),
        icon: MoneyIcon,
        appearance: 'success' as appearanceTypes,
      };
      const failedRefundLabel = {
        label: t('transactionDetails.paymentHistory.refunded.failure.title'),
        icon: UndoIcon,
        appearance: 'danger' as appearanceTypes,
      };
      const pendingRefundLabel = {
        label: t('transactionDetails.paymentHistory.refunded.pending.title'),
        icon: UndoIcon,
        appearance: 'warning' as appearanceTypes,
      };
      const successRefundLabel = {
        label: t('transactionDetails.paymentHistory.refunded.success.title'),
        icon: UndoIcon,
        appearance: 'default' as appearanceTypes,
      };
      const successPartialRefundLabel = {
        label: t(
          'transactionDetails.paymentHistory.partially_refunded.success.title',
        ),
        icon: UndoIcon,
        appearance: 'default' as appearanceTypes,
      };
      const pendingPartialRefundLabel = {
        label: t(
          'transactionDetails.paymentHistory.partially_refunded.pending.title',
        ),
        icon: UndoIcon,
        appearance: 'warning' as appearanceTypes,
      };
      const failedPartialRefundLabel = {
        label: t(
          'transactionDetails.paymentHistory.partially_refunded.failure.title',
        ),
        icon: UndoIcon,
        appearance: 'danger' as appearanceTypes,
      };
      const labels = {
        paid_success: paidSuccessLabel,
        partially_refunded_pending: pendingPartialRefundLabel,
        partially_refunded_failure: failedPartialRefundLabel,
        partially_refunded_success: successPartialRefundLabel,
        refunded_pending: pendingRefundLabel,
        refunded_failure: failedRefundLabel,
        refunded_success: successRefundLabel,
      };
      return labels;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return eventStatusLabelProps;
};

export default useEventLabelProps;
