import React, { useEffect, useState } from 'react';
import { FeatureFlag, featureFlagService } from 'commons/services';

type ShowComponentArgs = {
  feature: FeatureFlag | FeatureFlag[];
  children: JSX.Element;
};
function ShowComponent({ feature, children }: ShowComponentArgs) {
  const [ShowComponent, setShowComponent] = useState<boolean>(false);

  useEffect(() => {
    if (Array.isArray(feature)) {
      const requests = feature.map((f) =>
        featureFlagService.isFeatureFlagEnabled(f),
      );
      Promise.all(requests)
        .then((results) => {
          results.map((result) => (result ? setShowComponent(result) : null));
        })
        .catch(() => null);
    } else {
      featureFlagService
        .isFeatureFlagEnabled(feature)
        .then((result) => {
          setShowComponent(result);
        })
        .catch(() => setShowComponent(false));
    }
  }, [feature]);

  return ShowComponent ? children : <></>;
}

export default ShowComponent;
