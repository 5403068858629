import React from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Box, Link, Spinner, Text } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { ROUTES } from 'App/Routes/constants';
import { useStoreInfo } from 'commons/hooks';
import {
  formatCurrency,
  formatTime,
  useTranslationWithPrefix,
} from 'commons/utils';
import { ErrorMessage } from 'domains/FutureIncomes/ErrorMessage';
import { useDateFutureIncomesMobileQuery } from 'domains/FutureIncomes/hooks';
import { useIntersectionObserver } from 'usehooks-ts';
import { IncomesByOrderProps } from './IncomesByOrder';
import IncomesByOrderSkeleton from './IncomesByOrderSkeleton';

function IncomesByOrderMobile({ date }: IncomesByOrderProps): JSX.Element {
  const { storeInfo } = useStoreInfo();
  const { push } = useHistory();
  const { t } = useTranslationWithPrefix('futureIncome.incomesByOrder');
  const { data, isError, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useDateFutureIncomesMobileQuery(date);

  const handleOnEndScroll = () => {
    if (isError || isLoading || isFetching || !hasNextPage || !data.meta)
      return;

    fetchNextPage();
  };

  const { ref } = useIntersectionObserver({
    onChange: (isIntersecting) => isIntersecting && handleOnEndScroll(),
  });

  if (isLoading) return <IncomesByOrderSkeleton />;
  if (isError) return <ErrorMessage />;

  const goToTransaction = (orderId: number) => {
    track('pago-nube-futuros-ingresos-seleccion-venta');
    push(`${ROUTES.TRANSACTION_DETAILS}/${orderId}`);
  };

  return (
    <DataList>
      {data.items.map((income, index, arr) => (
        <DataList.Row topDivider={index !== 0} key={income.orderId} gap="1">
          <Box
            display="flex"
            justifyContent="space-between"
            ref={index + 1 === arr.length ? ref : undefined}
          >
            <Link
              appearance="primary"
              textDecoration="none"
              onClick={() => goToTransaction(income.orderId)}
            >
              {t('order', { orderNumber: income.orderNumber })}
            </Link>
            <Text>{formatCurrency(income.netAmount, storeInfo)}</Text>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Text>{income.customer}</Text>
            <Text>{formatTime(income.date)}</Text>
          </Box>
        </DataList.Row>
      ))}
      {isFetching && (
        <DataList.Row>
          <Box display="flex" justifyContent="center" padding="1">
            <Spinner />
          </Box>
        </DataList.Row>
      )}
    </DataList>
  );
}

export default IncomesByOrderMobile;
