import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@nimbus-ds/components';
import { Layout, Page } from '@nimbus-ds/patterns';
import { Country } from 'App/i18n/enums';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile, useStoreInfo } from 'commons/hooks';
import { GetBalanceResponse } from 'commons/services/merchantService/merchantService';
import { MoneyObjectInterface } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import { CountryToCurrency } from 'commons/utils/country-currency';
import MobileTopNavBar from 'domains/Brazil/commons/components/MobileTopNavBar';
import NuvemPagoCta from 'domains/Brazil/commons/components/NuvemPagoCta';
import { WithdrawalPageArgs } from '../../withdrawal';
import AmountCard from './components/AmountCard';
import BalanceCard, { BalanceSummary } from './components/BalanceCard';
import Controls from './components/Controls';

const parseBalanceData = (
  data: Partial<GetBalanceResponse>,
): BalanceSummary => {
  const balance = data.availableToWithdraw?.value ?? 0;
  const fee = data.transferFee?.value ?? 0;
  const available = balance < fee ? 0 : balance - fee;

  return { balance, fee, available };
};

type withdrawAmountFormArgs = {
  balance?: GetBalanceResponse;
  requestError: boolean;
  callBack: (amount: MoneyObjectInterface) => void;
  refreshHandler: () => void;
} & WithdrawalPageArgs;

function WithdrawAmountForm({
  balance,
  requestError,
  callBack,
  refreshHandler,
}: withdrawAmountFormArgs): JSX.Element {
  const isMobile = useIsMobile();
  const { t } = useTranslationWithPrefix('withdraw');
  const { storeInfo } = useStoreInfo();
  const [balanceSummary, setBalanceSummary] = useState<BalanceSummary>();
  const [inputValue, setInputValue] = useState(0);
  const [inputError, setInputError] = useState(false);
  const [disableControls, setDisableControls] = useState(false);
  const [disableForm, setDisableForm] = useState(false);

  const submit = useCallback(
    (amount) => {
      callBack({
        value: amount,
        currency: CountryToCurrency(storeInfo.country as Country),
      });
    },
    [callBack, storeInfo.country],
  );

  useEffect(() => {
    setBalanceSummary(parseBalanceData(balance ?? {}));
  }, [balance]);

  useEffect(() => {
    setDisableForm(!balanceSummary || requestError);
  }, [balanceSummary, requestError]);

  useEffect(() => {
    setDisableControls(
      !balanceSummary || requestError || inputError || !inputValue,
    );
  }, [balanceSummary, inputError, inputValue, requestError]);

  return (
    <Box
      backgroundColor={isMobile ? 'neutral-background' : 'neutral-surface'}
      maxWidth="100vw"
      minHeight="100vh"
      padding="none"
      margin="none"
    >
      {isMobile && <MobileTopNavBar backLinkPath={ROUTES.DASHBOARD} />}
      <Page
        data-testid="WithdrawAmountForm-Grid"
        maxWidth="800px"
        height="100%"
      >
        <Page.Header
          title={t('transferAmountForm.title')}
          paddingX={isMobile ? '4' : '6'}
        />
        <Page.Body paddingX={isMobile ? '4' : '6'}>
          <Layout columns="1">
            <Layout.Section>
              {balanceSummary ? (
                <AmountCard
                  value={inputValue}
                  maxValue={balanceSummary?.available ?? 0}
                  setValue={setInputValue}
                  setError={setInputError}
                  disabled={disableForm}
                />
              ) : (
                <AmountCard.Skeleton />
              )}
            </Layout.Section>
            <Layout.Section>
              {balanceSummary ? (
                <BalanceCard
                  summary={balanceSummary}
                  error={requestError}
                  refreshHandler={refreshHandler}
                />
              ) : (
                <BalanceCard.Skeleton />
              )}
            </Layout.Section>
            <Layout.Section>
              <Controls
                disabled={disableControls}
                submitHandler={() => submit(inputValue)}
              />
            </Layout.Section>
            <Layout.Section>
              <NuvemPagoCta
                href={
                  'https://atendimento.nuvemshop.com.br/pt_BR/gestao-dos-pagamentos/como-resgatar-saldo-do-nuvem-pago'
                }
                title={t('cta')}
                trackingTag="nuvempago_transfer_can_link_click"
              />
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </Box>
  );
}

export default WithdrawAmountForm;
