import React from 'react';
import { Box, Link, Sidebar, Title } from '@nimbus-ds/components';
import { ChevronLeftIcon } from '@nimbus-ds/icons';

export default function SidebarHeader({
  title,
  onRemove,
}: {
  title: string;
  onRemove: () => void;
}): JSX.Element {
  return (
    <Sidebar.Header>
      <Box display="flex" flexDirection="column" gap="6">
        <Link onClick={onRemove} textDecoration="none">
          <ChevronLeftIcon />
        </Link>
        <Title>{title}</Title>
      </Box>
    </Sidebar.Header>
  );
}
