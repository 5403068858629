import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { Alert, Box } from '@nimbus-ds/components';
import { getIsMobileDevice } from '@tiendanube/nexo/helpers';
import AppLayout from 'App/components/AppLayout';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { HelpLink, RedirectCard } from 'commons/components';
import {
  useBackLink,
  useFeatureDiscovery,
  useIdentityValidation,
} from 'commons/hooks';
import { FeatureName } from 'commons/services/featureDiscoveryService/featureDiscoveryServiceResponses';
import { useTranslationWithPrefix } from 'commons/utils';
import { KYC_ANALYSIS_DAYS } from 'commons/utils/constants';
import ConfigurationSkeleton from './ConfigurationSkeleton';
import OnBoardingModal from './OnBoardingModal';
import PersonalData from './PersonalData';

interface InterfaceReference {
  submit: () => Promise<boolean>;
  scrollIntoView: () => void;
}

interface stateType {
  showDocumentsSuccessAlert?: boolean;
  scrollTo?: string;
  hideOnBoardingModal?: boolean;
}

type AlertAppearence = 'primary' | 'success' | 'warning' | 'danger' | 'neutral';

function Configuration(): JSX.Element {
  const { t } = useTranslationWithPrefix('configuration');
  const location = useLocation<stateType>();
  const bankAccountRef = useRef<InterfaceReference>();
  const { backLink: backLinkState } = useBackLink();
  const backLink = useMemo(() => {
    return {
      path: backLinkState.backLinkPath,
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  const [alertState, setAlertState] = useState({
    appearance: 'primary' as AlertAppearence,
    children: '',
    show: false,
    title: '',
  });
  const [redirectPath, setRedirectPath] = useState('');
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [onBoardingModalOpen, setOnBoardingModalOpen] = useState(false);
  const identityValidation = useIdentityValidation();
  const onBoardingModalDiscovery = useFeatureDiscovery(
    FeatureName.OnBoardingConfigurationModal,
  );

  useEffect(() => {
    if (location.state?.showDocumentsSuccessAlert) {
      setAlertState({
        appearance: 'success',
        children: t('validationSuccess.description', {
          kycAnalysisDays: KYC_ANALYSIS_DAYS,
        }),
        show: true,
        title: t('validationSuccess.title'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.showDocumentsSuccessAlert]);

  useEffect(() => {
    const openOnBoardingModal = () => {
      setOnBoardingModalOpen(true);
    };

    const onBoardingModalToBeOpen =
      identityValidation.data?.status === 'pending' &&
      !location.state?.hideOnBoardingModal &&
      !onBoardingModalDiscovery.isLoading &&
      !onBoardingModalDiscovery.isDiscovered;
    if (onBoardingModalToBeOpen) openOnBoardingModal();
  }, [
    identityValidation.data?.status,
    location.state?.hideOnBoardingModal,
    onBoardingModalDiscovery.isDiscovered,
    onBoardingModalDiscovery.isLoading,
  ]);

  useEffect(() => {
    if (location.state?.scrollTo) {
      switch (location.state.scrollTo) {
        case 'bank-account':
          bankAccountRef.current?.scrollIntoView();
          break;
        default:
          break;
      }
    }
  }, [location.state?.scrollTo]);

  useEffect(() => {
    const getRedirectPath = async () => {
      const isMobileDevice = await getIsMobileDevice(nexo);
      if (isMobileDevice) {
        setRedirectPath(ROUTES.HOME);
        setIsMobileDevice(true);
      } else {
        setRedirectPath(backLink.path ?? 'payments');
        setIsMobileDevice(false);
      }
    };
    getRedirectPath();
  }, [backLink.path]);

  function closeAlert() {
    setAlertState({
      appearance: 'primary',
      children: '',
      show: false,
      title: '',
    });
  }

  function onOnboardingModalDismiss() {
    onBoardingModalDiscovery.markAsDiscovered.mutate();
    setOnBoardingModalOpen(false);
  }

  return (
    <AppLayout
      title={t('title')}
      backLinkPath={redirectPath}
      backLinkText={t('backLinkText')}
      backLinkIsOldAdmin={!backLink.path && !isMobileDevice}
    >
      <Box display="flex" flexDirection="column" gap="6" alignItems="stretch">
        <Alert {...alertState} onRemove={closeAlert} />
        <OnBoardingModal
          open={onBoardingModalOpen}
          onDismiss={onOnboardingModalDismiss}
        />
        <PersonalData />
        <RedirectCard
          title={t('section.paymentMethods')}
          path={ROUTES.PAYMENT_METHODS}
        />
        <RedirectCard
          title={t('section.settlementPeriod')}
          path={ROUTES.SETTLEMENT_PERIOD}
        />
        <RedirectCard
          title={t('section.installments')}
          path={ROUTES.INSTALLMENTS}
        />
        <HelpLink text={t('helpLink.text')} href={t('helpLink.href')} />
      </Box>
    </AppLayout>
  );
}

Configuration.Skeleton = ConfigurationSkeleton;
export default Configuration;
