import { useEffect, useState } from 'react';
import { useIdentityValidation } from 'commons/hooks';
import { getUrlSearchParams } from 'commons/utils';
import {
  dataDogLogger,
  DataDogLoggerActionTypes,
} from 'commons/utils/dataDogLogger';
import { EventCallback, setupEventHandlers } from './setupEventHandlers';

interface UseProofOfLifeConfigProps {
  onStart?: EventCallback;
  onFinish: EventCallback;
  onExit: EventCallback;
  onContinueOnAnotherDevice?: EventCallback;
}

function getConfigFrom(url: string) {
  const searchParams = getUrlSearchParams(url);
  const clientId = searchParams.get('merchantToken');
  const flowId = searchParams.get('flowId');
  const metadata = searchParams.get('metadata') ?? undefined;
  if (!clientId || !flowId) {
    const message = 'Invalid proof of life URL';
    dataDogLogger.generate({
      actionMessage: message,
      actionData: {
        url,
      },
      actionOwner: 'ProofOfLifeVerification',
      actionType: DataDogLoggerActionTypes.Error,
    });
    throw new Error(message);
  }

  return {
    clientId,
    flowId,
    metadata,
  };
}

export const useProofOfLifeVerification = ({
  onFinish,
  onExit,
  onContinueOnAnotherDevice,
}: UseProofOfLifeConfigProps) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [verification, setVerification] = useState<
    MetamapVerification | undefined
  >();

  const identityValidation = useIdentityValidation();

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://web-button.getmati.com/button.js';
    script.async = true;
    script.onload = () => {
      setScriptLoaded(true);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!scriptLoaded || !identityValidation.data?.proofOfLifeUrl) return;

    const config = getConfigFrom(identityValidation.data.proofOfLifeUrl);

    const verificationInstance = new MetamapVerification({
      clientId: config.clientId,
      flowId: config.flowId,
      metadata: config.metadata,
    });

    setVerification(verificationInstance);

    return setupEventHandlers(
      verificationInstance,
      onFinish,
      onExit,
      onContinueOnAnotherDevice,
    );
  }, [
    onFinish,
    onExit,
    onContinueOnAnotherDevice,
    scriptLoaded,
    identityValidation.data?.proofOfLifeUrl,
  ]);

  const startVerification = () => {
    verification?.start();
  };

  return {
    startVerification,
    isLoading: identityValidation.isLoading || !scriptLoaded,
  };
};
