import React from 'react';
import { AspectRatio, Thumbnail } from '@nimbus-ds/components';

interface ThumbnailFileSkeletonProps {
  aspectRatio: AspectRatio;
}

function ThumbnailFileSkeleton({
  aspectRatio,
}: ThumbnailFileSkeletonProps): JSX.Element {
  return <Thumbnail.Skeleton aspectRatio={aspectRatio} />;
}

export default ThumbnailFileSkeleton;
