import React from 'react';
import { Box, Button, Link, Text } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { useTranslationWithPrefix } from 'commons/utils';
import DesktopCard from '../components/DesktopCard';
import { useIdValidationRoutes } from '../hooks';

function PepOrSoContact(): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');
  const { goToNextRoute } = useIdValidationRoutes();

  const footerButtons = (
    <Box>
      <Button appearance="primary" onClick={() => goToNextRoute()}>
        {t('buttons.understand')}
      </Button>
    </Box>
  );

  return (
    <AppLayout title={t('title')} footer={footerButtons}>
      <DesktopCard>
        <Box>
          <Text>
            {t('notEligible.indication')}
            <Text as="span" fontWeight="bold">
              {t('notEligible.pepOrSo')}
            </Text>
            {t('notEligible.comma')}
            {t('notEligible.contact1').toLowerCase()}
            <Link as="a" href={`mailto: ${t('notEligible.email')}`}>
              {t('notEligible.email')}
            </Link>
            {t('notEligible.contact2')}
          </Text>
        </Box>
      </DesktopCard>
    </AppLayout>
  );
}

export default PepOrSoContact;
