import { EventHistoryType } from 'commons/types';

export function hasSuccessRefund(events?: EventHistoryType[]) {
  return Boolean(
    events?.some(
      (event) =>
        (event.type === 'refunded' || event.type === 'partially_refunded') &&
        event.status === 'success',
    ),
  );
}
