import React, { useEffect, useState } from 'react';
import { AspectRatio, FileUploader, IconButton } from '@nimbus-ds/components';
import { TrashIcon } from '@nimbus-ds/icons';
import { ThumbnailWithAction } from '@nimbus-ds/patterns';
import { documentsService } from 'commons/services';
import { pdfThumbnailPath, useTranslationWithPrefix } from 'commons/utils';
import { MAX_DOCUMENT_SIZE } from 'commons/utils/constants';
import {
  dataDogLogger,
  DataDogLoggerActionTypes,
} from 'commons/utils/dataDogLogger';
import ThumbnailFileSkeleton from './ThumbnailFileSkeleton';
import './ThumbnailFile.css';

function logRejectionByFileType(file: File) {
  dataDogLogger.generate({
    actionMessage: `File upload rejected for file ${file.name}`,
    actionOwner: 'ThumbnailFile.jsx',
    actionData: {
      fileName: file.name,
      fileType: file.type,
    },
    actionType: DataDogLoggerActionTypes.Warn,
  });
}

function logRejectionByFileSize(file: File) {
  dataDogLogger.generate({
    actionMessage: `File size exceeded for ${file.name}`,
    actionOwner: 'ThumbnailFile.jsx',
    actionData: {
      fileName: file.name,
      fileType: file.type,
      fileSize: file.size,
    },
    actionType: DataDogLoggerActionTypes.Warn,
  });
}

function logFailure(file: File, documentName: string, error: unknown) {
  dataDogLogger.generate({
    actionMessage: `File upload error`,
    actionOwner: 'ThumbnailFile.jsx',
    actionData: {
      fileSize: file.size,
      fileRawName: file.name,
      fileName: documentName,
    },
    actionType: DataDogLoggerActionTypes.Error,
    error,
  });
}

function logFileSize(file: File, documentName: string) {
  dataDogLogger.generate({
    actionMessage: `The merchant triggered the sending of documents and their file size is ${file.size}`,
    actionOwner: 'ThumbnailFile.jsx',
    actionData: {
      fileSize: file.size,
      fileRawName: file.name,
      fileName: documentName,
    },
    actionType: DataDogLoggerActionTypes.Info,
  });
}

interface InterfaceThumbnailFileWithText {
  helperText: string;
  documentName: string;
  onSuccess: () => void;
  onError: (text: string) => void;
  onDelete: () => void;
  initialThumbnailFile?: string;
  onRow?: boolean;
  accept?: string;
  open?: boolean;
  multiple?: boolean;
  aspectRatio: AspectRatio;
  disabled?: boolean;
  ariaLabel: string;
}

function ThumbnailFile({
  aspectRatio,
  disabled = false,
  ariaLabel,
  accept = 'image/jpeg,image/png,application/pdf',
  onSuccess,
  onError,
  onDelete,
  helperText,
  documentName,
  initialThumbnailFile = '',
}: InterfaceThumbnailFileWithText): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');
  const [thumbnailImageSrc, setThumbnailImageSrc] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchFileType = async () => {
      const file = await fetch(initialThumbnailFile);
      const blob = await file.blob();
      if (blob.type === 'application/pdf') {
        setThumbnailImageSrc(pdfThumbnailPath);
      } else {
        setThumbnailImageSrc(initialThumbnailFile);
      }
    };
    fetchFileType();
  }, [initialThumbnailFile]);

  const hasValidType = (file: File) => {
    const VALID_TYPES = ['image/jpeg', 'image/png', 'application/pdf'];

    return VALID_TYPES.includes(file.type);
  };

  const hasValidSize = (file: File) => {
    return file.size <= MAX_DOCUMENT_SIZE && file.size > 0;
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const files = event.target.files && Object.values(event.target.files);
    const file = files ? files[0] : undefined;
    if (!file) return;

    if (!hasValidType(file)) {
      logRejectionByFileType(file);
      onError(t('errors.invalidType'));
      return;
    }

    logFileSize(file, documentName);

    if (!hasValidSize(file)) {
      logRejectionByFileSize(file);
      onError(t('errors.sizeLimit'));
      return;
    }

    setIsLoading(true);
    documentsService
      .uploadDocuments(documentName, file)
      .then((response) => {
        if (file.type === 'application/pdf') {
          setThumbnailImageSrc(pdfThumbnailPath);
        } else {
          const url = response.url;
          setThumbnailImageSrc(url);
        }
        onSuccess();
      })
      .catch((error: unknown) => {
        logFailure(file, documentName, error);
        onError(t('errors.serverError'));
      })
      .finally(() => setIsLoading(false));
  };

  const onFileDelete = () => {
    setThumbnailImageSrc('');
    onDelete();
  };

  if (isLoading) return <ThumbnailFileSkeleton aspectRatio={aspectRatio} />;

  return (
    <>
      {thumbnailImageSrc ? (
        <ThumbnailWithAction
          thumbnail={{
            src: thumbnailImageSrc,
            alt: ariaLabel,
            aspectRatio: aspectRatio,
            width: '100%',
          }}
        >
          <IconButton
            source={<TrashIcon color="black" />}
            size="2rem"
            onClick={onFileDelete}
          />
        </ThumbnailWithAction>
      ) : (
        <FileUploader
          aspectRatio={aspectRatio}
          onChange={onFileChange}
          placeholder={helperText}
          disabled={disabled}
          accept={accept}
          id={documentName}
        />
      )}
    </>
  );
}

ThumbnailFile.Skeleton = ThumbnailFileSkeleton;
export default ThumbnailFile;
