import React from 'react';
import { Select } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { useTranslationWithPrefix } from 'commons/utils';
import StatusFilterEnum from 'domains/Brazil/commons/types/TransactionStatusFilterEnum';

const SELECT_OPTIONS = Object.values(StatusFilterEnum);

interface StatusFilterInterface {
  status: StatusFilterEnum;
  setStatus: React.Dispatch<React.SetStateAction<StatusFilterEnum>>;
}

function StatusFilter({
  status,
  setStatus,
}: StatusFilterInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('home');
  const options = SELECT_OPTIONS.map((s) => ({
    label: t(`status.${s}`),
    value: s,
  }));

  return (
    <FormField.Select
      id="status"
      name="status"
      label={t('transactionsSearch.selectStatus')}
      value={status}
      onChange={({ target }: any) => setStatus(target.value)}
      data-testid="statusFilter"
    >
      <React.Fragment>
        {options.map(({ label, value }) => {
          return <Select.Option label={label} value={value} key={value} />;
        })}
      </React.Fragment>
    </FormField.Select>
  );
}

export default StatusFilter;
