import axios from 'App/axios';
import { MoneyObjectInterface } from 'commons/types';
import { GetRefundDetailsResponse } from './refundServiceResponses';

export type GetRefundDetailsType = (
  id: string,
) => Promise<GetRefundDetailsResponse>;

const getRefundDetails: GetRefundDetailsType = async (id: string) => {
  const response = await axios.get(`/admin/transactions/${id}/refund-details`);
  return response.data;
};

type RefundType = (
  id: string,
  amount: MoneyObjectInterface,
  restock?: boolean,
) => Promise<void>;

const refund: RefundType = async (
  id: string,
  amount: MoneyObjectInterface,
  restock?: boolean,
) => {
  const response = await axios.post(`/admin/orders/${id}/refunds`, {
    amount,
    restock,
  });
  return response.data;
};

interface ConfigurationServicesInterface {
  getRefundDetails: GetRefundDetailsType;
  refund: RefundType;
}

const configurationService: ConfigurationServicesInterface = {
  getRefundDetails,
  refund,
};

export default configurationService;
