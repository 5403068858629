import React from 'react';
import { Box, Spinner } from '@nimbus-ds/components';
import { ExclamationTriangleIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { DataList } from '@nimbus-ds/patterns';
import { TransactionObjectInterface } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import EmptyTransactions from '../EmptyTransactions';
import HomeTableMobileRow from './HomeTableMobileRow';

interface HomeTableBodyProps {
  hasEmptyTransactions: boolean;
  transactions: TransactionObjectInterface[];
  isLoading: boolean;
  aRef?: (node?: Element | null) => void;
}

function HomeTableBody({
  hasEmptyTransactions,
  transactions,
  isLoading,
  aRef,
}: HomeTableBodyProps) {
  const { t } = useTranslationWithPrefix('home.homeTable');

  if (hasEmptyTransactions) return <EmptyTransactions />;

  return transactions.length || isLoading ? (
    <DataList>
      {transactions.map((order, index, arr) => (
        <HomeTableMobileRow
          key={`home-table-mobile-row-${index}`}
          order={order}
          aRef={index + 1 === arr.length ? aRef : undefined}
        />
      ))}
      {isLoading && (
        <Box display="flex" justifyContent="center" paddingY="6">
          <Spinner size="large" />
        </Box>
      )}
    </DataList>
  ) : (
    <EmptyMessage
      icon={<ExclamationTriangleIcon size={32} />}
      title={t('emptySearch.title')}
      text={t('emptySearch.text')}
    />
  );
}

export default HomeTableBody;
