import React from 'react';
import { Box } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import { FiltersProvider } from './FiltersContext';
import { Transfered } from './Transfered';
import { WithdrawsTableDesktop, WithdrawsTableMobile } from './WithdrawsTable';

function Withdraws(): JSX.Element {
  const { t } = useTranslationWithPrefix('withdraws');
  const isMobile = useIsMobile();

  return (
    <FiltersProvider>
      <AppLayout title={t('title')}>
        <Box display="flex" flexDirection="column" gap="12">
          <Transfered />
          {isMobile ? <WithdrawsTableMobile /> : <WithdrawsTableDesktop />}
        </Box>
      </AppLayout>
    </FiltersProvider>
  );
}

export default Withdraws;
