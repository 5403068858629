import { useQuery } from '@tanstack/react-query';
import { bankAccountService, QueryType } from 'commons/services';
import { GetBankAccountDataType } from 'commons/services/bankAccountService/bankAccountService';

export const useBankAccount: QueryType<GetBankAccountDataType> = (options) => {
  const bankAccountQuery = useQuery(
    ['bankAccount'],
    () => bankAccountService.getBankAccount(),
    options,
  );

  return bankAccountQuery;
};
