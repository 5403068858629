import React from 'react';
import { Trans } from 'react-i18next';
import { Alert, Link, Text } from '@nimbus-ds/components';
import { useStoreInfo } from 'commons/hooks';
import { ChargebackStatus, ChargebackType } from 'commons/types';
import {
  formatLongDateWithoutYear,
  useTranslationWithPrefix,
} from 'commons/utils';

interface ChargebackStatusAlertProps {
  chargeback: ChargebackType;
}

function ChargebackStatusAlert({
  chargeback,
}: ChargebackStatusAlertProps): JSX.Element {
  const { t } = useTranslationWithPrefix(
    'transactionDetails.chargebackStatusAlert',
  );
  const { storeInfo } = useStoreInfo();

  const OwnerMail = () => (
    <Link as="a" href={`mailto: ${storeInfo.user?.email}`}>
      {storeInfo.owner?.email}
    </Link>
  );

  switch (chargeback?.status) {
    case ChargebackStatus.Pending:
      return (
        <Alert
          show
          title={t(`pending.${chargeback.reason}.title`)}
          appearance="warning"
        >
          <Text>
            <Trans
              t={t}
              i18nKey="pending.description"
              values={{
                deadline: formatLongDateWithoutYear(
                  storeInfo,
                  chargeback.deadline.toString(),
                ),
              }}
              components={[<OwnerMail key="status-alert-user-email" />]}
            />
          </Text>
          <Link as="a" href={t('pending.documentationUrl')} target="_blank">
            {t('pending.uploadDocumentation')}
          </Link>
        </Alert>
      );

    case ChargebackStatus.DisputeRejected:
      return (
        <Alert show title={t('disputeRejected.title')} appearance="danger">
          <Text>
            <Trans
              t={t}
              i18nKey="disputeRejected.description"
              values={{
                deadline: formatLongDateWithoutYear(
                  storeInfo,
                  chargeback.deadline.toString(),
                ),
              }}
            />
          </Text>
        </Alert>
      );

    case ChargebackStatus.DisputeSent:
    case ChargebackStatus.DisputeReceived:
      return (
        <Alert show title={t('disputeSent.title')} appearance="primary">
          <Text>{t(`disputeSent.${chargeback.reason}.description`)}</Text>
        </Alert>
      );

    case ChargebackStatus.ResolvedMerchant:
    case ChargebackStatus.ResolvedInsurance:
      return (
        <Alert show title={t('resolvedMerchant.title')} appearance="success">
          <Text>{t('resolvedMerchant.description')}</Text>
        </Alert>
      );

    case ChargebackStatus.ResolvedCustomer:
      return (
        <Alert show title={t('resolvedCustomer.title')} appearance="neutral">
          <Text>{t('resolvedCustomer.description')}</Text>
        </Alert>
      );

    case ChargebackStatus.ResolvedWithoutDispute:
      return (
        <Alert
          show
          title={t('resolvedWithoutDispute.title')}
          appearance="neutral"
        >
          <Text>{t('resolvedWithoutDispute.description')}</Text>
        </Alert>
      );
  }
}

export default ChargebackStatusAlert;
