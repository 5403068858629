import React from 'react';
import { CashIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { HelpLink } from 'commons/components';
import { useTranslationWithPrefix } from 'commons/utils';

function EmptyTransactions(): JSX.Element {
  const { t } = useTranslationWithPrefix('home.emptyTransactions');

  return (
    <EmptyMessage
      title={t('title')}
      text={t('description')}
      icon={<CashIcon size="large" />}
      data-testid="empty-transactions-message"
      actions={
        <HelpLink
          appearance="primary"
          textDecoration="none"
          text={t('helpLink.text')}
          href={t('helpLink.href')}
        />
      }
    />
  );
}

export default EmptyTransactions;
