import React, { useState } from 'react';
import { Box, Icon, Input, Label, Text } from '@nimbus-ds/components';
import { ExclamationCircleIcon } from '@nimbus-ds/icons';
import { fieldHandler } from './FieldHandler.interface';

type amountNameInput = fieldHandler & {
  title: string;
  placeHolder: string;
  helpText?: string;
};
function AmountInput({
  handler,
  value,
  placeHolder,
  title,
  helpText,
}: amountNameInput): JSX.Element {
  const [validationError, setValidationError] = useState(false);
  const handleAmountField = (event: any) => {
    const amount = event.target.value?.replace(/\D/g, '');

    if (amount <= 0) {
      setValidationError(true);
    } else {
      setValidationError(false);
    }
    handler(`${Number(amount / 100).toFixed(2)}`);
  };

  return (
    <Box display="grid" gridGap="2">
      <Label htmlFor="amountPaymentLinkForm">{title}</Label>
      <Input
        id="amountPaymentLinkForm"
        name="amount"
        value={new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(Number(value))}
        onChange={handleAmountField}
        min={'0'}
        appearance={validationError ? 'danger' : 'neutral'}
        placeholder={placeHolder}
      />
      {validationError && (
        <Box display="flex" flexDirection="row" gap="1" alignItems="center">
          <Icon color="danger-textHigh" source={<ExclamationCircleIcon />} />
          <Text color="danger-textHigh">{helpText}</Text>
        </Box>
      )}
    </Box>
  );
}

function AmountInputSkeleton() {
  return (
    <Box display="grid" gridGap="2">
      <Input.Skeleton />
    </Box>
  );
}

AmountInput.Skeleton = AmountInputSkeleton;

export default AmountInput;
