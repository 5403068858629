import { useQuery } from '@tanstack/react-query';
import {
  configurationService,
  GetIdValidationResponse,
  QueryType,
} from 'commons/services';

export const useIdentityValidation: QueryType<GetIdValidationResponse> = (
  options,
) => {
  const idValidationQuery = useQuery(
    ['identityValidation'],
    () => configurationService.getIdValidation(),
    options,
  );

  return idValidationQuery;
};
