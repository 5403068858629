import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import HelpTextSkeleton from './HelpTextSkeleton';
import './HelpText.css';

declare const window: Window &
  typeof globalThis & {
    newrelic: any;
  };

function HelpText(): JSX.Element {
  const { t } = useTranslation();

  const addNewRelicPageAction = () => {
    if (window.newrelic) {
      window.newrelic.addPageAction('Pago Nube help text click');
    }
  };

  return (
    <div className="help-text-wrapper">
      <Link
        as="a"
        href={t('aboutPage')}
        target="_blank"
        onClick={addNewRelicPageAction}
        fontSize="highlight"
      >
        {t('home.helpText.description')}
        <ExternalLinkIcon />
      </Link>
    </div>
  );
}

HelpText.Skeleton = HelpTextSkeleton;
export default HelpText;
