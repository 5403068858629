import React from 'react';
import { Box, Card, Spinner, Text } from '@nimbus-ds/components';
import { ErrorMessage } from 'commons/components';
import { MoneyObjectInterface } from 'commons/types';
import Money from 'domains/Home/Money';
import MoneyCardSkeleton from './MoneyCardSkeleton';

interface MoneyCardProps {
  title: string;
  amount: MoneyObjectInterface;
  skeleton?: boolean;
  showLoadingDelay?: boolean;
  hasError?: boolean;
  primaryButton?: React.ReactNode;
  loadingText?: string;
  color: 'primary' | 'success' | 'warning' | 'danger' | 'neutral';
  onClick?: () => void;
}

function MoneyCard({
  title,
  amount,
  skeleton = false,
  showLoadingDelay = false,
  hasError = false,
  loadingText = '',
  primaryButton,
  color,
  onClick,
}: MoneyCardProps): JSX.Element {
  const renderMoney = () => {
    if (showLoadingDelay) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="4"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Text textAlign="left">{title}</Text>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner size="large" color="primary-interactive" />
            <Text textAlign="center">{loadingText}</Text>
          </Box>
        </Box>
      );
    } else if (hasError) {
      return (
        <Box padding="4">
          <Text>{title}</Text>
          <ErrorMessage />
        </Box>
      );
    }

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        onClick={onClick}
        cursor={onClick ? 'pointer' : undefined}
        padding="4"
      >
        <Box display="flex" flexDirection="column" gap="1">
          <Text>{title}</Text>
          <Money amount={amount} />
        </Box>
        <Box alignItems="center" display="flex">
          {primaryButton}
        </Box>
      </Box>
    );
  };

  if (skeleton && !showLoadingDelay) {
    return (
      <MoneyCardSkeleton color={color} withPrimaryButton={!!primaryButton} />
    );
  }

  return (
    <Card backgroundColor={`${color}-surface`} padding="none">
      {renderMoney()}
    </Card>
  );
}

MoneyCard.Skeleton = MoneyCardSkeleton;
export default MoneyCard;
