import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/ReduxProvider/store';
import { getSettings } from 'redux/selectors/settingsSelector';
import {
  setHideBlockedFreemiumModal,
  setHideReportPopover,
} from 'redux/slices/settingsSlice';
import { ISetSettingsActionArgs, ISettingsState } from 'redux/slices/types';

export interface IUseSettingsInLocalStorage {
  settings: ISettingsState;
  setHideReportPopover: (args: ISetSettingsActionArgs) => any;
  setHideBlockedFreemiumModal: (args: ISetSettingsActionArgs) => any;
}

function useSettingsInLocalStorage(): IUseSettingsInLocalStorage {
  const settings = useSelector(getSettings);
  const dispatch = useAppDispatch();

  const dispatchSetHideReportPopover = useCallback(
    (args: ISetSettingsActionArgs) => dispatch(setHideReportPopover(args)),
    [dispatch],
  );

  const dispatchSetHideBlockedFreemiumModal = useCallback(
    (args: ISetSettingsActionArgs) =>
      dispatch(setHideBlockedFreemiumModal(args)),
    [dispatch],
  );

  return {
    settings,
    setHideReportPopover: dispatchSetHideReportPopover,
    setHideBlockedFreemiumModal: dispatchSetHideBlockedFreemiumModal,
  };
}

export default useSettingsInLocalStorage;
