import React from 'react';
import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';
import SkeletonHandler from './Skeleton';

interface Props {
  description: string | undefined;
}

export default function DescriptionCard(props: Props): JSX.Element {
  const { t } = useTranslationWithPrefix('paymentLink.details.description');

  return (
    <Box>
      <Card padding="none">
        <Box marginLeft={'4'} marginTop={'4'}>
          <SkeletonHandler
            skeletonComponent={<Title.Skeleton />}
            trigger={!props?.description}
          >
            <Card.Header title={t('title')} />
          </SkeletonHandler>
        </Box>
        <Card.Body>
          <Box marginLeft={'4'}>
            <Box
              justifyContent={'flex-start'}
              alignItems={'stretch'}
              marginBottom={'4'}
            >
              <Box justifyContent={'space-between'}>
                <SkeletonHandler
                  skeletonComponent={<Text.Skeleton width="100%" />}
                  trigger={!props?.description}
                >
                  <Text
                    fontSize="base"
                    textAlign="left"
                    color="neutral-textLow"
                  >
                    {props.description}
                  </Text>
                </SkeletonHandler>
              </Box>
            </Box>
          </Box>
        </Card.Body>
      </Card>
    </Box>
  );
}
