/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { Box, Button, Icon, Text, Title } from '@nimbus-ds/components';
import { SlidersIcon } from '@nimbus-ds/icons';
import { navigateHeaderRemove } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import StatusFilterEnum from 'domains/Brazil/commons/types/TransactionStatusFilterEnum';
import TypeFilterEnum from 'domains/Brazil/commons/types/TransactionTypeFilterEnum';
import DateRangeFilterEnum from '../../../commons/types/DateRangeFilterEnum';
import DashboardContext, {
  DashboardContextArgs,
} from '../../Dashboard.context';
import { filters } from '../list/DashboardList';
import SearchBar, { filterObject } from '../search/SearchBar';
import DashboardFiltersContext from './DashboardFiltersContext';
import FilterChips from './FilterChips';
import SideModalFilter from './SideModalFilter';

type filtersArgs = {
  onFilterChange: (filtersObj: filters) => void;
};
function DashboardFilters({ onFilterChange }: filtersArgs) {
  const [open, setOpen] = useState<boolean>(false);
  const [objectQuery, setQuery] = useState<filterObject>();
  const [typeFilter, setTypeFilter] = useState<TypeFilterEnum>(
    TypeFilterEnum.ALL,
  );
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRangeFilterEnum>(
    DateRangeFilterEnum.ALL,
  );
  const [statusFilter, setStatusFilter] = useState<StatusFilterEnum>(
    StatusFilterEnum.ALL,
  );
  const [fromDateFilter, setFromDateFilter] = useState<string | undefined>();
  const [toDateFilter, setToDateFilter] = useState<string | undefined>();
  const [customSearchFilter, setCustomSearchFilter] = useState<
    string[] | undefined
  >();
  const { showSkeleton }: DashboardContextArgs = useContext(DashboardContext);
  const isMobile = useIsMobile();

  const { t } = useTranslationWithPrefix('dashboard.components.filters');

  const context = useMemo(() => {
    return {
      dateRange: dateRangeFilter,
      fromDate: fromDateFilter,
      toDate: toDateFilter,
      setDateRange: setDateRangeFilter,
      setFromDate: setFromDateFilter,
      setStatus: setStatusFilter,
      setToDate: setToDateFilter,
      setType: setTypeFilter,
      status: statusFilter,
      type: typeFilter,
      customSearch: customSearchFilter,
      setCustomSearch: setCustomSearchFilter,
      page: 0,
      setPage: () => {},
      itemsPerPage: 0,
    };
  }, [
    dateRangeFilter,
    fromDateFilter,
    statusFilter,
    toDateFilter,
    typeFilter,
    customSearchFilter,
  ]);

  useEffect(() => {
    navigateHeaderRemove(nexo);

    const filterObj: filters & filterObject = {
      status: statusFilter,
      date: {
        to: toDateFilter,
        from: fromDateFilter,
      },
      paymentMethod: typeFilter,
      withOrderNumber: true,
      ...objectQuery,
    };

    onFilterChange(filterObj);
  }, [
    fromDateFilter,
    onFilterChange,
    statusFilter,
    objectQuery,
    toDateFilter,
    typeFilter,
  ]);

  return (
    <Box display={'grid'} gridGap={'4'} data-testid="dashboard-filters">
      {showSkeleton ? (
        <Box width={{ xs: '50%', md: '15%' }}>
          <Text.Skeleton width="80%" height="28px" />
        </Box>
      ) : (
        <Title as="h2">{t('title')}</Title>
      )}
      <DashboardFiltersContext.Provider value={context}>
        <Box display={'flex'} gap={'2-5'} justifyContent={'space-between'}>
          <SearchBar onChange={setQuery} />
          {showSkeleton ? (
            <Button.Skeleton data-testid="filter-button-skeleton" width="10%" />
          ) : (
            <Button
              onClick={() => {
                track('nuvempago_dashboard_filter_button_click');

                setOpen(!open);
              }}
              data-testid="filterButton"
            >
              <Icon source={<SlidersIcon />} />
              {isMobile ? <Box></Box> : t('filterButton')}
            </Button>
          )}
        </Box>
        <SideModalFilter showModal={open} setShowModal={setOpen} />
        <FilterChips />
      </DashboardFiltersContext.Provider>
    </Box>
  );
}

export default DashboardFilters;
