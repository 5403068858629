import React from 'react';
import { Tag, Text } from '@nimbus-ds/components';
import { ValidationStatusType } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
interface StatusLabelInterface {
  statusType: ValidationStatusType;
  useKycV2?: boolean;
}

function StatusLabel({
  statusType,
  useKycV2 = false,
}: StatusLabelInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('configuration.personalData');

  const pendingLabel = (
    <Tag appearance="warning" id={''}>
      <Text fontSize="caption">{t('pending.name')}</Text>
    </Tag>
  );

  const activeLabel = (
    <Tag appearance="success" id={''}>
      <Text fontSize="caption">{t('active.name')}</Text>
    </Tag>
  );

  const pendingReviewLabel = useKycV2 ? (
    <Tag appearance="neutral" id={''}>
      <Text fontSize="caption">{t('kycV2.pending_review.name')}</Text>
    </Tag>
  ) : (
    <Tag appearance="primary" id={''}>
      <Text fontSize="caption">{t('pending_review.name')}</Text>
    </Tag>
  );

  const pendingDocumentationLabel = (
    <Tag appearance="warning" id={''}>
      <Text fontSize="caption">{t('pending_documentation.name')}</Text>
    </Tag>
  );

  const deniedLabel = (
    <Tag appearance="danger" id={''}>
      <Text fontSize="caption">{t('denied.name')}</Text>
    </Tag>
  );

  const blockedLabel = (
    <Tag appearance="danger" id={''}>
      <Text fontSize="caption">{t('blocked.name')}</Text>
    </Tag>
  );

  const labels = {
    denied: deniedLabel,
    pending: pendingLabel,
    pending_review: pendingReviewLabel,
    pending_documentation: pendingDocumentationLabel,
    active: activeLabel,
    blocked: blockedLabel,
  };

  const labelToRender = labels[statusType];
  if (labelToRender === undefined)
    throw new Error(`Unknown status ${statusType}`);
  return labelToRender;
}

export default StatusLabel;
