import React from 'react';
import { Box, Skeleton, Text, Title } from '@nimbus-ds/components';

export interface BalanceCardInterface {
  title: string;
  balance: number | undefined;
  highlight?: boolean;
  children?: JSX.Element;
  onClick?: (event?: any) => void;
}

function BalanceCard({
  title,
  balance,
  children,
  highlight,
  onClick = () => null,
}: BalanceCardInterface): JSX.Element {
  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return (
    <Box
      borderColor={
        highlight ? 'primary-interactive' : 'neutral-surfaceHighlight'
      }
      backgroundColor={highlight ? 'primary-surface' : 'neutral-surface'}
      borderWidth={'1'}
      borderStyle={'solid'}
      borderRadius={'2'}
      padding={'4'}
      onClick={onClick}
      width={'100%'}
      data-testid="dashboard-balance-card"
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} flexDirection={'column'} gap={'1'}>
          <Box width={'100%'}>
            <Text
              as="p"
              color="primary-textHigh"
              fontWeight={'regular'}
              fontSize={'base'}
            >
              {title}
            </Text>
            <Title as="h4" textAlign="left" fontWeight={'medium'}>
              {amount.format((balance ?? 0) / 100)}
            </Title>
          </Box>
        </Box>
        {children}
      </Box>
    </Box>
  );
}

function BalanceCardSkeleton(): JSX.Element {
  return (
    <Skeleton
      width="100%"
      height="70px"
      borderRadius="8px"
      data-testid="dashboard-balance-card-skeleton"
    />
  );
}

BalanceCard.Skeleton = BalanceCardSkeleton;

export default BalanceCard;
