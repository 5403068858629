import React, { Suspense, useEffect, useState } from 'react';
import { ToastProvider } from '@nimbus-ds/components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  connect,
  getCurrentPathname,
  iAmReady,
} from '@tiendanube/nexo/helpers';
import ErrorBoundary from 'App/components/ErrorBoundary';
import nexo from 'App/nexoClient';
import Amplitude from './components/Amplitude/Amplitude';
import ContextWrapper from './ContextWrapper';
import i18nInit from './i18n';
import { queryClientConfig } from './queryClientConfig';
import ReduxProvider from './ReduxProvider';
import Routes from './Routes';
import '@nimbus-ds/styles/dist/index.css';
import './App.css';

i18nInit();

const queryClient = new QueryClient(queryClientConfig);

function App(): JSX.Element {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    connect(nexo).then(async () => {
      const pathname = await getCurrentPathname(nexo);
      const query = pathname?.split('?') ?? [pathname];

      if (pathname && query[0] !== window.location.pathname) {
        window.location.replace(`${window.location.origin}${pathname}`);
      }
      setReady(true);
      iAmReady(nexo);
    });
  }, []);

  if (!ready) return <></>;

  return (
    <Suspense fallback={null}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <ReduxProvider>
            <ToastProvider>
              <ContextWrapper>
                <Routes key="routes" />
              </ContextWrapper>
              <Amplitude key="amplitude" />
            </ToastProvider>
          </ReduxProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
