import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const datadogConfig = {
  clientToken: 'pub337bee7e316c05a308b2bca7c55d6b03',
  site: 'datadoghq.com',
  service: 'services-nuvem-pago-new-admin-app',
  env: process.env.REACT_APP_ENV,
  version: process.env.REACT_APP_VERSION,
  forwardErrorsToLogs: true,
  trackResources: true,
  trackLongTasks: true,
  useCrossSiteSessionCookie: true,
};

datadogRum.init({
  ...datadogConfig,
  applicationId: '66cb1380-336c-40b1-ac80-29bcc220f2f3',
  sessionSampleRate: 10,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogLogs.init({
  ...datadogConfig,
  sessionSampleRate: 100,
});

datadogRum.startSessionReplayRecording();
