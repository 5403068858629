import React from 'react';
import { Box, Card, Text, Thumbnail, Title } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { useStoreInfo } from 'commons/hooks';
import { Prices, ProductDetails } from 'commons/services/refundService';
import { formatCurrency, useTranslationWithPrefix } from 'commons/utils';
import OrderDetailCardSkeleton from './OrderDetailCardSkeleton';

interface OrderDetailCardInterface {
  orderId: number;
  prices: Prices;
  products: ProductDetails[];
  coupon?: string;
}

function OrderDetailCard({
  orderId,
  prices,
  products,
  coupon,
}: OrderDetailCardInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('refund.orderDetailCard');
  const { storeInfo } = useStoreInfo();

  return (
    <Card padding="none">
      <Card.Header padding="base">
        <Title as="h3">{t('title', { orderId })}</Title>
      </Card.Header>
      <Card.Body padding="none">
        <DataList bottomDivider={false}>
          {products.map((product) => (
            <DataList.Row
              key={product.sku}
              gap="1"
              topDivider={false}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="row" gap="2">
                <Thumbnail
                  src={product.image}
                  alt={`${product.name}-img`}
                  width="72px"
                />
                <Box display="flex" flexDirection="column" gap="2">
                  <Text fontWeight="medium" fontSize="highlight">
                    {product.name}
                  </Text>
                  <Text color="neutral-textDisabled">
                    {t('sku', { sku: product.sku })}
                  </Text>
                  <Text fontSize="highlight">
                    {t('productAmount', {
                      amount: product.quantity,
                      price: formatCurrency(product.unitPrice, storeInfo),
                    })}
                  </Text>
                </Box>
              </Box>
              <Text fontSize="highlight">
                {formatCurrency(product.totalPrice, storeInfo)}
              </Text>
            </DataList.Row>
          ))}
          <DataList.Row
            paddingY="2"
            topDivider={false}
            backgroundColor="neutral-surface"
          >
            <Box
              display="flex"
              flexDirection="row"
              gap="2"
              justifyContent="space-between"
            >
              <Text>{t('subtotal')}</Text>
              <Text>{formatCurrency(prices.subtotal, storeInfo)}</Text>
            </Box>
          </DataList.Row>
          <DataList.Row paddingY="2" topDivider={false}>
            <Box
              display="flex"
              flexDirection="row"
              gap="2"
              justifyContent="space-between"
            >
              <Text>{t('shipping')}</Text>
              <Text>{formatCurrency(prices.shipping, storeInfo)}</Text>
            </Box>
          </DataList.Row>
          {prices.discount && prices.discount.value > 0 && (
            <DataList.Row paddingY="2" topDivider={false}>
              <Box
                display="flex"
                flexDirection="row"
                gap="2"
                justifyContent="space-between"
              >
                <Text>
                  {coupon ? t('couponDiscount', { coupon }) : t('discount')}
                </Text>
                <Text color="danger-textLow">
                  -{formatCurrency(prices.discount, storeInfo)}
                </Text>
              </Box>
            </DataList.Row>
          )}
          {!!prices.totalRefundedAmount.value && (
            <DataList.Row paddingY="2" topDivider={false}>
              <Box
                display="flex"
                flexDirection="row"
                gap="2"
                justifyContent="space-between"
              >
                <Text>{t('previousRefund')}</Text>
                <Text>
                  -{formatCurrency(prices.totalRefundedAmount, storeInfo)}
                </Text>
              </Box>
            </DataList.Row>
          )}
          <DataList.Row topDivider={false} backgroundColor="neutral-surface">
            <Box
              display="flex"
              flexDirection="row"
              gap="2"
              justifyContent="space-between"
            >
              <Text fontWeight="bold">{t('total')}</Text>
              <Text fontWeight="bold">
                {formatCurrency(prices.total, storeInfo)}
              </Text>
            </Box>
          </DataList.Row>
        </DataList>
      </Card.Body>
    </Card>
  );
}

OrderDetailCard.Skeleton = OrderDetailCardSkeleton;

export default OrderDetailCard;
