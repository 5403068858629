import React from 'react';
import { Alert } from '@nimbus-ds/components';
import { useStoreInfo } from 'commons/hooks';
import { TransactionStatusType } from 'commons/types';
import { formatPaymentDate, useTranslationWithPrefix } from 'commons/utils';

interface InterfacePaymentAlert {
  skeleton?: boolean;
  status?: TransactionStatusType;
  date?: string;
  settlementPeriod?: number;
}

function PaymentAlert({
  skeleton = false,
  date,
  settlementPeriod,
  status,
}: InterfacePaymentAlert): JSX.Element {
  const { t } = useTranslationWithPrefix('transactionDetails.paymentAlert');
  const { storeInfo } = useStoreInfo();
  const datesToRefund = 15;

  const statusApproved = status === 'paid';
  const statusRefund = status === 'refunded';
  const statusPartiallyRefund = status === 'partially_refunded';
  const statusChargeback =
    status === 'chargebacked' || status === 'chargeback_dispute';

  if (skeleton) return <></>;

  if (statusApproved)
    return (
      <Alert>
        {t('approved', {
          date:
            Number.isInteger(settlementPeriod) &&
            date &&
            formatPaymentDate(
              date,
              settlementPeriod as number,
              storeInfo.language,
            ),
        })}
      </Alert>
    );

  if (statusChargeback)
    return (
      <Alert show>
        {t('chargeback', {
          date:
            date && formatPaymentDate(date, datesToRefund, storeInfo.language),
        })}
      </Alert>
    );

  if (statusRefund || statusPartiallyRefund)
    return (
      <Alert show>
        {t(statusRefund ? 'refund' : 'partiallyRefund', {
          date: date && formatPaymentDate(date, 0, storeInfo.language),
        })}
      </Alert>
    );

  return <></>;
}

export default PaymentAlert;
