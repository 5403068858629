import axios from 'App/axios';
import { FIPValidatorFields } from '../../types/FIPValidatorFields.enum';
import { RecipientType } from '../../types/RecipientType.enum';

export interface MissingInfoResponse {
  readonly identityId: string;
  readonly document: string;
  readonly recipientType: RecipientType;
  readonly undefinedParents: FIPValidatorFields[];
  readonly undefinedFields: string[];
}

export async function getMissingInfo(): Promise<MissingInfoResponse> {
  const { data } = await axios.get<MissingInfoResponse>(
    '/admin/merchants/missing-info',
  );

  return data;
}

export async function patchMissingInfo(
  identityId: string,
  payload: Record<string, any>,
): Promise<any> {
  const { data } = await axios.patch<any>(
    `/admin/identities/${identityId}`,
    payload,
  );

  return data;
}

export default {
  getMissingInfo,
  patchMissingInfo,
};
