import {
  InstallmentsFeeResponsible,
  MoneyObjectInterface,
} from 'commons/types';
import { formatCurrency } from 'commons/utils';
import { TFunction } from 'i18next';
import { IStoreInfo } from 'redux/slices/types';
import { getInstallmentPlan } from './getInstallmentPlan';

export function getInstallmentsText(
  t: TFunction,
  storeInfo: IStoreInfo,
  installments?: number,
  installmentsValue?: MoneyObjectInterface,
  installmentsPlan?: string,
  installmentsFeeResponsible?: InstallmentsFeeResponsible,
): string {
  const value = installmentsValue
    ? formatCurrency(installmentsValue, storeInfo)
    : '';

  if (installments === 1) {
    return t('oneInstallment', { value });
  }

  const installmentsText = t('installments', {
    quantity: installments,
    value: installmentsValue
      ? formatCurrency(installmentsValue, storeInfo)
      : '',
  });

  const installmentPlanText = getInstallmentPlan(
    t,
    installmentsFeeResponsible,
    installmentsPlan,
  );
  const planText = installmentPlanText
    ? t('withPlan', { installmentPlan: installmentPlanText })
    : '';

  return `${installmentsText}${planText}`;
}
