import React from 'react';
import { Button, Modal, Text } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

interface Props {
  amount: number;
  openConfirmationModal: boolean;
  handleOpenConfirmation: (value: boolean) => void;
  actionCallback: () => void;
}

function ConfirmationModal({
  amount,
  handleOpenConfirmation,
  openConfirmationModal,
  actionCallback,
}: Props): JSX.Element {
  const { t } = useTranslationWithPrefix('refund');

  const formatAmount = (amount: number) =>
    (Math.round(amount) / 100).toFixed(2).replace('.', ',');

  const title = t('confirmationModal.title_with_amount', {
    amount: formatAmount(amount),
  });
  const message = t('confirmationModal.message_refund');
  const buttonText = t('confirmationModal.refund');

  return (
    <Modal
      maxWidth={{
        xs: '343px',
        md: '343px',
        lg: '500px',
        xl: '500px',
      }}
      data-testid="Confirmation-Modal"
      open={openConfirmationModal}
      onDismiss={handleOpenConfirmation}
    >
      <Modal.Header title={title} />
      <Modal.Body padding="none">
        <Text as="p" fontSize="base" lineHeight="base" textAlign="left">
          {message}
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="Handle-Refund"
          appearance="primary"
          onClick={actionCallback}
        >
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
