import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ISetSettingsActionArgs, ISettingsState } from './types';

const hideReportPopoverKey: Keys = 'hideReportPopover';
const hideBlockedFreemiumModalKey: Keys = 'hideBlockedFreemiumModal';

type Keys = keyof ISettingsState;

const getFromLocalStorage = (key: Keys): boolean | null => {
  try {
    const value = localStorage.getItem(key);
    if (value) return JSON.parse(value);
    return null;
  } catch (e) {
    /* eslint-disable-next-line no-console */
    console.warn('localStorage not available', e);
    return null;
  }
};

const setFromLocalStorage = (key: Keys, value: boolean): void => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    /* eslint-disable-next-line no-console */
    console.warn('set localStorage not available', e);
  }
};

export const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState: (): ISettingsState => {
    const hideReportPopover = getFromLocalStorage(hideReportPopoverKey);
    const hideBlockedFreemiumModal = getFromLocalStorage(
      hideBlockedFreemiumModalKey,
    );

    return {
      hideReportPopover: hideReportPopover ?? false,
      hideBlockedFreemiumModal: hideBlockedFreemiumModal ?? false,
    };
  },
  reducers: {
    setHideReportPopover: (
      state,
      action: PayloadAction<ISetSettingsActionArgs>,
    ): void => {
      setFromLocalStorage(
        hideReportPopoverKey,
        action.payload.newSpecificSettingChange,
      );
      state.hideReportPopover = action.payload.newSpecificSettingChange;
    },
    setHideBlockedFreemiumModal: (
      state,
      action: PayloadAction<ISetSettingsActionArgs>,
    ): void => {
      setFromLocalStorage(
        hideBlockedFreemiumModalKey,
        action.payload.newSpecificSettingChange,
      );
      state.hideBlockedFreemiumModal = action.payload.newSpecificSettingChange;
    },
  },
});

export const { setHideReportPopover, setHideBlockedFreemiumModal } =
  settingsSlice.actions;

export default settingsSlice.reducer;
