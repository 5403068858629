/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { track } from '@amplitude/analytics-browser';
import { Box, Button, Link, Text, Title } from '@nimbus-ds/components';
import { CogIcon } from '@nimbus-ds/icons';
import { goToOldAdmin } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { Notification } from 'commons/components';
import { useIsMobile, useStoreInfo } from 'commons/hooks';
import {
  FeatureFlag,
  featureFlagService,
  NotificationTypes,
} from 'commons/services';
import dashboardService, {
  KycInfo,
} from 'commons/services/dashboardService/dashboard.service';
import { useTranslationWithPrefix } from 'commons/utils';
import StatusFilterEnum from 'domains/Brazil/commons/types/TransactionStatusFilterEnum';
import TypeFilterEnum from 'domains/Brazil/commons/types/TransactionTypeFilterEnum';
import MobileTopNavBar from '../commons/components/MobileTopNavBar';
import NuvemPagoCta from '../commons/components/NuvemPagoCta';
import DateRangeFilterEnum from '../commons/types/DateRangeFilterEnum';
import Notifications from '../Notifications/Notification';
import { NotificationType } from '../Notifications/Notification.context';
import BalanceCards from './components/balance/DashboardCards';
import DashboardFilters from './components/filter/DashboardFilters';
import DashboardFiltersContext from './components/filter/DashboardFiltersContext';
import DashboardFiltersV2 from './components/filter-v2/DashboardFiltersV2';
import DisableNotification from './components/kyc/DisableNotification';
import KycModal from './components/kyc/KycModal';
import DashboardList, { filters } from './components/list/DashboardList';
import DashboardListV2, {
  filters as filtersV2,
} from './components/list-v2/DashboardList';
import DashboardListMobile from './components/list-v2/mobile/DashboardListMobile';
import { filterObject } from './components/search/SearchBar';
import DashboardContext from './Dashboard.context';

function Dashboard(): JSX.Element {
  const [filters, setFilters] = useState<filters & filterObject>();
  const [filtersV2, setFiltersV2] = useState<filtersV2 & filterObject>();
  const [kycInfo, setKycInfo] = useState<KycInfo>();
  const [isLoadingBalance, setLoadingBalance] = useState<boolean>(true);
  const [isLoadingKyc, setLoadingKyc] = useState<boolean>(true);
  const [isLoadingTransactions, setLoadingTransactions] =
    useState<boolean>(true);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true);
  const { storeInfo } = useStoreInfo();
  const [hasTransactionWithoutFilters, setHasTransactionWithoutFilters] =
    useState<boolean>(false);
  const [isKycRejectNotificationByCommons, setKycRejectNotificationByCommons] =
    useState(false);
  const [shouldUseDashboardV2, setDashboardV2] = useState(false);
  const [typeFilter, setTypeFilter] = useState<TypeFilterEnum>(
    TypeFilterEnum.ALL,
  );
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRangeFilterEnum>(
    DateRangeFilterEnum.ALL,
  );
  const [statusFilter, setStatusFilter] = useState<StatusFilterEnum>(
    StatusFilterEnum.ALL,
  );
  const [fromDateFilter, setFromDateFilter] = useState<string | undefined>();
  const [toDateFilter, setToDateFilter] = useState<string | undefined>();
  const [customSearchFilter, setCustomSearchFilter] = useState<
    string[] | undefined
  >();
  const [page, setPage] = useState<number>(1);

  const filtersContext = useMemo(() => {
    return {
      dateRange: dateRangeFilter,
      fromDate: fromDateFilter,
      toDate: toDateFilter,
      setDateRange: setDateRangeFilter,
      setFromDate: setFromDateFilter,
      setStatus: setStatusFilter,
      setToDate: setToDateFilter,
      setType: setTypeFilter,
      status: statusFilter,
      type: typeFilter,
      customSearch: customSearchFilter,
      setCustomSearch: setCustomSearchFilter,
      page,
      setPage,
      itemsPerPage: 10,
    };
  }, [
    dateRangeFilter,
    fromDateFilter,
    statusFilter,
    toDateFilter,
    typeFilter,
    customSearchFilter,
    page,
    setPage,
  ]);

  useEffect(() => {
    featureFlagService
      .isFeatureFlagEnabled(
        FeatureFlag.FS_NUVEMPAGO_KYC_REJECT_NOTIFICATION_BY_COMMONS,
      )
      .then(setKycRejectNotificationByCommons)
      .catch(() => setKycRejectNotificationByCommons(false));

    track('nuvem_pago_management_painel');
  }, []);

  useEffect(() => {
    featureFlagService
      .isFeatureFlagEnabled(
        FeatureFlag.FS_NUVEMPAGO_DASHBOARD_DATA_FROM_COMMONS,
      )
      .then(setDashboardV2)
      .catch(() => setDashboardV2(false));
  }, []);

  const context = useMemo(() => {
    return {
      showSkeleton,
      setShowSkeleton,
      isLoadingBalance,
      setLoadingBalance,
      isLoadingKyc,
      setLoadingKyc,
      isLoadingTransactions,
      setLoadingTransactions,
      hasTransactionWithoutFilters,
      setHasTransactionWithoutFilters,
    };
  }, [
    hasTransactionWithoutFilters,
    isLoadingBalance,
    isLoadingKyc,
    isLoadingTransactions,
    showSkeleton,
  ]);

  const { t } = useTranslationWithPrefix('dashboard');
  const isMobile = useIsMobile();

  const goToNuvemPagoSettings = useCallback(() => {
    track('nuvempago_dashboard_settings_button_click');

    goToOldAdmin(nexo, '/payments');
  }, []);

  const [isRefundPartialEnabled, setIsRefundPartialEnabled] =
    useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const refundPartialIsEnabled =
        await featureFlagService.isFeatureFlagEnabled(
          FeatureFlag.FS_NUVEMPAGO_REFUND_PARTIAL_ROLLOUT,
        );
      setIsRefundPartialEnabled(refundPartialIsEnabled);
    };

    fetchData();
  }, [storeInfo.id, isRefundPartialEnabled]);

  useEffect(() => {
    dashboardService
      .getKycInfo()
      .then(setKycInfo)
      .finally(() => {
        setLoadingKyc(false);
      });
  }, []);

  useEffect(() => {
    const shouldShowSkeleton =
      isLoadingBalance || isLoadingTransactions || isLoadingKyc;
    setShowSkeleton(shouldShowSkeleton);
  }, [isLoadingBalance, isLoadingTransactions, isLoadingKyc]);

  const KycNotification = useCallback((): JSX.Element => {
    if (kycInfo === undefined) return <></>;

    if (
      !kycInfo?.approvedOnKyc &&
      !kycInfo?.blocked &&
      hasTransactionWithoutFilters
    ) {
      return (
        <KycModal
          title={t('components.kycModal.withdrawBlocked.title')}
          description={t('components.kycModal.withdrawBlocked.contentA')}
          content={t('components.kycModal.withdrawBlocked.contentB')}
        />
      );
    }
    if (
      !isKycRejectNotificationByCommons &&
      !kycInfo?.approvedOnKyc &&
      kycInfo?.blocked &&
      hasTransactionWithoutFilters
    ) {
      return (
        <KycModal
          title={t('components.kycModal.kycRejected.title')}
          description={t('components.kycModal.kycRejected.contentA')}
          content={t('components.kycModal.kycRejected.contentB')}
        />
      );
    }

    return <Box />;
  }, [
    hasTransactionWithoutFilters,
    isKycRejectNotificationByCommons,
    kycInfo,
    t,
  ]);

  return (
    <DashboardContext.Provider value={context}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        padding="4"
        gap="4"
        minHeight="100vh"
        backgroundColor={isMobile ? 'neutral-background' : 'neutral-surface'}
        data-testid="Dashboard-nuvem-pago"
      >
        <MobileTopNavBar padding="none">
          {isMobile && (
            <Link
              textDecoration="none"
              onClick={goToNuvemPagoSettings}
              data-testid={'config-button'}
            >
              <Text color="neutral-textHigh">{t('configure')}</Text>
            </Link>
          )}
        </MobileTopNavBar>
        {showSkeleton ? (
          <Box
            data-testid="config-button-skeleton"
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Title.Skeleton />
            <Button.Skeleton />
          </Box>
        ) : (
          <Box display={'inline-flex'} justifyContent={'space-between'}>
            <Title>{t('title')}</Title>
            {!isMobile && (
              <Button
                onClick={goToNuvemPagoSettings}
                data-testid={'config-button'}
              >
                {t('configure')}
                <CogIcon />
              </Button>
            )}
          </Box>
        )}
        {!isKycRejectNotificationByCommons && kycInfo?.blocked && (
          <DisableNotification />
        )}
        <Notification
          listeningToTypes={[
            NotificationTypes.WithdrawalRefused,
            NotificationTypes.WithdrawalProcessing,
            NotificationTypes.WithdrawalCreated,
            NotificationTypes.WithdrawalCanceled,
            NotificationTypes.WithdrawBlockedByRisk,
            NotificationTypes.WithdrawBlockedByLending,
            NotificationTypes.WithdrawUnblockedByLending,
            NotificationTypes.LendingInstallmentWillOverdue,
            NotificationTypes.LendingInstallmentDueToday,
            NotificationTypes.LendingInstallmentOverdue,
            NotificationTypes.LivenessPending,
            NotificationTypes.LivenessInAnalysis,
            NotificationTypes.LivenessApproved,
            NotificationTypes.LivenessPartiallyDenied,
            NotificationTypes.KycDeniedNewCohort,
            NotificationTypes.KycDeniedMerchantBase,
            NotificationTypes.ManualEnrichment,
          ]}
        />
        <Notifications
          listenToNotificationTypes={[NotificationType.WithdrawalFailed]}
        />
        <DashboardFiltersContext.Provider value={filtersContext}>
          <Box>
            <BalanceCards
              kycInfo={
                kycInfo ?? { isAllowedToWithdraw: false, approvedOnKyc: false }
              }
            />

            {hasTransactionWithoutFilters &&
              (shouldUseDashboardV2 ? (
                <DashboardFiltersV2 onFilterChange={setFiltersV2} />
              ) : (
                <DashboardFilters onFilterChange={setFilters} />
              ))}
          </Box>
          <Box>
            {shouldUseDashboardV2 ? (
              isMobile ? (
                <DashboardListMobile
                  filters={filtersV2}
                  isRefundPartial={isRefundPartialEnabled}
                />
              ) : (
                <DashboardListV2
                  filters={filtersV2}
                  isRefundPartial={isRefundPartialEnabled}
                />
              )
            ) : (
              <DashboardList
                filters={filters}
                isRefundPartial={isRefundPartialEnabled}
                data-testid="dashboard-list"
              />
            )}
          </Box>
        </DashboardFiltersContext.Provider>
        <KycNotification />
        {!isMobile && (
          <Box justifyContent={'center'} display={'grid'}>
            {showSkeleton ? (
              <NuvemPagoCta.Skeleton />
            ) : (
              <NuvemPagoCta
                data-testid="cta-skeleton"
                trackingTag="nuvempago_dashboard_can_link_click"
                href={
                  'https://atendimento.nuvemshop.com.br/pt_BR/informacoes-gerais-nuvem-pago/conheca-o-painel-do-nuvem-pago'
                }
                title={t('cta')}
              />
            )}
          </Box>
        )}
      </Box>
    </DashboardContext.Provider>
  );
}

export default Dashboard;
