import { useToast as nimbusUseToast } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';
import { IUseToast } from './types';

function useToast(): IUseToast {
  const { addToast } = nimbusUseToast();
  const { t } = useTranslationWithPrefix('hooks.useToast');

  const showToastError = (text?: string) => {
    addToast({
      type: 'danger',
      text: text ?? t('error'),
      id: 'errorToast',
    });
  };

  const showToastSuccess = (text?: string) => {
    addToast({
      type: 'success',
      text: text ?? t('success'),
      id: 'successToast',
    });
  };

  return { showToastError, showToastSuccess };
}

export default useToast;
