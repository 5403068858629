import { Roles } from 'commons/components/Roles/Roles.enum';
import { useStoreInfo } from 'commons/hooks';

type useHasRoleProps = {
  role?: Roles;
  roles?: Roles[];
};

export function useHasRole({ role, roles = [] }: useHasRoleProps): boolean {
  const {
    storeInfo: { user },
  } = useStoreInfo();

  if (!user) return false;

  if (user.isOwner) return true;

  if (user.isEmployee) return true;

  if (user.roles.includes(Roles.FULL)) return true;

  if (role) {
    return user.roles.includes(role);
  }

  if (roles.length) {
    return roles.every((role) => user.roles.includes(role));
  }

  return false;
}
