import { track } from '@amplitude/analytics-browser';
import {
  dataDogLogger,
  DataDogLoggerActionTypes,
} from 'commons/utils/dataDogLogger';

export type EventCallback = (detail: any) => void | Promise<void>;

function log(message: string) {
  dataDogLogger.generate({
    actionMessage: message,
    actionOwner: 'ProofOfLifeIntegration',
    actionType: DataDogLoggerActionTypes.Info,
    actionData: {},
  });
}

export const setupEventHandlers = (
  verification: MetamapVerification,
  onFinish: EventCallback,
  onExit: EventCallback,
  onContinueOnAnotherDevice?: EventCallback,
) => {
  const eventHandlers = [
    {
      event: 'metamap:userStartedSdk',
      handler: () => {
        track('pago-nube-prueba-de-vida-usuario-comienza');
        log('User started proof of life verification');
      },
    },
    {
      event: 'metamap:userFinishedSdk',
      handler: ({ detail }: { detail: any }) => {
        track('pago-nube-prueba-de-vida-usuario-termina-prueba');
        log('User finished proof of life verification');
        onFinish(detail);
      },
    },
    {
      event: 'metamap:exitedSdk',
      handler: ({ detail }: { detail: any }) => {
        track('pago-nube-prueba-de-vida-usuario-sale');
        log('User exited proof of life verification');
        onExit(detail);
      },
    },
    {
      event: 'metamap:continuedOnAnotherDevice',
      handler: ({ detail }: { detail: any }) => {
        track('pago-nube-prueba-de-vida-usuario-contina-en-otro-dispositivo');
        log('User continued proof of life verification on another device');
        onContinueOnAnotherDevice?.(detail);
      },
    },
  ];

  eventHandlers.forEach(({ event, handler }) => {
    verification.on(event, handler);
  });

  return () => {
    eventHandlers.forEach(({ event, handler }) => {
      verification.off(event, handler);
    });
  };
};
