/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-key */

import React, { useCallback, useContext } from 'react';
import { useEffect, useState } from 'react';
import { Box, Table, Text } from '@nimbus-ds/components';
import { DataTable } from '@nimbus-ds/patterns';
import dashboardService, {
  ChargesDashboardTransaction,
} from 'commons/services/dashboardServiceV2/dashboard.service';
import {
  ApiFilter,
  filterBuilder,
} from 'commons/services/dashboardServiceV2/filter.builder';
import { useTranslationWithPrefix } from 'commons/utils';
import FilterStatus from 'domains/Brazil/commons/types/TransactionStatusFilterEnum';
import RenderOnError from '../../../commons/components/RenderOnError';
import DashboardContext, {
  DashboardContextArgs,
} from '../../Dashboard.context';
import DashboardFiltersContext from '../filter/DashboardFiltersContext';
import EmptyList from '../list/EmptyList';
import EmptyState from '../list/EmptyState';
import ListItem from '../list/ListItem';
import { filterObject } from '../search/SearchBar';
import PaginateTransactions from './PaginateTransactions';

function RenderOnLoad(props: { children: JSX.Element; onLoad: boolean }) {
  if (!props.onLoad) return props.children;

  return (
    <Box>
      <DashboardListDesktopSkeleton />
    </Box>
  );
}

export type filters = {
  date?: {
    from: string | undefined;
    to: string | undefined;
  };
  paymentMethod?: string;
  status?: FilterStatus;
  withOrderNumber?: boolean;
  count?: number;
  page?: number;
  itemsPerPage?: number;
  stringQuery?: string;
};

type dashboardListArgs = {
  filters?: filters & filterObject;
  isRefundPartial: boolean;
};

function DashboardList({
  filters,
  isRefundPartial,
}: dashboardListArgs): JSX.Element {
  const [listItens, setListItens] = useState<ChargesDashboardTransaction[]>([]);
  const [errorOnLoad, setError] = useState<boolean>(false);
  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);
  const [isLoadingMoreItems, setLoadingMoreItems] = useState<boolean>(false);
  const [itemsCount, setItemsCount] = useState<number>(0);
  const { itemsPerPage } = useContext(DashboardFiltersContext);

  const {
    showSkeleton,
    setLoadingTransactions,
    setHasTransactionWithoutFilters,
  }: DashboardContextArgs = useContext(DashboardContext);

  const { t } = useTranslationWithPrefix('dashboard.errorHandler');

  const fetch = useCallback(
    (
      setCallback: (transactions: ChargesDashboardTransaction[]) => void,
      apiFilters: Partial<ApiFilter>,
    ) => {
      setLoadingMoreItems(true);
      dashboardService
        .getTransactions(apiFilters)
        .then((response) => {
          if (response.transactions.length) {
            setHasTransactionWithoutFilters(true);
          }
          setItemsCount(response.itemsCount);

          setCallback(response.transactions);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoadingFilters(false);
          setLoadingTransactions(false);
          setLoadingMoreItems(false);
        });
    },
    [setHasTransactionWithoutFilters, setLoadingTransactions, setItemsCount],
  );

  const refreshHandler = useCallback(() => {
    setError(false);
    fetch(setListItens, { page: 1, items: itemsPerPage });
  }, [fetch, itemsPerPage]);

  useEffect(() => {
    let apiFilters;
    if (filters) apiFilters = filterBuilder(filters);
    if (apiFilters) fetch(setListItens, apiFilters);
  }, [fetch, filters]);

  const RenderEmptyIfNoTransactions = useCallback(
    (props: {
      listItens: ChargesDashboardTransaction[];
      itemsCount: number;
      itemsPerPage: number;
    }): JSX.Element => {
      if (isLoadingMoreItems) return <DashboardListDesktopSkeleton />;
      if (props.listItens.length) {
        return (
          <PaginateTransactions
            listItens={props.listItens}
            itemsCount={props.itemsCount}
            isRefundPartial={isRefundPartial}
          />
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { page, itemsPerPage, ...restFilters } = filters || {};
      const hasNoFilters = Object.keys(restFilters).length === 0;
      return hasNoFilters ? <EmptyState /> : <EmptyList />;
    },
    [filters, isRefundPartial, isLoadingMoreItems],
  );

  return (
    <Box data-testid="dashboard-list-v2">
      <RenderOnError
        message={t('message')}
        label={t('label')}
        error={errorOnLoad}
        refreshHandler={refreshHandler}
      >
        <RenderOnLoad onLoad={showSkeleton || loadingFilters}>
          <RenderEmptyIfNoTransactions
            listItens={listItens}
            itemsCount={itemsCount}
            itemsPerPage={itemsPerPage}
          />
        </RenderOnLoad>
      </RenderOnError>
    </Box>
  );
}

function DashboardListDesktopSkeleton(): JSX.Element {
  return (
    <Box
      width={'fill'}
      height={'hug'}
      display={'grid'}
      justifyContent={'center'}
      alignItems={'center'}
      data-testid="Dashboard-List-Skeleton"
    >
      <DataTable header={<TableHeaderSkeleton />}>
        {GenerateSkeletonList(ListItem.Skeleton)}
      </DataTable>
    </Box>
  );
}

function GenerateSkeletonList(
  SkeletonComponent: () => JSX.Element,
  count = 10,
): JSX.Element[] {
  const itensMap: JSX.Element[] = [];
  for (let i = 0; i < count; i++) {
    itensMap.push(<SkeletonComponent key={`list-item-skeleton:${i}`} />);
  }
  return itensMap;
}

function TableHeaderSkeleton(): JSX.Element {
  return (
    <Table.Head>
      <Table.Row width={'fill'}>
        <DataTable.Cell width={'10%'}>
          <Text.Skeleton width="90%" height="20px" />
        </DataTable.Cell>
        <DataTable.Cell width={'6%'}>
          <Text.Skeleton width="90%" height="20px" />
        </DataTable.Cell>
        <DataTable.Cell width={'22%'}>
          <Text.Skeleton width="50%" height="20px" />
        </DataTable.Cell>
        <DataTable.Cell width={'12%'}>
          <Text.Skeleton width="80%" height="20px" />
        </DataTable.Cell>
        <DataTable.Cell width={'15%'}>
          <Text.Skeleton width="60%" height="20px" />
        </DataTable.Cell>
        <DataTable.Cell width={'20%'}>
          <Text.Skeleton width="40%" height="20px" />
        </DataTable.Cell>
      </Table.Row>
    </Table.Head>
  );
}

export default DashboardList;
