import axios from 'App/axios';
import { TypeTag } from 'domains/Brazil/commons/components/StatusTag';
import { CustomerType } from 'domains/Brazil/commons/types/CustomerType.enum';
import { DocumentType } from 'domains/Brazil/commons/types/DocumentType.enum';
import { PaymentMethod } from 'domains/Brazil/commons/types/PaymentMethod.enum';
import { TransactionDetailsType } from 'domains/Brazil/commons/types/TransactionsDetailsType';
import { DashboardTransaction } from './dashboard.service';
import { PagarmeStatusTranslation } from './pagarme-status-translation.service';

const transform = (data: DashboardTransaction): TransactionDetailsType => {
  const status = PagarmeStatusTranslation(data.status, data.rejectedBy);
  const documentType = data.customer.documentType as DocumentType;
  const customerType =
    documentType === DocumentType.CPF
      ? CustomerType.Individual
      : CustomerType.Company;
  const failureCode =
    status === TypeTag.RejectedBySecurity
      ? 'card_rejected_by_security'
      : data.failureCode;
  const orderNumber =
    data.orderNumber && data.orderNumber > 0 ? `${data.orderNumber}` : '---';

  return {
    status,
    orderNumber,
    id: data.id,
    orderId: data.orderId,
    createdAt: data.createdAt,
    customer: {
      ...data.customer,
      documentType,
      phone: data.customer.phones[0] ?? undefined,
      type: customerType,
    },
    payment: {
      failureCode,
      paid: data.adjustedAmounts.paid,
      fee: data.adjustedAmounts.fee,
      cancelFee: data.adjustedAmounts.cancelFee,
      refunded: data.adjustedAmounts.refunded,
      total: data.adjustedAmounts.total,
      consumerInterest: data.adjustedAmounts.consumerInterest,
      paymentMethod: data.paymentMethod as PaymentMethod,
      installments: data.installments ?? 1,
      /** @todo add card number to transaction summary */
      cardNumber: undefined,
      /** @todo add card brand to transaction summary */
      brand: undefined,
      /** @todo add card holder name to transaction summary */
      holderName: undefined,
      /** @todo add card holder document to transaction summary */
      document: undefined,
    },
  };
};

const getDetails = async (
  transactionId: string,
): Promise<TransactionDetailsType> => {
  const { data } = await axios.get<DashboardTransaction>(
    `/dashboard/activities/${transactionId}`,
  );

  return transform(data);
};

const transactionsService = {
  getDetails,
};

export default transactionsService;
