import React from 'react';
import { Box, Card, Title } from '@nimbus-ds/components';
import { ErrorMessage } from 'commons/components';
import { useBankAccount, useIdentityValidation } from 'commons/hooks';
import useShowProofOfLifeErrorAlert from 'commons/hooks/useShowProofOfLifeErrorAlert';
import { useTranslationWithPrefix } from 'commons/utils';
import NewIdentityAlert from './IdentityAlert/NewIdentityAlert';
import PendingProofOfLifeAlert from './IdentityAlert/NewIdentityAlert/PendingProofOfLifeAlert';
import BankAccount from './BankAccount';
import FiscalDocument from './FiscalDocument';
import IdentityAlert from './IdentityAlert';
import PersonalDataSkeleton from './PersonalDataSkeleton';
import StatusLabel from './StatusLabel';

function PersonalData() {
  const { t } = useTranslationWithPrefix('configuration.personalData');
  const identityValidation = useIdentityValidation();
  const bankAccount = useBankAccount();
  const proofOfLifeErrorAlert = useShowProofOfLifeErrorAlert();

  if (bankAccount.isLoading || identityValidation.isLoading) {
    return <PersonalDataSkeleton />;
  }
  if (bankAccount.isError || identityValidation.isError)
    return (
      <Card>
        <Card.Header>
          <Title as="h4">{t('cardTitle')}</Title>
        </Card.Header>
        <ErrorMessage />
      </Card>
    );

  const bankName = bankAccount.data.bankName;
  const beneficiaryName = bankAccount.data.holderName;
  const beneficiaryCbu = bankAccount.data.accountNumber;
  const isRegistered = bankAccount.data.isRegistered;
  const fiscalNumber = bankAccount.data.fiscalDocument;
  const validationStatus = identityValidation.data.status;
  const validationUpdatedAt = identityValidation.data.updatedAt || '';
  const useKycV2 = identityValidation.data.useKycV2;

  const showPendingProofOfLifeAlert = proofOfLifeErrorAlert.show;

  const NewIdAlert = () => {
    return showPendingProofOfLifeAlert ? (
      <PendingProofOfLifeAlert
        proofOfLifeUrl={proofOfLifeErrorAlert.proofOfLifeUrl}
        errorCode={proofOfLifeErrorAlert.errorCode}
      />
    ) : (
      <NewIdentityAlert
        identityStatus={validationStatus}
        updatedAt={validationUpdatedAt}
        kycDocumentsErrors={identityValidation.data.kycDocumentErrors}
      />
    );
  };

  return (
    <Card>
      <Card.Header>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Title as="h4">{t('cardTitle')}</Title>
          {showPendingProofOfLifeAlert ? null : (
            <StatusLabel statusType={validationStatus} useKycV2={useKycV2} />
          )}
        </Box>
      </Card.Header>
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="6">
        {useKycV2 ? (
          NewIdAlert()
        ) : (
          <IdentityAlert
            identityStatus={validationStatus}
            updatedAt={validationUpdatedAt}
          />
        )}
        <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
          <FiscalDocument fiscalNumber={fiscalNumber} />
          <BankAccount
            isRegistered={isRegistered}
            bankName={bankName}
            beneficiaryName={beneficiaryName}
            beneficiaryCbu={beneficiaryCbu}
          />
        </Box>
      </Box>
    </Card>
  );
}

PersonalData.displayName = 'PersonalData';

export default PersonalData;
