import React, { createContext, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { ROUTES } from 'App/Routes/constants';
import { useFeatureDiscovery } from 'commons/hooks';
import { FeatureName } from 'commons/services/featureDiscoveryService/featureDiscoveryServiceResponses';
import { OnBoardingSteps } from './OnBoardingSteps';

interface HomeMockContextInterface {
  step: number;
  nextStep: () => void;
  finishOnBoarding: () => void;
  closeOnBoarding: () => void;
  isMutating: boolean;
}

export const HomeMockContext = createContext<HomeMockContextInterface>({
  step: 1,
  nextStep: () => undefined,
  finishOnBoarding: () => undefined,
  closeOnBoarding: () => undefined,
  isMutating: false,
});

interface InterfaceHomeMockProvider {
  children: React.ReactNode;
  initialStep?: OnBoardingSteps;
}

function HomeMockProvider({
  children,
  initialStep = OnBoardingSteps.Transfer,
}: InterfaceHomeMockProvider): JSX.Element {
  const [step, setStep] = useState<OnBoardingSteps>(initialStep);
  const { isMutating, markAsDiscovered } = useFeatureDiscovery(
    FeatureName.OnBoarding,
  );
  const history = useHistory();

  const nextStep = useCallback(() => {
    track(`nuvem-pago-onboardingstep${step}-click`);
    setStep((current) => current + 1);
  }, [step]);

  const finishOnBoarding = useCallback(() => {
    track(`nuvem-pago-onboardingstep4-click`);
    markAsDiscovered.mutate(undefined, {
      onSuccess: () =>
        history.push(ROUTES.CONFIG, { hideOnBoardingModal: true }),
    });
  }, [history, markAsDiscovered]);

  const closeOnBoarding = useCallback(() => {
    track(`nuvem-pago-onboarding-close`);
    markAsDiscovered.mutate(undefined, {
      onSuccess: () => history.push(ROUTES.HOME),
    });
  }, [history, markAsDiscovered]);

  const values = useMemo(
    () => ({
      step,
      nextStep,
      finishOnBoarding,
      closeOnBoarding,
      isMutating,
    }),
    [step, nextStep, finishOnBoarding, closeOnBoarding, isMutating],
  );

  return (
    <HomeMockContext.Provider value={values}>
      {children}
    </HomeMockContext.Provider>
  );
}

export default HomeMockProvider;
