import React from 'react';
import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { PaymentMethodsType } from 'commons/types';
import { capitalize, useTranslationWithPrefix } from 'commons/utils';
import {
  documentAnonimize,
  documentType,
} from 'domains/Brazil/commons/utils/document';
import {
  isCreditCard,
  isPix,
} from 'domains/Brazil/commons/utils/paymentMethod';

export interface PaymentCardInterface {
  holder_name: string;
  name: string;
  document: string;
  last_four_digits?: string;
  brand?: string;
  installments: number;
  paymentMethod: PaymentMethodsType;
}

export function PaymentCard({
  holder_name,
  name,
  document,
  last_four_digits,
  brand,
  installments,
  paymentMethod,
}: Partial<PaymentCardInterface>): JSX.Element {
  const { t } = useTranslationWithPrefix('refund.paymentCard');
  const { t: paymentMethodsT } = useTranslationWithPrefix('paymentMethods');

  return (
    <Card data-testid="PaymentCard">
      <Card.Header color={'neutral-textHigh'} title={t('title')} />
      <Card.Body>
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          alignSelf={'stretch'}
          flexDirection={'column'}
          gap="2"
        >
          <Text
            fontSize="base"
            fontWeight={'medium'}
            color={'neutral-textLow'}
            textAlign="left"
            data-testid="PayerName"
          >
            {holder_name ?? name}
          </Text>
          {paymentMethod && isPix(paymentMethod) && (
            <Text
              fontSize="base"
              fontWeight={'regular'}
              color={'neutral-textLow'}
              textAlign="left"
              data-testid="PaymentMethod"
            >
              {paymentMethodsT(paymentMethod)}
            </Text>
          )}
          <Text
            fontSize="base"
            fontWeight={'regular'}
            color={'neutral-textLow'}
            textAlign="left"
            data-testid="PayerDocument"
          >
            {t('document', {
              type: documentType(document ?? '').toUpperCase(),
              document: documentAnonimize(document ?? ''),
              interpolation: { escapeValue: false },
            })}
          </Text>
          {isCreditCard(paymentMethod ?? '') && (
            <>
              <Text
                fontSize="base"
                fontWeight={'regular'}
                color={'neutral-textLow'}
                textAlign="left"
                data-testid="PaymentCardData"
              >
                {t('brand', {
                  brand: brand ? capitalize(brand) : '',
                  last_four_digits: last_four_digits,
                })}
              </Text>
              <Text
                fontSize="base"
                fontWeight={'regular'}
                color={'neutral-textLow'}
                textAlign="left"
                data-testid="PaymentInstallments"
              >
                {t('installments', { count: installments })}
              </Text>
            </>
          )}
        </Box>
      </Card.Body>
    </Card>
  );
}

export function Skeleton(): JSX.Element {
  return (
    <Card data-testid="PaymentCard-Skeleton">
      <Card.Header>
        <Box>
          <Title.Skeleton width="208px" height="20px" />
        </Box>
      </Card.Header>
      <Card.Body>
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          alignSelf={'stretch'}
          flexDirection={'column'}
          borderWidth="1"
          gap="2"
        >
          <Text.Skeleton width="100%" height="20px" />
          <Text.Skeleton width="100%" height="20px" />
          <Text.Skeleton width="100%" height="20px" />
          <Text.Skeleton width="208px" height="20px" />
        </Box>
      </Card.Body>
    </Card>
  );
}

PaymentCard.Skeleton = Skeleton;
