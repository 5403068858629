import React from 'react';
import { Link } from '@nimbus-ds/components';
import { QuestionCircleIcon } from '@nimbus-ds/icons';
import './HelpText.css';

function HelpTextSkeleton(): JSX.Element {
  return (
    <div className="help-text-wrapper">
      <QuestionCircleIcon />
      <Link.Skeleton />
    </div>
  );
}

export default HelpTextSkeleton;
