type Requester = () => Promise<any>;
type PollingProps = {
  onSuccess: (data: any) => boolean | undefined;
  onError: (error: any) => void;
  requester: Requester;
  backoffDelay?: number;
  pollingTimeout?: number;
  maxAttempts?: number;
};

const Polling = ({
  onSuccess,
  onError,
  backoffDelay = 500,
  pollingTimeout = 10_000,
  requester,
  maxAttempts = 10,
}: PollingProps) => {
  let attempts = 0;
  const timeLimit = Date.now() + pollingTimeout;

  const poll = async () => {
    if (Date.now() > timeLimit) {
      onError(new Error('Polling timeout'));
      return;
    }

    if (attempts >= maxAttempts) {
      onError(new Error('Max attempts reached'));
      return;
    }

    try {
      const response = await requester();
      const isSuccessful = onSuccess(response);
      if (isSuccessful) {
        return;
      }
    } catch (error) {
      onError(error);
    }

    attempts++;
    poll();
  };
  setTimeout(poll, backoffDelay * attempts);
};

export default Polling;
