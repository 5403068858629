import React from 'react';
import { useHistory } from 'react-router';
import { Box, Button, Text, Title } from '@nimbus-ds/components';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';

function EmptyWithdraws(): JSX.Element {
  const { t } = useTranslationWithPrefix('withdraws.empty');
  const isMobile = useIsMobile();
  const history = useHistory();

  const goBack = () => history.goBack();

  return (
    <Box
      display="flex"
      flexDirection={{
        xs: 'column',
        md: 'row',
      }}
      justifyContent="center"
      alignItems="center"
      gap="4"
      marginTop={isMobile ? '12' : undefined}
    >
      <img
        alt="Empty withdraws state"
        src={'/assets/img/empty-withdraws.png'}
        width={isMobile ? 200 : 300}
      />
      <Box
        display="flex"
        flexDirection="column"
        gap="1"
        alignItems={{
          xs: 'center',
          md: 'flex-start',
        }}
      >
        <Title
          as="h4"
          textAlign={{
            xs: 'center',
            md: 'left',
          }}
        >
          {t('title')}
        </Title>
        <Text
          textAlign={{
            xs: 'center',
            md: 'left',
          }}
        >
          {t('description')}
        </Text>
        <Box
          display="flex"
          justifyContent="center"
          gap="4"
          mt="4"
          flexWrap="wrap"
        >
          <Button appearance="primary" onClick={goBack}>
            {t('back')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default EmptyWithdraws;
