import { combineReducers } from 'redux';
import backLinkReducer from 'redux/slices/backLinkSlice';
import bankAccountReducer from 'redux/slices/bankAccountSlice';
import identityValidationReducer from 'redux/slices/identityValidationSlice';
import settingsReducer from 'redux/slices/settingsSlice';
import storeInfoReducer from 'redux/slices/storeInfoSlice';

const rootReducer = combineReducers({
  storeInfo: storeInfoReducer,
  bankAccount: bankAccountReducer,
  identityValidation: identityValidationReducer,
  backLink: backLinkReducer,
  settings: settingsReducer,
});

export default rootReducer;
