import React, { useEffect, useState } from 'react';
import { Box, Card, Checkbox, Input, Text, Title } from '@nimbus-ds/components';
import { ExclamationCircleIcon } from '@nimbus-ds/icons';
import { FormField } from '@nimbus-ds/patterns';
import { useStoreInfo } from 'commons/hooks';
import { FeatureFlag, featureFlagService } from 'commons/services';
import {
  formatCurrencyWithStoreInfo,
  useTranslationWithPrefix,
} from 'commons/utils';
import {
  isCreditCard,
  isPix,
} from 'domains/Brazil/commons/utils/paymentMethod';

interface RefundAmountInterface {
  refundableAmount: number;
  paymentMethod: string;
  setRequestRefundAmount: React.Dispatch<React.SetStateAction<number>>;
  setIsValidInputAmount: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTotalRefund: React.Dispatch<React.SetStateAction<boolean>>;
}

const getAmountFromInput = (value: string) => {
  const normalizedValue = value.replace(/[^\d,]/g, '');
  const amount = centsToDecimal(getDigitsFrom(normalizedValue));
  return amount;
};
const getDigitsFrom = (string: string) => Number(string.replace(/\D/g, ''));
const centsToDecimal = (amount: number) => Number((amount / 100).toFixed(2));
const formatAmount = (amount: number) =>
  `${Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
  }).format(amount)}`;

enum ValidationError {
  LESS_THAN_MINIMUM = 'lessThanMinimum',
  GREATER_THAN_MAXIMUM = 'greaterThanMaximum',
}

export default function RefundAmountCard({
  refundableAmount,
  paymentMethod,
  setRequestRefundAmount,
  setIsValidInputAmount,
  setIsTotalRefund,
}: RefundAmountInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('refund.refundAmountCard');
  const { storeInfo } = useStoreInfo();

  const [inputAmount, setInputAmount] = useState<string>('');
  const [invalidInputError, setInvalidInputError] = useState<
    ValidationError | undefined
  >();
  const [isFullRefund, setFullRefund] = useState(false);
  const [partialPixRefundEnabled, isPartialPixRefundEnabled] = useState(false);
  const [, refundableAmountFormatted] = formatCurrencyWithStoreInfo(
    refundableAmount,
    storeInfo,
  ).split('$');

  useEffect(() => {
    setIsTotalRefund(isFullRefund);
  }, [isFullRefund, setIsTotalRefund]);

  useEffect(() => {
    featureFlagService
      .isFeatureFlagEnabled(FeatureFlag.FS_NUVEMPAGO_PARTIAL_REFUND_PIX)
      .then((isEnabled) => {
        isPartialPixRefundEnabled(isEnabled);
        if (isPix(paymentMethod ?? '') && !isEnabled) {
          setIsTotalRefund(true);
          setRequestRefundAmount(refundableAmount);
          setIsValidInputAmount(true);
        }
      })
      .catch(() => {
        isPartialPixRefundEnabled(false);
      });
  }, [
    paymentMethod,
    refundableAmount,
    setIsTotalRefund,
    setIsValidInputAmount,
    setRequestRefundAmount,
  ]);

  const invalidInput = (error?: ValidationError) => {
    setIsValidInputAmount(false);
    setInvalidInputError(error);
  };

  const validInput = () => {
    setIsValidInputAmount(true);
    setInvalidInputError(undefined);
  };

  const validateAmount = (amount: number) => {
    const MINIMUM_REFUND_AMOUNT = 0.01;
    if (amount < MINIMUM_REFUND_AMOUNT)
      invalidInput(ValidationError.LESS_THAN_MINIMUM);
    else if (amount > refundableAmount)
      invalidInput(ValidationError.GREATER_THAN_MAXIMUM);
    else validInput();

    if (amount > 0 && amount === refundableAmount) setFullRefund(true);
    else setFullRefund(false);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = getAmountFromInput(e.target.value);
    validateAmount(amount);
    setRequestRefundAmount(amount);
    setInputAmount(formatAmount(amount));
  };

  const handleCheckBoxChange = () => {
    validateAmount(refundableAmount);
    setRequestRefundAmount(refundableAmount);
    setInputAmount(formatAmount(refundableAmount));
  };

  const getHelpText = (error?: ValidationError) =>
    error ? t(`errors.${error}`) : '';

  const renderInputBasedOnPaymentMethod = () => {
    if (isCreditCard(paymentMethod ?? '') || partialPixRefundEnabled) {
      return (
        <Box display="flex" flexDirection="column" gap="4">
          <Box
            flex-direction="column"
            align-items="flex-start"
            align-self="stretch"
          >
            <FormField.Input
              type="text"
              name="refundAmount"
              appendPosition="start"
              placeholder="0,00"
              appearance={invalidInputError ? 'danger' : 'none'}
              value={inputAmount}
              onChange={handleAmountChange}
              helpIcon={ExclamationCircleIcon}
              helpText={getHelpText(invalidInputError)}
              showHelpText={!!invalidInputError}
              data-testid="refundAmount"
            />
          </Box>
          <Checkbox
            label={t('checkboxTitle', { amount: refundableAmountFormatted })}
            name="isFullRefund"
            onChange={handleCheckBoxChange}
            checked={isFullRefund}
            data-testid="isFullRefund"
          />
        </Box>
      );
    } else {
      return (
        <Box display="flex" flexDirection="column" gap="4">
          <Text>
            {t('checkboxTitle', { amount: refundableAmountFormatted })}
          </Text>
        </Box>
      );
    }
  };
  return (
    <Card data-testid="RefundAmountCard">
      <Card.Header title={t('title')} />
      <Card.Body>{renderInputBasedOnPaymentMethod()}</Card.Body>
    </Card>
  );
}

export function Skeleton(): JSX.Element {
  return (
    <Card data-testid="RefundAmountCard-Skeleton">
      <Card.Header>
        <Box>
          <Title.Skeleton width="208px" height="20px" />
        </Box>
      </Card.Header>
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="4">
          <Box
            flex-direction="column"
            align-items="flex-start"
            align-self="stretch"
          >
            <Input.Skeleton />
          </Box>
          <Text.Skeleton width="80px" height="14px" />
        </Box>
      </Card.Body>
    </Card>
  );
}

RefundAmountCard.Skeleton = Skeleton;
