import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { IBackLinkState, ISetBackLinkActionArgs } from './types';

const initialState: IBackLinkState = {
  backLinkPath: null,
  backLinkText: null,
};

export const backLinkSlice = createSlice({
  name: 'backLinkSlice',
  initialState,
  reducers: {
    setBackLink: (
      state,
      action: PayloadAction<ISetBackLinkActionArgs>,
    ): void => {
      state.backLinkPath = action.payload.backLinkPath;
      state.backLinkText = action.payload.backLinkText;
    },
    clearBackLink: (state): void => {
      state.backLinkPath = null;
      state.backLinkText = null;
    },
  },
});

export const { setBackLink, clearBackLink } = backLinkSlice.actions;
export default backLinkSlice.reducer;
