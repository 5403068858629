import React, { useState } from 'react';
import { Alert, Box, Card } from '@nimbus-ds/components';
import { Layout, Page } from '@nimbus-ds/patterns';
import { ROUTES } from 'App/Routes/constants';
import { DateLabel, HelpLink } from 'commons/components';
import { useIsMobile, useStoreInfo } from 'commons/hooks';
import { MoneyObjectInterface } from 'commons/types';
import { formatCurrency, useTranslationWithPrefix } from 'commons/utils';
import MobileTopNavBar from 'domains/Brazil/commons/components/MobileTopNavBar';
import BankAccountDetails from '../BankAccountDetails';

export type transferStateType = {
  amount: MoneyObjectInterface;
  createdAt: string;
  bankAccountDetails: {
    holderName: string;
    holderDocument: string;
    bankName: string;
    branchNumber: string;
    branchCheckDigit?: string;
    accountNumber: string;
    accountCheckDigit?: string;
  };
};

function TransferDetailsSuccess(
  args: Readonly<transferStateType>,
): JSX.Element {
  const { t } = useTranslationWithPrefix('partialTransfer.transferDetails');
  const { storeInfo } = useStoreInfo();
  const isMobile = useIsMobile();
  const [showAlert, setShowAlert] = useState(true);

  const bankAccountDetails = args.bankAccountDetails;

  return (
    <Box
      backgroundColor={isMobile ? 'neutral-background' : 'neutral-surface'}
      maxWidth="100vw"
      minHeight="100vh"
      padding="none"
      margin="none"
    >
      {isMobile && <MobileTopNavBar backLinkPath={ROUTES.DASHBOARD} />}
      <Page
        data-testid="TransferDetailsSuccess-Grid"
        maxWidth="800px"
        height="100%"
      >
        <Page.Header title={t('title')} paddingX={isMobile ? '4' : '6'} />
        <Page.Body paddingX={isMobile ? '4' : '6'}>
          <Layout columns="1">
            <Layout.Section>
              <DateLabel date={args.createdAt} />
            </Layout.Section>
            <Layout.Section>
              <Alert
                show={showAlert}
                appearance="success"
                title={t('alert.title')}
                onRemove={() => setShowAlert(false)}
              >
                {t('alert.subtitle')}
              </Alert>
            </Layout.Section>
            <Layout.Section>
              <Card>
                <Card.Header
                  title={formatCurrency(
                    {
                      value: args.amount.value / 100,
                      currency: args.amount.currency,
                    },
                    storeInfo,
                  )}
                />
              </Card>
            </Layout.Section>
            <Layout.Section>
              <BankAccountDetails
                holderName={bankAccountDetails.holderName}
                document={bankAccountDetails.holderDocument}
                bank={bankAccountDetails.bankName}
                branchNumber={bankAccountDetails.branchNumber}
                branchCheckDigit={bankAccountDetails.branchCheckDigit}
                accountNumber={bankAccountDetails.accountNumber}
                accountCheckDigit={bankAccountDetails.accountCheckDigit}
              />
            </Layout.Section>
            <Layout.Section>
              <HelpLink
                text={t('helpLink.text')}
                href={t('helpLink.href')}
                appearance="primary"
                textDecoration="none"
                trackingTag="nuvempago_transfer_can_link_click"
              />
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </Box>
  );
}

export default TransferDetailsSuccess;
