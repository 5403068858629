import React from 'react';
import {
  ChargebackType,
  EventHistoryType,
  PaymentMethodsType,
  TransactionStatusType,
} from 'commons/types';
import ChargebackStatusAlert from '../ChargebackStatusAlert';
import RefundStatusAlert from '../RefundStatusAlert';

interface StatusAlertProps {
  chargeback?: ChargebackType;
  status?: TransactionStatusType;
  lastEvent?: EventHistoryType;
  paymentMethod?: PaymentMethodsType;
}

function StatusAlert({
  chargeback,
  status,
  lastEvent,
  paymentMethod,
}: StatusAlertProps): JSX.Element | null {
  if (chargeback) return <ChargebackStatusAlert chargeback={chargeback} />;

  if (status === 'refunded' || status === 'partially_refunded')
    return (
      <RefundStatusAlert
        isTotalRefund={status === 'refunded'}
        lastEvent={lastEvent}
        paymentMethod={paymentMethod}
      />
    );

  return null;
}

export default StatusAlert;
