import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Box } from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';
import { setDeepProperty } from 'commons/utils/deepProperties';
import MobileTopNavBar from 'domains/Brazil/commons/components/MobileTopNavBar';
import RenderOnError from 'domains/Brazil/commons/components/RenderOnError';
import identityService, {
  MissingInfoResponse,
} from 'domains/Brazil/commons/services/identity/identityService';
import { RecipientType } from 'domains/Brazil/commons/types/RecipientType.enum';
import FormSkeleton from './components/FormSkeleton';

const LazyPersonForm = lazy(() => import('./components/PersonForm'));
const LazyCompanyForm = lazy(() => import('./components/CompanyForm'));

function RegisterIdentityInfo(): JSX.Element {
  const { t } = useTranslationWithPrefix('identity.info.register');
  const { t: navT } = useTranslationWithPrefix('navigation');
  const isMobile = useIsMobile();
  const [missingInfo, setMissingInfo] = useState<MissingInfoResponse>();
  const [hasError, setHasError] = useState(false);
  const history = useHistory();

  const fetchData = useCallback(async () => {
    try {
      setHasError(false);
      const data = await identityService.getMissingInfo();
      setMissingInfo(data);
    } catch (error) {
      setHasError(true);
    }
  }, []);

  useEffect(() => {
    navigateHeader(nexo, { goTo: ROUTES.DASHBOARD, text: navT('back') });
  }, [navT]);

  useEffect(() => {
    if (missingInfo) return;

    fetchData();
  }, [missingInfo, fetchData]);

  const submitHandler = useCallback(
    (data: Record<string, any>) => {
      if (!missingInfo) return;

      const transformedData = Object.entries(data).reduce(
        (obj, [key, value]) => {
          setDeepProperty(obj, key, value);
          return obj;
        },
        {} as Record<string, any>,
      );

      return identityService
        .patchMissingInfo(missingInfo.identityId, transformedData)
        .then(() => {
          setTimeout(() => history.push(ROUTES.DASHBOARD), 3000);
        });
    },
    [history, missingInfo],
  );

  const LazyFormComponent =
    missingInfo?.recipientType === RecipientType.Individual
      ? LazyPersonForm
      : LazyCompanyForm;

  return (
    <Box
      backgroundColor={isMobile ? 'neutral-background' : 'neutral-surface'}
      maxWidth="100vw"
      minHeight="100vh"
      data-testid="register-identity-info"
    >
      <MobileTopNavBar backLinkPath={ROUTES.DASHBOARD} />
      <RenderOnError
        title={t('onError.message')}
        message=""
        label={t('onError.label')}
        error={hasError}
        refreshHandler={fetchData}
      >
        {missingInfo ? (
          <Suspense fallback={<FormSkeleton />}>
            <LazyFormComponent
              document={missingInfo.document}
              fieldCodes={missingInfo.undefinedFields}
              onSubmit={submitHandler}
            />
          </Suspense>
        ) : (
          <FormSkeleton />
        )}
      </RenderOnError>
    </Box>
  );
}

export default RegisterIdentityInfo;
