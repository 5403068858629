import {
  dataDogLogger,
  DataDogLoggerActionTypes,
} from 'commons/utils/dataDogLogger';
import jsSHA from 'jssha';
import { IStoreInfo } from 'redux/slices/types';

export {};

declare global {
  interface Window {
    IncogniaWebSdk: any;
    TextEncoder: any;
  }
}

export type Event = {
  tag: string;
  accountId?: string | null;
  properties: Record<string, any>;
};

export type IncogniaWebSdk = {
  init(key: string): void;
  identify(args: { accountId: string }): void;
  sendCustomEvent(event: Event): void;
  getSessionToken(): Promise<string>;
};

export enum CustomEvents {
  TRANSFER = 'TRANSFER',
  IDENTIFY = 'IDENTIFY',
}

export enum UserLevel {
  EMPLOYEE = 'EMPLOYEE',
  NORMAL = 'NORMAL',
}

export const HEADER_ACCOUNT_ID = 'x-fingerprint-accountId';
export const HEADER_SESSION_TOKEN = 'x-fingerprint-token';

export const IncogniaWebSdk: IncogniaWebSdk = window.IncogniaWebSdk ?? {
  init: () => {},
  identify: () => {},
  sendCustomEvent: () => {},
  getSessionToken: async () => {},
};

export const ACCOUNT_ID_KEY = '+wm|`?3NRnkjT~1t.}N07T';

export const AccountStorage = {
  set(value: string): string {
    sessionStorage.setItem(ACCOUNT_ID_KEY, value);
    return value;
  },
  get(): string | null {
    return sessionStorage.getItem(ACCOUNT_ID_KEY);
  },
};

export const init = (appKey: string) => {
  try {
    IncogniaWebSdk.init(appKey);
  } catch (error: any) {
    dataDogLogger.generate({
      actionMessage: 'Fingerprint error on init method',
      actionOwner: 'FingerprintClient',
      actionType: DataDogLoggerActionTypes.Error,
      error: error,
      actionData: { appKey },
    });
  }
};

export const identify = (storeInfo: IStoreInfo) => {
  try {
    if (!storeInfo.user) {
      throw new Error('User is undefined');
    }

    const accountId = AccountStorage.set(storeInfo.user.id.toString());
    const userLevel = storeInfo.user.isEmployee
      ? UserLevel.EMPLOYEE
      : UserLevel.NORMAL;

    IncogniaWebSdk.identify({ accountId });
    sendCustomEvent({
      tag: CustomEvents.IDENTIFY,
      properties: {
        store_id: storeInfo.id,
        geo: storeInfo.country,
        currency: storeInfo.currency,
        user_id: storeInfo.user.id,
        user_level: userLevel.toLowerCase(),
      },
    });
  } catch (error: any) {
    dataDogLogger.generate({
      actionMessage: 'Fingerprint error on identify method',
      actionOwner: 'FingerprintClient',
      actionType: DataDogLoggerActionTypes.Error,
      error: error,
      actionData: { storeInfo },
    });
  }
};

export const sendCustomEvent = (event: Event): void => {
  try {
    IncogniaWebSdk.sendCustomEvent({
      ...event,
      accountId: AccountStorage.get(),
    });
  } catch (error: any) {
    dataDogLogger.generate({
      actionMessage: 'Fingerprint error on sendCustomEvent method',
      actionOwner: 'FingerprintClient',
      actionType: DataDogLoggerActionTypes.Error,
      error: error,
      actionData: { event, accountId: AccountStorage.get() },
    });
  }
};

export const encryptData = (data: string | undefined) => {
  if (!data) return;

  try {
    const hash = new jsSHA('SHA-512', 'TEXT', { encoding: 'UTF8' });
    return hash.update(data!.toString()).getHash('HEX');
  } catch (error: any) {
    dataDogLogger.generate({
      actionMessage: 'Fingerprint error on encryptData method',
      actionOwner: 'FingerprintClient',
      actionType: DataDogLoggerActionTypes.Error,
      error: error,
      actionData: { data },
    });
  }
};

export const getHeaders = async () => {
  try {
    const accountId = AccountStorage.get();
    const sessionToken = await IncogniaWebSdk.getSessionToken();

    return {
      [HEADER_ACCOUNT_ID]: accountId,
      [HEADER_SESSION_TOKEN]: sessionToken,
    };
  } catch (error: any) {
    dataDogLogger.generate({
      actionMessage: 'Fingerprint error on getHeaders method',
      actionOwner: 'FingerprintClient',
      actionType: DataDogLoggerActionTypes.Error,
      error: error,
      actionData: {},
    });
    return {};
  }
};
