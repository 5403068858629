import React from 'react';
import { track } from '@amplitude/analytics-browser';
import { Icon, Link } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { HelpLink } from '@nimbus-ds/patterns';

type nuvemPagoCta = {
  href: string;
  title: string;
  trackingTag?: string;
};

function Skeleton(): JSX.Element {
  return (
    <Link.Skeleton width="40%" data-testid="Nuvem-Pago-Cta-Skeleton">
      <Icon.Skeleton />
    </Link.Skeleton>
  );
}

NuvemPagoCta.Skeleton = Skeleton;

export default function NuvemPagoCta({
  href,
  title,
  trackingTag,
}: nuvemPagoCta): JSX.Element {
  const handleLinkClick = () => {
    if (trackingTag !== undefined) {
      track(trackingTag);
    }
  };
  return (
    <HelpLink data-testid="HelperLink">
      <Link
        appearance="primary"
        as="a"
        target="_blank"
        textDecoration="none"
        data-testid="MoreInfoLink"
        href={href}
        onClick={handleLinkClick}
      >
        {title}
        <Icon color="currentColor" source={<ExternalLinkIcon />} />
      </Link>
    </HelpLink>
  );
}
