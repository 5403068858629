import React from 'react';
import { Redirect } from 'react-router-dom';
import { Box, Spinner } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { Roles } from 'commons/components/Roles/Roles.enum';
import { useFeatureFlag } from 'commons/hooks';
import { FeatureFlag } from 'commons/services';
import { useHasRole } from './useHasRole';

export interface InterfaceRequireRoleProps {
  children: React.ReactNode;
  role: Roles;
  redirectPath?: string;
}

function RequireRole({
  children,
  role,
  redirectPath = ROUTES.HOME,
}: InterfaceRequireRoleProps): JSX.Element | null {
  const hasRole = useHasRole({ role });

  const { enabled, loading } = useFeatureFlag(
    FeatureFlag.FS_VALIDATE_USER_ROLE,
  );

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        height="100vh"
        justifyContent="center"
      >
        <Spinner />
      </Box>
    );
  }

  if (!enabled) {
    return <>{children}</>;
  }

  return <>{hasRole ? children : <Redirect to={redirectPath} />}</>;
}

export default RequireRole;
