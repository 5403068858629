import React from 'react';
import { Box, Text, Thumbnail } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { ProductDetails } from 'commons/services/refundService';
import { Currency } from 'commons/types/Currency.enum';
import { useTranslationWithPrefix } from 'commons/utils';
import MoneyField from 'domains/Brazil/commons/components/MoneyField';

interface Props {
  product: ProductDetails | undefined;
}
export default function ProductInfo(props: Props): JSX.Element {
  const { t } = useTranslationWithPrefix('refund.productInfo');

  return (
    <DataList.Row
      key={props?.product?.sku}
      gap="none"
      padding="none"
      topDivider={false}
      flexDirection="row"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        flexDirection="row"
        gap="2"
        data-testid={`product-${props.product?.sku}`}
      >
        <Thumbnail
          src={props?.product?.image}
          alt={`${props?.product?.name}-img`}
          width="72px"
        />
        <Box display="flex" flexDirection="column" gap="none">
          <Text
            color="neutral-textLow"
            fontWeight="medium"
            fontSize="highlight"
            lineHeight="highlight"
          >
            {props?.product?.name}
          </Text>
          <Text color="neutral-textDisabled" lineHeight="base">
            {t('sku', { sku: props?.product?.sku })}
          </Text>
          <MoneyField
            color="neutral-textLow"
            textAlign="left"
            fontSize="highlight"
            lineHeight="base"
            value={Number(props?.product?.unitPrice.value)}
            currency={Currency.BRL}
            prefixString={`${props?.product?.quantity} x `}
          />
        </Box>
      </Box>
      <MoneyField
        color="neutral-textLow"
        textAlign="right"
        fontSize="highlight"
        value={Number(props?.product?.totalPrice.value)}
        currency={Currency.BRL}
      />
    </DataList.Row>
  );
}

export function Skeleton(): JSX.Element {
  return (
    <DataList.Row
      gap="1"
      topDivider={false}
      flexDirection="row"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="row" gap="2">
        <Thumbnail.Skeleton width="72px" />
        <Box display="flex" flexDirection="column" gap="2">
          <Text.Skeleton width="135px" height="16px" />
          <Text.Skeleton width="102px" height="16px" />
          <Text.Skeleton width="102px" height="16px" />
        </Box>
      </Box>
      <Text.Skeleton width="64px" height="18px" />
    </DataList.Row>
  );
}

ProductInfo.Skeleton = Skeleton;
