import React from 'react';
import { Alert, Icon, Link, Text } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';

interface DetailAlertProps {
  title: string;
  message: string;
  linkTitle: string | undefined;
  linkUrl: string | undefined;
  appearance:
    | 'primary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'neutral'
    | undefined;
  onRemove?: () => void;
  dataTestId?: string;
}

const DetailAlert: React.FC<DetailAlertProps> = ({
  title,
  message,
  linkTitle,
  linkUrl,
  appearance,
  onRemove,
  dataTestId = 'detail-alert',
}) => {
  return (
    <Alert
      appearance={appearance}
      title={title}
      onRemove={onRemove}
      data-testid={dataTestId}
    >
      <Text color="neutral-textLow">{message}</Text>
      {linkUrl && (
        <Link
          color="neutral-textLow"
          appearance="neutral"
          target="_blank"
          as="a"
          href={linkUrl}
        >
          {linkTitle}
          <Icon source={<ExternalLinkIcon />} />
        </Link>
      )}
    </Alert>
  );
};

export default DetailAlert;
