import { useState } from 'react';
import { QueryKey, useQuery } from '@tanstack/react-query';
import { PaginatedResponse } from 'commons/types';

export function usePaginatedDesktopQuery<ItemType>(
  queryKey: QueryKey,
  queryFunction: ({
    page,
  }: {
    page: number;
  }) => Promise<PaginatedResponse<ItemType>>,
) {
  const [page, setPage] = useState(1);
  const query = useQuery([...queryKey, page], () => queryFunction({ page }), {
    select: (data) => {
      const meta = data.meta;
      return {
        ...data,
        meta: {
          ...meta,
          firstItemOfPage: meta.itemsPerPage * (meta.page - 1) + 1,
          lastItemOfPage:
            meta.itemsPerPage * (meta.page - 1) + data.items.length,
          totalPages: Math.ceil(meta.totalCount / meta.itemsPerPage),
        },
      };
    },
  });

  return {
    ...query,
    page,
    setPage,
  };
}
