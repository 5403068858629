import axios from 'App/axios';
import { DateRangeType } from 'commons/types';
import {
  GetTotalAmountResponse,
  GetWithdrawsResponse,
} from './withdrawsServiceResponses';

type GetWithdrawsType = (
  page: number,
  dateRange?: DateRangeType,
  fromDate?: string,
  toDate?: string,
  countWithoutFilters?: boolean,
) => Promise<GetWithdrawsResponse>;

const getWithdraws: GetWithdrawsType = async (
  page: number,
  dateRange?: DateRangeType,
  fromDate?: string,
  toDate?: string,
  countWithoutFilters?: boolean,
) => {
  const params = {
    page: page,
    range: dateRange,
    from_date: fromDate,
    to_date: toDate,
    count_without_filters: countWithoutFilters,
  };

  const data = (
    await axios.get<GetWithdrawsResponse>(`/admin/withdraws`, { params })
  ).data;

  data.withdraws = data.withdraws.map((withdraw) => ({
    ...withdraw,
    withdrawNumber: withdraw.withdrawNumber ?? withdraw.id,
  }));

  return data;
};

type GetTotalAmountType = (
  dateRange?: DateRangeType,
  fromDate?: string,
  toDate?: string,
) => Promise<GetTotalAmountResponse>;

const getTotalAmount: GetTotalAmountType = async (
  dateRange?: DateRangeType,
  fromDate?: string,
  toDate?: string,
) => {
  const params = {
    range: dateRange,
    from_date: fromDate,
    to_date: toDate,
  };

  const response = await axios.get(`/admin/withdraws/total-amount`, { params });

  return response.data;
};

interface WithdrawsServiceInterface {
  getWithdraws: GetWithdrawsType;
  getTotalAmount: GetTotalAmountType;
}

const withdrawsService: WithdrawsServiceInterface = {
  getWithdraws,
  getTotalAmount,
};

export default withdrawsService;
