import React, { useState } from 'react';
import {
  Box,
  Card,
  Checkbox,
  Icon,
  Input,
  Text,
  Title,
} from '@nimbus-ds/components';
import { MoneyIcon } from '@nimbus-ds/icons';
import { useStoreInfo } from 'commons/hooks';
import { MoneyObjectInterface } from 'commons/types';
import { formatCurrency, useTranslationWithPrefix } from 'commons/utils';
import RefundCardSkeleton from './RefundCardSkeleton';

interface RefundCardInterface {
  remainingAmountToRefund: MoneyObjectInterface;
  hasAlreadyRefunded: boolean;
  amountToRefund: number;
  setAmountToRefund: React.Dispatch<React.SetStateAction<number>>;
  errorInput: string;
  setErrorInput: React.Dispatch<React.SetStateAction<string>>;
}

function RefundCard({
  remainingAmountToRefund,
  hasAlreadyRefunded,
  amountToRefund,
  setAmountToRefund,
  errorInput,
  setErrorInput,
}: RefundCardInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('refund.refundCard');
  const { storeInfo } = useStoreInfo();
  const [totalRefund, setTotalRefund] = useState(false);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = Number(e.target.value);

    if (amount < 0) {
      setAmountToRefund(0);
      return;
    } else setAmountToRefund(amount);

    if (amount > remainingAmountToRefund.value) {
      setErrorInput(t('amountError'));
    } else if (amount > 0 && amount < 1) {
      setErrorInput(t('minAmountError'));
    } else if (errorInput) {
      setErrorInput('');
    }

    if (amount === remainingAmountToRefund.value) {
      setTotalRefund(true);
    }

    if (totalRefund && amount !== remainingAmountToRefund.value) {
      setTotalRefund(false);
    }
  };

  const handleCheckboxChange = () => {
    if (!totalRefund) {
      setTotalRefund(true);
      setAmountToRefund(remainingAmountToRefund.value);
    } else {
      setTotalRefund(false);
      setAmountToRefund(0);
    }

    if (errorInput) {
      setErrorInput('');
    }
  };

  return (
    <Card>
      <Card.Header padding="none">
        <Title as="h3">{t('title')}</Title>
      </Card.Header>
      <Box display="flex" gap="4" flexDirection="column">
        <Box display="flex" gap="2" flexDirection="column">
          <Input
            appearance={errorInput ? 'danger' : 'neutral'}
            append={
              <Icon color="neutral-textDisabled" source={<MoneyIcon />} />
            }
            appendPosition="start"
            placeholder="0,00"
            value={amountToRefund || ''}
            type="number"
            onChange={handleAmountChange}
            min={0}
          />
          {errorInput && <Text color="danger-textLow">{errorInput}</Text>}
        </Box>
        <Checkbox
          name="refundCard"
          label={t(hasAlreadyRefunded ? 'refundAvailable' : 'refundTotal', {
            total: formatCurrency(remainingAmountToRefund, storeInfo),
          })}
          checked={totalRefund}
          onChange={handleCheckboxChange}
        />
      </Box>
    </Card>
  );
}

RefundCard.Skeleton = RefundCardSkeleton;

export default RefundCard;
