import React from 'react';
import { Box, Icon, Text, Title } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { useTranslationWithPrefix } from 'commons/utils';
import FooterButtons from '../FooterButtons';

function ProofOfLifeInfoSkeleton(): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation.proofOfLife');

  const InfoBox = () => (
    <Box display="flex" flexDirection="row" alignItems="flex-start" gap="3">
      <Icon.Skeleton width="2em" />
      <Text.Skeleton width="15em" />
    </Box>
  );

  return (
    <AppLayout skeleton title={t('title')} footer={<FooterButtons.Skeleton />}>
      <Box display="flex" flexDirection="column" gap="6">
        <Title.Skeleton as="h5" width="20em" />
        <Title.Skeleton as="h5" width="10em" />
        <Box display="flex" flexDirection="column" gap="4">
          <InfoBox />
          <InfoBox />
          <InfoBox />
        </Box>
      </Box>
    </AppLayout>
  );
}

export default ProofOfLifeInfoSkeleton;
