import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  reportService,
  ReportStatusDataType,
} from 'commons/services/reportService';
import { ReportStatus } from 'commons/types';

const REFETCH_FREQUENCY = 10000; // 10 seconds
const MAX_REFETCH_COUNT = 12; // max 2 minutes

export function useGetReportStatusQueryPolling({
  requestId,
  onFinished,
}: {
  requestId?: number;
  onFinished: (args: ReportStatusDataType) => void;
}) {
  const [isPolling, setIsPolling] = useState(false);

  const getReportStatusQuery = useQuery(['getReportStatusPolling', requestId], {
    queryFn: () => {
      if (!requestId) return;

      setIsPolling(true);
      return reportService.getReportStatus(requestId);
    },
    enabled: !!requestId,
    refetchInterval(data, query) {
      const shouldRefetch =
        query.state.status !== 'error' &&
        (!data ||
          (data.status === ReportStatus.Pending &&
            query.state.dataUpdateCount <= MAX_REFETCH_COUNT));

      if (shouldRefetch) {
        return REFETCH_FREQUENCY;
      }

      setIsPolling(false);
      return false;
    },
  });

  const reportStatusAfterPolling = (status?: ReportStatus) => {
    return !status || status === ReportStatus.Pending
      ? ReportStatus.Failed
      : status;
  };

  useEffect(() => {
    if (!isPolling && getReportStatusQuery.isSuccess) {
      onFinished({
        status: reportStatusAfterPolling(getReportStatusQuery.data?.status),
        temporaryDownloadUrl: getReportStatusQuery.data?.temporaryDownloadUrl,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getReportStatusQuery.data?.status,
    getReportStatusQuery.data?.temporaryDownloadUrl,
    getReportStatusQuery.isSuccess,
    isPolling,
    onFinished,
  ]);

  return {
    isPolling,
    query: getReportStatusQuery,
  };
}
