import { KycDocumentErrorsType } from 'commons/types';

export function getErrorCode(
  error: KycDocumentErrorsType,
  juridicalPerson?: boolean,
): string {
  let errorCode = juridicalPerson
    ? `errorBanner.juridical.${error.errorCode}`
    : `errorBanner.physical.${error.errorCode}`;

  if (error.errorCode === 'documents_expired' && juridicalPerson) {
    if (error.documentName === 'company_composition') errorCode += '_statute';
    else errorCode += '_id';
  }

  return errorCode;
}
