import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  Alert,
  AlertProps,
  Box,
  Button,
  Icon,
  Tag,
  Text,
} from '@nimbus-ds/components';
import { UndoIcon } from '@nimbus-ds/icons';
import AppLayout from 'App/components/AppLayout';
import { ROUTES } from 'App/Routes/constants';
import { HelpLink } from 'commons/components';
import { Roles } from 'commons/components/Roles/Roles.enum';
import ShowWithRole from 'commons/components/Roles/ShowWithRoles';
import { useIsMobile } from 'commons/hooks';
import { transactionsService } from 'commons/services/transactionsService';
import { TransactionDetailsType } from 'commons/types';
import { daysPassedSince, transactionTagProps } from 'commons/utils';
import Client from './Client';
import Header from './Header';
import PaymentAlert from './PaymentAlert';
import PaymentDetails from './PaymentDetails';
import PaymentHistory from './PaymentHistory';
import PaymentMethod from './PaymentMethod';
import StatusAlert from './StatusAlert';
import TrackingPage from './TrackingPage';
import { hasSuccessRefund } from './utils';

interface TransactionDetailParams {
  orderId: string;
}

interface TransactionDetailLocation {
  showRefundAlert?: boolean;
  refundError?: boolean;
  pendingRefundWarning?: boolean;
}

function TransactionDetail(): JSX.Element {
  const location = useLocation<TransactionDetailLocation>();
  const [transactionDetails, setTransactionDetails] =
    useState<TransactionDetailsType>();
  const [isLoading, setIsLoading] = useState(true);
  const { orderId } = useParams<TransactionDetailParams>();
  const isMobile = useIsMobile();
  const [alertState, setAlertState] = useState<AlertProps>({
    appearance: 'primary',
    children: '',
    show: false,
    title: '',
  });
  const { push } = useHistory();
  const { t } = useTranslation();

  const isCreditCard = transactionDetails?.paymentMethod === 'credit_card';
  const isBankTransfer = transactionDetails?.paymentMethod === 'wire_transfer';
  const isPaymentMethodAvailableToRefund = isCreditCard || isBankTransfer;

  const showRefundButton =
    (transactionDetails?.status === 'paid' ||
      transactionDetails?.status === 'partially_refunded') &&
    isPaymentMethodAvailableToRefund;

  function closeAlert() {
    setAlertState({
      appearance: 'primary',
      children: '',
      show: false,
      title: '',
    });
  }

  useEffect(() => {
    if (location.state?.showRefundAlert) {
      if (location.state?.refundError) {
        setAlertState({
          appearance: 'danger',
          children: t('transactionDetails.refundError.description'),
          show: true,
          title: t('transactionDetails.refundError.title'),
        });
      } else if (location.state?.pendingRefundWarning) {
        setAlertState({
          appearance: 'warning',
          children: t('transactionDetails.hasPendingRefund.description'),
          show: true,
          title: t('transactionDetails.hasPendingRefund.title'),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.showRefundAlert]);

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      setIsLoading(true);
      const response = await transactionsService.getTransactionDetails(orderId);
      setTransactionDetails(response);
      setIsLoading(false);
    };
    fetchTransactionDetails();
  }, [orderId]);

  const orderNumber = transactionDetails?.transactions[0].orderNumber;
  const isAbandonedCart = orderNumber === -1;
  const isPending =
    transactionDetails?.status === 'pending' ||
    transactionDetails?.status === 'authorized';

  const rejectedText =
    transactionDetails?.transactions.at(0)?.reason ===
    'card_rejected_fraud_high_risk'
      ? 'rejectedBySecurity'
      : 'rejectedByEmissor';

  const statusLabel = transactionTagProps(
    transactionDetails?.status || 'pending',
    t,
    '1',
    rejectedText,
    transactionDetails?.transactions[0].chargeback,
  );

  const refundTransaction = () => {
    if (
      transactionDetails?.paymentDate &&
      daysPassedSince(transactionDetails.paymentDate) > 120
    ) {
      setAlertState({
        appearance: 'danger',
        children: t('transactionDetails.errors.tooOld'),
        show: true,
        title: t('transactionDetails.errors.title'),
      });
    } else {
      push(`${ROUTES.REFUND}/${orderId}`);
    }
  };

  const refundButton = showRefundButton ? (
    <ShowWithRole role={Roles.REFUND}>
      <Button
        onClick={refundTransaction}
        appearance={isMobile ? 'transparent' : 'neutral'}
      >
        {!isMobile && <Icon source={<UndoIcon />} />}
        {t('transactionDetails.refundButton')}
      </Button>
    </ShowWithRole>
  ) : null;

  const isRefund =
    transactionDetails?.status === 'refunded' ||
    transactionDetails?.status === 'partially_refunded';
  const paymentAlertDate = isRefund
    ? transactionDetails?.totalRefunds?.limitDate
    : transactionDetails?.transactions.at(-1)?.happenedAt;

  return (
    <AppLayout
      skeleton={isLoading}
      subtitleItems={
        isLoading ? (
          <Tag.Skeleton />
        ) : (
          <Tag appearance={statusLabel.appearance}>
            <Icon source={<statusLabel.icon size={12} />} />
            <Text fontSize="caption">{statusLabel.label}</Text>
          </Tag>
        )
      }
      title={
        isAbandonedCart
          ? t('transactionDetails.abandonedTitle', { id: `#${orderId}` })
          : t('transactionDetails.orderTitle', { id: `#${orderNumber}` })
      }
      navBarItems={refundButton}
      headerItems={isMobile ? undefined : refundButton}
    >
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <Alert {...alertState} onRemove={closeAlert} />
        <StatusAlert
          chargeback={transactionDetails?.transactions[0].chargeback}
          status={transactionDetails?.transactions[0].status}
          lastEvent={transactionDetails?.events?.at(-1)}
          paymentMethod={transactionDetails?.paymentMethod}
        />
        <Header
          skeleton={isLoading}
          orderId={orderId}
          createdAt={transactionDetails?.paidAt}
          isAbandonedCart={isAbandonedCart}
        />
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: '1fr',
            md: '2fr 1fr',
          }}
          gridGap="4"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap="4"
          >
            <PaymentDetails
              skeleton={isLoading}
              orderId={orderId}
              status={transactionDetails?.status}
              orderPrice={transactionDetails?.order.price}
              feesAndTaxes={transactionDetails?.feesAndTaxes}
              netAmount={transactionDetails?.netAmount}
              installmentsPlan={transactionDetails?.order.installmentsPlan}
              totalRefunds={transactionDetails?.totalRefunds}
            />
            <PaymentAlert
              skeleton={isLoading}
              status={transactionDetails?.status}
              date={paymentAlertDate}
              settlementPeriod={transactionDetails?.order.settlementPeriod}
            />
            <PaymentMethod
              skeleton={isLoading}
              card={transactionDetails?.customer.card}
              installments={transactionDetails?.order.installments}
              installmentsValue={transactionDetails?.order.installmentsValue}
              installmentsPlan={transactionDetails?.order.installmentsPlan}
              installmentsFeeResponsible={
                transactionDetails?.order.installmentsFeeResponsible
              }
              paymentMethod={transactionDetails?.paymentMethod}
              paymentMethodBrand={transactionDetails?.paymentMethodBrand}
              orderId={orderId}
              hasSuccessRefund={hasSuccessRefund(transactionDetails?.events)}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap="4"
          >
            <Client
              skeleton={isLoading}
              customer={transactionDetails?.customer}
            />
            <TrackingPage
              skeleton={isLoading}
              url={transactionDetails?.statusPageUrl}
            />
            {!isPending && (
              <PaymentHistory
                skeleton={isLoading}
                transactions={transactionDetails?.transactions}
                events={transactionDetails?.events}
                customerRefunds={
                  transactionDetails?.totalRefunds?.customerRefunds
                }
              />
            )}
          </Box>
        </Box>
        <HelpLink
          href={t('transactionDetails.paymentDetails.feesInfoHelpLink.href')}
          text={t('transactionDetails.paymentDetails.feesInfoHelpLink.text')}
          appearance="primary"
          textDecoration="none"
        />
      </Box>
    </AppLayout>
  );
}

export default TransactionDetail;
