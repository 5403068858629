import React from 'react';
import { Link } from '@nimbus-ds/components';
import { SearchIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { useTranslationWithPrefix } from 'commons/utils';

interface EmptySearchInterface {
  onClick: () => void;
}

export default function EmptySearch({
  onClick,
}: EmptySearchInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('withdraws.emptySearch');

  return (
    <EmptyMessage
      icon={<SearchIcon size="large" />}
      title={t('title')}
      actions={
        <Link appearance="primary" onClick={onClick}>
          {t('clearFilters')}
        </Link>
      }
    />
  );
}
