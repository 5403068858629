import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { Box, Checkbox } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { useToast } from 'commons/hooks';
import { documentsService, GetIdValidationResponse } from 'commons/services';
import { useTranslationWithPrefix } from 'commons/utils';
import { DocumentsContext } from './DocumentsContext';
import MultipleDniDocument from './MultipleDniDocument';
import NotEligibleMessage from './NotEligibleMessage';
import StatuteDocument from './StatuteDocument';
import ValidationContent from './ValidationContent';

interface JuridicalValidationInterface {
  identityValidation: GetIdValidationResponse;
}

function JuridicalValidation({
  identityValidation,
}: JuridicalValidationInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');
  const documents = useContext(DocumentsContext);
  const history = useHistory();
  const [dniDocumentsCompleted, setDniDocumentsCompleted] = useState(false);
  const [numberOfOwners, setNumberOfOwners] = useState(1);
  const [statuteDocumentsCompleted, setStatuteDocumentsCompleted] =
    useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const { showToastError } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [eligiblePerson, setEligiblePerson] = useState(true);

  const toggleCheckbox = () => {
    setEligiblePerson((isEligible) => !isEligible);
  };

  const redirectToConfig = (state?: unknown) => {
    history.replace(ROUTES.CONFIG, state);
  };

  const onSubmit = async () => {
    if (dniDocumentsCompleted && statuteDocumentsCompleted) {
      try {
        setIsLoading(true);
        await documentsService.postKycInfo(numberOfOwners);
        redirectToConfig({ showDocumentsSuccessAlert: true });
      } catch {
        showToastError(t('error'));
      }
      setIsLoading(false);
    } else {
      setShowErrors(true);
    }
  };

  const renderEligible = () => (
    <Box display="flex" flexDirection="column" alignItems="flex-end" gap="4">
      <StatuteDocument
        verifyUploadedDocuments={setStatuteDocumentsCompleted}
        statuteThumbnailFile={documents.statute}
        showErrors={showErrors}
      />
      <MultipleDniDocument
        dniThumbnailFiles={documents.dnis}
        showErrors={showErrors}
        subtitle={t('juridical.dni.subtitle')}
        title={t('juridical.dni.title')}
        verifyUploadedDocuments={setDniDocumentsCompleted}
        eligiblePerson={eligiblePerson}
        toggleCheckbox={toggleCheckbox}
        setNumberOfOwners={setNumberOfOwners}
      />
    </Box>
  );

  const renderNotEligible = () => (
    <Box display="flex" flexDirection="column" alignItems="flex-start" gap="6">
      <Checkbox
        label={t('physical.checkbox')}
        name="checkbox"
        checked={eligiblePerson}
        onChange={toggleCheckbox}
      />
      <NotEligibleMessage />
    </Box>
  );

  return (
    <ValidationContent
      renderEligible={renderEligible}
      eligiblePerson={eligiblePerson}
      isLoading={isLoading}
      renderNotEligible={renderNotEligible}
      juridicalPerson
      onSubmit={onSubmit}
      kycDocumentErrors={identityValidation.kycDocumentErrors}
      status={identityValidation.status}
    />
  );
}

export default JuridicalValidation;
