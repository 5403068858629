import React, { useEffect, useState } from 'react';
import { Box, Card, Text, Title } from '@nimbus-ds/components';
import ThumbnailFile from 'commons/components/ThumbnailFile/ThumbnailFile';
import { useTranslationWithPrefix } from 'commons/utils';
import StatuteDocumentSkeleton from './StatuteDocumentSkeleton';
import './MultipleDniDocument.css';

interface InterfaceDniDoc {
  showErrors: boolean;
  statuteThumbnailFile?: string;
  verifyUploadedDocuments: React.Dispatch<React.SetStateAction<boolean>>;
}

function StatuteDocument({
  showErrors = false,
  statuteThumbnailFile = '',
  verifyUploadedDocuments,
}: InterfaceDniDoc): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');
  const [uploaded, setUploaded] = useState(Boolean(statuteThumbnailFile));
  const [errorText, setErrorText] = useState({
    show: false,
    text: '',
  });

  useEffect(() => {
    verifyUploadedDocuments(uploaded);
  }, [uploaded, verifyUploadedDocuments]);

  useEffect(() => {
    if (showErrors && !uploaded) {
      setErrorText({
        show: true,
        text: t('errors.uploadOneFile'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showErrors, uploaded]);

  const handleOnSuccess = () => {
    setUploaded(true);
    setErrorText({
      show: false,
      text: '',
    });
  };

  const handleOnError = (text: string) => {
    setUploaded(false);
    setErrorText({
      show: true,
      text,
    });
  };

  return (
    <Card>
      <Card.Header>
        <Title as="h4">{t('juridical.statute.title')}</Title>
      </Card.Header>
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <Text>{t('juridical.statute.subtitle')}</Text>
        <Box display="flex" maxWidth="400px">
          <ThumbnailFile
            aspectRatio="2/1"
            documentName="companyComposition"
            onSuccess={handleOnSuccess}
            onError={handleOnError}
            ariaLabel="statute"
            helperText={t('juridical.statute.chooseFile')}
            accept="image/jpeg,image/png,application/pdf"
            initialThumbnailFile={statuteThumbnailFile}
            onDelete={() => setUploaded(false)}
          />
        </Box>
        {errorText.show && (
          <Text color="danger-interactive">{errorText.text}</Text>
        )}
      </Box>
    </Card>
  );
}

StatuteDocument.Skeleton = StatuteDocumentSkeleton;
export default StatuteDocument;
