import React from 'react';
import { Box, Button, Checkbox } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { useTranslationWithPrefix } from 'commons/utils';
import DniDocument from './SingleDniDocument';

function IdentityValidationSkeleton(): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');
  return (
    <AppLayout title={t('title')}>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <DniDocument.Skeleton />
        <Checkbox.Skeleton />
        <Button.Skeleton />
      </Box>
    </AppLayout>
  );
}

export default IdentityValidationSkeleton;
