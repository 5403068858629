import React from 'react';
import { useIsMobile } from 'commons/hooks';
import IncomesByDateDesktop from './IncomesByDateDesktop';
import IncomesByDateMobile from './IncomesByDateMobile';
import IncomesByDateSkeleton from './IncomesByDateSkeleton';

function IncomesByDate(): JSX.Element {
  const isMobile = useIsMobile();

  return isMobile ? <IncomesByDateMobile /> : <IncomesByDateDesktop />;
}

IncomesByDate.Skeleton = IncomesByDateSkeleton;
export default IncomesByDate;
