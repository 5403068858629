import React, { useContext } from 'react';
import { useBankAccount, useIdentityValidation } from 'commons/hooks';
import { isJuridicalPerson } from 'commons/utils';
import IdentityValidationRoutes from './NewIdentityValidation/IdentityValidationRoutes';
import { DocumentsContext } from './DocumentsContext';
import IdentityValidationError from './IdentityValidationError';
import IdentityValidationSkeleton from './IdentityValidationSkeleton';
import JuridicalValidation from './JuridicalValidation';
import PhysicalPersonValidation from './PhysicalPersonValidation';

function IdentityValidation(): JSX.Element {
  const documents = useContext(DocumentsContext);
  const bankAccount = useBankAccount();
  const identityValidation = useIdentityValidation();
  const isLoading =
    bankAccount.isLoading ||
    documents.loading ||
    bankAccount.error ||
    identityValidation.isLoading;

  /*
    We cannot render JuridicalValidation nor PhysicalPersonValidation until documents are finally loaded
    because a first-time render validation is fired (verifyUploadedDocuments) to know if the merchant is missing
    documents or not.
    
    This specially breaks on StatuteDocument because it initializes state by using a prop statuteThumbnailFile
      const [uploaded, setUploaded] = useState(Boolean(statuteThumbnailFile));
    If we render before the API call, then the uploaded initial state will be inferred as "false" because:
    - On first time render, statuteThumbnailFile is not available yet. This sets uploaded state as false.
    - On second time render, api responds with statuteThumbnailFile. However, uploaded state keeps set as "false"
  */
  if (isLoading) return <IdentityValidationSkeleton />;
  if (identityValidation.isError) return <IdentityValidationError />;

  if (identityValidation.data.useKycV2) {
    return <IdentityValidationRoutes />;
  }

  return (
    <>
      {isJuridicalPerson(bankAccount?.data?.fiscalDocument) ? (
        <JuridicalValidation identityValidation={identityValidation.data} />
      ) : (
        <PhysicalPersonValidation
          identityValidation={identityValidation.data}
        />
      )}
    </>
  );
}

IdentityValidation.Skeleton = IdentityValidationSkeleton;
export default IdentityValidation;
