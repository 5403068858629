import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { Box, Checkbox } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { useToast } from 'commons/hooks';
import { documentsService, GetIdValidationResponse } from 'commons/services';
import { useTranslationWithPrefix } from 'commons/utils';
import { DocumentsContext } from './DocumentsContext';
import NotEligibleMessage from './NotEligibleMessage';
import DniDocument from './SingleDniDocument';
import ValidationContent from './ValidationContent';

interface PhysicalPersonValidationInterface {
  identityValidation: GetIdValidationResponse;
}

function PhysicalPersonValidation({
  identityValidation,
}: PhysicalPersonValidationInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');
  const history = useHistory();
  const documents = useContext(DocumentsContext);
  const [dniDocumentsCompleted, setDniDocumentsCompleted] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [eligiblePerson, setEligiblePerson] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { showToastError } = useToast();

  const toggleCheckbox = () => {
    setEligiblePerson((isEligible) => !isEligible);
  };

  const redirectToConfig = (state?: unknown) => {
    history.replace(ROUTES.CONFIG, state);
  };

  const onSubmit = async () => {
    if (dniDocumentsCompleted) {
      try {
        setIsLoading(true);
        await documentsService.postKycInfo(1);
        redirectToConfig({ showDocumentsSuccessAlert: true });
      } catch {
        showToastError(t('error'));
      }
      setIsLoading(false);
    } else {
      setShowErrors(true);
    }
  };

  const renderEligible = () => (
    <Box display="flex" flexDirection="column" alignItems="flex-end" gap="4">
      <DniDocument
        dniThumbnailFiles={documents.dnis}
        showErrors={showErrors}
        title={t('physical.dni.title')}
        verifyUploadedDocuments={setDniDocumentsCompleted}
        eligiblePerson={eligiblePerson}
        toggleCheckbox={toggleCheckbox}
      />
    </Box>
  );

  const renderNotEligible = () => (
    <Box display="flex" flexDirection="column" alignItems="flex-start" gap="6">
      <Checkbox
        label={t('physical.checkbox')}
        name="checkbox"
        checked={eligiblePerson}
        onChange={toggleCheckbox}
      />
      <NotEligibleMessage />
    </Box>
  );

  return (
    <ValidationContent
      eligiblePerson={eligiblePerson}
      renderEligible={renderEligible}
      renderNotEligible={renderNotEligible}
      isLoading={isLoading}
      onSubmit={onSubmit}
      kycDocumentErrors={identityValidation.kycDocumentErrors}
      status={identityValidation.status}
    />
  );
}

export default PhysicalPersonValidation;
