import React from 'react';
import { Notification } from 'commons/components';
import { NotificationTypes } from 'commons/services';

function HomeCards(): JSX.Element {
  return (
    <Notification
      listeningToTypes={[
        NotificationTypes.NoType,
        NotificationTypes.PolRemediationPending,
        NotificationTypes.PolRemediationPendingModal,
        NotificationTypes.WithdrawsTemporarilyBlocked,
        NotificationTypes.D1Enabled,
      ]}
    />
  );
}

export default HomeCards;
