import React, { useState } from 'react';
import { Box, Card, Link, Text } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { ProductDetails } from 'commons/services/refundService';
import { Currency } from 'commons/types/Currency.enum';
import { useTranslationWithPrefix } from 'commons/utils';
import MoneyField from 'domains/Brazil/commons/components/MoneyField';
import ProductInfo from './ProductInfo';

type FieldsArgs = {
  label: string;
  value: number;
  fontWeight?: 'bold' | 'regular' | 'medium' | undefined;
  color?:
    | 'primary-textLow'
    | 'success-textLow'
    | 'warning-textLow'
    | 'danger-textLow'
    | 'neutral-textLow';
  role?: string;
  isPositiveValue?: boolean;
};

function Fields({
  label,
  value,
  fontWeight,
  color,
  role = '',
  isPositiveValue = true,
}: FieldsArgs): JSX.Element {
  return (
    <Box display={'inline-flex'} justifyContent={'space-between'}>
      <Text fontWeight={fontWeight} role={role}>
        {label}
      </Text>
      <MoneyField
        value={isPositiveValue ? +value : -value}
        currency={Currency.BRL}
        fontWeight={fontWeight}
        color={color}
      />
    </Box>
  );
}

function FieldsSkeleton(): JSX.Element {
  return (
    <Box display={'inline-flex'} justifyContent={'space-between'}>
      <Text.Skeleton width="184px" height="18px" />
      <Text.Skeleton width="64px" height="18px" />
    </Box>
  );
}

Fields.Skeleton = FieldsSkeleton;

type ShowAllProductsButtonArgs = {
  label: string;
  onClick: (...args: unknown[]) => unknown;
};

const ShowAllProductsButton = ({
  label,
  onClick,
}: ShowAllProductsButtonArgs): JSX.Element => (
  <DataList.Row paddingY="none" topDivider={false}>
    <Box display="flex" alignItems="center" justifyContent="center">
      <Link
        textDecoration="none"
        appearance="primary"
        fontSize="highlight"
        onClick={onClick}
        id="show-all-products-button"
      >
        {label}
      </Link>
    </Box>
  </DataList.Row>
);

type ListProductInfoArgs = {
  products: ProductDetails[] | undefined;
  total: number;
  shipping: number;
  subTotal: number;
  discount: number;
  partialRefunded: number;
};

function ListProductInfo({
  products,
  subTotal,
  discount,
  shipping,
  total,
  partialRefunded,
}: ListProductInfoArgs): JSX.Element {
  const [showAllProducts, setShowAllProducts] = useState(false);
  const { t } = useTranslationWithPrefix('refund.listProducts');

  if (products === undefined) {
    return <Box data-testid="products-empty-box" />;
  }

  const mainProducts = products.slice(0, 4);
  const otherProducts = products.slice(mainProducts.length);
  const hasOtherProducts = otherProducts.length > 0;

  const showAllProductsHandler = () => setShowAllProducts(!showAllProducts);

  return (
    <Card padding="none">
      <Card.Body padding="none">
        <DataList bottomDivider={false} data-testid="List-Product-Info">
          <Box display="flex" flexDirection="column" padding="4" gap="4">
            {mainProducts.map((product) => (
              <ProductInfo key={product.sku} product={product} />
            ))}
            {showAllProducts &&
              otherProducts.map((p) => <ProductInfo key={p.sku} product={p} />)}
            {hasOtherProducts && (
              <ShowAllProductsButton
                onClick={showAllProductsHandler}
                label={t(showAllProducts ? 'hide' : 'showAll')}
              />
            )}
          </Box>
          <DataList.Row
            paddingY="2"
            topDivider={false}
            backgroundColor="neutral-surface"
          >
            <Fields label={t('subTotal')} value={subTotal} />
          </DataList.Row>
          <DataList.Row paddingY="2" topDivider={false}>
            <Fields label={t('shipping')} value={shipping} />
          </DataList.Row>
          {partialRefunded > 0 && (
            <DataList.Row paddingY="2" topDivider={false}>
              <Fields
                label={t('refunded')}
                value={partialRefunded}
                isPositiveValue={false}
                color="danger-textLow"
                role="partialRefunded"
              />
            </DataList.Row>
          )}
          {discount > 0 && (
            <DataList.Row paddingY="2" topDivider={false}>
              <Fields
                label={t('discount')}
                value={discount}
                isPositiveValue={false}
                color="danger-textLow"
                role="discount"
              />
            </DataList.Row>
          )}
          <DataList.Row
            paddingY="2"
            paddingTop={'4'}
            paddingBottom={'4'}
            topDivider={false}
            backgroundColor="neutral-surface"
          >
            <Fields label={t('total')} value={total} fontWeight="bold" />
          </DataList.Row>
        </DataList>
      </Card.Body>
    </Card>
  );
}

export function Skeleton(): JSX.Element {
  return (
    <Card padding="none">
      <Card.Body padding="none">
        <DataList
          bottomDivider={false}
          data-testid="List-Product-Info-Skeleton"
        >
          <ProductInfo.Skeleton />
          <DataList.Row
            paddingY="2"
            topDivider={false}
            backgroundColor="neutral-surface"
          >
            <Fields.Skeleton />
          </DataList.Row>
          <DataList.Row paddingY="2" topDivider={false}>
            <Fields.Skeleton />
          </DataList.Row>
          <DataList.Row
            paddingY="2"
            topDivider={false}
            backgroundColor="neutral-surface"
          >
            <Fields.Skeleton />
          </DataList.Row>
        </DataList>
      </Card.Body>
    </Card>
  );
}

ListProductInfo.Skeleton = Skeleton;

export default ListProductInfo;
