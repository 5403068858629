import React from 'react';
import useShowProofOfLifeErrorAlert from 'commons/hooks/useShowProofOfLifeErrorAlert';
import PendingProofOfLifeAlert from 'domains/Configuration/PersonalData/IdentityAlert/NewIdentityAlert/PendingProofOfLifeAlert';

export default function ProofOfLifeErrorHomeAlert(): JSX.Element | null {
  const proofOfLifeErrorAlert = useShowProofOfLifeErrorAlert();
  if (!proofOfLifeErrorAlert.show) return null;

  return (
    <PendingProofOfLifeAlert
      proofOfLifeUrl={proofOfLifeErrorAlert.proofOfLifeUrl}
      errorCode={proofOfLifeErrorAlert.errorCode}
    />
  );
}
