import React, { useState } from 'react';
import { Box, Title } from '@nimbus-ds/components';
import { useQuery } from '@tanstack/react-query';
import { ErrorMessage } from 'commons/components';
import { usePrevious } from 'commons/hooks';
import { transactionsService } from 'commons/services';
import { DateRangeType, TransactionObjectInterface } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import { transactionStatusLabelMap } from 'domains/Home/StatusLabel';
import { useIntersectionObserver } from 'usehooks-ts';
import TransactionsFilter from '../TransactionsFilter';
import HomeTableBody from './HomeTableBody';
import HomeTableMobileSkeleton from './HomeTableMobileSkeleton';

function HomeTableMobile(): JSX.Element | null {
  const { t } = useTranslationWithPrefix('home.homeTable');
  const [transactions, setTransactions] = useState(
    [] as TransactionObjectInterface[],
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoadingFirstMount, setIsLoadingFirstMount] = useState(true);
  const [searchFilter, setSearchFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRangeType>();
  const [hasEmptyTransactions, setHasEmptyTransactions] = useState(false);

  const previousFilters = usePrevious({
    searchFilter,
    statusFilter,
    fromDateFilter,
    toDateFilter,
    dateRangeFilter,
  });

  const verifyEmptyTransactions = (
    transactions: TransactionObjectInterface[],
  ) => {
    const searchPerformed = searchFilter || statusFilter || dateRangeFilter;
    if (!transactions.length && !searchPerformed) {
      setHasEmptyTransactions(true);
    }
  };

  const filtersChanged = (): boolean => {
    return (
      searchFilter !== previousFilters?.searchFilter ||
      statusFilter !== previousFilters?.statusFilter ||
      fromDateFilter !== previousFilters?.fromDateFilter ||
      toDateFilter !== previousFilters?.toDateFilter ||
      dateRangeFilter !== previousFilters?.dateRangeFilter
    );
  };

  const transactionsQuery = useQuery(
    [
      'transactions',
      pageNumber,
      searchFilter,
      statusFilter,
      dateRangeFilter,
      fromDateFilter,
      toDateFilter,
    ],
    () =>
      transactionsService.getTransactions(
        pageNumber,
        searchFilter,
        statusFilter,
        dateRangeFilter,
        fromDateFilter,
        toDateFilter,
        statusFilter === transactionStatusLabelMap.chargebacked,
      ),
    {
      onSuccess: (response) => {
        setIsLoadingFirstMount(false);
        if (pageNumber === 1) {
          setTransactions([]);
        }
        if (filtersChanged() && pageNumber !== 1) {
          setPageNumber(1);
          return;
        }
        verifyEmptyTransactions(response.transactions);
        setTransactions((oldTransactions) => [
          ...oldTransactions,
          ...response.transactions,
        ]);
        setTotalPages(response.itemsCount / response.itemsPerPage);
        setTotalItems(response.itemsCount);
      },
    },
  );

  const fetchMoreItems = () => {
    if (transactionsQuery.isLoading || pageNumber >= totalPages) return;
    const nextPageNumber = pageNumber + 1;
    setPageNumber(nextPageNumber);
  };

  const { ref } = useIntersectionObserver({
    onChange: (isIntersecting) => isIntersecting && fetchMoreItems(),
  });

  const title = t('title');

  if (transactionsQuery.isError) {
    return (
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <Title as="h2">{title}</Title>
        <ErrorMessage />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      {isLoadingFirstMount ? (
        <Title.Skeleton as="h2" />
      ) : (
        <Title as="h2">{title}</Title>
      )}
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <TransactionsFilter
          skeleton={isLoadingFirstMount}
          setSearchFilter={setSearchFilter}
          setStatusFilter={setStatusFilter}
          setDateRangeFilter={setDateRangeFilter}
          setFromDateFilter={setFromDateFilter}
          setToDateFilter={setToDateFilter}
          transactionsCount={
            searchFilter && !transactionsQuery.isLoading
              ? totalItems
              : undefined
          }
        />
        <HomeTableBody
          hasEmptyTransactions={hasEmptyTransactions}
          transactions={transactions}
          isLoading={transactionsQuery.isLoading}
          aRef={ref}
        />
      </Box>
    </Box>
  );
}

HomeTableMobile.Skeleton = HomeTableMobileSkeleton;
export default HomeTableMobile;
