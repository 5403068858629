import { NotificationStyleType } from 'commons/types';
import { AppearanceTypes } from './types';

export const notificationStyle: Record<NotificationStyleType, AppearanceTypes> =
  {
    info: 'primary',
    success: 'success',
    warning: 'warning',
    error: 'danger',
  };

export const notificationActionStyle: Record<
  NotificationStyleType,
  'primary' | 'danger' | 'neutral'
> = {
  success: 'primary',
  info: 'primary',
  warning: 'neutral',
  error: 'danger',
};
