export function safelySetIntoLocalStorage(key: string, value: string): void {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    /* eslint-disable-next-line no-console */
    console.warn('[set] localStorage not available', e);
  }
}

export function safelyGetFromLocalStorage(key: string): string | null {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    /* eslint-disable-next-line no-console */
    console.warn('[get] localStorage not available', e);
    return null;
  }
}

export function safelyRemoveFromLocalStorage(key: string): void {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    /* eslint-disable-next-line no-console */
    console.warn('[remove] localStorage not available', e);
  }
}

export function safelySetIntoLocalStorageWithTTL(
  key: string,
  value: string,
  ttlInHours: number,
): void {
  const now = new Date();
  const ttlInMiliseconds = ttlInHours * 3600000;
  const item = {
    value: value,
    ttl: now.getTime() + ttlInMiliseconds,
  };
  safelySetIntoLocalStorage(key, JSON.stringify(item));
}

export function safelyGetFromLocalStorageWithTTL(key: string): string | null {
  const item = safelyGetFromLocalStorage(key);

  if (item === null) return null;

  const itemParsed = JSON.parse(item);
  const now = new Date();
  if (itemParsed.ttl && now.getTime() > itemParsed.ttl) {
    safelyRemoveFromLocalStorage(key);
    return null;
  }

  return itemParsed.value;
}
