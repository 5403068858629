export function makeOnlyNumberMask(value: string) {
  const currentValue = (value || '').toUpperCase();
  value = currentValue.replace(/\D/g, '');
  return value;
}

export function makeXOrDigitMask(value: string): string {
  const currentValue = (value || '').toUpperCase();

  if (currentValue.startsWith('X')) {
    value = 'X';
  } else {
    value = currentValue.replace(/\D/g, '');
  }
  return value;
}
