import { ROUTES } from 'App/Routes/constants';
import { History } from 'history';
import redirectToOrder from './redirectToOrder';

export function redirectToTransaction(
  orderId: number,
  isOldTransaction: boolean,
  isAbandonedCart: boolean,
  history: History,
) {
  if (isOldTransaction) redirectToOrder(orderId, isAbandonedCart);
  else history.push(`${ROUTES.TRANSACTION_DETAILS}/${orderId}`);
}
