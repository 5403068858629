export function objectToQuery<T = any>(queryObj: T) {
  let queryString = '';

  Object.entries(queryObj as T as any)?.forEach((filter) => {
    if (filter[1]) {
      const filterString = `${filter[0]}=${filter[1]}`;
      queryString = queryString.length
        ? `${queryString}&${filterString}`
        : filterString;
    }
  });

  return queryString;
}
