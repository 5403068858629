import React from 'react';
import { Card, Text, Title } from '@nimbus-ds/components';

interface InterfaceLoadingCard {
  title?: string;
  withHeaderLabel?: boolean;
  children?: JSX.Element;
}

function LoadingCard({
  title,
  withHeaderLabel,
  children,
}: InterfaceLoadingCard): JSX.Element {
  return (
    <Card>
      {withHeaderLabel && (
        <Card.Header padding="none">
          {title ? <Title as="h3">{title}</Title> : <Title.Skeleton as="h3" />}
        </Card.Header>
      )}
      {children ? children : <Text.Skeleton />}
    </Card>
  );
}

export default LoadingCard;
