import React, { useState } from 'react';
import { Box, Button, Modal, Text } from '@nimbus-ds/components';
import { useFeatureDiscovery } from 'commons/hooks';
import { FeatureName } from 'commons/services/featureDiscoveryService/featureDiscoveryServiceResponses';
import { useTranslationWithPrefix } from 'commons/utils';

function MinInstallmentFeatureModal(): JSX.Element {
  const { t } = useTranslationWithPrefix(
    'installments.minInstallmentFeatureModal',
  );
  const featureDiscovery = useFeatureDiscovery(FeatureName.MinInstallmentValue);
  const [modalDismissed, setModalDismissed] = useState(false);

  const modalOpen =
    !featureDiscovery.isLoading &&
    !featureDiscovery?.isDiscovered &&
    !modalDismissed;

  return (
    <Modal open={modalOpen} onDismiss={() => setModalDismissed(true)}>
      <Modal.Header title={t('title')} />
      <Box alignItems="stretch">
        <Text color="neutral-textHigh">{t('description')}</Text>
      </Box>
      <Modal.Footer>
        <Button
          appearance="primary"
          onClick={() => featureDiscovery.markAsDiscovered.mutate()}
          disabled={featureDiscovery.isMutating}
        >
          {t('button')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MinInstallmentFeatureModal;
