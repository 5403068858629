import { hasKycErrors } from 'domains/Configuration/PersonalData/IdentityAlert/NewIdentityAlert/getKycError';
import { useIdentityValidation } from './useIdentityValidation';

export default function useShowProofOfLifeErrorAlert() {
  const idValidation = useIdentityValidation();

  if (!idValidation.data)
    return {
      show: false,
    };

  const { hasProofOfLifeError } = hasKycErrors(
    idValidation.data.kycDocumentErrors,
  );
  const showProofOfLifeErrorAlert =
    hasProofOfLifeError && idValidation.data.status === 'active';

  return {
    show: showProofOfLifeErrorAlert,
    proofOfLifeUrl: idValidation.data.proofOfLifeUrl,
    errorCode: idValidation.data.kycDocumentErrors.find(
      (documentError) => documentError.documentName === 'proof_of_life',
    )?.errorCode,
  };
}
