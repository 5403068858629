import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@nimbus-ds/components';
import { ExclamationTriangleIcon } from '@nimbus-ds/icons';

function ErrorMessage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="2"
      justifyContent="center"
    >
      <ExclamationTriangleIcon size="medium" />
      <Text textAlign="center">{t('errorMessage.title')}</Text>
    </Box>
  );
}

export default ErrorMessage;
