import React from 'react';
import { Box, Button, Card, Text } from '@nimbus-ds/components';
import Money from 'domains/Home/Money';

interface MoneyCardProps {
  withPrimaryButton?: boolean;
  color: 'primary' | 'success' | 'warning' | 'danger' | 'neutral';
}

function MoneyCardSkeleton({
  withPrimaryButton = false,
  color,
}: MoneyCardProps): JSX.Element {
  return (
    <Card backgroundColor={`${color}-surface`} padding="none">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="4"
      >
        <Box display="flex" flexDirection="column" gap="1">
          <Text.Skeleton />
          <Money.Skeleton />
        </Box>
        {withPrimaryButton && <Button.Skeleton />}
      </Box>
    </Card>
  );
}

export default MoneyCardSkeleton;
