import React, { useContext } from 'react';
import { Box, Icon, Text, Title } from '@nimbus-ds/components';
import { ArrowRightIcon } from '@nimbus-ds/icons';
import { useQuery } from '@tanstack/react-query';
import { useStoreInfo } from 'commons/hooks';
import { withdrawsService } from 'commons/services';
import { formatCurrency, useTranslationWithPrefix } from 'commons/utils';
import { FiltersContext } from '../FiltersContext';
import TransferedSkeleton from './TransferedSkeleton';

function Transfered(): JSX.Element | null {
  const { t } = useTranslationWithPrefix('withdraws.transfered');
  const { fromDateFilter, toDateFilter, dateRangeFilter, searched } =
    useContext(FiltersContext);
  const { storeInfo } = useStoreInfo();
  const totalAmountQuery = useQuery(
    // TODO: look for a way of handling query keys
    ['withdrawsTotalAmount', dateRangeFilter, fromDateFilter, toDateFilter],
    () =>
      withdrawsService.getTotalAmount(
        dateRangeFilter,
        fromDateFilter,
        toDateFilter,
      ),
    {
      // TODO: remove retry when defined retry method
      retry: false,
    },
  );
  const amount = totalAmountQuery.data?.amount || { value: 0, currency: 'ARS' };

  if (totalAmountQuery.isLoading) {
    return <TransferedSkeleton />;
  }

  if (amount.value === 0 && !searched) return null;

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap="4">
      <Box
        backgroundColor="danger-surface"
        height="36px"
        width="36px"
        minHeight="36px"
        minWidth="36px"
        borderRadius="2"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Icon
          data-testid="callout-card-icon"
          source={
            <ArrowRightIcon
              size="medium"
              style={{ transform: 'rotate(-90deg)' }}
            />
          }
        />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" gap="2">
        <Text fontSize="highlight">{t('totalTransfered')}</Text>
        <Title as="h2">{formatCurrency(amount, storeInfo)}</Title>
      </Box>
    </Box>
  );
}

export default Transfered;
