import { DateRangeType } from 'commons/types';
import moment from 'moment';

export default function getFromDate(
  dateRange?: DateRangeType,
  from?: string,
): string {
  switch (dateRange) {
    case 'yesterday':
      return moment().subtract(1, 'days').format('DD/MM/YYYY');

    case 'seven_days':
      return moment().subtract(7, 'days').format('DD/MM/YYYY');

    case 'thirty_days':
      return moment().subtract(30, 'days').format('DD/MM/YYYY');

    case 'custom':
      return moment(from).format('DD/MM/YYYY');

    default:
      return '';
  }
}
