import { PaymentMethodsType } from 'commons/types';
import { PaymentMethod } from '../types/PaymentMethod.enum';

export const isCreditCard = (paymentMethod: string | PaymentMethodsType) =>
  paymentMethod === PaymentMethod.CreditCard;

export const isPix = (paymentMethod: string | PaymentMethodsType) =>
  paymentMethod === PaymentMethod.Pix;

export const isRefundablePaymentMethod = (
  paymentMethod: string | PaymentMethodsType,
) => isPix(paymentMethod) || isCreditCard(paymentMethod);
