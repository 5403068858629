import { TFunction } from 'react-i18next';
import { ExclamationCircleIcon, MoneyIcon, UndoIcon } from '@nimbus-ds/icons';
import { ChargebackStatus, TransactionStatusType } from 'commons/types';
import { ChargebackType } from 'commons/types';

type appearanceTypes =
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'neutral'
  | undefined;

interface transactionTagPropsValue {
  id: string;
  label: string;
  icon: any;
  appearance: appearanceTypes;
}

const transactionTagProps = (
  statusType: TransactionStatusType,
  t: TFunction<string, undefined>,
  tagKey: string,
  rejectedText = 'rejected',
  chargeback?: ChargebackType,
): transactionTagPropsValue => {
  const approvedTag = {
    label: t('commons.transaction.status.approved'),
    icon: MoneyIcon,
    appearance: 'success' as appearanceTypes,
  };

  const rejectedTag = {
    label: t(`commons.transaction.status.${rejectedText}`),
    icon: MoneyIcon,
    appearance: 'danger' as appearanceTypes,
  };

  const pendingTag = {
    label: t('commons.transaction.status.pending'),
    icon: MoneyIcon,
    appearance: 'warning' as appearanceTypes,
  };

  const refundTag = {
    label: t('commons.transaction.status.refund'),
    icon: UndoIcon,
    appearance: 'neutral' as appearanceTypes,
  };

  const partialRefundTag = {
    label: t('commons.transaction.status.partialRefund'),
    icon: UndoIcon,
    appearance: 'neutral' as appearanceTypes,
  };

  const chargebackTag = {
    label: t('commons.transaction.status.chargeback'),
    icon: ExclamationCircleIcon,
    appearance: 'neutral' as appearanceTypes,
  };

  const tags = {
    authorized: pendingTag,
    pending: pendingTag,
    fraud_analysis_approved: pendingTag,
    paid: approvedTag,
    refunded: refundTag,
    partially_refunded: partialRefundTag,
    failed: rejectedTag,
    chargebacked: chargebackTag,
    chargeback_dispute: chargebackTag,
  };

  const chargebackPendingTag = {
    label: t('commons.transaction.status.chargebackV2.pending'),
    icon: ExclamationCircleIcon,
    appearance: 'warning' as appearanceTypes,
  };

  const chargebackDisputeRejectedTag = {
    label: t('commons.transaction.status.chargebackV2.disputeRejected'),
    icon: ExclamationCircleIcon,
    appearance: 'danger' as appearanceTypes,
  };

  const chargebackAnalysisTag = {
    label: t('commons.transaction.status.chargebackV2.dispute'),
    icon: ExclamationCircleIcon,
    appearance: 'primary' as appearanceTypes,
  };

  const chargebackWithoutDisputeTag = {
    label: t('commons.transaction.status.chargebackV2.withoutDispute'),
    icon: ExclamationCircleIcon,
    appearance: 'neutral' as appearanceTypes,
  };

  const chargebackResolvedCustomerTag = {
    label: t('commons.transaction.status.chargebackV2.resolved'),
    icon: ExclamationCircleIcon,
    appearance: 'neutral' as appearanceTypes,
  };

  const chargebackResolvedMerchantTag = {
    label: t('commons.transaction.status.chargebackV2.resolved'),
    icon: ExclamationCircleIcon,
    appearance: 'success' as appearanceTypes,
  };

  const chargebackV2Tags = {
    [ChargebackStatus.Pending]: chargebackPendingTag,
    [ChargebackStatus.ResolvedWithoutDispute]: chargebackWithoutDisputeTag,
    [ChargebackStatus.DisputeSent]: chargebackAnalysisTag,
    [ChargebackStatus.DisputeReceived]: chargebackAnalysisTag,
    [ChargebackStatus.ResolvedMerchant]: chargebackResolvedMerchantTag,
    [ChargebackStatus.ResolvedInsurance]: chargebackResolvedMerchantTag,
    [ChargebackStatus.ResolvedCustomer]: chargebackResolvedCustomerTag,
    [ChargebackStatus.DisputeRejected]: chargebackDisputeRejectedTag,
  };

  return chargeback
    ? { id: `id-${tagKey}`, ...chargebackV2Tags[chargeback.status] }
    : {
        id: `id-${tagKey}`,
        ...tags[statusType],
      };
};

export default transactionTagProps;
