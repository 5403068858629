import { createSlice } from '@reduxjs/toolkit';
import { IBankAccount } from './types';

const setBankAccount = (state: IBankAccount, action: any) =>
  (state = action.payload);

const bankAccountInitialState: IBankAccount = {
  fiscalNumber: '',
  cbu: '',
  beneficiary: '',
  bank: '',
  isRegistered: false,
  hasError: false,
};

const bankAccountSlice = createSlice({
  name: 'bankAccount',
  initialState: bankAccountInitialState,
  reducers: {
    setBankAccountAction: setBankAccount,
  },
});

export const { setBankAccountAction } = bankAccountSlice.actions;

export default bankAccountSlice.reducer;
