import { Locale } from 'App/i18n/enums';
import { MoneyObjectInterface } from 'commons/types';
import { IStoreInfo } from 'redux/slices/types';
import 'moment/locale/es';
import 'moment/locale/pt';

export const buildQueryParamsUrlWithSnakeCase = (
  object: Record<string, string>,
): string =>
  Object.keys(object)
    .map(
      (key) =>
        `${key
          .replace(/([A-Z])/g, ' $1')
          .split(' ')
          .join('_')
          .toLowerCase()}=${object[key]}`,
    )
    .join('&');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const removeEmptyValues = <T>(object: any): Record<string, string> =>
  Object.keys(object).reduce((acc, key: any) => {
    if (object[key] !== '') {
      return { ...acc, [key]: object[key].toString() };
    }
    return acc;
  }, {});

const getCurrencyByCountry = (country: string) => {
  switch (country) {
    case 'AR':
      return 'ARS';

    case 'BR':
      return 'BRL';

    case 'MX':
      return 'MXN';

    default:
      break;
  }
};

export const formatCurrency = (
  money: MoneyObjectInterface,
  storeInfo: IStoreInfo,
): string => {
  const locale = `${storeInfo.language}-${storeInfo.country}`;
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: money.currency || getCurrencyByCountry(storeInfo.country),
  })
    .format(money.value)
    .replace(/\s/g, '');
};

export const formatCurrencyWithStoreInfo = (
  value: number,
  storeInfo: IStoreInfo,
): string => {
  const locale = `${storeInfo.language}-${storeInfo.country}`;
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: getCurrencyByCountry(storeInfo.country),
  })
    .format(value)
    .replace(/\s/g, '');
};

const validateText = (text: string) => (typeof text === 'string' ? text : '');

/** TODO: from mAPI return string with null, FIX mAPI! */
export const emptyIsNullInText = (text: string): string => {
  return validateText(text).search('null') !== -1 ? '' : text;
};

export const cleanBlanks = (text: string): string =>
  validateText(text).replace(/ /g, '');
// TODO: Analyze if the BFF could return the small image in another property
/**
 * Function valid to obtein image url in 100px from CDN e.g.
 * https://d26lpennugtm8s.cloudfront.net/stores/[STOREID]/products/[NAME][HASH]
 * 1024-1024.jpg
 *
 * @param url
 */
export const imageIn100pxfromCDN = (url: string): string =>
  validateText(url).replace('1024-1024.', '100-0.');

export const countNewLineInText = (text: string): number =>
  validateText(text).split('\n').length || 1;

export const getFixedLocaleFromLanguage = (language: string): Locale => {
  if (language.startsWith('es')) {
    return Locale.ARGENTINA;
  } else {
    return Locale.BRAZIL;
  }
};

export const getFixedLocaleFromLocale = (locale: string): Locale => {
  const language = locale.split('-')[0];
  if (language === 'es') {
    if (locale === Locale.ARGENTINA) {
      return Locale.ARGENTINA;
    } else {
      return Locale.MEXICO;
    }
  } else {
    return Locale.BRAZIL;
  }
};

export const isJuridicalPerson = (fiscalNumber?: string): boolean => {
  if (!fiscalNumber) return false;
  return fiscalNumber.startsWith('3');
};

export const getCuitOrCuilLabel = (fiscalNumber: string): string => {
  if (!fiscalNumber) return 'CUIT/CUIL';
  if (isJuridicalPerson(fiscalNumber)) {
    return 'CUIT';
  }
  return 'CUIT/CUIL';
};

export const formatCuit = (cuit: string): string => {
  if (!cuit) return '';
  const lastInx = cuit.length - 1;
  return `${cuit.substring(0, 2)}-${cuit.substring(
    2,
    lastInx,
  )}-${cuit.substring(lastInx)}`;
};

export const pdfThumbnailPath = `${process.env.REACT_APP_PUBLIC_URL}/assets/img/pdfThumbnail.png`;

export const capitalize = (word: string): string => {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

export const formatPercentage = (value: number): string => {
  const normalizedValue = value <= 1 ? value * 100 : value;
  return (
    // eslint-disable-next-line prefer-template
    Intl.NumberFormat('es-AR', { maximumFractionDigits: 2 }).format(
      normalizedValue,
    ) + '%'
  );
};

export const snakeToTitleCase = (s: string) => {
  return s
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`);
};

export { default as transactionLabelProps } from './transactionStatusLabelProps';
export { default as transactionTagProps } from './transactionTagProps';
export { default as withdrawTagProps } from './withdrawStatusTagProps';
export { default as useTranslationWithPrefix } from './useTranslationWithPrefix';
export { default as redirectToOrder } from './redirectToOrder';
export { default as bankAccountIsUnknown } from './bankAccountIsUnknown';
export * from './dateUtils';
export * from './failure-code.enum';
export * from './transformToCamel';
export * from './getUrlSearchParams';
export * as cbuValidator from './cbu';
export * from './downloadFromUrl';
export * from './redirectToTransaction';
export * from './isOldTransaction';
