import { useEffect, useState } from 'react';

const MAX_PIXELS_MOBILE_DEVICE = 992;

export default function useIsMobile(): boolean {
  const isMobileWindowWidth = () =>
    window.innerWidth < MAX_PIXELS_MOBILE_DEVICE;
  const [isMobile, setIsMobile] = useState(isMobileWindowWidth());

  useEffect(() => {
    function handleResize() {
      setIsMobile(isMobileWindowWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
}
