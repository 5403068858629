import axios from 'App/axios';
import camelcase from 'camelcase';

type UploadDocumentsResponse = {
  name: string;
  url: string;
};

type UploadDocumentsType = (
  name: string,
  document: File,
) => Promise<UploadDocumentsResponse>;

// TODO: move to utils
const getBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function DataURIToBlob(dataURI: string) {
  const splitDataURI = dataURI.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

const uploadDocuments: UploadDocumentsType = async (
  name: string,
  document: File,
) => {
  // TODO: REINTENTOS (axios-retry)
  // TODO: ver si el limite es en imagen o decode64
  // TODO: look for a better solution
  const renamedDocument = new File([document], name, { type: document.type });
  const fileBase64 = (await getBase64(renamedDocument)) as string;
  const blob = DataURIToBlob(fileBase64);
  const file = new File([blob], name, { type: document.type });

  const data = new FormData();
  data.append('file', file);

  const response = await axios.post(`/admin/documents`, data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

  return response.data;
};

type ValidDocuments =
  | 'dniFront'
  | 'dniBack'
  | `dniFront${number}`
  | `dniBack${number}`
  | 'companyComposition';

type ValidDocumentsSnakeCase =
  | 'dni_front'
  | 'dni_back'
  | `dni_front${number}`
  | `dni_back${number}`
  | 'company_composition';

type InterceptedGetDocumentsResponse = {
  [documentKey in ValidDocuments & ValidDocumentsSnakeCase]: string;
};

type GetDocumentsResponse = {
  [documentKey in ValidDocuments]: string;
};

async function getDocuments(): Promise<GetDocumentsResponse | undefined> {
  let response: InterceptedGetDocumentsResponse | undefined;
  try {
    response = (
      await axios.get<InterceptedGetDocumentsResponse>('/admin/documents')
    ).data;
  } catch (error: any) {
    if (error.isAxiosError && error.response?.status === 404) {
      // when no documents present, api returns 404
      return;
    } else {
      throw error;
    }
  }

  /*
    App/axios interceptor returns the response with duplicated keys, both in camelCase and snake_case.
    See: https://github.com/TiendaNube/services-nuvem-pago-new-admin-app/blob/devel/src/App/axios.ts#L20
    So, if the API returns
    {
      "company_composition": "url",
      "dni_back1": "url",
      "dni_front1": "url"
    }

    It transforms it to:
    {
      "company_composition": "url",
      "dni_back1": "url",
      "dni_front1": "url",
      "companyComposition": "url",
      "dniBack1": "url",
      "dniFront1": "url"
    }

    This causes https://tiendanube.atlassian.net/browse/FNPC-1197 because the Documents component asserts
    that there are more documents than expected and it breaks the natural document validation.
  */
  const onlyCamelCaseDocuments = Object.entries(response).reduce(
    (accumulator, currentValue) => {
      const [key, value] = currentValue;
      const keyOriginallyIsCamelCase = camelcase(key) === key;
      if (keyOriginallyIsCamelCase) {
        return {
          ...accumulator,
          [key]: value,
        };
      } else {
        return accumulator;
      }
    },
    {} as GetDocumentsResponse,
  );
  return onlyCamelCaseDocuments;
}

type PostKycInfoType = (numberOfOwners: number) => Promise<void>;

const postKycInfo: PostKycInfoType = async (numberOfOwners: number) => {
  const response = await axios.post('/admin/kyc-info', {
    numberOfOwners,
  });
  return response.data;
};

const documentService = {
  uploadDocuments,
  getDocuments,
  postKycInfo,
};

export default documentService;
