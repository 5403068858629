import React from 'react';
import { Box, Link, Table, Text } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { useIsMobile } from 'commons/hooks';

interface IncomesByOrderSkeletonPops {
  numberOfRows?: number;
}

function IncomesByOrderSkeleton({
  numberOfRows = 7,
}: IncomesByOrderSkeletonPops): JSX.Element {
  const isMobile = useIsMobile();
  const mobileRows = [];
  for (let i = 0; i < numberOfRows; i++) {
    mobileRows.push(
      <DataList.Row key={i} gap="1">
        <Box display="flex" justifyContent="space-between">
          <Link.Skeleton />
          <Text.Skeleton />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Text.Skeleton />
          <Text.Skeleton />
        </Box>
      </DataList.Row>,
    );
  }

  const desktopRows = [];
  for (let i = 0; i < numberOfRows; i++) {
    desktopRows.push(
      <Table.Row key={i}>
        <Table.Cell>
          <Link.Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Text.Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Text.Skeleton />
        </Table.Cell>
        <Table.Cell>
          <Text.Skeleton />
        </Table.Cell>
      </Table.Row>,
    );
  }

  const desktopHeaders = [];
  for (let i = 0; i < 4; i++) {
    desktopHeaders.push(
      <Table.Cell backgroundColor="neutral-surface" key={i}>
        <Text.Skeleton />
      </Table.Cell>,
    );
  }

  if (isMobile) return <DataList>{mobileRows}</DataList>;
  return (
    <Table>
      <Table.Head>{desktopHeaders}</Table.Head>
      <Table.Body>{desktopRows}</Table.Body>
    </Table>
  );
}

export default IncomesByOrderSkeleton;
