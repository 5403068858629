import React, { useEffect } from 'react';
import { Box, Button, Link, Text, Title } from '@nimbus-ds/components';
import { navigateHeaderRemove } from '@tiendanube/nexo/helpers';
import AppLayout from 'App/components/AppLayout';
import nexo from 'App/nexoClient';
import { HelpLink } from 'commons/components';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';

interface EmptyStatePagoNubeInterface {
  title: string;
  primaryActionLabel: string;
  onClickPrimary?: () => void;
  descriptionFirstParagraph: string;
  descriptionSecondParagraph: string;
  onClickSecondary?: () => void;
  secondaryActionLabel?: string;
}

function EmptyStatePagoNube({
  title,
  primaryActionLabel,
  onClickPrimary,
  descriptionFirstParagraph,
  descriptionSecondParagraph,
  onClickSecondary,
  secondaryActionLabel,
}: EmptyStatePagoNubeInterface): JSX.Element {
  const isMobile = useIsMobile();
  const { t } = useTranslationWithPrefix('emptyState');

  useEffect(() => {
    navigateHeaderRemove(nexo);
  }, []);

  return (
    <AppLayout hideBackButton title={t('title')}>
      <Box display="flex" flexDirection="column" gap={isMobile ? '8' : 'none'}>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="center"
          alignItems="center"
          gap="4"
          paddingX="0-5"
        >
          <Box width={isMobile ? '100%' : '60%'}>
            <img src="/assets/img/landing.png" width="100%" />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap="4"
            justifyContent={isMobile ? 'center' : 'flex-start'}
            alignItems={isMobile ? 'center' : 'flex-start'}
          >
            <Title as="h2">{title}</Title>
            <Text textAlign={isMobile ? 'center' : 'left'}>
              {descriptionFirstParagraph}
            </Text>
            <Text textAlign={isMobile ? 'center' : 'left'}>
              {descriptionSecondParagraph}
            </Text>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              gap="4"
            >
              <Button appearance="primary" onClick={onClickPrimary}>
                {primaryActionLabel}
              </Button>
              <Link appearance="primary" onClick={onClickSecondary}>
                {secondaryActionLabel}
              </Link>
            </Box>
          </Box>
        </Box>
        <HelpLink text={t('helpLink.text')} href={t('helpLink.href')} />
      </Box>
    </AppLayout>
  );
}

export default EmptyStatePagoNube;
