import React, { useCallback, useMemo, useState } from 'react';

export enum NotificationType {
  RefundSuccess = 'RefundSuccess',
  PartialRefundSuccess = 'PartialRefundSuccess',
  WithdrawalFailed = 'WithdrawalFailed',
}

export type NotificationAlert = {
  id: string;
  type: NotificationType;
  content: string;
  appearance?: 'primary' | 'success' | 'warning' | 'danger' | 'neutral';
  title?: string;
};

export interface NotificationContextInterface {
  pushNotification: (notification: NotificationAlert) => void;
  findNotificationByType: (type: NotificationType) => NotificationAlert[];
  removeNotification: (id: string) => void;
}

export const NotificationBRContext =
  React.createContext<NotificationContextInterface>(
    {} as NotificationContextInterface,
  );

export default function NotificationBRProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [notifications, setNotifications] = useState<NotificationAlert[]>([]);

  const pushNotification = useCallback(
    (notification: NotificationAlert) => {
      const exists = notifications.find(
        (notificationStored) => notificationStored.id === notification.id,
      );

      if (exists) return;

      setNotifications([...notifications, notification]);
    },
    [notifications],
  );

  const findNotificationByType = useCallback(
    (type: NotificationType) => {
      return notifications.filter((notification) => notification.type === type);
    },
    [notifications],
  );

  const removeNotification = useCallback(
    (id: string) => {
      const newNotificationsList = notifications.filter(
        (notification) => notification.id !== id,
      );
      setNotifications(newNotificationsList);
    },
    [notifications],
  );

  const context = useMemo(() => {
    return { removeNotification, pushNotification, findNotificationByType };
  }, [findNotificationByType, pushNotification, removeNotification]);

  return (
    <NotificationBRContext.Provider value={context}>
      {children}
    </NotificationBRContext.Provider>
  );
}
