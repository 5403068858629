import { createSlice } from '@reduxjs/toolkit';
import { IIdentityValidation } from './types';

const setIdentityValidation = (state: IIdentityValidation, action: any) =>
  (state = action.payload);

const identityValidationInitialState: IIdentityValidation = {
  status: 'pending',
  kycDocumentErrors: [],
  updatedAt: '',
};

const identityValidationSlice = createSlice({
  name: 'identityValidation',
  initialState: identityValidationInitialState,
  reducers: {
    setIdentityValidationAction: setIdentityValidation,
  },
});

export const { setIdentityValidationAction } = identityValidationSlice.actions;

export default identityValidationSlice.reducer;
