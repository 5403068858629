import axios from 'App/axios';
import { ReportDateRangeType, ReportStatus } from 'commons/types';
import { reportRangeToDates } from 'commons/utils';

export type SendReportResponseDataType = {
  requestId: number;
};

export interface SendReportArgs {
  dateRange: ReportDateRangeType;
  fromDate?: string;
  toDate?: string;
}

const sendReport = async (
  dateRange: ReportDateRangeType,
  fromDate?: string,
  toDate?: string,
) => {
  const data = reportRangeToDates(dateRange, fromDate, toDate);

  const response = await axios.post(`/admin/transactions-report`, data);
  return response.data;
};

const sendReportV2 = async (
  dateRange: ReportDateRangeType,
  fromDate?: string,
  toDate?: string,
) => {
  const data = reportRangeToDates(dateRange, fromDate, toDate);

  const response = await axios.post<SendReportResponseDataType>(
    `/admin/v2/transactions-report`,
    data,
  );
  return response.data;
};

export type ReportStatusDataType = {
  status: ReportStatus;
  temporaryDownloadUrl?: string;
};

const getReportStatus = async (requestId: number) => {
  const response = await axios.get<ReportStatusDataType>(
    `/admin/transactions-report/${requestId}`,
  );
  return response.data;
};

const sendReportService = {
  sendReport,
  sendReportV2,
  getReportStatus,
};

export default sendReportService;
