import React, { useEffect, useState } from 'react';
import { Box, Card, Checkbox, Text, Title } from '@nimbus-ds/components';
import ThumbnailFile from 'commons/components/ThumbnailFile/ThumbnailFile';
import { DocumentsInterface } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import SingleDniDocumentSkeleton from './SingleDniDocumentSkeleton';

interface SingleDniDocumentInterface {
  dniThumbnailFiles: DocumentsInterface;
  showErrors: boolean;
  title: string;
  verifyUploadedDocuments: React.Dispatch<React.SetStateAction<boolean>>;
  eligiblePerson?: boolean;
  toggleCheckbox?: () => void;
}

function SingleDniDocument({
  dniThumbnailFiles = {},
  showErrors = false,
  title,
  verifyUploadedDocuments,
  eligiblePerson,
  toggleCheckbox,
}: SingleDniDocumentInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');
  const [uploadedDocuments, setUploadedDocuments] = useState(0);
  const [errorText, setErrorText] = useState({
    show: false,
    text: '',
  });

  // TODO: revisar todo los useEffect que dependen de thumbnailFiles o similar
  // y fijarse si se pueden poner como estado inicial. Me parece que en el context
  // tiene un loading que evita que renderice sin la info

  useEffect(() => {
    const dniBack = dniThumbnailFiles.dniBack ? 1 : 0;
    const dniFront = dniThumbnailFiles.dniFront ? 1 : 0;

    setUploadedDocuments(dniBack + dniFront);
  }, [dniThumbnailFiles]);

  useEffect(() => {
    verifyUploadedDocuments(uploadedDocuments === 2);
  }, [uploadedDocuments, verifyUploadedDocuments]);

  useEffect(() => {
    if (showErrors && uploadedDocuments !== 2) {
      setErrorText({
        show: true,
        text: t('errors.uploadEveryFile'),
      });
    } else {
      setErrorText({
        show: false,
        text: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showErrors, uploadedDocuments]);

  const renderDni = (side: string, thumbnailFile: string) => (
    <ThumbnailFile
      aspectRatio="1/1"
      documentName={`dni${side}`}
      onSuccess={() => setUploadedDocuments((uplDocs) => uplDocs + 1)}
      onError={(text: string) => setErrorText({ show: true, text })}
      ariaLabel={`dni${side}`}
      helperText={t(`dniFile.${side}`)}
      accept="image/jpeg,image/png,application/pdf"
      initialThumbnailFile={thumbnailFile}
      onDelete={() => setUploadedDocuments((uplDocs) => uplDocs - 1)}
      onRow
    />
  );

  return (
    <Card>
      <Card.Header>
        <Title as="h4">{title}</Title>
      </Card.Header>
      <Box display="flex" flexDirection="column" gap="4">
        <Box display="flex" gap="6" maxWidth="400px">
          {renderDni('Front', dniThumbnailFiles?.dniFront)}
          {renderDni('Back', dniThumbnailFiles?.dniBack)}
        </Box>
        {errorText.show && (
          <Text color="danger-interactive">{errorText.text}</Text>
        )}
        <Checkbox
          label={t('physical.checkbox')}
          name="checkbox"
          checked={eligiblePerson}
          onChange={toggleCheckbox}
        />
      </Box>
    </Card>
  );
}

SingleDniDocument.Skeleton = SingleDniDocumentSkeleton;
export default SingleDniDocument;
