import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Card,
  IconButton,
  Select,
  Text,
} from '@nimbus-ds/components';
import { ChevronLeftIcon, SlidersIcon } from '@nimbus-ds/icons';
import { SideModal } from '@nimbus-ds/patterns';
import CustomRangeSearch from 'commons/components/CustomRangeSearch/CustomRangeSearch';
import { DateRangeType } from 'commons/types';
import {
  getRelativeDateFromToday,
  todayDate,
  useTranslationWithPrefix,
} from 'commons/utils';
import { FiltersContext } from '../../FiltersContext';
import getFromDate from './getFromDate';
import getToDate from './getToDate';
import WithdrawsFilterSkeleton from './WithdrawsFilterSkeleton';

function WithdrawsFilter(): JSX.Element {
  const { t } = useTranslationWithPrefix('withdraws');
  const {
    fromDateFilter,
    toDateFilter,
    dateRangeFilter,
    setFromDateFilter,
    setToDateFilter,
    setDateRangeFilter,
    setSearched,
    setPageNumber,
  } = useContext(FiltersContext);
  const [dateRange, setDateRange] = useState<DateRangeType>(dateRangeFilter);
  const [showSidebar, setShowSidebar] = useState(false);
  const [fromDate, setFromDate] = useState(getRelativeDateFromToday(-30));
  const [toDate, setToDate] = useState(todayDate());

  const dateRangeOptions = [
    'all',
    'yesterday',
    'seven_days',
    'thirty_days',
    'custom',
  ].map((dateRangeOption) => ({
    label: t(`dateRangeOptions.${dateRangeOption}`),
    value: dateRangeOption as DateRangeType,
  }));

  const setAllFilters = () => {
    if (dateRange !== 'custom') {
      setFromDateFilter(undefined);
      setToDateFilter(undefined);
    } else {
      setFromDateFilter(fromDate);
      setToDateFilter(toDate);
    }
    setPageNumber(1);
    setSearched(true);
    setDateRangeFilter(dateRange);
    setShowSidebar(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <Card>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {dateRangeFilter === 'custom' ? (
            <Box>
              <Text>{t('filter.custom')}</Text>
              <Text color="neutral-textDisabled">
                {t('fromTo', {
                  interpolation: { escapeValue: false },
                  from: getFromDate(dateRangeFilter, fromDateFilter),
                  to: getToDate(dateRangeFilter, toDateFilter),
                })}
              </Text>
            </Box>
          ) : (
            <Text>{t(`filter.${dateRangeFilter}`)}</Text>
          )}
          <IconButton
            source={<SlidersIcon size="small" />}
            size="1.75rem"
            onClick={() => setShowSidebar(true)}
          />
        </Box>
      </Card>

      <SideModal
        open={showSidebar}
        onRemove={() => setShowSidebar(false)}
        headerAction={
          <IconButton
            source={<ChevronLeftIcon size="small" />}
            size="1.75rem"
            onClick={() => setShowSidebar(false)}
            backgroundColor="transparent"
            borderColor="transparent"
          />
        }
        padding="small"
        paddingHeader="none"
        title={t('withdrawsSearch.title')}
      >
        <Box
          justifyContent="space-between"
          display="flex"
          flexDirection="column"
          alignItems="stretch"
          gap="8"
        >
          <Box display="flex" flexDirection="column" gap="1">
            <Text fontSize="caption">{t('withdrawsSearch.date')}</Text>
            <Select
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value as DateRangeType)}
              name="selectDate"
              id="selectDate"
            >
              {dateRangeOptions.map((opt) => (
                <Select.Option key={opt.value} {...opt} />
              ))}
            </Select>
          </Box>
          {dateRange === 'custom' && (
            <CustomRangeSearch
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
            />
          )}
          <Box
            justifyContent="flex-end"
            display="flex"
            flexDirection="row"
            alignItems="stretch"
            gap="4"
          >
            <Button onClick={() => setShowSidebar(false)}>
              {t('withdrawsSearch.cancel')}
            </Button>
            <Button appearance="primary" onClick={setAllFilters}>
              {t('withdrawsSearch.filter')}
            </Button>
          </Box>
        </Box>
      </SideModal>
    </Box>
  );
}

WithdrawsFilter.Skeleton = WithdrawsFilterSkeleton;
export default WithdrawsFilter;
