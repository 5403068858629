import axios from 'App/axios';
import { InterceptorStatus } from 'commons/enums/InterceptorStatus.enum';
import { InterceptorResponseType } from 'commons/types/InterceptorResponseType';

const validateTwoFA = async (twoFactorCode: string, withdrawId: number) => {
  try {
    const URL = `/admin/transfers/${withdrawId}/2fa`;
    const { data } = await axios.patch<InterceptorResponseType>(URL, {
      code: twoFactorCode,
    });
    return data.status === InterceptorStatus.Approved;
  } catch (error) {
    return false;
  }
};

export const twoFAService = {
  validateTwoFA,
};
