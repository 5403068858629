export async function downloadFromUrl(url: string): Promise<boolean> {
  try {
    const response = await fetch(url);
    const fileBlob = await response.blob();
    const urlObject = new URL(url);

    // Get file name
    const urlParts = urlObject.pathname.split('/');
    const fileName = urlParts[urlParts.length - 1];

    const fileUrl = window.URL.createObjectURL(fileBlob);
    const link = document.createElement('a');

    link.href = fileUrl;
    link.download = fileName;
    link.click();

    document.body.removeChild(link);

    return true;
  } catch (error) {
    return false;
  }
}
