import React from 'react';
import { Text } from '@nimbus-ds/components';
import { Layout } from '@nimbus-ds/patterns';
import AppLayout from 'App/components/AppLayout';
import { MoneyCard } from 'domains/Home/HomeCards/MoneyCard';
import IncomesByDate from './IncomesByDate/IncomesByDate';
import { IncomesSummary } from './IncomesSummary';

function FutureIncomesSkeleton(): JSX.Element {
  return (
    <AppLayout title={''} skeleton>
      <Layout columns="1">
        <Text.Skeleton />
        <Layout.Section>
          <MoneyCard.Skeleton color="primary" />
        </Layout.Section>
        <Layout.Section>
          <IncomesSummary.Skeleton />
        </Layout.Section>
        <Layout.Section>
          <IncomesByDate.Skeleton />
        </Layout.Section>
      </Layout>
    </AppLayout>
  );
}

export default FutureIncomesSkeleton;
