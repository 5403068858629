import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { track } from '@amplitude/analytics-browser';
import { Alert, AlertProperties, Text } from '@nimbus-ds/components';
import { ChargebackWithOrderDataType } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import ChargebackList from './ChargebackList';

interface ChargebackAlertProps {
  chargebacks: ChargebackWithOrderDataType[];
  status: string;
  appearence: AlertProperties['appearance'];
}

export default function ChargebackAlert({
  chargebacks,
  status,
  appearence,
}: ChargebackAlertProps) {
  const { t } = useTranslationWithPrefix(`home.chargebackAlerts.${status}`);
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    if (showAlert && chargebacks.length) {
      track('pago-nube-contracargo-view', { status });
    }
  }, [chargebacks.length, showAlert, status]);

  return (
    <Alert
      title={t('title', {
        count: chargebacks.length,
      })}
      show={showAlert && !!chargebacks.length}
      appearance={appearence}
      onRemove={() => setShowAlert(false)}
    >
      <Text>
        <Trans
          t={t}
          i18nKey="description"
          count={chargebacks.length}
          components={[
            <ChargebackList
              key={`chargeback-list-${status}`}
              chargebacks={chargebacks}
              status={status}
            />,
          ]}
        />
      </Text>
    </Alert>
  );
}
