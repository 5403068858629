import React from 'react';
import { Box } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { useTranslationWithPrefix } from 'commons/utils';
import PaymentDetail from './PaymentDetail';
import RefundEventCard from './RefundEventCard';

function RefundReceiptSkeleton(): JSX.Element | null {
  const { t } = useTranslationWithPrefix('refundReceipt');

  return (
    <AppLayout title={t('title')}>
      <Box display="flex" flexDirection="column" gap="4">
        <RefundEventCard.Skeleton />
        <PaymentDetail.Skeleton />
      </Box>
    </AppLayout>
  );
}

export default RefundReceiptSkeleton;
