import React, { useState } from 'react';
import { Box, Label, Text, Textarea } from '@nimbus-ds/components';
import { fieldHandler } from './FieldHandler.interface';

type descriptionInput = fieldHandler & { title?: string; placeHolder: string };

function DescriptionInput({
  handler,
  value,
  title,
  placeHolder,
}: descriptionInput): JSX.Element {
  const maxCaracters = 200; //limit of caracters on field

  const [caracters, setCaracters] = useState(0);

  const handleDescriptionField = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const text = event.target.value.slice(0, maxCaracters);
    setCaracters(text.length);
    handler(text);
  };

  return (
    <Box display="grid" gridGap="2">
      {title && <Label htmlFor="payment-link-description">{title}</Label>}
      <Textarea
        name="description"
        value={value}
        maxLength={maxCaracters}
        onChange={handleDescriptionField}
        placeholder={placeHolder}
        id={'payment-link-description'}
      />
      <Text color="neutral-textLow">{`${caracters}/${maxCaracters} caracteres.`}</Text>
    </Box>
  );
}

function DescriptionInputSkeleton(): JSX.Element {
  return (
    <Box display="grid" gridGap="2">
      <Label.Skeleton />
      <Textarea.Skeleton />
    </Box>
  );
}

DescriptionInput.Skeleton = DescriptionInputSkeleton;

export default DescriptionInput;
