import { OrderType } from 'commons/types/OrderType.enum';
import TransactionStatusFilterEnum from 'domains/Brazil/commons/types/TransactionStatusFilterEnum';

export type filters = {
  date?: {
    from: string | undefined;
    to: string | undefined;
  };
  paymentMethod?: string;
  status?: TransactionStatusFilterEnum;
  withOrderNumber?: boolean;
  orderNumber?: string;
  orderType?: string;
  document?: string;
  paymentAmount?: string;
  customer?: string;
};

enum ApiStatusFilters {
  Pending = 'pending',
  Authorized = 'authorized',
  InFraudAnalysis = 'in_fraud_analysis',
  Paid = 'approved',
  Failed = 'failed',
  Canceled = 'canceled',
  Chargedback = 'chargedback',
  Declined = 'declined',
  Procesing = 'processing',
  Refunded = 'returned',
  PartiallyRefunded = 'partially_returned',
}

export type ApiFilter = {
  dateFrom: string;
  dateTo: string;
  transactionStatus: string;
  rejectedBy: string;
  paymentAmount: string;
  customer: string;
  orderNumber: string;
  withOrderNumber: boolean;
  orderType: string;
  count: number;
};

export const apiStatusMap = new Map<TransactionStatusFilterEnum, string>([
  [TransactionStatusFilterEnum.REJECTED_BY_EMISSOR, ApiStatusFilters.Declined],
  [TransactionStatusFilterEnum.REJECTED_BY_SECURITY, ApiStatusFilters.Declined],
  [TransactionStatusFilterEnum.ALL_REJECTED, ApiStatusFilters.Declined],
  [TransactionStatusFilterEnum.PAID, ApiStatusFilters.Paid],
  [TransactionStatusFilterEnum.REFUND, ApiStatusFilters.Refunded],
  [
    TransactionStatusFilterEnum.PARTIAL_REFUND,
    ApiStatusFilters.PartiallyRefunded,
  ],
  [TransactionStatusFilterEnum.CHARGEBACKED, ApiStatusFilters.Chargedback],
  [TransactionStatusFilterEnum.WAITING, ApiStatusFilters.Pending],
  [TransactionStatusFilterEnum.INPROCESS, ApiStatusFilters.Pending],
]);

export function FilterBuilder(filters: filters): Partial<ApiFilter> {
  const apiFilter: Partial<ApiFilter> = {
    withOrderNumber: true,
    count: 100,
  };

  if (filters.date) {
    apiFilter.dateTo = filters?.date?.to;
    apiFilter.dateFrom = filters.date.from;
  }

  if (filters.status) {
    apiFilter.transactionStatus = apiStatusMap.get(filters.status);
  }

  if (filters?.status === TransactionStatusFilterEnum.REJECTED_BY_EMISSOR) {
    apiFilter.rejectedBy = 'issuer';
  } else if (
    filters?.status === TransactionStatusFilterEnum.REJECTED_BY_SECURITY
  ) {
    apiFilter.rejectedBy = 'anti_fraud';
  }

  if (filters.paymentAmount) {
    apiFilter.paymentAmount = filters.paymentAmount;
  }

  if (filters.customer) {
    apiFilter.customer = filters.customer;
  }

  if (filters.orderNumber) {
    apiFilter.orderNumber = filters.orderNumber;
  }

  if (filters.orderType) {
    apiFilter.orderType =
      filters.orderType === 'payment_link' ? 'payment_link' : 'checkout';
  }

  if (filters.paymentMethod && filters.paymentMethod !== 'all') {
    apiFilter.orderType =
      filters.paymentMethod.toLowerCase() === OrderType.Checkout.toLowerCase()
        ? 'checkout'
        : 'payment_link';
  }

  if (apiFilter?.orderType === 'payment_link') {
    apiFilter.withOrderNumber = false; //If is filtering by payment link it's not required to call nuvemshop
  }

  if (apiFilter?.orderType || apiFilter?.transactionStatus) {
    apiFilter.count = 1000;
  }

  return apiFilter;
}
