import React from 'react';
import { Box, Text } from '@nimbus-ds/components';
import { Currency } from 'commons/types/Currency.enum';
import MoneyField from 'domains/Brazil/commons/components/MoneyField';

interface propsArg {
  label: string;
  value: number;
  fontWeight?: 'bold' | 'regular' | 'medium' | undefined;
}

export function RefundMoneyField({
  label,
  value,
  fontWeight,
}: propsArg): JSX.Element {
  return (
    <Box display={'inline-flex'} justifyContent={'space-between'}>
      <Text fontWeight={fontWeight} color={'neutral-textHigh'}>
        {label}
      </Text>
      <MoneyField
        value={value}
        currency={Currency.BRL}
        fontWeight={fontWeight}
        color="neutral-textHigh"
      />
    </Box>
  );
}
