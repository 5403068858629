import React from 'react';
import { Box, Text } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { useTranslationWithPrefix } from 'commons/utils';

function PaymentMethodsSkeleton(): JSX.Element {
  const { t } = useTranslationWithPrefix('paymentMethods');

  return (
    <AppLayout title={t('title')}>
      <Box display="flex" flexDirection="column" gap="4">
        <Text.Skeleton />
      </Box>
    </AppLayout>
  );
}

export default PaymentMethodsSkeleton;
