import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { LoadingCard } from 'commons/components';
import { StoreFlag } from 'commons/enums/StoreFlag.enum';
import { useStoreInfo } from 'commons/hooks';
import { useStoreFlag } from 'commons/hooks/useStoreFlag';
import {
  MoneyObjectInterface,
  TaxInterface,
  TransactionStatusType,
} from 'commons/types';
import { formatCurrency } from 'commons/utils';
import { FeesInfoPopover } from './FeesInfoPopover';

interface InterfacePaymentDetails {
  orderId: string;
  status?: TransactionStatusType;
  orderPrice?: MoneyObjectInterface;
  feesAndTaxes?: {
    totalTaxes?: MoneyObjectInterface;
    processingFee?: MoneyObjectInterface;
    installmentsFee?: MoneyObjectInterface;
    taxesDetails?: TaxInterface[];
  };
  skeleton?: boolean;
  installmentsPlan?: string;
  totalRefunds?: {
    storeRefunds: MoneyObjectInterface;
    customerRefunds: MoneyObjectInterface;
  };
  netAmount?: MoneyObjectInterface;
}

function PaymentDetails({
  status = 'pending',
  orderPrice,
  feesAndTaxes = {},
  skeleton = false,
  installmentsPlan = '',
  totalRefunds,
  netAmount,
}: InterfacePaymentDetails): JSX.Element {
  const { t } = useTranslation();
  const { storeInfo } = useStoreInfo();

  const { enabled: feesInfoPopoverEnabled } = useStoreFlag(
    StoreFlag.SubjectToIIBBWithholding,
  );

  if (skeleton) return <LoadingCard withHeaderLabel />;

  const isTotalRefund = status === 'refunded';
  const isPartialRefund = status === 'partially_refunded';
  const isARefund = isTotalRefund || isPartialRefund;

  const renderTaxesDetails = () => {
    return feesAndTaxes.taxesDetails?.map((tax) => (
      <Box
        key={tax.description}
        paddingLeft="2"
        paddingTop="2"
        display="flex"
        justifyContent="space-between"
      >
        <Text>
          {t(`transactionDetails.paymentDetails.taxes.${tax.description}`, {
            percentage: tax.percentage * 100,
          })}
        </Text>
        <Text>{formatCurrency(tax.amount, storeInfo)}</Text>
      </Box>
    ));
  };

  const renderPricesList = () => {
    return (
      <>
        {feesAndTaxes.installmentsFee &&
          feesAndTaxes.installmentsFee.value > 0 && (
            <Box display="flex" gap="4" justifyContent="space-between">
              <Text>
                {t(
                  `transactionDetails.paymentDetails.installments_fee${
                    feesInfoPopoverEnabled ? 'AndIIBB' : ''
                  }`,
                  {
                    installmentsPlan:
                      installmentsPlan === 'Standard'
                        ? 'Pago Nube'
                        : installmentsPlan,
                  },
                )}
              </Text>
              <Text color="danger-interactive">
                -{formatCurrency(feesAndTaxes.installmentsFee, storeInfo)}
              </Text>
            </Box>
          )}
        {feesAndTaxes.processingFee && feesAndTaxes.processingFee.value > 0 && (
          <Box display="flex" gap="4" justifyContent="space-between">
            <Box display="flex" flexDirection="row" gap="1">
              <Text>
                {t(
                  `transactionDetails.paymentDetails.processingFee${
                    feesInfoPopoverEnabled ? 'AndIIBB' : ''
                  }`,
                )}
              </Text>
            </Box>
            <Text color="danger-interactive">
              -{formatCurrency(feesAndTaxes.processingFee, storeInfo)}
            </Text>
          </Box>
        )}
        {feesAndTaxes.totalTaxes && (
          <Box>
            {feesAndTaxes.totalTaxes.value > 0 && (
              <Box display="flex" gap="4" justifyContent="space-between">
                <Text>
                  {t(`transactionDetails.paymentDetails.total_taxes`)}
                </Text>
                <Text color="danger-interactive">
                  -{formatCurrency(feesAndTaxes.totalTaxes, storeInfo)}
                </Text>
              </Box>
            )}
            {renderTaxesDetails()}
          </Box>
        )}
      </>
    );
  };

  const renderRefundList = () => {
    if (!totalRefunds) return null;
    return (
      <>
        <Text fontWeight="bold">
          {' '}
          {t('transactionDetails.paymentDetails.refundsTitle')}{' '}
        </Text>
        <Box display="flex" gap="4" justifyContent="space-between">
          <Text>
            {t(
              `transactionDetails.paymentDetails.${
                isTotalRefund ? 'customerRefunds' : 'customerPartialRefunds'
              }`,
            )}
          </Text>
          <Text color="danger-interactive">
            -{formatCurrency(totalRefunds.customerRefunds, storeInfo)}
          </Text>
        </Box>
        <Box display="flex" gap="4" justifyContent="space-between">
          <Text>{t('transactionDetails.paymentDetails.storeRefunds')}</Text>
          <Text>{formatCurrency(totalRefunds.storeRefunds, storeInfo)}</Text>
        </Box>
      </>
    );
  };

  function showChargebackValue() {
    switch (status) {
      case 'chargebacked':
      case 'chargeback_dispute':
        return true;

      default:
        return false;
    }
  }

  const renderChargebackValue = () => (
    <Box display="flex" gap="4" justifyContent="space-between">
      <Text>{t(`transactionDetails.paymentDetails.chargebackValue`)}</Text>
      <Text>{orderPrice ? formatCurrency(orderPrice, storeInfo) : 0}</Text>
    </Box>
  );

  function showFooter() {
    switch (status) {
      case 'paid':
      case 'refunded':
      case 'partially_refunded':
      case 'chargebacked':
      case 'chargeback_dispute':
        return true;

      default:
        return false;
    }
  }

  const renderFooter = () => (
    <Box backgroundColor="neutral-surface" padding="4">
      <Box
        display="flex"
        gap="4"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Text fontWeight="bold">
          {t('transactionDetails.paymentDetails.total')}
        </Text>
        <Text
          fontWeight="bold"
          color={
            netAmount && netAmount.value < -1
              ? 'danger-interactive'
              : 'neutral-textHigh'
          }
        >
          {`${netAmount && formatCurrency(netAmount, storeInfo)}`}
        </Text>
      </Box>
    </Box>
  );

  return (
    <Card padding="none">
      <Card.Header padding="base">
        <Box display="flex" flexDirection="row" gap="1" alignItems="center">
          <Title as="h3">{t('transactionDetails.paymentDetails.order')}</Title>
          {feesInfoPopoverEnabled && <FeesInfoPopover />}
        </Box>
      </Card.Header>
      <Card.Body padding="base">
        <Box
          display="flex"
          flexDirection="column"
          gap="4"
          justifyContent="flex-start"
          paddingBottom={!showFooter() ? '4' : 'none'}
          alignItems="stretch"
        >
          {isARefund && (
            <Text fontWeight="bold">
              {' '}
              {t('transactionDetails.paymentDetails.saleTitle')}{' '}
            </Text>
          )}
          <Box display="flex" gap="4" justifyContent="space-between">
            <Text>{t('transactionDetails.paymentDetails.value')}</Text>
            <Text>
              {orderPrice ? formatCurrency(orderPrice, storeInfo) : 0}
            </Text>
          </Box>
          {showFooter() && renderPricesList()}
          {showChargebackValue() && renderChargebackValue()}
          {isARefund && renderRefundList()}
        </Box>
      </Card.Body>
      {showFooter() && renderFooter()}
    </Card>
  );
}

export default PaymentDetails;
