import React, { createContext, useMemo, useState } from 'react';
import { DateRangeType } from 'commons/types';

interface FiltersContextInterface {
  pageNumber: number;
  dateRangeFilter: DateRangeType;
  fromDateFilter?: string;
  toDateFilter?: string;
  searched: boolean;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setDateRangeFilter: React.Dispatch<React.SetStateAction<DateRangeType>>;
  setFromDateFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
  setToDateFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSearched: React.Dispatch<React.SetStateAction<boolean>>;
  clearFilters: () => void;
}

export const FiltersContext = createContext<FiltersContextInterface>({
  pageNumber: 1,
  dateRangeFilter: 'thirty_days',
  searched: false,
  setPageNumber: () => undefined,
  setDateRangeFilter: () => undefined,
  setFromDateFilter: () => undefined,
  setToDateFilter: () => undefined,
  setSearched: () => undefined,
  clearFilters: () => undefined,
});

interface InterfaceFiltersProvider {
  children: React.ReactNode;
}

function FiltersProvider({ children }: InterfaceFiltersProvider): JSX.Element {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [dateRangeFilter, setDateRangeFilter] =
    useState<DateRangeType>('thirty_days');
  const [fromDateFilter, setFromDateFilter] = useState<string>();
  const [toDateFilter, setToDateFilter] = useState<string>();
  const [searched, setSearched] = useState<boolean>(false);

  const clearFilters = useMemo(
    () =>
      function () {
        setPageNumber(1);
        setFromDateFilter(undefined);
        setToDateFilter(undefined);
        setDateRangeFilter('all');
        setSearched(false);
      },
    [],
  );

  const values = useMemo(
    () => ({
      pageNumber,
      setPageNumber,
      fromDateFilter,
      setFromDateFilter,
      toDateFilter,
      setToDateFilter,
      dateRangeFilter,
      setDateRangeFilter,
      searched,
      setSearched,
      clearFilters,
    }),
    [
      pageNumber,
      setPageNumber,
      fromDateFilter,
      setFromDateFilter,
      toDateFilter,
      setToDateFilter,
      dateRangeFilter,
      setDateRangeFilter,
      searched,
      setSearched,
      clearFilters,
    ],
  );

  return (
    <FiltersContext.Provider value={values}>{children}</FiltersContext.Provider>
  );
}

export default FiltersProvider;
