import React from 'react';
import { useParams } from 'react-router';
import { Layout } from '@nimbus-ds/patterns';
import { useQuery } from '@tanstack/react-query';
import AppLayout from 'App/components/AppLayout';
import { useSatismeter, useStoreInfo } from 'commons/hooks';
import { futureIncomesService } from 'commons/services';
import {
  formatLongDateWithoutYear,
  useTranslationWithPrefix,
} from 'commons/utils';
import SummarySection from '../components/SummarySection';
import { ErrorMessage } from '../ErrorMessage';
import { FutureIncomesHelpLink } from '../FutureIncomesHelpLink';
import DateFutureIncomeSkeleton from './DateFutureIncomesSkeleton';
import IncomesByOrder from './IncomesByOrder';

type DateParam = {
  date: string;
};

function DateFutureIncomes(): JSX.Element {
  useSatismeter('pagonube_satismeter_agenda_liquidacion');
  const { t } = useTranslationWithPrefix('futureIncome');
  const { storeInfo } = useStoreInfo();
  const { date } = useParams<DateParam>();

  const { data, isError, isLoading } = useQuery(
    ['dateFutureIncomeSummary'],
    () => futureIncomesService.getFutureIncomesSummary(date),
  );

  if (isLoading) return <DateFutureIncomeSkeleton />;

  if (isError) {
    return (
      <AppLayout title={t('title')}>
        <ErrorMessage />
        <FutureIncomesHelpLink />
      </AppLayout>
    );
  }

  return (
    <AppLayout title={formatLongDateWithoutYear(storeInfo, date)}>
      <Layout columns="1">
        <SummarySection
          updatedAt={data.updatedAt}
          waitingFunds={data.waitingFunds}
          grossFunds={data.grossFunds}
          processingFee={data.processingFee}
          installmentsFees={data.installmentsFees}
          totalTaxes={data.totalTaxes}
        />
        <Layout.Section>
          <IncomesByOrder date={date} />
        </Layout.Section>
      </Layout>
      <FutureIncomesHelpLink />
    </AppLayout>
  );
}

export default DateFutureIncomes;
