import { InstallmentPaymentInfoDto } from './dtos/installment-payment-info.dto';
import axios from './axios';

async function getPaymentInfo(): Promise<
  InstallmentPaymentInfoDto | undefined
> {
  const { data } = await axios.get<InstallmentPaymentInfoDto>(
    '/installments/payment-info',
  );

  return data;
}

export default {
  getPaymentInfo,
};
