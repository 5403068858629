import { TypeTag } from 'domains/Brazil/commons/components/StatusTag';
import { PagarmeTransactionStatus } from './dashboard.service';

export function PagarmeStatusTranslation(
  pagarmeStatus: PagarmeTransactionStatus,
  rejectedBy?: string,
) {
  const statusMap = new Map<PagarmeTransactionStatus, TypeTag>([
    [PagarmeTransactionStatus.Authorized, TypeTag.Authorized],
    [PagarmeTransactionStatus.Chargedback, TypeTag.Contested],
    [PagarmeTransactionStatus.Failed, TypeTag.Failed],
    [PagarmeTransactionStatus.Paid, TypeTag.Paid],
    [PagarmeTransactionStatus.Pending, TypeTag.Pending],
    [PagarmeTransactionStatus.PendingRefund, TypeTag.Pending],
    [PagarmeTransactionStatus.Refunded, TypeTag.Refunded],
    [PagarmeTransactionStatus.PartiallyRefunded, TypeTag.PartiallyRefunded],
    [PagarmeTransactionStatus.Refused, TypeTag.Failed],
    [PagarmeTransactionStatus.WaitingPayment, TypeTag.Pending],
    [PagarmeTransactionStatus.Processing, TypeTag.Pending],
  ]);

  let status = statusMap.get(pagarmeStatus) ?? TypeTag.Pending;

  if (pagarmeStatus === PagarmeTransactionStatus.Failed) {
    status =
      rejectedBy === 'anti_fraud' ? TypeTag.RejectedBySecurity : TypeTag.Failed;
  }

  return status;
}
