export enum SettlementStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Reproved = 'REPROVED',
  OnHold = 'ON_HOLD',
}

export enum WithdrawStatus {
  AntifraudSuccess = 'antifraud_success',
  AntifraudPending = 'antifraud_pending',
  AntifraudFailed = 'antifraud_failed',
  AntifraudOnHold = 'antifraud_on_hold',
  Pending = 'pending',
  Failed = 'failed',
  Success = 'success',
}

export type WithdrawStatusType = SettlementStatus | WithdrawStatus;
