import React from 'react';
import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { PaymentLinkAddressType } from 'commons/types/PaymentLinkDetailsType';
import { useTranslationWithPrefix } from 'commons/utils';
import SkeletonHandler from './Skeleton';

interface Props {
  address: PaymentLinkAddressType | undefined;
}

export default function AddressCard(props: Props): JSX.Element {
  const { t } = useTranslationWithPrefix('paymentLink.details.address');

  const renderBoxLabel = () => (
    <Box marginBottom="1" marginTop={'none'}>
      <SkeletonHandler
        skeletonComponent={<Text.Skeleton width="30%" />}
        trigger={!props.address}
      >
        <Text fontSize="caption" textAlign="left" color="neutral-textLow">
          {t('label')}
        </Text>
      </SkeletonHandler>
    </Box>
  );

  const renderBoxAddress = (content: string | undefined) => (
    <Box marginBottom="1">
      <SkeletonHandler
        skeletonComponent={<Text.Skeleton width="100%" />}
        trigger={!content}
      >
        <Text fontSize="base" textAlign="left" color="neutral-textLow">
          {content}
        </Text>
      </SkeletonHandler>
    </Box>
  );

  function renderAddressState(
    state: string | undefined,
    city: string | undefined,
  ) {
    return (
      <Box marginBottom="1">
        <SkeletonHandler
          skeletonComponent={<Text.Skeleton width="100%" />}
          trigger={!state && !city}
        >
          <Text fontSize="base" textAlign="left" color="neutral-textLow">
            {`${city} - ${state}`}
          </Text>
        </SkeletonHandler>
      </Box>
    );
  }

  function maskZipCode(zipcode: string | undefined) {
    if (zipcode?.replaceAll(/\D/g, '').length !== 8) return zipcode;

    return zipcode.replace(/(\d{5})(\d{3})/, '$1-$2');
  }

  function renderAddressZipCode(zipcode: string | undefined) {
    return (
      <Box justifyContent={'space-between'}>
        <SkeletonHandler
          skeletonComponent={<Text.Skeleton width="100%" />}
          trigger={!zipcode}
        >
          <Text fontSize="base" textAlign="left" color="neutral-textLow">
            {t('zipcode', {
              zipcode: maskZipCode(zipcode),
            })}
          </Text>
        </SkeletonHandler>
      </Box>
    );
  }
  return (
    <Box>
      <Card padding="none">
        <Box marginLeft={'4'} marginTop={'4'}>
          <SkeletonHandler
            skeletonComponent={<Title.Skeleton />}
            trigger={!props.address}
          >
            <Card.Header title={t('title')} />
          </SkeletonHandler>
        </Box>
        <Card.Body>
          <Box marginLeft={'4'} marginBottom={'4'}>
            <Box justifyContent={'flex-start'} alignItems={'stretch'}>
              {renderBoxLabel()}
              {renderBoxAddress(props.address?.street)}
              {renderAddressState(props.address?.state, props.address?.city)}
              {renderAddressZipCode(props.address?.zipCode)}
            </Box>
          </Box>
        </Card.Body>
      </Card>
    </Box>
  );
}
