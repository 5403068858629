import React from 'react';
import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { useIsMobile } from 'commons/hooks';
import { InstallmentPaymentInfoDto } from 'commons/services/lending/dtos/installment-payment-info.dto';
import { Currency } from 'commons/types/Currency.enum';
import { useTranslationWithPrefix } from 'commons/utils';
import MoneyField from 'domains/Brazil/commons/components/MoneyField';
import ContentLine from './ContentLine';

type SummaryProps = {
  loading: boolean;
  paymentInfo?: InstallmentPaymentInfoDto;
};

function Summary({ loading, paymentInfo }: SummaryProps): JSX.Element {
  const { t } = useTranslationWithPrefix(
    'lending.installment.paymentInfo.summary',
  );
  const isMobile = useIsMobile();

  const isOverdue = new Date(paymentInfo?.dueAt ?? 0) < new Date();
  return (
    <Box data-testid="lending-payment-totals">
      <Card padding="none">
        <Card.Header>
          <Box marginLeft={'4'} marginTop={'4'}>
            {loading ? (
              <Title.Skeleton />
            ) : (
              <Title fontSize="5">{t('title')}</Title>
            )}
          </Box>
        </Card.Header>

        <Card.Body>
          <Box
            marginLeft={'4'}
            marginRight={'4'}
            display={'flex'}
            justifyContent="space-between"
            flexDirection="column"
            minHeight={isMobile ? '35px' : undefined}
          >
            <ContentLine loading={loading}>
              <Text>{t('installmentAmount')}</Text>

              <MoneyField
                value={paymentInfo?.baseAmount ?? 0}
                currency={Currency.BRL}
                type="cents"
              />
            </ContentLine>

            {isOverdue && !loading && (
              <ContentLine loading={loading}>
                <Text>{t('overdueInterest')}</Text>
                <MoneyField
                  value={-(paymentInfo?.taxesAmount ?? 0)}
                  color="danger-textLow"
                  currency={Currency.BRL}
                  type="cents"
                />
              </ContentLine>
            )}
          </Box>
        </Card.Body>

        <Box
          display={'flex'}
          flexDirection={'column'}
          padding={'none'}
          backgroundColor={'neutral-surface'}
        >
          <Box
            marginLeft={'4'}
            marginRight={'4'}
            borderRadius={'none'}
            paddingTop={'2'}
            paddingBottom={'2'}
          >
            <ContentLine loading={loading}>
              <Text fontWeight={'bold'}>{t('totalAmount')}</Text>
              <MoneyField
                value={paymentInfo?.totalAmount ?? 0}
                currency={Currency.BRL}
                fontWeight="bold"
                type="cents"
              />
            </ContentLine>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default Summary;
