import { useFeatureDiscovery } from 'commons/hooks';
import { FeatureName } from 'commons/services/featureDiscoveryService/featureDiscoveryServiceResponses';

export function useDiscoveringFutureIncomes() {
  const { isLoading, markAsDiscovered, isDiscovered } = useFeatureDiscovery(
    FeatureName.FutureIncomes,
  );

  const markFutureIncomesAsDiscovered = () => {
    markAsDiscovered.mutate();
  };

  function isDiscovering() {
    if (isLoading) return false;
    return !isDiscovered;
  }

  return {
    markFutureIncomesAsDiscovered,
    isDiscovering: isDiscovering(),
  };
}
