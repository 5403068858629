import React from 'react';
import { Box, Card, Title } from '@nimbus-ds/components';

function RedirectCardSkeleton(): JSX.Element {
  return (
    <Card padding="none">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="4"
      >
        <Title.Skeleton as="h4" />
      </Box>
    </Card>
  );
}

export default RedirectCardSkeleton;
