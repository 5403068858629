/* eslint-disable max-len */
import React from 'react';
type SkeletonHandlerArgs = {
  skeletonComponent: JSX.Element;
  trigger: boolean;
  children: JSX.Element | JSX.Element[];
};

function SkeletonHandler({
  skeletonComponent,
  trigger,
  children,
}: SkeletonHandlerArgs): JSX.Element {
  if (trigger) {
    return skeletonComponent;
  } else {
    return <>{children}</>;
  }
}

export default SkeletonHandler;
