import React from 'react';
import { Alert } from '@nimbus-ds/components';
import { useIdentityValidation } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';

function DisabledBanner(): JSX.Element {
  const { t } = useTranslationWithPrefix('home.disabledBanner');
  const identityValidation = useIdentityValidation();
  const storeDisabled = identityValidation.data?.status === 'denied';

  return (
    <Alert title={t('title')} show={storeDisabled} appearance="neutral">
      {t('description')}
    </Alert>
  );
}

export default DisabledBanner;
