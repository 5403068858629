import React, { useEffect } from 'react';
import { Box, Spinner } from '@nimbus-ds/components';
import { navigateHeaderRemove } from '@tiendanube/nexo/helpers';
import AppLayout from 'App/components/AppLayout';
import nexo from 'App/nexoClient';
import { useTranslationWithPrefix } from 'commons/utils';

function HomeMockSkeleton(): JSX.Element {
  const { t } = useTranslationWithPrefix('home');

  useEffect(() => {
    navigateHeaderRemove(nexo);
  }, []);

  return (
    <AppLayout hideBackButton title={t('title')}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    </AppLayout>
  );
}

export default HomeMockSkeleton;
