import React from 'react';
import { Box, Title } from '@nimbus-ds/components';
import { useIsMobile } from 'commons/hooks';

interface AppLayoutInterface {
  children: React.ReactNode;
  title?: string;
}

function AppLayout(props: AppLayoutInterface): JSX.Element {
  const isMobile = useIsMobile();

  return (
    <Box
      backgroundColor={isMobile ? 'neutral-background' : undefined}
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      width="100%"
      height="100vh"
      gap="4"
      padding="4"
    >
      {props.title && (
        <Title as="h1" color="primary-textHigh">
          {props.title}
        </Title>
      )}
      {props.children}
    </Box>
  );
}

export default AppLayout;
