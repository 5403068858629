import React from 'react';
import { Box, Card, Link, Text, Title } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';
import ClientCardSkeleton from './ClientCardSkeleton';

interface ClientCardInterface {
  name: string;
  email: string;
}

function ClientCard({ name, email }: ClientCardInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('refund.clientCard');

  return (
    <Card>
      <Card.Header padding="none">
        <Title as="h3">{t('title')}</Title>
      </Card.Header>
      <Box display="flex" gap="4" flexDirection="column">
        <Text>{name}</Text>
        <Link appearance="primary" as="a" href={`mailto: ${email}`}>
          {email}
        </Link>
      </Box>
    </Card>
  );
}

ClientCard.Skeleton = ClientCardSkeleton;

export default ClientCard;
