import React from 'react';
import { Skeleton } from '@nimbus-ds/components';

const ContentOrSkeleton = ({
  loading,
  children,
  skeletonTestId,
  skeletonWidth,
}: {
  loading: boolean;
  children: JSX.Element;
  skeletonTestId?: string;
  skeletonWidth?: string;
}) =>
  loading ? (
    <Skeleton
      data-testid={skeletonTestId}
      width={skeletonWidth ?? '100%'}
      height="1.5rem"
      borderRadius="4px"
    />
  ) : (
    children
  );

export default ContentOrSkeleton;
