import React, { useCallback } from 'react';

type EventTypeArg = React.MouseEvent<HTMLElement>;
type OnClickHandler = (event: EventTypeArg) => void;

/**
 * Encapsulates an `onClick` callback that performs navigation so as not to
 * interfere with keypress + click shortcuts.
 *
 * **Motivation:** the `onClick` callback is called even when the click is
 * combined with a key press (e.g. Shift + click to open in a new tab).
 * This behavior is not ideal when navigating programmatically (e.g. using
 * `goTo` from `nexo` with a relative path to a Stratus page, outside of this
 * app) but still providing a canonical URL in the `href` of an `<a>`, so that
 * the link can be opened directly in another tab or window.
 *
 * **NOTE:** The handler generated by this hook uses `Event.preventDefault()`
 * internally.
 */
export function useUnobstructiveClick(
  onClickHandler: OnClickHandler,
): OnClickHandler {
  return useCallback(
    (event: EventTypeArg) => {
      // Keep the shortcuts to open in a new tab/window and open the context menu via key press + click.
      if (event.ctrlKey || event.shiftKey || event.metaKey) {
        return;
      }

      // Otherwise, suppress the click.
      event.preventDefault();

      onClickHandler(event);
    },
    [onClickHandler],
  );
}
