import React from 'react';
import { useIsMobile } from 'commons/hooks';
import IncomesByOrderDesktop from './IncomesByOrderDesktop';
import IncomesByOrderMobile from './IncomesByOrderMobile';
import IncomesByOrderSkeleton from './IncomesByOrderSkeleton';

export interface IncomesByOrderProps {
  date: string;
}

function IncomesByOrder({ date }: IncomesByOrderProps): JSX.Element {
  const isMobile = useIsMobile();

  return isMobile ? (
    <IncomesByOrderMobile date={date} />
  ) : (
    <IncomesByOrderDesktop date={date} />
  );
}

IncomesByOrder.Skeleton = IncomesByOrderSkeleton;
export default IncomesByOrder;
