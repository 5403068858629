import React from 'react';
import { Alert, Box } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { ROUTES } from 'App/Routes/constants';
import { useTranslationWithPrefix } from 'commons/utils';
import BankAccountInputs from './BankAccountInputs';

function BankAccountRegistrationSkeleton(): JSX.Element {
  const { t } = useTranslationWithPrefix('bankAccountRegistration');

  return (
    <AppLayout
      title={t('title')}
      subtitle={t('subtitle')}
      backLinkPath={ROUTES.CONFIG}
    >
      <Box display="flex" flexDirection="column" gap="4">
        <Alert.Skeleton />
        <BankAccountInputs.Skeleton />
      </Box>
    </AppLayout>
  );
}

export default BankAccountRegistrationSkeleton;
