import React, { useContext, useEffect, useState } from 'react';
import { Box, Pagination, Table, Text } from '@nimbus-ds/components';
import { useQuery } from '@tanstack/react-query';
import { ErrorMessage } from 'commons/components';
import { withdrawsService } from 'commons/services';
import { WithdrawObjectInterface } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import { FiltersContext } from '../../FiltersContext';
import EmptySearch from '../EmptySearch';
import { EmptyWithdraws } from '../EmptyWithdraws';
import { WithdrawsFilter } from '../WithdrawsFilter';
import WithdrawsTableDesktopSkeleton from './WithdrawsTableDesktopSkeleton';
import WithdrawsTableRows from './WithdrawsTableRows';

function WithdrawsTableDesktop(): JSX.Element | null {
  const { t } = useTranslationWithPrefix('withdraws.withdrawsTable');
  const [fetchCountWithoutFilters, setFetchCountWithoutFilters] =
    useState(true);

  const {
    pageNumber,
    setPageNumber,
    fromDateFilter,
    toDateFilter,
    dateRangeFilter,
    clearFilters,
  } = useContext(FiltersContext);
  const withdrawsQuery = useQuery(
    // TODO: look for a way of handling query keys
    ['withdraws', pageNumber, dateRangeFilter, fromDateFilter, toDateFilter],
    () =>
      withdrawsService.getWithdraws(
        pageNumber,
        dateRangeFilter,
        fromDateFilter,
        toDateFilter,
        fetchCountWithoutFilters,
      ),
    {
      // TODO: remove retry when defined retry method
      retry: false,
    },
  );

  useEffect(() => {
    if (withdrawsQuery.isSuccess) {
      setFetchCountWithoutFilters(false);
    }
  }, [withdrawsQuery.isSuccess]);

  let withdraws = [] as WithdrawObjectInterface[];
  let hasEmptyWithdraws = false;
  let isEmptySearch = false;
  let totalItems = 0;
  let itemsPerPage = 0;
  let totalPages = 0;

  if (withdrawsQuery.isLoading) return <WithdrawsTableDesktopSkeleton />;

  if (withdrawsQuery.isError) {
    return (
      <Box alignItems="stretch" gap="4" display="flex" flexDirection="column">
        <ErrorMessage />
      </Box>
    );
  }

  if (withdrawsQuery.isSuccess) {
    const withdrawsData = withdrawsQuery.data;
    withdraws = withdrawsData.withdraws;
    totalItems = withdrawsData.meta.totalCount;
    itemsPerPage = withdrawsData.meta.itemsPerPage;
    totalPages = Math.ceil(
      withdrawsData.meta.totalCount / withdrawsData.meta.itemsPerPage,
    );
    hasEmptyWithdraws = withdrawsData.meta.totalCountWithoutFilters === 0;
    isEmptySearch = !withdraws.length;
  }

  if (hasEmptyWithdraws) {
    return <EmptyWithdraws />;
  }

  if (isEmptySearch) {
    return (
      <Box alignItems="stretch" gap="12" display="flex" flexDirection="column">
        <WithdrawsFilter />
        <EmptySearch onClick={clearFilters} />
      </Box>
    );
  }

  const headers = (
    <Table.Row backgroundColor="neutral-surface">
      {[
        t('headers.withdraw'),
        t('headers.date'),
        t('headers.client'),
        t('headers.value'),
        t('headers.status'),
      ].map((header, index) => (
        <Table.Cell key={index}>
          <Text fontWeight="medium">{header}</Text>
        </Table.Cell>
      ))}
    </Table.Row>
  );

  const firstItemCount = itemsPerPage * (pageNumber - 1) + 1;
  const lastItemCount = itemsPerPage * (pageNumber - 1) + withdraws.length;
  const renderFooter = (
    <Table.Head>
      <Table.Cell width="1200px">
        <Box
          justifyContent="space-between"
          gap="4"
          display="flex"
          flexDirection="row"
        >
          <Text>
            {t(
              `showingWithdraws${
                lastItemCount - firstItemCount > 1 ? '' : 'One'
              }`,
              {
                first: firstItemCount,
                last: lastItemCount,
                total: totalItems,
              },
            )}
          </Text>
          {totalPages > 1 && (
            <Pagination
              activePage={pageNumber}
              pageCount={totalPages}
              onPageChange={(page) => setPageNumber(page)}
            />
          )}
        </Box>
      </Table.Cell>
    </Table.Head>
  );

  return (
    <Box alignItems="stretch" gap="4" display="flex" flexDirection="column">
      <WithdrawsFilter />
      <Table>
        <Table.Head>{headers}</Table.Head>
        <Table.Body>
          {withdraws.map((withdraw) => (
            <WithdrawsTableRows key={withdraw.id} withdraw={withdraw} />
          ))}
        </Table.Body>
      </Table>
      {renderFooter}
    </Box>
  );
}

WithdrawsTableDesktop.Skeleton = WithdrawsTableDesktopSkeleton;
export default WithdrawsTableDesktop;
