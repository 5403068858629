import React from 'react';
import { Box, Button, Spinner } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { ROUTES } from 'App/Routes/constants';
import { KycDocumentErrorsType, ValidationStatusType } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import ErrorBanner from '../ErrorBanner';
import SiteAlert from '../SiteAlert';
import { getErrorCode } from './getErrorCode';

interface ValidationContentInterface {
  eligiblePerson: boolean;
  renderEligible: () => JSX.Element;
  renderNotEligible: () => JSX.Element;
  isLoading: boolean;
  onSubmit: () => Promise<void>;
  juridicalPerson?: boolean;
  kycDocumentErrors: KycDocumentErrorsType[];
  status: ValidationStatusType;
}

function ValidationContent({
  eligiblePerson,
  renderEligible,
  renderNotEligible,
  isLoading,
  onSubmit,
  juridicalPerson,
  kycDocumentErrors,
  status,
}: ValidationContentInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('identityValidation');

  const errorBanners = kycDocumentErrors?.map((error) => {
    const errorCode = getErrorCode(error, juridicalPerson);

    return (
      <ErrorBanner
        key={`validation-error-banner-${error.errorCode}`}
        description={t(errorCode)}
        status={status}
      />
    );
  });

  return (
    <AppLayout
      title={t('title')}
      backLinkPath={ROUTES.CONFIG}
      backLinkText={t('backLinkText')}
      footer={
        eligiblePerson && (
          <Button
            appearance="primary"
            onClick={() => {
              (async () => await onSubmit())();
            }}
            disabled={isLoading}
          >
            {isLoading && <Spinner size="small" />}
            {t('submitButton')}
          </Button>
        )
      }
    >
      <Box display="flex" flexDirection="column" gap="6">
        <SiteAlert show={status === 'pending'} />
        {errorBanners}
        {eligiblePerson ? renderEligible() : renderNotEligible()}
      </Box>
    </AppLayout>
  );
}

export default ValidationContent;
