import { TFunction } from 'react-i18next';
import { ExclamationCircleIcon, IconProps } from '@nimbus-ds/icons';
import { ChargebackStatus, EventStatusType, EventType } from 'commons/types';

type appearanceTypes =
  | 'danger'
  | 'primary'
  | 'secondary'
  | 'default'
  | 'warning'
  | 'success'
  | undefined;

interface chargebackLabelPropsValue {
  label: string;
  icon: React.FC<IconProps>;
  appearance: appearanceTypes;
}

const chargebackLabelProps = (
  t: TFunction<string, undefined>,
  type: EventType,
  status: EventStatusType,
  chargebackStatus: ChargebackStatus,
): chargebackLabelPropsValue => {
  const chargebackDisputeSuccessLabel = {
    label: t(
      'transactionDetails.paymentHistory.chargebackDispute.success.title',
    ),
    icon: ExclamationCircleIcon,
    appearance: 'primary' as appearanceTypes,
  };

  const chargebackDisputePendingLabel = {
    label: t(
      'transactionDetails.paymentHistory.chargebackDispute.pending.title',
    ),
    icon: ExclamationCircleIcon,
    appearance: 'primary' as appearanceTypes,
  };

  const chargebackDisputeFailureLabel = {
    label: t(
      'transactionDetails.paymentHistory.chargebackDispute.failure.title',
    ),
    icon: ExclamationCircleIcon,
    appearance: 'danger' as appearanceTypes,
  };

  const chargebackResolvedWithoutDisputeLabel = {
    label: t(
      'transactionDetails.paymentHistory.chargeback.resolvedWithoutDispute.title',
    ),
    icon: ExclamationCircleIcon,
    appearance: 'default' as appearanceTypes,
  };

  const chargebackResolvedMerchantLabel = {
    label: t(
      'transactionDetails.paymentHistory.chargeback.resolvedMerchant.title',
    ),
    icon: ExclamationCircleIcon,
    appearance: 'success' as appearanceTypes,
  };

  const chargebackResolvedCustomerLabel = {
    label: t(
      'transactionDetails.paymentHistory.chargeback.resolvedCustomer.title',
    ),
    icon: ExclamationCircleIcon,
    appearance: 'default' as appearanceTypes,
  };

  const chargebackPendingLabel = {
    label: t('transactionDetails.paymentHistory.chargeback.pendingFraud.title'),
    icon: ExclamationCircleIcon,
    appearance: 'warning' as appearanceTypes,
  };

  if (type === 'chargeback_dispute') {
    switch (status) {
      case 'success':
        return chargebackDisputeSuccessLabel;
      case 'pending':
        return chargebackDisputePendingLabel;
      case 'failure':
        return chargebackDisputeFailureLabel;
    }
  }

  if (status === 'pending') return chargebackPendingLabel;

  switch (chargebackStatus) {
    case ChargebackStatus.ResolvedCustomer:
      return chargebackResolvedCustomerLabel;

    case ChargebackStatus.ResolvedMerchant:
    case ChargebackStatus.ResolvedInsurance:
      return chargebackResolvedMerchantLabel;

    case ChargebackStatus.ResolvedWithoutDispute:
      return chargebackResolvedWithoutDisputeLabel;

    case ChargebackStatus.DisputeReceived:
      return chargebackDisputePendingLabel;

    case ChargebackStatus.DisputeSent:
      return chargebackDisputeSuccessLabel;

    case ChargebackStatus.DisputeRejected:
      return chargebackDisputeFailureLabel;

    case ChargebackStatus.Pending:
      return chargebackPendingLabel;
  }
};

export default chargebackLabelProps;
