import React from 'react';
import { Modal, Text } from '@nimbus-ds/components';
import { NotificationComponentProps } from '../types';

const ModalNotification: React.VFC<NotificationComponentProps> = ({
  id,
  title,
  message,
  show,
  onClose = () => {},
  action,
  'data-testid': dataTestId,
}) => (
  <Modal key={id} open={show} onDismiss={onClose} data-testid={dataTestId}>
    <Modal.Header title={title} />
    <Modal.Body padding="none">
      <div style={{ whiteSpace: 'pre-line' }}>
        <Text as="p" fontSize="base" lineHeight="base" textAlign="left">
          {message}
        </Text>
      </div>
    </Modal.Body>
    {action && <Modal.Footer>{action}</Modal.Footer>}
  </Modal>
);

export default ModalNotification;
