import React from 'react';
import { Box } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { useTranslationWithPrefix } from 'commons/utils';
import WithInterest from './WithInterest';
import { WithoutInterestSkeleton } from './WithoutInterest';

function InstallmentsSkeleton(): JSX.Element {
  const { t } = useTranslationWithPrefix('installments');

  return (
    <AppLayout title={t('title')}>
      <Box display="flex" flexDirection="column" gap="4">
        <div data-test-id="installments-skeleton" />
        <WithInterest.Skeleton />
        <WithoutInterestSkeleton />
      </Box>
    </AppLayout>
  );
}

export default InstallmentsSkeleton;
