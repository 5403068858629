import React, { MouseEvent, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Link, Text } from '@nimbus-ds/components';
import { goToOldAdmin } from '@tiendanube/nexo/helpers';
import nexo from 'App/nexoClient';
import { ROUTES } from 'App/Routes/constants';
import { useStoreURL } from 'commons/hooks';
import { OrderType } from 'commons/types/OrderType.enum';
import { useTranslationWithPrefix } from 'commons/utils';
import { TypeTag } from 'domains/Brazil/commons/components/StatusTag';

type DashboardLinkField = {
  orderNumber?: string;
  type?: OrderType;
  transactionId: string;
  status: string;
  isRefundPartial: boolean;
};

export const amplitudeTagStatusMap = new Map<string, string>([
  [TypeTag.Paid, 'approved'],
  [TypeTag.Refunded, 'refunded'],
  [TypeTag.PartiallyRefunded, 'partial_refunded'],
  [TypeTag.Contested, 'chargeback'],
  [TypeTag.Failed, 'issuer_bank_rejected'],
  [TypeTag.RejectedBySecurity, 'security_rejected'],
  [TypeTag.Pending, 'pending'],
]);

function DashboardLinkField({
  orderNumber,
  type = OrderType.Checkout,
  transactionId,
  status,
  isRefundPartial,
}: DashboardLinkField): JSX.Element {
  const { t } = useTranslationWithPrefix('dashboard.components.dashboardLink');
  const history = useHistory();
  const { buildAbsoluteStoreURL } = useStoreURL();

  const amplitudeTag = useCallback(() => {
    if (type === OrderType.Checkout) {
      return `nuvempago_dashboard_${amplitudeTagStatusMap.get(
        status,
      )}_transaction_details_click`;
    } else {
      return `nuvempago_dashboard_${amplitudeTagStatusMap.get(
        status,
      )}_payment_link_detail_click`;
    }
  }, [status, type]);

  const detailsPath = useMemo(() => {
    if (type === OrderType.Checkout) {
      if (isRefundPartial) {
        return `/admin/v2/nuvempago#${ROUTES.TRANSACTION_DETAILS}/${transactionId}`;
      } else {
        return `/admin/nuvempago/dashboard/${transactionId}`;
      }
    }

    return `/admin/v2/nuvempago#${ROUTES.PAYMENT_LINK_DETAILS}/${transactionId}`;
  }, [isRefundPartial, transactionId, type]);

  const goToTransactionDetails = useCallback(
    (transactionId) => {
      if (isRefundPartial) {
        history.push(`${ROUTES.TRANSACTION_DETAILS}/${transactionId}`);
      } else {
        goToOldAdmin(nexo, `nuvempago/dashboard/${transactionId}`);
      }
    },
    [history, isRefundPartial],
  );

  const goToPaymentLinkDetails = useCallback(
    (transactionId) => {
      history.push(`${ROUTES.PAYMENT_LINK_DETAILS}/${transactionId}`);
    },
    [history],
  );

  const redirectTo = useCallback(
    (event: MouseEvent, transactionId: number | string) => {
      // Keep the shortcuts to open link in a new tab/window and open the context menu via key press + click.
      if (event.ctrlKey || event.shiftKey || event.metaKey) return;

      // If no keys were pressed, suppress the click and handle the redirection manually.
      event.preventDefault();
      track(amplitudeTag());
      if (type === OrderType.Checkout) {
        goToTransactionDetails(transactionId);
      } else {
        goToPaymentLinkDetails(transactionId);
      }
    },
    [amplitudeTag, goToPaymentLinkDetails, goToTransactionDetails, type],
  );

  return (
    <Link
      data-testid={`dashboardField-${transactionId}-${isRefundPartial}`}
      appearance="primary"
      as="a"
      onClick={(event: MouseEvent) => redirectTo(event, transactionId)}
      textDecoration="none"
      href={buildAbsoluteStoreURL(detailsPath)}
    >
      {orderNumber ? t(type, { orderNumber: orderNumber }) : '---'}
    </Link>
  );
}

function Skeleton(): JSX.Element {
  return <Text.Skeleton width="100%" height="20px" />;
}

DashboardLinkField.Skeleton = Skeleton;

export default DashboardLinkField;
