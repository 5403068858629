import React from 'react';
import { Box } from '@nimbus-ds/components';
import { Currency } from 'commons/types/Currency.enum';
import { OrderType } from 'commons/types/OrderType.enum';
import ClientNameField from 'domains/Brazil/commons/components/ClientName';
import DateField from 'domains/Brazil/commons/components/DateField';
import MoneyField from 'domains/Brazil/commons/components/MoneyField';
import StatusTag from 'domains/Brazil/commons/components/StatusTag';
import DashboardLinkField from '../../list/Link';

type listItemArgs = {
  type: OrderType;
  transactionId: string;
  orderNumber?: string;
  clientName: string;
  amount: string | number;
  status: string;
  date: string;
  isRefundPartial: boolean;
};
function MobileListItem({
  status,
  amount,
  type,
  date,
  orderNumber,
  transactionId,
  clientName,
  isRefundPartial,
}: listItemArgs) {
  return (
    <Box
      display={'grid'}
      borderWidth={'none'}
      borderTopWidth={'1'}
      borderStyle={'solid'}
      borderColor={'neutral-surfaceHighlight'}
      gap={'2'}
      paddingY={'4'}
      data-testid="mobile-list-item"
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={'row'}
      >
        <DashboardLinkField
          type={type}
          orderNumber={orderNumber}
          transactionId={transactionId}
          status={status}
          isRefundPartial={isRefundPartial}
        />
        <DateField date={date} />
      </Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={'row'}
      >
        <ClientNameField name={clientName} />
        <MoneyField value={amount} currency={Currency.BRL} />
      </Box>
      <Box>
        <StatusTag type={status} />
      </Box>
    </Box>
  );
}

function Skeleton(): JSX.Element {
  return (
    <Box
      display={'grid'}
      borderWidth={'none'}
      borderTopWidth={'1'}
      borderStyle={'solid'}
      borderColor={'neutral-surfaceHighlight'}
      gap={'2'}
      paddingY={'4'}
      data-testid="mobile-list-item-skeleton"
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={'row'}
      >
        <Box width={'30%'}>
          <DashboardLinkField.Skeleton />
        </Box>
        <Box width={'10%'}>
          <DateField.Skeleton />
        </Box>
      </Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={'row'}
        gap={'2'}
      >
        <Box width={'75%'}>
          <ClientNameField.Skeleton />
        </Box>
        <Box width={'25%'}>
          <MoneyField.Skeleton />
        </Box>
      </Box>
      <Box width={'50%'}>
        <StatusTag.Skeleton />
      </Box>
    </Box>
  );
}

MobileListItem.Skeleton = Skeleton;

export default MobileListItem;
