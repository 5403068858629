import React from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Box, Title } from '@nimbus-ds/components';
import { InteractiveList } from '@nimbus-ds/patterns';
import { ROUTES } from 'App/Routes/constants';
import { useStoreInfo } from 'commons/hooks';
import { FutureIncomeDetails } from 'commons/services/futureIncomesService/futureIncomesService';
import { formatCurrency, formatLongDateWithoutYear } from 'commons/utils';

interface IncomeByDateTableProps {
  incomes: FutureIncomeDetails[];
  aRef?: (node?: Element | null) => void;
}

function IncomesByDateTable({
  incomes,
  aRef,
}: IncomeByDateTableProps): JSX.Element {
  const { storeInfo } = useStoreInfo();
  const { push } = useHistory();

  const goToDateDetailsPage = (date: string) => {
    track('pago-nube-futuros-ingresos-agenda-día');
    push(`${ROUTES.FUTURE_INCOME}/${date}`);
  };

  return (
    <>
      {incomes.map((income, index, arr) => (
        <div key={index}>
          <InteractiveList.ButtonItem
            key={index}
            title=""
            showTitle={false}
            iconButton={{ onClick: () => goToDateDetailsPage(income.date) }}
            topDivider={index !== 0}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              ref={index + 1 === arr.length ? aRef : undefined}
            >
              <Title as="h5" fontWeight="regular">
                {formatLongDateWithoutYear(storeInfo, income.date)}
              </Title>
              <Title as="h5" fontWeight="regular">
                {formatCurrency(income.waitingFunds, storeInfo)}
              </Title>
            </Box>
          </InteractiveList.ButtonItem>
        </div>
      ))}
    </>
  );
}

export default IncomesByDateTable;
