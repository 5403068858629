import React from 'react';
import { Alert, Box, Button, Card, Text } from '@nimbus-ds/components';
import AppLayout from 'App/components/AppLayout';
import { BankAccountInfo } from 'commons/components';
import { useTranslationWithPrefix } from 'commons/utils';
import MoneyCard from './MoneyCard';

function TransferSkeleton(): JSX.Element {
  const { t } = useTranslationWithPrefix('transfer');

  return (
    <AppLayout
      backLinkText={t('nuvemshopPanel')}
      title={t('title')}
      subtitle={t('subtitle')}
      footer={<Button.Skeleton />}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="flex-end"
        gap="4"
      >
        <MoneyCard.Skeleton />
        <Alert.Skeleton />
        <Card>
          <Text.Skeleton />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            gap="4"
          >
            <BankAccountInfo.Skeleton />
            <Text.Skeleton />
          </Box>
        </Card>
      </Box>
    </AppLayout>
  );
}

export default TransferSkeleton;
