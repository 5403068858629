import React from 'react';
import { Box, Card, Input, Text } from '@nimbus-ds/components';
import {
  formatCuit,
  getCuitOrCuilLabel,
  useTranslationWithPrefix,
} from 'commons/utils';
import BankAccountInputsSkeleton from './BankAccountInputsSkeleton';

interface BankAccountInputProps {
  isRegistered: boolean;
  fiscalNumber: string;
  beneficiaryName: string;
  cbu: string;
  handleChangeCbu: React.ChangeEventHandler<HTMLInputElement>;
  handleChangeBeneficiaryName: React.ChangeEventHandler<HTMLInputElement>;
  bank: string;
  error: {
    beneficiaryName: string;
    cbu: string;
    bankName: string;
  };
}

function BankAccountInputs({
  fiscalNumber,
  beneficiaryName,
  cbu,
  handleChangeCbu,
  handleChangeBeneficiaryName,
  bank,
  error,
}: BankAccountInputProps) {
  const { t } = useTranslationWithPrefix('bankAccountRegistration');

  return (
    <Card>
      <Box display="flex" flexDirection="column" gap="4">
        <Box display="flex" flexDirection="column" gap="1">
          <Text fontSize="caption">{getCuitOrCuilLabel(fiscalNumber)}</Text>
          <Input
            aria-label={'cuit-input'}
            name={'cuit-input'}
            value={formatCuit(fiscalNumber)}
            disabled
          />
        </Box>
        <Box display="flex" flexDirection="column" gap="1">
          <Text fontSize="caption">{t('beneficiaryName')}</Text>
          <Input
            onChange={handleChangeBeneficiaryName}
            aria-label={'beneficiaryName'}
            name={'beneficiaryName'}
            value={beneficiaryName}
            type="text"
            appearance={error['beneficiaryName'] ? 'danger' : 'neutral'}
          />
          {error['beneficiaryName'] ? (
            <Text fontSize="caption" color="danger-interactive">
              {error['beneficiaryName']}
            </Text>
          ) : null}
        </Box>
        <Box display="flex" flexDirection="column" gap="1">
          <Text fontSize="caption">{t('cbu')}</Text>
          <Input
            onChange={handleChangeCbu}
            aria-label={'cbu'}
            name={'cbu'}
            value={cbu}
            type="number"
            appearance={error['cbu'] ? 'danger' : 'neutral'}
          />
          {error['cbu'] ? (
            <Text fontSize="caption" color="danger-interactive">
              {error['cbu']}
            </Text>
          ) : null}
        </Box>
        {bank && (
          <Box display="flex" flexDirection="column" gap="1">
            <Text fontSize="caption">{t('bank')}</Text>
            <Input
              aria-label={'bank-input'}
              name={'bank-input'}
              value={bank}
              disabled
              appearance={error['bankName'] ? 'danger' : 'neutral'}
              maxLength={45}
            />
            {error['bankName'] ? (
              <Text fontSize="caption" color="danger-interactive">
                {error['bankName']}
              </Text>
            ) : null}
          </Box>
        )}
      </Box>
    </Card>
  );
}
BankAccountInputs.Skeleton = BankAccountInputsSkeleton;
export default BankAccountInputs;
