import React from 'react';
import { Box, Select, Text } from '@nimbus-ds/components';
import { GetAvailiableBanksResponse } from 'commons/services/bankAccountService/bankAccountService';

type SelectAvailableBanksArgs = {
  title: string;
  bank: string | undefined;
  setBank: (value: string) => void;
  availableBanks: GetAvailiableBanksResponse[];
};

function SelectAvailableBanks({
  title,
  bank,
  setBank,
  availableBanks,
}: SelectAvailableBanksArgs): JSX.Element {
  return (
    <Box gap={'1'}>
      <Box paddingBottom={'2'}>
        <Text>{title}</Text>
      </Box>
      <Box>
        <Select
          appearance="neutral"
          id="available-bank-account"
          data-testid="available-banks-select"
          name="available-banks"
          onChange={(event) => setBank(event.target?.value)}
        >
          {availableBanks &&
            availableBanks.map((element) => {
              return (
                <Select.Option
                  key={element.bank}
                  label={`${element.bank} - ${element.bankDescription}`}
                  data-testid={`select-option-${element.bank}`}
                  value={element.bank}
                  defaultValue={bank}
                  selected={element.bank === bank}
                />
              );
            })}
        </Select>
      </Box>
    </Box>
  );
}

export default SelectAvailableBanks;
