import { QueryKey, useInfiniteQuery } from '@tanstack/react-query';
import { PaginatedResponse } from 'commons/types';

export function usePaginatedMobileQuery<ItemType>(
  queryKey: QueryKey,
  queryFunction: ({
    page,
  }: {
    page: number;
  }) => Promise<PaginatedResponse<ItemType>>,
) {
  const query = useInfiniteQuery({
    queryKey: queryKey,
    queryFn: ({ pageParam = 1 }) => queryFunction({ page: pageParam }),
    getNextPageParam: (lastPage) => {
      if (
        lastPage.items.length === lastPage.meta.itemsPerPage &&
        lastPage.meta.totalCount > lastPage.meta.itemsPerPage
      )
        return lastPage.meta.page + 1;
    },
  });

  const data = query.data;
  const allItems = data?.pages.flatMap((page) => page.items) || [];

  const meta = data?.pages[data.pages.length - 1].meta;

  return {
    ...query,
    data: {
      ...data,
      items: allItems,
      meta,
    },
  };
}
