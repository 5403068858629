import React from 'react';
import { Box } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import {
  getRelativeDateFromToday,
  useTranslationWithPrefix,
} from 'commons/utils';

interface DateRangeFilterInterface {
  fromDate: string | undefined;
  setFromDate: React.Dispatch<React.SetStateAction<string | undefined>>;
  toDate: string | undefined;
  setToDate: React.Dispatch<React.SetStateAction<string | undefined>>;
}

type Event = React.ChangeEvent<HTMLInputElement>;

function DateRangeFilter({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}: DateRangeFilterInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('home');

  return (
    <Box
      display="flex"
      flex-direction="column"
      align-items="flex-start"
      gap="2"
      flex="1 0 0"
    >
      <FormField.Input
        label={t('transactionsSearch.fromDate', { date: '' })}
        name="fromDate"
        type="date"
        value={fromDate ?? ''}
        max={getRelativeDateFromToday(0)}
        onChange={({ target }: Event) => setFromDate(target.value || undefined)}
        data-testid="fromDate"
      />
      <FormField.Input
        label={t('transactionsSearch.toDate', { date: '' })}
        name="toDate"
        type="date"
        value={toDate ?? ''}
        max={getRelativeDateFromToday(0)}
        onChange={({ target }: Event) => setToDate(target.value || undefined)}
        data-testid="toDate"
      />
    </Box>
  );
}

export default DateRangeFilter;
