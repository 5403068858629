import React from 'react';
import { Box, Input, Text } from '@nimbus-ds/components';
import { todayDate, useTranslationWithPrefix } from 'commons/utils';

interface CustomRangeSearchProps {
  fromDate: string;
  toDate: string;
  setFromDate: (v: string) => void;
  setToDate: (v: string) => void;
}

function CustomRangeSearch({
  fromDate,
  toDate,
  setFromDate,
  setToDate,
}: CustomRangeSearchProps) {
  const { t } = useTranslationWithPrefix('commons.customRangeSearch');

  return (
    <Box display="flex" flexDirection="row" gap="4">
      <Box display="flex" flexDirection="column" gap="1">
        <Text fontSize="caption">{t('fromDate')}</Text>
        <Input
          aria-label="fromDate"
          name="fromDate"
          type="date"
          value={fromDate}
          onChange={(event) => setFromDate(event.target.value)}
          max={toDate}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="1">
        <Text fontSize="caption">{t('toDate')}</Text>
        <Input
          aria-label="toDate"
          name="toDate"
          type="date"
          value={toDate}
          onChange={(event) => setToDate(event.target.value)}
          max={todayDate()}
        />
      </Box>
    </Box>
  );
}

export default CustomRangeSearch;
