import React, { createContext, useEffect, useState } from 'react';
import { documentsService } from 'commons/services';
import { DocumentsInterface } from 'commons/types';

interface DocumentsContextInterface {
  dnis: DocumentsInterface;
  statute: string;
  loading: boolean;
}

export const DocumentsContext = createContext({} as DocumentsContextInterface);

interface InterfaceDocumentsProvider {
  children: React.ReactNode;
}

function DocumentsProvider({
  children,
}: InterfaceDocumentsProvider): JSX.Element {
  const [documents, setDocuments] = useState({
    dnis: {},
    statute: '',
    loading: true,
  });

  useEffect(() => {
    const fetchDocuments = async () => {
      const response = await documentsService.getDocuments();
      if (response) {
        const companyComposition = response.companyComposition
          ? response.companyComposition
          : '';
        // @ts-expect-error ignore delete
        delete response.companyComposition;
        setDocuments({
          dnis: response,
          statute: companyComposition,
          loading: false,
        });
      } else {
        setDocuments((state) => ({ ...state, loading: false }));
      }
    };
    fetchDocuments();
  }, []);

  return (
    <DocumentsContext.Provider value={documents}>
      {children}
    </DocumentsContext.Provider>
  );
}

export default DocumentsProvider;
