import React from 'react';
import { useHistory } from 'react-router';
import { Alert, Button } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { useIdentityValidation } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';

function BlockedBanner(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslationWithPrefix('home.blockedBanner');
  const identityValidation = useIdentityValidation();
  const storeDisabled = identityValidation.data?.status === 'blocked';

  const redirectToValidateIdentity = () => {
    history.push(ROUTES.IDENTITY_VALIDATION);
  };

  return (
    <Alert title={t('title')} show={storeDisabled} appearance="danger">
      {t('description')}
      <Button appearance="neutral" onClick={redirectToValidateIdentity}>
        {t('validateButton')}
      </Button>
    </Alert>
  );
}

export default BlockedBanner;
