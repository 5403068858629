import React from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import {
  Alert,
  Box,
  Button,
  Link,
  List,
  Modal,
  Text,
} from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { useIsMobile } from 'commons/hooks';
import { useTranslationWithPrefix } from 'commons/utils';

interface OnBoardingModalProps {
  open: boolean;
  onDismiss: () => void;
}

function OnBoardingModal({
  open,
  onDismiss,
}: OnBoardingModalProps): JSX.Element {
  const { t } = useTranslationWithPrefix('configuration.onBoardingModal');
  const history = useHistory();
  const isMobile = useIsMobile();

  const redirectToIdentityValidation = () => {
    track('nuvem-pago-startkyc-click');
    onDismiss();
    history.push(ROUTES.IDENTITY_VALIDATION);
  };

  return (
    <Modal
      open={open}
      onDismiss={onDismiss}
      maxWidth={{ xs: '100%', md: '80%' }}
    >
      <Modal.Header title={t('title')} />
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="6">
        <Alert appearance="neutral" title={t('alertTitle')} show>
          <List>
            <List.Item>{t('firstBullet')}</List.Item>
            <List.Item>
              {t('secondBullet')}
              <Link as="a" href={t('forbiddenIndustriesPage')} target="_blank">
                {t('amongOther')}
              </Link>
            </List.Item>
          </List>
        </Alert>
        <Text>{t('description')}</Text>
        <Box display="flex" justifyContent="flex-end" gap="4">
          <Button onClick={onDismiss} appearance="neutral">
            <Text
              fontSize={isMobile ? 'caption' : 'base'}
              textAlign="center"
              fontWeight="medium"
              color="neutral-textHigh"
            >
              {t('leaveForLater')}
            </Text>
          </Button>
          <Button onClick={redirectToIdentityValidation} appearance="primary">
            <Text
              fontSize={isMobile ? 'caption' : 'base'}
              textAlign="center"
              color="neutral-background"
              fontWeight="medium"
            >
              {t('startValidation')}
            </Text>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default OnBoardingModal;
