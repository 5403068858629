import React from 'react';
import { Card } from '@nimbus-ds/components';
import { useIsMobile } from 'commons/hooks';

interface DesktopCardProps {
  children: React.ReactNode;
}

export default function DesktopCard({
  children,
}: DesktopCardProps): JSX.Element {
  const isMobile = useIsMobile();
  return isMobile ? (
    <>{children}</>
  ) : (
    <Card>
      <Card.Body padding="base">{children}</Card.Body>
    </Card>
  );
}
