export const MIN_REPORT_DAY =
  process.env.REACT_APP_MIN_REPORT_DAY || '2022-02-09';

export const MIN_TRANSACTION_DETAIL_DAY =
  process.env.REACT_APP_MIN_TRANSACTION_DETAIL_DAY || '2022-10-01';

export const KYC_ANALYSIS_DAYS =
  Number(process.env.REACT_APP_KYC_ANALYSIS_DAYS) || 3;

export const KYC_DEFAULT_UPDATED_STATUS =
  process.env.REACT_APP_KYC_DEFAULT_UPDATED_STATUS || '2023-05-24';

export const MAX_DOCUMENT_SIZE = process.env.REACT_APP_MAX_DOCUMENT_SIZE
  ? Number(process.env.REACT_APP_MAX_DOCUMENT_SIZE)
  : 7000000;
