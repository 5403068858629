import React, { useCallback, useState } from 'react';
import { Alert, Box, Button, Input, Modal, Text } from '@nimbus-ds/components';
import { twoFAService } from 'commons/services/twoFAService/twoFAService';
import { useTranslationWithPrefix } from 'commons/utils';

type TwoFAModalProps = {
  open: boolean;
  setModalState: (stateOpen: boolean) => void;
  onSubmitSuccess: () => void;
  onSubmitError: () => void;
  onDismiss: () => void;
  withdrawId?: number;
};

function TwoFAModal({
  open,
  onSubmitSuccess,
  setModalState,
  onDismiss,
  withdrawId,
}: TwoFAModalProps): JSX.Element {
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [showAlert2FA, setShowAlert2FA] = useState(false);
  const { t } = useTranslationWithPrefix('twoFA');

  const closeModal = useCallback(() => {
    setModalState(false);
    onDismiss();
    setShowAlert2FA(false);
  }, [onDismiss, setModalState]);

  const submit2FACode = useCallback(
    (twoFactorCode: string) => {
      twoFAService
        .validateTwoFA(twoFactorCode, withdrawId!)
        .then((isValid) => {
          if (isValid) {
            setShowAlert2FA(false);
            onSubmitSuccess();
          } else {
            setShowAlert2FA(true);
          }
        })
        .catch(() => {
          setShowAlert2FA(true);
        });
    },
    [onSubmitSuccess, withdrawId],
  );

  return (
    <Modal open={open} onDismiss={() => closeModal()}>
      <Modal.Header title={t('title')} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        gap="6"
        data-testid="twoFAModal"
      >
        <Text color="neutral-textHigh" fontSize="base">
          {t('text')}
        </Text>
        <Input
          placeholder="Código"
          onChange={(event) => setTwoFactorCode(event.target.value)}
        />
        <Alert
          show={showAlert2FA}
          appearance="danger"
          onRemove={() => setShowAlert2FA(false)}
        >
          {t('invalidCode')}
        </Alert>
        <Box display="flex" justifyContent="flex-end" gap="4">
          <Button
            onClick={() => submit2FACode(twoFactorCode)}
            appearance="primary"
            disabled={false}
          >
            {t('confirmButton')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default TwoFAModal;
