import React from 'react';
import { Box, Card, Tag, Text, Title } from '@nimbus-ds/components';

function RefundEventCardSkeleton(): JSX.Element {
  return (
    <Card>
      <Card.Header color={'neutral-textHigh'}>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Title.Skeleton as="h4" />
          <Tag.Skeleton />
        </Box>
      </Card.Header>
      <Card.Body>
        <Text.Skeleton />
      </Card.Body>
    </Card>
  );
}

export default RefundEventCardSkeleton;
