import React, { useEffect } from 'react';
import { track } from '@amplitude/analytics-browser';
import { useTranslationWithPrefix } from 'commons/utils';
import OnBoardingPopover from 'domains/Home/HomeMock/OnBoardingPopover';

interface IProps {
  visible: boolean;
  onPopoverCloseClick: React.MouseEventHandler<HTMLElement>;
  children: React.ReactElement;
}

export function FutureIncomesPopover(props: IProps) {
  const { t } = useTranslationWithPrefix('home');

  useEffect(() => {
    if (props.visible) {
      track('pago-nube-futuros-ingresos-tooltip');
    }
  }, [props.visible]);

  return (
    <OnBoardingPopover
      visible={props.visible}
      title={t('futureIncomesPopover.title')}
      description={t('futureIncomesPopover.description')}
      linkText={t('futureIncomesPopover.understood')}
      onLinkClick={props.onPopoverCloseClick}
      position="bottom"
      zIndex={'600'} // Forced to be -100 so if the user opens the Filter Sidebar it's displayed below it
    >
      {props.children}
    </OnBoardingPopover>
  );
}
