import React from 'react';
import { Box, Card, Link, Text, Title } from '@nimbus-ds/components';
import { LoadingCard } from 'commons/components';
import { CustomerInterface } from 'commons/types';
import { formatCuit, useTranslationWithPrefix } from 'commons/utils';

interface InterfaceClient {
  customer?: CustomerInterface;
  skeleton?: boolean;
}

function Client({ customer, skeleton = false }: InterfaceClient): JSX.Element {
  const { t } = useTranslationWithPrefix('transactionDetails.client');

  if (skeleton || !customer) return <LoadingCard withHeaderLabel />;

  return (
    <Card>
      <Card.Header padding="none">
        <Title as="h3">{t('title')}</Title>
      </Card.Header>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap="4"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="2"
          alignItems="flex-start"
        >
          <Text>{customer.name}</Text>
          <Link
            as="a"
            href={`mailto: ${customer.email}`}
            appearance="primary"
            textDecoration="none"
          >
            {customer.email}
          </Link>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="2"
          alignItems="flex-start"
        >
          <Text>{t('document')}</Text>
          <Text>
            {customer.document.length > 9
              ? formatCuit(customer.document)
              : customer.document}
          </Text>
        </Box>
      </Box>
    </Card>
  );
}

export default Client;
