export enum ChargebackStatus {
  Pending = 'pending',
  ResolvedWithoutDispute = 'resolved_without_dispute',
  DisputeSent = 'dispute_sent',
  DisputeRejected = 'dispute_rejected',
  DisputeReceived = 'dispute_received',
  ResolvedMerchant = 'resolved_merchant',
  ResolvedCustomer = 'resolved_customer',
  ResolvedInsurance = 'resolved_insurance',
}

export enum ChargebackReason {
  Fraud = 'fraud',
  Dispute = 'dispute',
}

export type ChargebackType = {
  status: ChargebackStatus;
  deadline: string;
  reason: ChargebackReason;
};

export type ChargebackWithOrderDataType = ChargebackType & {
  orderId: number;
  orderNumber: number;
};
