/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { Box, Spinner } from '@nimbus-ds/components';
import { getStoreInfo, iAmReady } from '@tiendanube/nexo/helpers';
import useStoreInfo from 'commons/hooks/useStoreInfo';
import { merchantService } from 'commons/services';
import { StoreNotFoundError } from 'commons/services/merchantService/merchantService';
import { dataDogLogger } from 'commons/utils/dataDogLogger';
import { IStoreInfo } from 'redux/slices/types';
import saveLanguage from './i18n/saveLanguage';
import { identify } from './fingerprintClient';
import nexo from './nexoClient';

interface ContextWrapperInterface {
  children: React.ReactNode;
}

const ContextWrapper = ({ children }: ContextWrapperInterface): JSX.Element => {
  const { setStoreInfo } = useStoreInfo();
  const [isFetchingStoreInfo, setIsFetchingStoreInfo] = useState(true);
  const handleError = useErrorHandler();

  useEffect(() => {
    const fetchStoreInfo = async () => {
      let storeInfo;
      try {
        storeInfo = await getStoreInfo(nexo);
        saveLanguage(storeInfo.language, storeInfo.country);

        const [activeStore, user] = await Promise.all([
          merchantService.getActiveStore(),
          merchantService.getActiveUser(),
        ]);

        const activeStoreInfo: IStoreInfo = {
          ...storeInfo,
          user,
          active: true,
          owner: activeStore.owner,
          flags: activeStore.flags,
        };
        setStoreInfo(activeStoreInfo);

        // storeInfo.id means Store Id
        dataDogLogger.setUser(storeInfo.id);
        identify(activeStoreInfo);
      } catch (error) {
        if (error instanceof StoreNotFoundError)
          setStoreInfo({
            active: false,
            ...storeInfo,
          });
        else handleError(error);
      }
    };

    const fetchContext = async () => {
      await fetchStoreInfo();
      setIsFetchingStoreInfo(false);
    };

    fetchContext();
  }, [setStoreInfo, setIsFetchingStoreInfo, handleError]);

  if (isFetchingStoreInfo)
    return (
      <Box
        display="flex"
        alignItems="center"
        height="100vh"
        justifyContent="center"
      >
        <Spinner />
      </Box>
    );

  return <Box height="100%">{children}</Box>;
};

export default ContextWrapper;
