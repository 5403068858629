import axios from 'App/axios';
import { MoneyObjectInterface, PaginatedResponse } from 'commons/types';

export type FutureIncomeDetails = {
  date: string;
  waitingFunds: MoneyObjectInterface;
};

export type InstallmentFee = {
  installmentPlan: string;
  fee: MoneyObjectInterface;
};

export type GetFutureIncomesDataType = PaginatedResponse<FutureIncomeDetails>;

export type GetFutureIncomesSummaryDataType = {
  waitingFunds: MoneyObjectInterface;
  grossFunds: MoneyObjectInterface;
  processingFee: MoneyObjectInterface;
  installmentsFees: InstallmentFee[];
  totalTaxes: MoneyObjectInterface;
  updatedAt: string;
};

export type FutureIncomeParams = {
  page?: number;
};

export type GetFutureIncomesType = (
  params?: FutureIncomeParams,
) => Promise<GetFutureIncomesDataType>;

const getFutureIncomes: GetFutureIncomesType = async (
  params?: FutureIncomeParams,
) => {
  const response = await axios.get<GetFutureIncomesDataType>(
    '/admin/settlements',
    { params },
  );

  return response.data;
};

export type GetFutureIncomesSummaryType = (
  date?: string,
) => Promise<GetFutureIncomesSummaryDataType>;

const getFutureIncomesSummary: GetFutureIncomesSummaryType = async (
  date?: string,
) => {
  const response = await axios.get<GetFutureIncomesSummaryDataType>(
    `/admin/settlements-summary${date ? `/${date}` : ''}`,
  );

  return response.data;
};

export type DateFutureIncomeDetails = {
  orderId: number;
  orderNumber: number;
  amount: MoneyObjectInterface;
  netAmount: MoneyObjectInterface;
  customer: string;
  date: string;
};

export type GetDateFutureIncomesDataType =
  PaginatedResponse<DateFutureIncomeDetails>;

export type GetDateFutureIncomesType = (
  date: string,
  params?: FutureIncomeParams,
) => Promise<GetDateFutureIncomesDataType>;

const getDateFutureIncomes: GetDateFutureIncomesType = async (
  date,
  params?: FutureIncomeParams,
) => {
  const response = await axios.get<GetDateFutureIncomesDataType>(
    `/admin/settlements/${date}`,
    { params },
  );

  return response.data;
};

interface futureIncomesServiceInterface {
  getFutureIncomes: GetFutureIncomesType;
  getFutureIncomesSummary: GetFutureIncomesSummaryType;
  getDateFutureIncomes: GetDateFutureIncomesType;
}

const futureIncomesService: futureIncomesServiceInterface = {
  getFutureIncomes,
  getFutureIncomesSummary,
  getDateFutureIncomes,
};

export default futureIncomesService;
