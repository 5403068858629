import React, { useContext, useState } from 'react';
import { Box, Spinner } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { useQuery } from '@tanstack/react-query';
import { ErrorMessage } from 'commons/components';
import { withdrawsService } from 'commons/services';
import { WithdrawObjectInterface } from 'commons/types';
import { useIntersectionObserver } from 'usehooks-ts';
import { FiltersContext } from '../../FiltersContext';
import EmptySearch from '../EmptySearch/EmptySearch';
import { EmptyWithdraws } from '../EmptyWithdraws';
import { WithdrawsFilter } from '../WithdrawsFilter';
import WithdrawsTableMobileRow from './WithdrawsTableMobileRow';

function WithdrawsTableMobile(): JSX.Element | null {
  const [withdraws, setWithdraws] = useState<WithdrawObjectInterface[]>([]);
  const [fetchCountWithoutFilters, setFetchCountWithoutFilters] =
    useState(true);

  const {
    pageNumber,
    setPageNumber,
    fromDateFilter,
    toDateFilter,
    dateRangeFilter,
    clearFilters,
  } = useContext(FiltersContext);
  const withdrawsQuery = useQuery(
    // TODO: look for a way of handling query keys
    ['withdraws', pageNumber, dateRangeFilter, fromDateFilter, toDateFilter],
    () =>
      withdrawsService.getWithdraws(
        pageNumber,
        dateRangeFilter,
        fromDateFilter,
        toDateFilter,
        fetchCountWithoutFilters,
      ),
    {
      onSuccess: (response) => {
        if (pageNumber === 1) {
          setWithdraws([]);
        }
        setWithdraws((oldWithdraws) => [
          ...oldWithdraws,
          ...response.withdraws,
        ]);
        setFetchCountWithoutFilters(false);
      },
      // TODO: remove retry when defined retry method
      retry: false,
    },
  );

  let hasEmptyWithdraws = false;
  let isEmptySearch = false;
  let totalPages = 0;

  const fetchMoreItems = () => {
    if (withdrawsQuery.isLoading || pageNumber >= totalPages) return;
    setPageNumber((pn) => pn + 1);
  };

  const { ref } = useIntersectionObserver({
    onChange: (isIntersecting) => isIntersecting && fetchMoreItems(),
  });

  if (withdrawsQuery.isError) {
    return (
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
        <ErrorMessage />
      </Box>
    );
  }

  if (withdrawsQuery.isSuccess) {
    const withdrawsData = withdrawsQuery.data;
    totalPages = Math.ceil(
      withdrawsData.meta.totalCount / withdrawsData.meta.itemsPerPage,
    );
    hasEmptyWithdraws = withdrawsData.meta.totalCountWithoutFilters === 0;
    isEmptySearch = !withdraws.length;
  }

  if (hasEmptyWithdraws) {
    return <EmptyWithdraws />;
  }

  if (isEmptySearch) {
    return (
      <Box alignItems="stretch" gap="12" display="flex" flexDirection="column">
        <WithdrawsFilter />
        <EmptySearch onClick={clearFilters} />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <WithdrawsFilter />
      <DataList>
        {withdraws.map((withdraw, index, arr) => (
          <WithdrawsTableMobileRow
            key={withdraw.id}
            withdraw={withdraw}
            aRef={index + 1 === arr.length ? ref : undefined}
          />
        ))}
        {withdrawsQuery.isLoading && (
          <Box display="flex" justifyContent="center" paddingY="6">
            <Spinner size="large" />
          </Box>
        )}
      </DataList>
    </Box>
  );
}

export default WithdrawsTableMobile;
