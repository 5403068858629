import React from 'react';
import { Link } from '@nimbus-ds/components';
import { ExclamationTriangleIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { useTranslationWithPrefix } from 'commons/utils';

function ErrorMessage(): JSX.Element {
  const { t } = useTranslationWithPrefix('futureIncome');
  return (
    <EmptyMessage
      title={t('error.title')}
      icon={<ExclamationTriangleIcon size="medium" />}
      actions={
        <Link
          as="a"
          onClick={() => window.location.reload()}
          target="_blank"
          appearance="primary"
          textDecoration="none"
        >
          {t('error.reload')}
        </Link>
      }
    />
  );
}

export default ErrorMessage;
