import { TFunction } from 'react-i18next';
import { WithdrawStatusType } from 'commons/types';
import {
  SettlementStatus,
  WithdrawStatus,
} from 'commons/types/WithdrawStatusType';

type appearanceTypes =
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'neutral'
  | undefined;

interface withdrawTagPropsValue {
  id: string;
  children: string;
  appearance: appearanceTypes;
}

const withdrawTagProps = (
  t: TFunction<string, undefined>,
  statusType: WithdrawStatusType,
  tagKey: string,
): withdrawTagPropsValue => {
  const approvedTag = {
    children: t('withdraws.status.approved'),
    appearance: 'success' as appearanceTypes,
  };

  const rejectedTag = {
    children: t(`withdraws.status.rejected`),
    appearance: 'danger' as appearanceTypes,
  };

  const pendingTag = {
    children: t('withdraws.status.pending'),
    appearance: 'warning' as appearanceTypes,
  };

  const riskAnalysisTag = {
    children: t('withdraws.status.riskAnalysis'),
    appearance: 'warning' as appearanceTypes,
  };

  const settlementTags = {
    [SettlementStatus.Approved]: approvedTag,
    [SettlementStatus.Pending]: pendingTag,
    [SettlementStatus.Rejected]: rejectedTag,
    [SettlementStatus.Reproved]: rejectedTag,
    [SettlementStatus.OnHold]: riskAnalysisTag,
  };

  const withdrawTags = {
    [WithdrawStatus.AntifraudPending]: riskAnalysisTag,
    [WithdrawStatus.AntifraudSuccess]: pendingTag,
    [WithdrawStatus.AntifraudFailed]: rejectedTag,
    [WithdrawStatus.Pending]: pendingTag,
    [WithdrawStatus.Failed]: rejectedTag,
    [WithdrawStatus.Success]: approvedTag,
    [WithdrawStatus.AntifraudOnHold]: riskAnalysisTag,
  };

  const tags = {
    ...settlementTags,
    ...withdrawTags,
  };

  return {
    id: `id-${tagKey}`,
    ...tags[statusType],
  };
};

export default withdrawTagProps;
