import React from 'react';
import { Box, Button, Modal, Text } from '@nimbus-ds/components';
import { useTranslationWithPrefix } from 'commons/utils';

interface ProofOfLifePendingModalProps {
  onDismiss: () => void;
  open: boolean;
  proofOfLifeUrl?: string;
}

function ProofOfLifePendingModal({
  onDismiss,
  open,
  proofOfLifeUrl,
}: ProofOfLifePendingModalProps): JSX.Element {
  const { t } = useTranslationWithPrefix('commons.proofOfLifePendingModal');

  const onUpdate = () => {
    window.open(proofOfLifeUrl, '_blank');
    onDismiss();
  };

  return (
    <Modal
      open={open}
      onDismiss={onDismiss}
      maxWidth={{ xs: '80%', md: '500px' }}
    >
      <Modal.Header title={t('title')} />
      <Box display="flex" flexDirection="column" alignItems="stretch" gap="6">
        <Text color="neutral-textHigh" fontSize="base">
          {t('description')}
        </Text>
        <Box display="flex" justifyContent="flex-end" gap="4">
          <Button onClick={onUpdate} appearance="primary">
            {t('update')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ProofOfLifePendingModal;
