import React from 'react';
import { Box, Button, Icon, Input } from '@nimbus-ds/components';
import { SlidersIcon } from '@nimbus-ds/icons';
import { useTranslationWithPrefix } from 'commons/utils';

function TransactionsFilterMock(): JSX.Element {
  const { t } = useTranslationWithPrefix('home');

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch" gap="4">
      <Box display="flex" gap="1">
        <Input.Search placeholder={t('transactionsSearch.search')} />
        <Button>
          <Icon color="currentColor" source={<SlidersIcon />} />
          {t('transactionsSearch.button')}
        </Button>
      </Box>
    </Box>
  );
}

export default TransactionsFilterMock;
