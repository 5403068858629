import React from 'react';
import { useHistory } from 'react-router';
import { Box, Card, Title } from '@nimbus-ds/components';
import { ChevronRightIcon } from '@nimbus-ds/icons';
import RedirectCardSkeleton from './RedirectCardSkeleton';

interface RedirectCardInterface {
  title: string;
  path: string;
}

function RedirectCard({ title, path }: RedirectCardInterface): JSX.Element {
  const history = useHistory();

  const redirectToInstallments = () => history.push(path);

  return (
    <Card padding="none">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={redirectToInstallments}
        cursor="pointer"
        padding="4"
      >
        <Title as="h4">{title}</Title>
        <Box paddingRight="2">
          <ChevronRightIcon />
        </Box>
      </Box>
    </Card>
  );
}

RedirectCard.Skeleton = RedirectCardSkeleton;

export default RedirectCard;
