import React from 'react';
import { useShouldLinkTo, Views } from './useShouldLinkTo';

export function RestrictVisibility(props: {
  view: Views;
  children?: React.ReactNode;
}) {
  const { shouldLinkTo } = useShouldLinkTo();
  return shouldLinkTo(props.view) ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : null;
}
