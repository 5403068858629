import React from 'react';
import { useHistory } from 'react-router';
import { Alert, Button } from '@nimbus-ds/components';
import { ROUTES } from 'App/Routes/constants';
import { ValidationStatusType } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import { KYC_ANALYSIS_DAYS } from 'commons/utils/constants';
import moment from 'moment';

interface InterfaceIdentityAlert {
  identityStatus: ValidationStatusType;
  updatedAt: string;
}

const IdentityAlert = ({
  identityStatus,
  updatedAt,
}: InterfaceIdentityAlert): JSX.Element | null => {
  const history = useHistory();
  const { t } = useTranslationWithPrefix('configuration.personalData');
  const statusDescription = t(`${identityStatus}.description`, {
    interpolation: { escapeValue: false },
    updatedDate: moment(updatedAt).format('DD/MM'),
    kycAnalysisDays: KYC_ANALYSIS_DAYS,
  });
  const appearanceByStatus = {
    pending: 'warning' as const,
    pending_review: 'primary' as const,
    pending_documentation: 'warning' as const,
    denied: 'danger' as const,
    blocked: 'danger' as const,
  };

  const redirectToValidateIdentity = () => {
    history.push(ROUTES.IDENTITY_VALIDATION);
  };

  const AlertButton = () => {
    switch (identityStatus) {
      case 'pending':
      case 'blocked':
      case 'pending_documentation':
        return (
          <Button appearance="neutral" onClick={redirectToValidateIdentity}>
            {t(`${identityStatus}.validateButton`)}
          </Button>
        );

      default:
        return undefined;
    }
  };

  const alertTitle = () => {
    switch (identityStatus) {
      case 'blocked':
      case 'pending_documentation':
        return t(`${identityStatus}.title`);

      default:
        return undefined;
    }
  };

  if (identityStatus === 'active') return null;

  return (
    <Alert
      show
      appearance={appearanceByStatus[identityStatus]}
      title={alertTitle()}
    >
      {statusDescription}
      {AlertButton()}
    </Alert>
  );
};

export default IdentityAlert;
