import React from 'react';
import { useHistory } from 'react-router';
import { track } from '@amplitude/analytics-browser';
import { Box } from '@nimbus-ds/components';
import { ChevronRightIcon } from '@nimbus-ds/icons';
import { ROUTES } from 'App/Routes/constants';
import { MoneyObjectInterface } from 'commons/types';
import { useTranslationWithPrefix } from 'commons/utils';
import { MoneyCard } from '../MoneyCard';
import {
  FutureIncomesPopover,
  useDiscoveringFutureIncomes,
} from './FutureIncomesPopover';

export function FutureIncomesCard(props: {
  amount: MoneyObjectInterface;
  skeleton: boolean;
  showLoadingDelay: boolean;
  hasError: boolean;
}): JSX.Element {
  const { t } = useTranslationWithPrefix('home');
  const { push } = useHistory();
  const { markFutureIncomesAsDiscovered, isDiscovering } =
    useDiscoveringFutureIncomes();

  const redirectToFutureIncome = () => {
    track('pago-nube-futuros-ingresos-card');
    push(ROUTES.FUTURE_INCOME);
  };

  return (
    <FutureIncomesPopover
      visible={isDiscovering}
      onPopoverCloseClick={markFutureIncomesAsDiscovered}
    >
      <MoneyCard
        title={t('descriptions.moneyToReceive')}
        amount={props.amount}
        skeleton={props.skeleton}
        showLoadingDelay={props.showLoadingDelay}
        loadingText="‎"
        hasError={props.hasError}
        color={'primary'}
        onClick={redirectToFutureIncome}
        primaryButton={
          <Box paddingRight="2">
            <ChevronRightIcon />
          </Box>
        }
      />
    </FutureIncomesPopover>
  );
}
