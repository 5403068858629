import React from 'react';
import { Select } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { useTranslationWithPrefix } from 'commons/utils';
import TypeFilterEnum from 'domains/Brazil/commons/types/TransactionTypeFilterEnum';

const SELECT_OPTIONS = Object.values(TypeFilterEnum);

interface TransactionTypeFilterInterface {
  type: TypeFilterEnum;
  setType: React.Dispatch<React.SetStateAction<TypeFilterEnum>>;
}

function TransactionTypeFilter({
  type,
  setType,
}: TransactionTypeFilterInterface): JSX.Element {
  const { t } = useTranslationWithPrefix('home');
  const options = SELECT_OPTIONS.map((s) => ({
    label: t(`transactionTypes.${s}`),
    value: s,
  }));

  return (
    <FormField.Select
      id="type"
      name="type"
      label={t('transactionsSearch.selectType')}
      value={type}
      onChange={({ target }: any) => setType(target.value)}
      data-testid="typeFilter"
    >
      <React.Fragment>
        {options.map(({ label, value }) => {
          return <Select.Option label={label} value={value} key={value} />;
        })}
      </React.Fragment>
    </FormField.Select>
  );
}

export default TransactionTypeFilter;
